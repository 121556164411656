<template>
  <card>
    <h3 slot="" class="title">Add Deal</h3>
    <div class="Queue">
      <form>
        <div class="row mb-4">
          <div class="col-md-6">
            <base-input type="text" label="Title" placeholder="Title" v-model="user.title">
            </base-input>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-6">
            <base-input type="email" label="Email address" placeholder="email" name="email"
                        v-validate="modelValidations.email" v-model="user.email" :error="getError('email')">
            </base-input>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-6">
            <base-input type="tel" label="Phone" placeholder="phone" name="phone" v-validate="modelValidations.phone"
                        v-model="user.phone" :error="getError('phone')">
            </base-input>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-6 ">
            <base-button native-type="submit" @click.prevent="addlead" type="success" class="btn-fill mr-4">
              Save
            </base-button>
            <base-button type="default" class="btn-fill" @click="$router.go(-1)">
              Back
            </base-button>
          </div>
        </div>
      </form>
    </div>
  </card>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      user: {
        title: '',
        name: '',
        email: '',
        phone: '',
        address: '',
        address_city: '',
        address_country: '',
        address_postal_code: '',
      },
      modelValidations: {

        email: {
          required: true,
          email: true
        },
        phone: {
          required: true
        }
      }
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    addlead() {
      this.$validator.validateAll().then(isValid => {
        if (isValid) {
          var data = {
            "TITLE": this.user.title,
            "terms": [{
              "type_id": "PHONE",
              "value": this.user.phone,
              "value_type": "WORK"
            },
              {
                "type_id": "EMAIL",
                "value": this.user.email,
                "value_type": "WORK"
              }
            ]
          };
          this.$insProgress.start();
          this.$store.dispatch("deal/insert", data).then((response) => {
            this.$router.push('/deals/index');
            this.$insProgress.finish();
            this.$notify({
              timeout: 2000,
              verticalAlign: 'top',
              horizontalAlign: 'right',
              message: 'Your record Added successfully!',
              icon: 'tim-icons icon-check-2',
              type: 'success',
            });
          }).catch((response) => {
            this.$insProgress.finish();
          });
        }
      });
    }
  }
};
</script>

<style scoped>
h3.title {
  font-weight: bold !important;
  color: #f16925 !important;
  font-family: 'Inter';
  font-size: 20px;
  letter-spacing: 2px;
}

.Queue .col-md-6 {
  margin-left: inherit;
  margin-right: inherit;
}
</style>
