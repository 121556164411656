<template>
  <div class="content leads-content deal-main-div">
    <div class=" leads-content" style="">
      <div class="row">
        <div class="col-12 pt-0 leads-col">
          <div class="card" card-body-classes="table-full-width">
            <!---->
            <div class="card-header top-leads responsive-leads">
              <div class="row deals-top-sec">
                <div class="col-12 pt-0 ">
                  <div class="leads_left ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      x="0px"
                      y="0px"
                      viewBox="0 0 32 32"
                      style="enable-background:new 0 0 32 32;"
                      xml:space="preserve"
                    >
                      <g id="deals_heading_svg">
                        <path
                          class="st16"
                          d="M14.3,1.7L1.6,10.9c-0.7,0.5-0.9,1.5-0.4,2.3c0,0,0.1,0.1,0.1,0.1l0,0c0.6,0.7,1.6,1.4,2.3,0.9l11.7-8.3   c0.3-0.3,0.8-0.3,1.1,0l11.7,8.4c0.7,0.5,1.8-0.2,2.4-0.9l0,0c0.6-0.7,0.5-1.7-0.2-2.3c0,0-0.1-0.1-0.1-0.1L17.7,1.7   C16.7,0.8,15.3,0.8,14.3,1.7z"
                        />
                        <polyline
                          class="st16"
                          points="3.9,13.6 3.9,30.9 28.1,30.9 28.1,13.7  "
                        />
                        <path
                          class="st17"
                          d="M17,18.6h-2c-0.9,0.1-1.7-0.6-1.8-1.5c-0.1-0.9,0.6-1.7,1.5-1.8c0.1,0,0.2,0,0.4,0h3.6   c0.4,0.1,0.7-0.2,0.8-0.5c0.1-0.4-0.2-0.7-0.5-0.8c-0.1,0-0.1,0-0.2,0h-2v-1.3c-0.1-0.4-0.4-0.6-0.8-0.5c-0.3,0-0.5,0.3-0.5,0.5   v1.3H15c-1.6,0-3,1.3-3,3s1.3,3,3,3h2c0.9,0,1.7,0.8,1.7,1.7s-0.8,1.7-1.7,1.7h-4.3c-0.4,0.1-0.6,0.4-0.5,0.8   c0,0.3,0.3,0.5,0.5,0.5h2.6v1.3c0.1,0.4,0.4,0.6,0.8,0.5c0.3,0,0.5-0.3,0.5-0.5v-1.3H17c1.6,0,3-1.3,3-3   C19.9,19.9,18.6,18.6,17,18.6L17,18.6z"
                        />
                      </g>
                    </svg>
                    <h4 class="card-title pull-left">Deals</h4>
                  </div>
                  <div class="form-group">
                    <div class="pull-right form-pull">
                      <div class="flex-med">
                        <p v-if="last_updated!== ''"><img src="../../../public/img/refresh.png"/>Refreshed:
                          {{ last_updated }}
                        </p>
                      </div>

                      <div class=" search-input el-input el-input--prefix el-input--suffix">
                        <div class="searchbarb">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                               stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                          </svg>
                        </div>
                        <input
                          type="search"
                          autocomplete="off"
                          placeholder="Search records"
                          @keyup.enter="deal_fetch"
                          @keyup="deal_fetch_debounce"
                          v-model="search"
                          aria-controls="datatables"
                          class="el-input__inner"
                        />
                        <!--                        <span class="el-input__prefix">-->
                        <!--                    <i class="el-input__icon el-icon-search searchbarb"></i>-->
                        <!--                        -->
                        <!--                      </span>-->

                      </div>


                      <!--                      <span class="add-btn-main">-->
                      <!--                        <div class="add-leads-btn ml-2" v-if="false">-->
                      <!--                          <button-->
                      <!--                            type="button"-->
                      <!--                            class=" pull-right btn btn-simple btn-linkedin mt-0 mb-0"-->
                      <!--                            @click="deal_fetch"-->
                      <!--                          >-->
                      <!--                            Search-->
                      <!--                          </button>-->
                      <!--                        </div>-->
                      <!--                      </span>-->
                      <div class="dealFilter inputSvg dealsIndexFilter">
                        <p>
                          <svg xmlns="http://www.w3.org/2000/svg" xmlns: xlink="http://www.w3.org/1999/xlink"
                               version="1.1" id="openDealleads" x="0px" y="0px" viewBox="0 0 32 32" xml:
                               space="preserve">
                            <path
                              d="M3.1,13.8V7.6h25.8v6.1c0,0.5-0.2,0.9-0.5,1.3l-7.2,7.1c-0.2,0.2-0.3,0.4-0.3,0.6v5c0,0.4-0.3,0.7-0.6,0.8  l-8.2,2.2c-0.5,0.1-1.1-0.3-1.1-0.8v-7.1c0-0.2-0.1-0.4-0.2-0.6L3.7,15C3.3,14.7,3.1,14.2,3.1,13.8z"
                              class="st0"></path>
                            <line x1="3.4" y1="11.9" x2="28.6" y2="11.9" class="st0"></line>
                            <circle cx="10.9" cy="2.7" r="1.5" class="st1"></circle>
                            <circle cx="15.9" cy="1.7" r="1.5" class="st1"></circle>
                            <circle cx="20.2" cy="3.8" r="1.5" class="st1"></circle>
                          </svg>
                        </p>

                        <el-select
                          class="select-primary"
                          size="large"
                          placeholder="Pipeline"
                          v-model="status.selected.pipline"
                          @change="pipeline_change"
                        >
                          <el-option
                            class="select-info"
                            value=""
                            label="Pipelines"
                          ></el-option>
                          <el-option
                            v-for="option in status.pipline"
                            class="select-info"
                            :value="option.id"
                            :label="option.NAME"
                            :key="option.id"
                          >
                          </el-option>
                        </el-select>
                      </div>


                      <div class="dealFilter inputSvg dealsIndexFilter" v-if="status.selected.pipline !== ''">
                        <p>


                          <svg xmlns="http://www.w3.org/2000/svg" id="openDealleads" class="colchic" fill="none"
                               viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4"
                                  class="st0"/>
                          </svg>


                        </p>
                        <el-select
                          class="select-primary"
                          size="large"
                          placeholder="Stage"
                          v-model="status.selected.stage"
                          @change="deal_fetch_debounce"
                        >
                          <el-option
                            class="select-info"
                            value=""
                            label="Stages"
                          ></el-option>
                          <el-option
                            v-for="option in selected_stages()"
                            class="select-info"
                            :value="option.id"
                            :label="option.NAME"
                            :key="option.id"
                          >
                          </el-option>
                        </el-select>
                      </div>


                      <div class="add-leads-btn ml-2 " v-if="false">
                        <button
                          type="button"
                          class=" pull-right btn btn-simple btn-linkedin mt-0 mb-0"
                          @click="deal_fetch"
                        >
                          Filter
                        </button>
                      </div>

                      <span>
                        <div
                          class="add-leads-btn add_deals_Button"
                          v-if="user.id === 1"
                        >
                          <router-link
                            :to="{ name: 'Add Deal' }"
                            class="nav-item dropdown-item"
                          ><button
                            type="button"
                            class=" pull-right btn btn-simple btn-linkedin m-0"
                          >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                version="1.1"
                                x="0px"
                                y="0px"
                                viewBox="0 0 32 32"
                                xml:space="preserve"
                              >
                                <g id="add_leads">
                                  <circle
                                    cx="16"
                                    cy="16"
                                    r="14.9"
                                    class="st22"
                                  ></circle>
                                  <line
                                    x1="25.6"
                                    y1="16"
                                    x2="6.4"
                                    y2="16"
                                    class="st22"
                                  ></line>
                                  <line
                                    x1="16"
                                    y1="25.6"
                                    x2="16"
                                    y2="6.4"
                                    class="st22"
                                  ></line>
                                </g>
                              </svg>
                            <!--<i class="fa fa-plus"></i> -->Add Deal
                            </button></router-link
                          >
                        </div>
                      </span>
                    </div>


                    <!---->
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="box-table">
                <div class="Table">
                  <div class="Heading">
                    <div class="Cell">
                      <p>Deal</p>
                    </div>
                    <!-- <div class="Cell">
                                        <p>Email</p>
                                    </div>
                                    <div class="Cell">
                                        <p>Phone</p>
                                    </div> -->
                    <div class="Cell Stage">
                      <p>Stage</p>
                    </div>
                    <div class="Cell">
                      <p>Amount</p>
                    </div>
                    <div class="Cell leads_svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        version="1.1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 32 32"
                        xml:space="preserve"
                        @click="orderby_toggle('created_at')"
                      >
                        <g id="filter_leads">
                          <polyline
                            points="30.9,8.5 16,23.5 1.1,8.5 "
                            class="st4"
                          ></polyline>
                        </g>
                      </svg>
                      <p>
                        Created
                        <!--  <font-awesome-icon style="" :icon="orderby.icon" ></font-awesome-icon>-->
                      </p>
                    </div>
                    <div class="Cell">
                      <p>Responsible</p>
                    </div>
                    <div class="Cell">
                      <p>Actions</p>
                    </div>
                  </div>
                  <template v-if="leads.length > 0">
                    <div class="Row" v-for="deal in leads" :key="deal.id">
                      <div class="Cell">
                        <div class="deals_first_col">
                          <div class="">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              version="1.1"
                              x="0px"
                              y="0px"
                              viewBox="0 0 32 32"
                              style="enable-background:new 0 0 32 32;"
                              xml:space="preserve"
                            >
                              <g id="deals_open">
                                <path
                                  class="st4"
                                  d="M3.8,26.9c-1.5,0-2.8-1.2-2.8-2.8V8c0-1.5,1.3-2.8,2.8-2.8h6.3c1.1,0,2.1,0.8,2.3,1.9l0.1,0.5   c0.1,0.4,0.4,0.7,0.8,0.7h8.3c1.5,0,2.8,1.2,2.8,2.8v2.9"
                                />
                                <path
                                  class="st4"
                                  d="M25.4,25.1c-0.8,1.1-2.1,1.8-3.4,1.8H3.8h0c-2,0-3.1-2.8-1.7-4.6l5-6.5C8,14.7,9.4,14,10.7,14h17.8l0,0   c2,0,3,2.8,1.7,4.5L25.4,25.1z"
                                />
                              </g>
                            </svg>
                          </div>
                          <p>{{ deal.TITLE }}</p>
                        </div>
                      </div>
                      <div class="Cell">
                        <div class="staging_main">
                          <div class="inquiry_svg">
                            <svg
                              v-if="deal.stage"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              viewBox="0 0 32 32"
                              fill="none"
                              stroke="#f16925"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-miterlimit="10"
                              style="enable-background:new 0 0 32 32;"
                              xml:space="preserve"
                            >
                              <g v-if="deal.stage.NAME === 'New Inquiry'">
                                <g>
                                  <path
                                    class="st4"
                                    d="M12.6,19.8c0,0,9-0.6,9,9.1c0,0,0-9.1,9.3-9.1c0,0-9.3,1.1-9.3-9.1C21.6,10.7,22.4,19.8,12.6,19.8"
                                  />
                                  <path
                                    class="st4"
                                    d="M3.8,8.6c0,0,6.5-0.4,6.5,6.6c0,0,0-6.6,6.8-6.6c0,0-6.8,0.8-6.8-6.6C10.3,2,10.8,8.6,3.8,8.6"
                                  />
                                  <path
                                    class="st4"
                                    d="M1.1,24.7c0,0,5.2-0.3,5.2,5.3c0,0,0-5.3,5.4-5.3c0,0-5.4,0.6-5.4-5.3C6.3,19.4,6.7,24.7,1.1,24.7"
                                  />
                                </g>
                              </g>

                              <g
                                v-if="deal.stage.NAME === 'Contact Attempted'"
                                id="connectionattempted_00000107590379365792513880000014066502916078176431_"
                              >
                                <path
                                  d="M16,23.9L16,23.9c-0.4,0-0.8-0.5-0.8-1.2L13.9,1.6c0-0.8,0.3-1.4,0.8-1.4h2.5c0.5,0,0.9,0.6,0.8,1.4l-1.2,21.1C16.8,23.3,16.4,23.9,16,23.9z"
                                  fill="#f16925"
                                />
                                <circle cx="16" cy="29.3" r="2.7"/>
                                <path d="M10.2,7.4c-3.5,0.7-6.7,2.5-9.1,5.2"/>
                                <path d="M10.7,16.7c-1.3,0.3-2.5,1.1-3.4,2.2"/>
                                <path d="M21.8,7.4c3.5,0.7,6.7,2.5,9.1,5.2"/>
                                <path d="M21.3,16.7c1.3,0.3,2.5,1.1,3.4,2.2"/>
                              </g>

                              <g
                                v-if="
                                  deal.stage.NAME === 'Automation Off-Board'
                                "
                              >
                                <g>
                                  <g>
                                    <line
                                      class="st4"
                                      x1="20"
                                      y1="4.9"
                                      x2="20"
                                      y2="1.5"
                                    />
                                    <line
                                      class="st4"
                                      x1="12"
                                      y1="5.1"
                                      x2="12"
                                      y2="1.5"
                                    />
                                    <line
                                      class="st4"
                                      x1="12"
                                      y1="26.9"
                                      x2="12"
                                      y2="30.3"
                                    />
                                    <line
                                      class="st4"
                                      x1="20"
                                      y1="26.6"
                                      x2="20"
                                      y2="30.3"
                                    />
                                  </g>
                                  <g>
                                    <line
                                      class="st4"
                                      x1="1.4"
                                      y1="19.8"
                                      x2="4.7"
                                      y2="19.8"
                                    />
                                    <line
                                      class="st4"
                                      x1="1.1"
                                      y1="11.8"
                                      x2="4.7"
                                      y2="11.8"
                                    />
                                    <line
                                      class="st4"
                                      x1="27.5"
                                      y1="19.8"
                                      x2="30.9"
                                      y2="19.8"
                                    />
                                    <line
                                      class="st4"
                                      x1="27.3"
                                      y1="11.8"
                                      x2="30.9"
                                      y2="11.8"
                                    />
                                  </g>
                                  <rect
                                    x="9.6"
                                    y="9.4"
                                    class="st4"
                                    width="12.9"
                                    height="13.1"
                                  />
                                </g>
                              </g>

                              <g
                                v-if="deal.stage.NAME === 'Contact Established'"
                              >
                                <circle
                                  class="st14"
                                  cx="16"
                                  cy="26.4"
                                  r="2.7"
                                />
                                <path
                                  class="st4"
                                  d="M7.4,16c1.1-1.5,4.7-3.1,8.8-3.1c3.8,0,7,1.7,8.4,3.1"
                                />
                                <path
                                  class="st4"
                                  d="M1.1,9.6C3,7,9.2,4.3,16.4,4.3c6.6,0,12.2,3,14.6,5.3"
                                />
                              </g>

                              <g
                                v-if="deal.stage.NAME === 'Active Prospects'"
                                id="activeprospects"
                              >
                                <circle
                                  class="st4"
                                  cx="10.9"
                                  cy="9.4"
                                  r="6.4"
                                />
                                <circle
                                  class="st4"
                                  cx="24.3"
                                  cy="4.2"
                                  r="3.1"
                                />
                                <path
                                  class="st4"
                                  d="M20.6,13.7c0.5-1.4,1.6-3.3,4.3-3.3c3.3,0,4.3,3.4,4.6,5.2c0.1,0.5,0.2,1,0.3,1.5c0.2,1.2,0,4.2-7.1,4.2"
                                />
                                <path
                                  class="st4"
                                  d="M2.1,30.9l0-0.4c0-2.6,0.7-5.2,2-7.3c1.3-2,3.4-3.7,6.6-3.7c3.2,0,5.4,1.7,6.8,3.7c1.4,2.1,2.1,4.8,2.1,7.5
    v0.1"
                                />
                              </g>

                              <g v-if="deal.stage.NAME === '1003 Requested'">
                                <rect
                                  x="1.1"
                                  y="1.1"
                                  class="st4"
                                  width="12.2"
                                  height="12.2"
                                />
                                <rect
                                  x="18.8"
                                  y="1.1"
                                  class="st4"
                                  width="12.2"
                                  height="12.2"
                                />
                                <rect
                                  x="1.1"
                                  y="18.8"
                                  class="st4"
                                  width="12.2"
                                  height="12.2"
                                />
                                <rect
                                  x="18.8"
                                  y="18.8"
                                  class="st4"
                                  width="12.2"
                                  height="12.2"
                                />
                                <polyline
                                  class="st4"
                                  points="9.9,23.1 6.2,26.8 4.7,25.2 	"
                                />
                              </g>

                              <g v-if="deal.stage.NAME === '1003 Completed'">
                                <rect
                                  x="1.1"
                                  y="1.1"
                                  width="12.2"
                                  height="12.2"
                                />
                                <rect
                                  x="18.8"
                                  y="1.1"
                                  width="12.2"
                                  height="12.2"
                                />
                                <rect
                                  x="1.1"
                                  y="18.8"
                                  width="12.2"
                                  height="12.2"
                                />
                                <rect
                                  x="18.8"
                                  y="18.8"
                                  width="12.2"
                                  height="12.2"
                                />
                                <polyline
                                  points="9.9,23.1 6.2,26.8 4.7,25.2 	"
                                />
                                <polyline
                                  points="27.3,23.1 23.7,26.8 22.1,25.2 	"
                                />
                                <polyline points="9.9,5.4 6.2,9.1 4.7,7.5 	"/>
                                <polyline
                                  points="27.4,5.4 23.7,9.1 22.2,7.5 	"
                                />
                              </g>

                              <g v-if="deal.stage.NAME === 'Credit Run'">
                                <g>
                                  <path
                                    class="st4"
                                    d="M28.5,25.5H9.4c-1.4,0-2.4-1.1-2.4-2.4V8.9c0-1.4,1.1-2.4,2.4-2.4h19.1c1.4,0,2.4,1.1,2.4,2.4v14.2
			C30.9,24.4,29.8,25.5,28.5,25.5z"
                                  />
                                  <line
                                    class="st4"
                                    x1="7.3"
                                    y1="12.4"
                                    x2="30.7"
                                    y2="12.4"
                                  />
                                  <line
                                    class="st4"
                                    x1="3.7"
                                    y1="11.3"
                                    x2="2.3"
                                    y2="11.3"
                                  />
                                  <line
                                    class="st4"
                                    x1="3.8"
                                    y1="16"
                                    x2="1.1"
                                    y2="16"
                                  />
                                  <line
                                    class="st4"
                                    x1="2.6"
                                    y1="20.8"
                                    x2="1.1"
                                    y2="20.8"
                                  />
                                  <circle
                                    class="st14"
                                    cx="27.1"
                                    cy="22.4"
                                    r="1"
                                  />
                                  <circle
                                    class="st14"
                                    cx="25.8"
                                    cy="22.4"
                                    r="1"
                                  />
                                </g>
                              </g>
                              <g v-if="deal.stage.NAME === 'Pre-Approval'">
                                <circle class="st4" cx="16" cy="10" r="4.4"/>
                                <polygon
                                  class="st4"
                                  points="15.9,1.1 11.1,2.5 7.8,6.3 7.1,11.3 9.2,15.8 13.4,18.6 18.5,18.6 22.7,15.8 24.8,11.3 24.1,6.3
		20.8,2.5 	"
                                />
                                <polyline
                                  class="st4"
                                  points="10,17.6 3.3,26.8 7.2,27.7 9.1,30.9 15.7,19.5 	"
                                />
                                <polyline
                                  class="st4"
                                  points="22,17.6 28.7,26.8 24.8,27.7 22.9,30.9 16.3,19.5 	"
                                />
                              </g>
                              <g v-if="deal.stage.NAME === 'Doc Requested'">
                                <polyline
                                  class="st4"
                                  points="24.9,20.8 24.9,30.9 1.1,30.9 1.1,1.1 24.9,1.1 24.9,6.7 	"
                                />
                                <line
                                  class="st4"
                                  x1="6.8"
                                  y1="6.9"
                                  x2="19.1"
                                  y2="6.9"
                                />
                                <line
                                  class="st4"
                                  x1="6.8"
                                  y1="13"
                                  x2="17.7"
                                  y2="13"
                                />
                                <line
                                  class="st4"
                                  x1="6.8"
                                  y1="19.1"
                                  x2="11.1"
                                  y2="19.1"
                                />
                                <line
                                  class="st4"
                                  x1="6.8"
                                  y1="25.1"
                                  x2="11.1"
                                  y2="25.1"
                                />
                                <path
                                  class="st14"
                                  d="M14.9,21v2.1c0,0.2,0.2,0.4,0.4,0.4h2.1c0.4,0,0.5-0.4,0.3-0.7c-0.6-0.6-1.5-1.5-2.1-2.1
		C15.4,20.5,14.9,20.7,14.9,21z"
                                />
                                <path
                                  class="st14"
                                  d="M18.6,22.1l-2.2-2.2c-0.2-0.2-0.2-0.4,0-0.6l8.1-8.1c0.2-0.2,0.4-0.2,0.6,0l2.2,2.2c0.2,0.2,0.2,0.4,0,0.6
		l-8.1,8.1C19,22.3,18.7,22.3,18.6,22.1z"
                                />
                                <path
                                  class="st14"
                                  d="M28.1,12.6l-2.2-2.2c-0.2-0.2-0.2-0.4,0-0.6l1.3-1.3c0.2-0.2,0.4-0.2,0.6,0l2.2,2.2c0.2,0.2,0.2,0.4,0,0.6
		l-1.3,1.3C28.5,12.8,28.2,12.8,28.1,12.6z"
                                />
                              </g>
                              <g v-if="deal.stage.NAME === 'Docs Back'">
                                <polyline
                                  class="st4"
                                  points="12.9,30.9 1.1,30.9 1.1,1.1 24.9,1.1 24.9,9.7 	"
                                />
                                <line
                                  class="st4"
                                  x1="6.8"
                                  y1="6.9"
                                  x2="19.1"
                                  y2="6.9"
                                />
                                <line
                                  class="st4"
                                  x1="6.8"
                                  y1="13"
                                  x2="13.7"
                                  y2="13"
                                />
                                <line
                                  class="st4"
                                  x1="6.8"
                                  y1="19.1"
                                  x2="10.1"
                                  y2="19.1"
                                />
                                <line
                                  class="st4"
                                  x1="6.8"
                                  y1="25.1"
                                  x2="10.1"
                                  y2="25.1"
                                />
                                <circle
                                  class="st4"
                                  cx="22.2"
                                  cy="22.2"
                                  r="8.8"
                                />
                                <polyline
                                  class="st4"
                                  points="25,20.5 20.7,24.8 18.9,22.9 	"
                                />
                              </g>
                              <g v-if="deal.stage.NAME === 'Submitted'">
                                <g>
                                  <polyline
                                    class="st4"
                                    points="7.5,9.6 16,1.1 24.5,9.6 		"
                                  />
                                  <line
                                    class="st4"
                                    x1="16"
                                    y1="1.1"
                                    x2="16"
                                    y2="22.2"
                                  />
                                </g>
                                <polyline
                                  class="st4"
                                  points="24.3,26.8 24.3,30.9 7.3,30.9 7.3,26.8 	"
                                />
                              </g>
                              <g v-if="deal.stage.NAME === 'Archive'">
                                <path
                                  class="st4"
                                  d="M30,16H2c-0.5,0-0.9-0.4-0.9-0.9V6.1c0-0.5,0.4-0.9,0.9-0.9h28c0.5,0,0.9,0.4,0.9,0.9v8.9
		C30.9,15.6,30.5,16,30,16z"
                                />
                                <path
                                  class="st4"
                                  d="M30.9,14.4v11.6c0,0.4-0.4,0.7-0.9,0.7H2c-0.5,0-0.9-0.3-0.9-0.7V14.4"
                                />
                                <polyline
                                  class="st4"
                                  points="23.1,9.6 23.1,11.5 9,11.5 9,9.6 	"
                                />
                                <polyline
                                  class="st4"
                                  points="23.1,20.4 23.1,22.3 9,22.3 9,20.4 	"
                                />
                              </g>
                              <g v-if="deal.stage.NAME === 'Recycled Stage'">
                                <g>
                                  <path
                                    class="st4"
                                    d="M15.8,5.3c-6,0-11,4.2-12.2,9.7"
                                  />
                                  <path
                                    class="st4"
                                    d="M27,23.8c0.9-1.7,1.4-3.7,1.4-5.9c0-4.6-2.5-8.6-6.2-10.8"
                                  />
                                  <path
                                    class="st4"
                                    d="M3.6,20.7c1.3,5.6,6.3,9.8,12.2,9.8c2.7,0,5.2-0.8,7.2-2.3"
                                  />
                                  <polyline
                                    class="st4"
                                    points="14.1,1.5 17.6,5.1 14.1,8.6 		"
                                  />
                                  <polyline
                                    class="st4"
                                    points="30.9,22.6 26.2,24.3 24.6,19.6 		"
                                  />
                                  <polyline
                                    class="st4"
                                    points="1.1,24.4 3.3,19.9 7.7,22.1 		"
                                  />
                                </g>
                              </g>
                              <g v-if="deal.stage.NAME === 'Deal lost'">
                                <path
                                  d="M30.5,12.3l-9.9-1.1c-0.2,0-0.2-0.1-0.3-0.2l-4-9.1c-0.2-0.3-0.6-0.3-0.7,0l-4,9.1c-0.1,0.2-0.2,0.2-0.3,0.2
		l-9.8,1.1c-0.3,0-0.5,0.5-0.2,0.7l7.3,6.6c0.1,0.1,0.2,0.2,0.2,0.4l-2,9.6c-0.1,0.3,0.3,0.6,0.6,0.5l8.6-4.9c0.2-0.1,0.3-0.1,0.4,0
		l8.6,4.9c0.3,0.2,0.7-0.1,0.6-0.5l-2-9.6c0-0.2,0-0.3,0.2-0.4l7.3-6.6C31.1,12.8,30.9,12.3,30.5,12.3z"
                                />
                              </g>
                              <g v-if="deal.stage.NAME === 'Deal won'">
                                <path
                                  fill="#f16925"
                                  d="M30.5,12.3l-9.9-1.1c-0.2,0-0.2-0.1-0.3-0.2l-4-9.1c-0.2-0.3-0.6-0.3-0.7,0l-4,9.1c-0.1,0.2-0.2,0.2-0.3,0.2
		l-9.8,1.1c-0.3,0-0.5,0.5-0.2,0.7l7.3,6.6c0.1,0.1,0.2,0.2,0.2,0.4l-2,9.6c-0.1,0.3,0.3,0.6,0.6,0.5l8.6-4.9c0.2-0.1,0.3-0.1,0.4,0
		l8.6,4.9c0.3,0.2,0.7-0.1,0.6-0.5l-2-9.6c0-0.2,0-0.3,0.2-0.4l7.3-6.6C31.1,12.8,30.9,12.3,30.5,12.3z"
                                />
                              </g>
                            </svg>
                          </div>
                          <div class="Inquiry_context">
                            <div class="inquiry_rating">
                              <svg
                                v-for="stage of deal.stages"
                                :key="stage"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                version="1.1"
                                x="0px"
                                y="0px"
                                viewBox="0 0 15 15"
                                xml:space="preserve"
                              >
                                <g
                                  :id="
                                    parseInt(stage.SORT) <=
                                    parseInt(deal.stage ? deal.stage.SORT : null)
                                      ? 'stage_rate_box1'
                                      : 'stage_rate_box'
                                  "
                                >
                                  <rect
                                    x="1.1"
                                    y="1.1"
                                    width="12.2"
                                    height="12.2"
                                    class="st4"
                                  ></rect>
                                </g>
                              </svg>
                            </div>
                            <p class="inquiry">
                              {{ deal.stage ? deal.stage.NAME : "" }}
                            </p>
                          </div>
                        </div>
                        <!--<p>{{ ( deal.terms.find(t => t.type_id === 'EMAIL') !== undefined ? deal.terms.find(t => t.type_id === 'EMAIL').value : '' ) }}</p>-->
                      </div>
                      <div class="Cell">
                        <!--<p>{{ ( deal.terms.find(t => t.type_id === 'PHONE') !== undefined ? deal.terms.find(t => t.type_id === 'PHONE').value : '' ) }}</p>-->
                        <p>{{ number_format(deal.OPPORTUNITY) }}</p>
                      </div>
                      <div class="Cell">
                        <p>{{  formatter(deal.DATE_CREATE,'h:mm:ss a MM-DD-YYYY') }}</p>
                        <!--<p>
                                            <el-select style="width:auto;padding-top:5px;" class="select-primary ml-2" size="large" placeholder="Stage" v-model="deal.stage.id" @change="dropdown_status_change(deal)">
                                                <el-option v-for="option in deal.stages" class="select-info" :value="option.id" :label="option.NAME" :key="option.id">
                                                </el-option>
                                            </el-select>

                                            <select v-if="deal.stages !== undefined || deal.stages !== null" class="select-box" @change="dropdown_status_change(deal.id)" :data-object-select-id="deal.id">
                                                <option v-for="option in deal.stages" :key="option.id" :value="option.id" v-bind:selected="option.id === (deal.stage ? deal.stage.id : '')">{{ option.NAME }}</option>
                                            </select>

                                        </p>-->
                      </div>
                      <div class="Cell">
                        <!--<p>{{  date_format(deal.DATE_CREATE) }}</p>-->
                        <div class="image_content" v-if="deal.responsible">
                          <img
                            :src="deal.responsible.image !== undefined && deal.responsible.image !== null ? deal.responsible.image :
                          '/img/mike.jpg'">
                          <router-link :to="{ name :'View Agent', params:{id:deal.responsible.id}}">
                            <p>{{ deal.responsible.name }}</p>
                          </router-link>

                        </div>
                      </div>
                      <div class="Cell leads_svg_block ">


                        <div class="deals-button">


                          <div>
                            <button
                              data-v-01e1f50f=""
                              type="button"
                              class="phone btn like btn-link btn-icon btn-fab btn-info btn-sm"
                              v-on:click="toggle_phone(deal)"
                            >
                              <!--<i data-v-01e1f50f="" class="fa fa-phone"></i>-->
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                id="phone_deals"
                                viewBox="0 0 32 32"
                              >
                                <path
                                  class="cls-1"
                                  d="M7.08,2.14h.08a2,2,0,0,1,1.45.74L8.73,3c1.11,1,2.37,2.28,3.58,3.49a2.24,2.24,0,0,1,.74,1.57,2.24,2.24,0,0,1-.74,1.56l-.1.1a4.92,4.92,0,0,0-.35,6.11,12.56,12.56,0,0,0,2,2.27h0a12.25,12.25,0,0,0,1.16,1.16,6,6,0,0,0,4,1.48,6.32,6.32,0,0,0,2.86-.69,2,2,0,0,0,.49-.37,2.24,2.24,0,0,1,1.57-.74,2.21,2.21,0,0,1,1.56.75l3.69,3.69a2,2,0,0,1,0,3l-.8.78-.07.06c-.47.46-.95.92-1.42,1.44a3.94,3.94,0,0,1-3.06,1.18A11.39,11.39,0,0,1,19.93,29,28.49,28.49,0,0,1,9.69,22.26,28,28,0,0,1,3,12.15c-.78-2.16-1.6-5.24.3-7l.1-.09.68-.71c.49-.51,1-1,1.51-1.49l.08-.08a2,2,0,0,1,1.43-.67m0-2A4,4,0,0,0,4.24,1.4c-.56.5-1.09,1-1.62,1.59l-.68.71C-1.17,6.52.53,11.26,1.1,12.83A30.08,30.08,0,0,0,8.3,23.7a30.55,30.55,0,0,0,10.94,7.19,13.29,13.29,0,0,0,4.62,1A6,6,0,0,0,28.39,30c.44-.49.92-.95,1.4-1.42l.82-.79a4,4,0,0,0,0-5.87l-3.7-3.69a4,4,0,0,0-5.95,0,4.17,4.17,0,0,1-1.94.47,4,4,0,0,1-2.65-1,13.06,13.06,0,0,1-1-1,.93.93,0,0,0-.14-.14,11.3,11.3,0,0,1-1.73-1.94,2.93,2.93,0,0,1,.21-3.63v0a4.14,4.14,0,0,0,1.33-3,4.15,4.15,0,0,0-1.33-3c-1.25-1.25-2.49-2.5-3.64-3.55A4,4,0,0,0,7.21.14Z"
                                />
                              </svg>
                            </button>
                          </div>


                          <div>
                            <button
                              data-v-01e1f50f=""
                              type="button"
                              class="btn like btn-link btn-icon btn-fab btn-info btn-sm"
                              v-on:click="toggle_chat(deal)"
                            >
                              <div
                                v-if="deal.notify"
                                class="notification d-none d-lg-block d-xl-block "
                                style="top: 4px; right: 4px;"
                              ></div>
                              <!---<i data-v-01e1f50f="" class="tim-icons icon-chat-33"></i>-->
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                id="sms_lead"
                                viewBox="0 0 32 32"
                              >
                                <path
                                  d="M12.44,21.82H4.81a3.7,3.7,0,0,1-3.7-3.69V6.41a3.7,3.7,0,0,1,3.7-3.7H27.19a3.7,3.7,0,0,1,3.7,3.7V18.13a3.7,3.7,0,0,1-3.7,3.69h0a1.55,1.55,0,0,0-1.55,1.55v3.8a2.13,2.13,0,0,1-3.49,1.63l-7.3-6.1A3.73,3.73,0,0,0,12.44,21.82Z"
                                  class="cls-1"
                                ></path>
                                <circle
                                  cx="10.29"
                                  cy="12.67"
                                  r="1.4"
                                  class="cls-2"
                                ></circle>
                                <circle
                                  cx="16"
                                  cy="12.67"
                                  r="1.4"
                                  class="cls-2"
                                ></circle>
                                <circle
                                  cx="21.71"
                                  cy="12.67"
                                  r="1.4"
                                  class="cls-2"
                                ></circle>
                              </svg>
                            </button>
                          </div>


                          <div>
                            <router-link
                              :to="{ name: 'Edit Deal', params: { id: deal.id } }"
                            >
                              <button
                                data-v-01e1f50f=""
                                type="button"
                                class="btn edit btn-link btn-icon btn-fab btn-warning btn-sm"
                              >
                                <!--<i data-v-01e1f50f="" class="tim-icons icon-pencil"></i>-->
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  version="1.1"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 32 32"
                                  xml:space="preserve"
                                >
                                  <g id="doc_requested_leads">
                                    <polyline
                                      points="24.9,20.8 24.9,30.9 1.1,30.9 1.1,1.1 24.9,1.1 24.9,6.7  "
                                      class="st4"
                                    ></polyline>
                                    <line
                                      x1="6.8"
                                      y1="6.9"
                                      x2="19.1"
                                      y2="6.9"
                                      class="st4"
                                    ></line>
                                    <line
                                      x1="6.8"
                                      y1="13"
                                      x2="17.7"
                                      y2="13"
                                      class="st4"
                                    ></line>
                                    <line
                                      x1="6.8"
                                      y1="19.1"
                                      x2="11.1"
                                      y2="19.1"
                                      class="st4"
                                    ></line>
                                    <line
                                      x1="6.8"
                                      y1="25.1"
                                      x2="11.1"
                                      y2="25.1"
                                      class="st4"
                                    ></line>
                                    <path
                                      d="M14.9,21v2.1c0,0.2,0.2,0.4,0.4,0.4h2.1c0.4,0,0.5-0.4,0.3-0.7c-0.6-0.6-1.5-1.5-2.1-2.1   C15.4,20.5,14.9,20.7,14.9,21z"
                                      class="st14"
                                    ></path>
                                    <path
                                      d="M18.6,22.1l-2.2-2.2c-0.2-0.2-0.2-0.4,0-0.6l8.1-8.1c0.2-0.2,0.4-0.2,0.6,0l2.2,2.2c0.2,0.2,0.2,0.4,0,0.6   l-8.1,8.1C19,22.3,18.7,22.3,18.6,22.1z"
                                      class="st14"
                                    ></path>
                                    <path
                                      d="M28.1,12.6l-2.2-2.2c-0.2-0.2-0.2-0.4,0-0.6l1.3-1.3c0.2-0.2,0.4-0.2,0.6,0l2.2,2.2c0.2,0.2,0.2,0.4,0,0.6   l-1.3,1.3C28.5,12.8,28.2,12.8,28.1,12.6z"
                                      class="st14"
                                    ></path>
                                  </g>
                                </svg>
                              </button
                              >
                            </router-link>
                          </div>


                          <div>
                            <button class="dealsScheduler">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                version="1.1"
                                x="0px"
                                y="0px"
                                viewBox="0 0 32 32"
                                style="enable-background:new 0 0 32 32;"
                                xml:space="preserve"
                              >
                                <g id="scheduler">
                                  <path
                                    class="st4"
                                    d="M6.9,19.7H2.8c-0.9,0-1.7-0.7-1.7-1.7V4.6C1.1,3.7,1.9,3,2.8,3h17.5C21.2,3,22,3.7,22,4.6v3.3"
                                  />
                                  <line
                                    class="st4"
                                    x1="8"
                                    y1="1.1"
                                    x2="7.9"
                                    y2="4.8"
                                  />
                                  <line
                                    class="st4"
                                    x1="14.8"
                                    y1="1.1"
                                    x2="14.7"
                                    y2="4.8"
                                  />
                                  <circle
                                    class="st4"
                                    cx="21.1"
                                    cy="21.4"
                                    r="9.7"
                                  />
                                  <polyline
                                    class="st4"
                                    points="21.1,16 21.1,21.4 26.2,21.4  "
                                  />
                                </g>
                              </svg>
                            </button>
                          </div>


                          <router-link :to="{ name :'View Deal', params:{id:deal.id}}">
                            <button data-v-01e1f50f="" type="button"
                                    class="btn edit btn-link btn-icon btn-fab btn-warning btn-sm">
                              <i data-v-01e1f50f="" class="fas fa-eye"></i>
                            </button>
                          </router-link>

                        </div>

                        <!--<button data-v-01e1f50f="" type="button" class="btn remove btn-link btn-icon btn-fab btn-danger btn-sm" @click="deal_delete(deal.id)">
                           <i data-v-01e1f50f="" class="tim-icons icon-simple-remove"></i>
                        </button>
                        <router-link :to="{ name :'Record Deal', params:{id:deal.id}}"><button data-v-01e1f50f="" type="button" class="btn edit btn-link btn-icon btn-fab btn-warning btn-sm">
                            <i data-v-01e1f50f="" class="tim-icons icon-triangle-right-17"></i>
                        </button></router-link>-->

                      </div>
                    </div>
                  </template>
                </div>
                <div
                  class="el-table__column-resize-proxy"
                  style="display: none"
                ></div>
                <div class="card-footer">
                  <div class="row">
                    <div class="col-12 flex-cust">
                      <p class="card-category" style="float:left;">
                        Showing {{ result.from }} to {{ result.to }} of
                        {{ result.total }} entries
                      </p>
                      <pagination
                        style="float:right;"
                        :data="result"
                        @pagination-change-page="deal_fetch"
                        :limit="1"
                      >
                        <span slot="prev-nav">&lt; Previous</span>
                        <span slot="next-nav">Next &gt;</span>
                      </pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <phonebox :object="lead_phone" :type="'deal'"></phonebox>
    <chatbox :object="lead_chat" :type="'deal'"></chatbox>
  </div>
</template>

<script>
// import { RingLoader } from 'vue-spinners-css';
import moment from "moment";
import swal from "sweetalert2";
import config from "@/config";
import {TimeSelect, DatePicker, Select, Option} from "element-ui";
import phonebox from "src/components/leads/phonebox";
import chatbox from "../../components/leads/chatbox";

const debounce = {
  interval: null,
  wait: 500,
  func: () => {
  },
  __: function (f, w) {
    if (this.interval) {
      clearTimeout(this.interval);
    }
    this.wait = w;
    this.func = f;
    this.interval = setTimeout(debounce.func, debounce.wait);
  }
};
export default {
  components: {
    phonebox,
    chatbox,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  data() {
    return {
      leads: [],
      result: {},
      orderby: {
        field: "created_at",
        direction: "DESC",
        icon: ["fas", "sort-amount-up"],
      },
      status: {
        pipline: [],
        selected: {
          pipline: "",
          stage: "",
        },
        simple: "",
        name: [],
        orig: [],
      },
      type: [
        {
          value: "lead",
          title: "Lead",
        },
        {
          value: "deal",
          title: "Deal",
        },
      ],
      channel: {},
      lead_phone: {
        lead: {},
        show: false,
      },
      lead_chat: {
        lead: {},
        show: false,
      },
      show: false,
      display: false,
      sync_loader: true,
      user: {},
      search: "",
      last_updated: '',
    };
  },
  methods: {
    sync() {
      this.$insProgress.start();
      this.$store.dispatch("lead/sync").then((response) => {
        this.$insProgress.finish();
        this.sync_loader = false;
      });
    },
    dropdown_status_change(deal) {
      if (deal.stage !== undefined && deal.stage !== null) {
        if (deal.stage.id) {
          this.$insProgress.start();
          this.$store
            .dispatch("deal/status_change", {
              id: deal.id,
              status_id: deal.stage.id,
            })
            .then((response) => {
              this.$insProgress.finish();
            });
        }
      }
      /*
      let select = document.querySelector("[data-object-select-id='"+id+"']")
      let status_id = select.selectedOptions[0].value;
      //console.log({
          id: id,
          status_id : status_id
      });

      this.$insProgress.start();
      this.$store.dispatch("deal/status_change",{
          id : id,
          status_id : status_id
      }).then((response)=>{
          this.$insProgress.finish();
      });
      */
    },
    orderby_toggle(field) {
      this.orderby.field = field;
      //console.log(this.orderby);

      if (this.orderby.direction === "DESC") {
        this.orderby.icon = ["fas", "sort-amount-down-alt"];
        this.orderby.direction = "ASC";
      } else {
        this.orderby.icon = ["fas", "sort-amount-up"];
        this.orderby.direction = "DESC";
      }
      this.deal_fetch();
    },
    status_fetch() {
      this.$insProgress.start();
      this.$store
        .dispatch("general/fetch_status")
        .then((response) => {
          //console.log(response);

          this.status.orig = response.data.data;
          this.$insProgress.finish();
          this.status_update();
        })
        .catch((e) => {
          this.$insProgress.finish();
        });
    },
    pipeline_change() {
      this.status.selected.stage = "";
      this.deal_fetch_debounce();
    },
    status_update() {
      let type = "deal";
      this.status.name = [];
      for (var x = 0; x < this.status.orig.length; x++) {
        if (type === "lead") {
          if (this.status.orig[x].ENTITY_ID === "STATUS") {
            this.status.name.push(this.status.orig[x]);
          }
        }
        if (type === "deal") {
          if (this.status.orig[x].ENTITY_ID.includes("DEAL_STAGE")) {
            this.status.name.push(this.status.orig[x]);
          }
        }
      }
      const filteredArr = this.status.name.reduce((acc, current) => {
        const x = acc.find((item) => item.NAME === current.NAME);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      this.status.name = filteredArr;
      //console.log(this.status.name);
    },
    deal_fetch_debounce() {
      debounce.__(this.deal_fetch, 1000);
    },
    deal_fetch(page = 1) {
      this.$insProgress.start();

      var data = {};
      if (this.search !== undefined && this.search !== "") {
        data = {
          page: page,
          search: this.search,
          field: this.orderby.field,
          direction: this.orderby.direction,
        };
        if (this.status.selected.pipline !== "") {
          data = {
            page: page,
            search: this.search,
            filter: true,
            status: this.status.selected,
            field: this.orderby.field,
            direction: this.orderby.direction,
          };
        }
      } else {
        if (this.status.selected.pipline !== "") {
          data = {
            page: page,
            filter: true,
            status: this.status.selected,
            field: this.orderby.field,
            direction: this.orderby.direction,
          };
        } else {
          data = {
            page: page,
            field: this.orderby.field,
            direction: this.orderby.direction,
          };
        }
      }

      this.$store
        .dispatch("deal/fetch", data)
        .then((response) => {
          //console.log(response.data.data);
          /*
      var leads = response.data.data.data;
      for(var x = 0; x< leads.length ;x++){
          leads[x].STAGE_ID = leads[x].STAGE_ID.split(":")[1];
      }
      */
          this.leads = response.data.data.data.map((d) => {
            if (d.stage === undefined || d.stage === null) {
              d.stage = {
                id: null,
              };
            }
            return d;
          });
          /*
      this.leads = this.leads.map((value, index) => {
          var lead = value;
          lead['notify'] = false;
          return lead;
      });
      */
          //console.log(this.leads);
          this.result = response.data.data;
          this.$insProgress.finish();
        })
        .catch((response) => {
          this.$insProgress.finish();
        });
    },
    toggle_phone(lead) {
      //console.log(lead);
      if (!this.lead_phone.show) {
        this.lead_phone.lead = lead;
        this.lead_phone.show = true;
      } else {
        if (this.lead_phone.lead.id === lead.id) {
          this.lead_phone.lead = {};
          this.lead_phone.show = false;
        } else {
          this.lead_phone.lead = lead;
          this.lead_phone.show = true;
        }
      }
    },
    toggle_chat(lead) {
      //console.log(lead);
      if (!this.lead_chat.show) {
        var ind = this.leads.findIndex((l) => l.id === lead.id);
        this.leads[ind]["notify"] = false;
        var title = this.leads[ind]["TITLE"];
        this.leads[ind]["TITLE"] = " ";
        this.leads[ind]["TITLE"] = title;
        this.$store.dispatch("deal/mark", this.leads[ind]["id"]);
        this.lead_chat.lead = lead;
        this.lead_chat.show = true;
      } else {
        if (this.lead_chat.lead.id === lead.id) {
          this.lead_chat.lead = {};
          this.lead_chat.show = false;
        } else {
          var ind = this.leads.findIndex((l) => l.id === lead.id);
          this.leads[ind]["notify"] = false;
          var title = this.leads[ind]["TITLE"];
          this.leads[ind]["TITLE"] = " ";
          this.leads[ind]["TITLE"] = title;
          this.$store.dispatch("deal/mark", this.leads[ind]["id"]);
          this.lead_chat.lead = lead;
          this.lead_chat.show = true;
        }
      }
    },

    conference_start(lead) {
      //console.log(lead);
      this.$store.dispatch("deal/conference_start", lead.id).then((response) => {
        //console.log(response);
      });
    },

    deal_delete(id) {
      swal({
        title: "Are you sure?",
        text: `You won't be able to revert this!`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Yes, delete it!",
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            this.$insProgress.start();
            this.$store
              .dispatch("deal/delete", id)
              .then((response) => {
                this.$insProgress.start();
                this.deal_fetch();
                this.$insProgress.finish();
                this.$notify({
                  timeout: 2000,
                  verticalAlign: "top",
                  horizontalAlign: "right",
                  message: "Your record deleted successfully!",
                  icon: "tim-icons icon-check-2",
                  type: "success",
                });
              })
              .catch((e) => {
                this.$insProgress.finish();
              });
          }
        })
        .catch((e) => {
          this.$insProgress.finish();
        });
    },
    recieved_message(data) {
      //console.log("index wali sarkar");
      //console.log(data);
      if (data.object.id !== undefined) {
        if (data.object.parent_type === "deal") {
          var ind = this.leads.findIndex((lead) => lead.id === data.object.id);
          //console.log(ind);
          //console.log("agya", ind);
          if (ind !== undefined && ind !== -1) {
            this.leads[ind]["notify"] = true;
            var title = this.leads[ind]["TITLE"];
            this.leads[ind]["TITLE"] = " ";
            this.leads[ind]["TITLE"] = title;
          }
          //console.log(this.leads);
        }
      }
    },
    formatter(payload,pattern){
      return moment(payload).format(pattern)
    },
    number_format(number) {
      return new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(number);
    },
    date_format(date_string) {
      const date = new Date(date_string);
      return date.toLocaleTimeString("en-US") + " " + (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1).toString() : date.getMonth() + 1) + "-" + (date.getDate() + 1 < 10 ? "0" + (date.getDate() + 1).toString() : date.getDate() + 1) + "-" + date.getFullYear();
    },
    date_format_2(date_string) {
      const date = new Date(date_string);
      return date.toLocaleTimeString(['en-US'], {hour: '2-digit', minute: '2-digit'})  //if you want, you can change locale date string
    },
    fetch_pipelines() {
      this.$store.dispatch("deal/fetch_pipelines").then((response) => {
        if (response.data.data !== undefined) {
          //console.log({PIPELINES:response.data.data});
          this.status.pipline = response.data.data;
        }
      });
    },
    selected_stages() {
      if (this.status.selected.pipline !== "") {
        let index = this.status.pipline.findIndex((p) => p.id === this.status.selected.pipline);
        return this.status.pipline[index].stages;
      }
      return [];
    },
    get_lastupdate_time() {
      this.$store
        .dispatch("deal/get_last_deal_time")
        .then((response) => {
          this.last_updated = this.formatter(response.data.data,'h:mm a');
        })
    },

  },
  created() {
    this.user = this.$store.getters["api/user"];
    this.channel = this.$pusher.subscribe("MessageEvent");
    this.channel.bind("received", this.recieved_message);
    this.get_lastupdate_time();
    this.deal_fetch();
    this.status_fetch();
    this.fetch_pipelines();
  },
};
</script>

<style scoped>


.el-select.select-primary.el-select--large {
  padding: 0px 10px;
  width: 200px;
  border: 1px solid #245482;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  height: 100%;
  border-left: 0px;
}

.dealFilter .select-primary input[placeholder="Pipeline"] {
  background: transparent !important;
}


@media screen and (max-width: 991px) {
  .deal-main-div .el-input__inner {
    width: 100% !important;
  }

  .search-input {
    margin-bottom: 15px;
    width: 100% !important;
  }
}

.leads-content .select-primary.el-select .el-input input {
  background-color: transparent !important;
  width: 180px;
  padding-left: 10px !important;
  border: none !important;
}

.leads-content .select-primary .el-tag,
.select-primary .el-tag.el-tag--info {
  background-color: transparent;
  background: linear-gradient(0deg, #ff6491 0%, #ff8d72 100%);
}

</style>

<style scoped>

.inputSvg p {
  width: 49px !important;
}

.Cell.leads_svg svg {
  width: 12px !important;
  position: absolute;
  left: -12px;
  cursor: pointer;
  top: 12px;
}

.select-box {
  -webkit-appearance: none;
  border: 2px solid #c3c3c3;
  padding: 7px 5px;
  margin: 5px 0px;
  position: relative;
  color: #8e8d8d;
  width: 100%;
  background-image: url("https://cdn.iconscout.com/icon/free/png-256/down-arrow-16-460295.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 98% center;
}

.select-icon {
  background: #c3c3c3;
  height: 39px;
  width: 18px;
  padding: 0px 4px;
  margin-top: 5px;
  color: white;
  position: absolute;
}

.filter-sec {
  padding-left: 10px;
  color: #a1a3ae !important;
  font-size: 0.75rem;
  background-color: transparent;
  border: none;
  width: 100%;
  height: 40px;
  text-align: left;
}

.searchbarb {
  background-color: #245482;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  width: 55px;
  height: 100%;
  font-size: 20px;
  color: #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchbarb svg {
  width: 24px;
  height: 24px;
}

.el-input__prefix {
  left: 0 !important;

}


.colchic {
  color: #fff;
}

.deal-main-div .el-input__inner {
  border-color: #124678 !important;
  width: 250px;
  height: 100%;
}

.inputSvg svg {
  width: 24px;
  height: 24px;
}

.el-select.select-primary.el-select--large {
  height: 100%;
}

.dealFilter {
  height: 42px;
}


.search-input {
  display: flex;
  align-items: center;
  height: 42px;
  width: 250px;
}

.search-input input {
  padding: 10px !important;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

</style>
