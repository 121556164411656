<template>
<card>


    <div class="Queue">

        <form>
            <div class="row mt-5">
              <div class="col-12 col-md-6">
                  <h3 slot="header" class="title">Lead Fields </h3>
              </div>
              <div class="col-12 col-md-6 d-flex align-items-center justify-content-end">
                  <h4><i class="tim-icons icon-refresh-02"  @click="sync()"></i></h4>
              </div>
              <div class="col-sm-12 mt-4">

                    <div class="main-boxs" v-for="item in lead">
                        <input type="checkbox" :id="'checkbox_' + item.id.toString()" :value="item.label" :checked="item.value === 'visible' ? 'checked':''" @change='update_field(item.id)'> <span class="checkbox-label"> {{item.label}} </span> <br>
                    </div>
                </div>
            </div>

        </form>
    </div>

    <div class="Queue">
        <form>
            <div class="row">
                <div class="col-sm-12">
                    <h3 class="title">Deal Fields </h3>
                    <div class="main-boxs" v-for="item in deal">
                        <input type="checkbox" :id="'checkbox_' + item.id.toString()" :value="item.label" :checked="item.value === 'visible' ? 'checked':''" @change='update_field(item.id)'> <span class="checkbox-label"> {{item.label}} </span> <br>
                    </div>

                </div>
            </div>

        </form>
        <div class="row">
            <div class="col-md-12 pt-4 text-left ml-3">
                <base-button type="default" class="btn-fill" @click="$router.go(-1)">
                    Back
                </base-button>
            </div>
        </div>
    </div>
</card>
</template>

<script>
import swal from "sweetalert2";
import config from "@/config";
import Multiselect from 'vue-multiselect'
import {
    TimeSelect,
    DatePicker,
    Select,
    Option
} from 'element-ui';
export default {
    components: {
        [Select.name]: Select,
        [Option.name]: Option,

        Multiselect,
    },
    data() {
        return {
            data: {},
            lead: [],
            deal: [],
        };
    },
    methods: {
        lead_field_fetch() {
            this.$insProgress.start();
            this.$store.dispatch("setting/lead_field_fetch").then((response) => {
                //console.log(response);
                this.lead = response.data.data;
                this.$insProgress.finish();
            }).catch((e) => {
                this.$insProgress.finish();
            });
        },
        deal_field_fetch() {
            this.$insProgress.start();
            this.$store.dispatch("setting/deal_field_fetch").then((response) => {
                //console.log({'deal': response});
                this.deal = response.data.data;
                this.$insProgress.finish();
            }).catch((e) => {
                this.$insProgress.finish();
            });
        },
        update_field(id) {
            //console.log(id);
            var check = document.getElementById('checkbox_' + id.toString());
            //console.log({"check": check});
            if (check.checked === true) {
                this.$insProgress.start();
                this.$store.dispatch("setting/update_to_visible", {
                    id: id
                }).then((response) => {

                    this.$insProgress.finish();
                }).catch((e) => {
                    this.$insProgress.finish();
                });
            } else {
                this.$insProgress.start();
                this.$store.dispatch("setting/update_to_none", {
                    id: id
                }).then((response) => {
                    //console.log({'status': response});
                    this.$insProgress.finish();
                }).catch((e) => {
                    this.$insProgress.finish();
                });
            }

        },
        sync() {
            this.$insProgress.start();
            this.$store.dispatch("setting/sync").then((response) => {
                this.$insProgress.finish();
            });
        },
    },
    created() {
        this.$insProgress.start();
        this.lead_field_fetch();
        this.deal_field_fetch();
    },
};
</script>

<style>
@media only screen and (max-width: 600px) {
    .Queue .main-boxs {
        width: 33% !important;
    }
}

@media only screen and (max-width: 480px) {
    .Queue .main-boxs {
        width: 50% !important;
    }

}

@media only screen and (max-width: 370px) {
    .Queue .main-boxs {
        width: 100% !important;
    }

}

.Queue .main-boxs {
    width: 25%;
    float: left;
    padding-left: 20px;
    margin-bottom: 10px;
}

.Queue {
    margin-bottom: 50px;
    display: block;
    width: 100%;
}

.card label {
    margin-left: 7px;
}

.check-box-lable {
    margin-bottom: 10px;
    margin-top: 10px;
}

.check-box-lable input {
    display: block;
    float: left;
    margin-top: 5px;
}

.Queue .el-select {
    width: 100%;
    margin-bottom: 10px;
}

lable {
    font-size: 0.75rem;
    margin-bottom: 5px;
}

.Queue .select-primary.el-select .el-input input {
    border-color: rgba(29, 37, 59, 0.5);
    color: #222a42;
    border-radius: 0.4285rem;
}

.Queue div.select-primary.el-select .el-input:hover input {
    border-color: rgba(29, 37, 59, 0.5) !important;
    color: #222a42 !important;
}

.Queue .select-primary .el-tag,
.select-primary .el-tag.el-tag--info {
    background-color: transparent;
    background: linear-gradient(0deg, #ff6491 0%, #ff8d72 100%);
}



.Queue #phonechallbox .el-input__prefix,
.el-input__suffix {
    top: 0 !important;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected.select-primary {
    color: #fff !important;
}


.alert.open.alert-with-icon.top.right.alert-primary {
    background: linear-gradient(0deg, #ff6491 0%, #ff8d72 100%);
}

h3.title {
  font-weight: bold !important;
  color: #f16925 !important;
  font-family: 'Inter';
  font-size: 20px;
  padding-top: 0px;
  letter-spacing: 2px;
}
i.tim-icons.icon-refresh-02 {
  margin: 0px;
  margin-left: auto;
  width: 100px;
  cursor: pointer;
  display: block;
  text-align: center;
  padding: 8px 30px;
  font-size: 20px;
  color: white;
  border-radius: 8px;
  background-color: #124678 !important;
}
</style>
