<template>
<card>
    <h3 slot="" class="title">Add Agent</h3>
    <form>
        <div class="row mb-4">
            <div class="col-md-6">
                <base-input type="text" label="FullName" placeholder="FullName" v-model="agent.name">
                </base-input>
            </div>
            <div class="col-md-6">
                <base-input type="text" label="UserName" placeholder="UserName" v-model="agent.username">
                </base-input>
            </div>
        </div>

        <div class="row mb-4">
            <div class="col-md-6">
                <base-input type="email" label="Email address" placeholder="email" name="email" v-validate="modelValidations.email" v-model="agent.email" :error="getError('email')">
                </base-input>
            </div>
            <div class="col-md-6">
                <base-input type="password" label="Password" placeholder="Password" name="password" v-validate="modelValidations.password" v-model="agent.password" :error="getError('password')">
                </base-input>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-md-6">
                <base-input type="text" label="Cell" placeholder="Cell" name="phone"  v-model="agent.phone">
                </base-input>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Assigned Number</label>
                <el-select class="select-primary " size="large" placeholder="Select" v-model="agent.twillio">
                    <el-option v-for="option in t_numbers.numbers" class="select-primary" :value="option.number" :label="option.number" :key="option.number">
                    </el-option>
                </el-select>
            </div>
            </div>
        </div>
      <div class="mb-4 mt-2">
        <base-button native-type="submit" @click.prevent="addagent" type="success" class="btn-fill">
            Save
        </base-button>
        <base-button type="default" class="btn-fill" @click="$router.go(-1)">
            Back
        </base-button>
      </div>
    </form>
</card>
</template>

<script>
import {
    TimeSelect,
    DatePicker,
    Select,
    Option
} from 'element-ui';
export default {
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
    },
    data() {
        return {
            agent: {
                name: '',
                username: '',
                email: '',
                password: '',
                c_password: '',
                number: '',
                phone : ''
            },
            t_numbers: {
                simple: '',
                numbers: [],
            },
            modelValidations: {

                email: {
                    required: true,
                    email: true
                },
                phone: {
                    required: true
                }
            }
        };
    },
    methods: {
        getError(fieldName) {
            return this.errors.first(fieldName);
        },
        addagent() {
            this.$validator.validateAll().then(isValid => {
                if (isValid) {
                    var data = {
                        "name": this.agent.name,
                        "username": this.agent.username,
                        "email": this.agent.email,
                        "password": this.agent.password,
                        "c_password": this.agent.password,
                        "twillio": this.agent.twillio,
                        "phone": this.agent.phone,
                    };
                    this.$insProgress.start();
                    this.$store.dispatch("agent/insert", data).then((response) => {
                        //console.log(response);
                        this.$router.push('/agents/index');
                        this.$insProgress.finish();
                        this.$notify({
                            timeout: 2000,
                            verticalAlign: 'top',
                            horizontalAlign: 'right',
                            message: 'Your record Added successfully!',
                            icon: 'tim-icons icon-check-2',
                            type: 'success',
                        });
                    });
                }
            });
        },
        twillio_nbr_fetch() {
            this.$insProgress.start();
            this.$store.dispatch("general/twillio_numbers").then((response) => {
                //console.log(response);
                this.t_numbers.numbers = response.data.data;
                this.$insProgress.finish();
            }).catch((e) => {
                this.$insProgress.finish();
            });
        },
    },
    created() {
        this.twillio_nbr_fetch();
    },
};
</script>

<style>
h3.title {
  font-weight: bold !important;
  color: #f16925 !important;
  font-family: 'Inter';
  padding-top: 40px;
  font-size: 20px;
  letter-spacing: 2px;
}
.el-select.select-primary.el-select--large input{
        color: #222a42;
    border-color: rgba(29, 37, 59, 0.5);
    border-radius: 0.4285rem;
  border: 1px solid rgba(29, 37, 59, 0.3);
}
.el-input.el-input--large.el-input--suffix:hover{
  color: #222a42 !important;
  border-color: rgba(29, 37, 59, 0.3) !important;
  border-radius: 0.4285rem !important;
}
input.el-input__inner {
  background-color: white !important;
  border: 1px solid #000 !important;
}
button.btn.btn-fill.btn-success {
  margin-right: 25px;
}
#phonechallbox .el-input__prefix, .el-input__suffix {
  top: 0px !important;
}
div.select-primary.el-select .el-input:hover input::placeholder {
  color: #b2b2b2  ;
}
div.select-primary.el-select .el-input:hover input {
  border-color:  #b2b2b2  !important;
}
.select-primary.el-select input {
  border-color: #b2b2b2  !important;
}
</style>
