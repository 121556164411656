<template>

  <div class="notifications-section" style="height: 460px">
    <div class="call-log-detail" v-for="log of logs.collection" :key="log.id">
      <!--outgoin call-->
      <div class="outMain">
        <div class="outMainInner">
          <div class="outMedia">
            <p class="">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
                   y="0px" viewBox="0 0 32 32" style="enable-background: new 0 0 32 32;
                          width: 24px;height: 24px;" xml:space="preserve">
                          <g id="phone_x5F_notreached" class="st0" v-if="false">
                              <path class="st1"
                                    d="M18.6,19.5c0.9-0.9,1.9-1.1,2.5-1.1c0.6,0,1.6,0.2,2.5,1.1l3.1,3.1c1.4,1.4,1.4,3.5,0,4.9L26,28.2   c-0.4,0.4-0.8,0.8-1.2,1.2c-1,1-2.2,1.5-3.8,1.5c-1.1,0-2.4-0.3-3.9-0.8c-3.2-1.2-6.4-3.3-9.2-6c-2.7-2.6-4.8-5.8-6-9.1   c-0.5-1.3-1.9-5.3,0.7-7.7l0.6-0.6c0.4-0.5,0.9-0.9,1.4-1.3c0.9-0.9,1.8-1.1,2.5-1.1c0.9,0,1.7,0.4,2.4,1.2c1,0.9,2,1.9,3.1,3   c0.9,0.9,1.1,1.9,1.1,2.5c0,0.6-0.2,1.6-1.1,2.5l0,0c-0.8,0.9-0.8,2.1-0.2,3c0.6,0.8,1.2,1.4,1.5,1.6c0,0,0.1,0.1,0.1,0.1   c0.2,0.2,0.4,0.5,0.8,0.8C15.8,20,17.4,20.1,18.6,19.5L18.6,19.5z"/>
                            <line class="st1" x1="31" y1="1" x2="19.2" y2="12.8"/>
                            <line class="st1" x1="19.2" y1="1" x2="31" y2="12.8"/>
                          </g>
                <g id="phone_x5F_incoming" class="st0" v-if="log.direction === 'inbound'">
                              <path class="st1"
                                    d="M18.6,19.5c0.9-0.9,1.9-1.1,2.5-1.1c0.6,0,1.6,0.2,2.5,1.1l3.1,3.1c1.4,1.4,1.4,3.5,0,4.9L26,28.2   c-0.4,0.4-0.8,0.8-1.2,1.2c-1,1-2.2,1.5-3.8,1.5c-1.1,0-2.4-0.3-3.9-0.8c-3.2-1.2-6.4-3.3-9.2-6c-2.7-2.6-4.8-5.8-6-9.1   c-0.5-1.3-1.9-5.3,0.7-7.7l0.6-0.6c0.4-0.5,0.9-0.9,1.4-1.3c0.9-0.9,1.8-1.1,2.5-1.1c0.9,0,1.7,0.4,2.4,1.2c1,0.9,2,1.9,3.1,3   c0.9,0.9,1.1,1.9,1.1,2.5c0,0.6-0.2,1.6-1.1,2.5l0,0c-0.8,0.9-0.8,2.1-0.2,3c0.6,0.8,1.2,1.4,1.5,1.6c0,0,0.1,0.1,0.1,0.1   c0.2,0.2,0.4,0.5,0.8,0.8C15.8,20,17.4,20.1,18.6,19.5L18.6,19.5z"/>
                  <g class="st3">
                                  <polyline class="st4" points="24,1 19.2,5.8 24,10.5   "/>
                    <line class="st4" x1="19.2" y1="5.8" x2="31" y2="5.8"/>
                              </g>
                          </g>
                <g id="phone_x5F_outgoing" v-if="log.direction === 'outbound'">
                              <path class="st4"
                                    d="M18.6,19.5c0.9-0.9,1.9-1.1,2.5-1.1c0.6,0,1.6,0.2,2.5,1.1l3.1,3.1c1.4,1.4,1.4,3.5,0,4.9L26,28.2   c-0.4,0.4-0.8,0.8-1.2,1.2c-1,1-2.2,1.5-3.8,1.5c-1.1,0-2.4-0.3-3.9-0.8c-3.2-1.2-6.4-3.3-9.2-6c-2.7-2.6-4.8-5.8-6-9.1   c-0.5-1.3-1.9-5.3,0.7-7.7l0.6-0.6c0.4-0.5,0.9-0.9,1.4-1.3c0.9-0.9,1.8-1.1,2.5-1.1c0.9,0,1.7,0.4,2.4,1.2c1,0.9,2,1.9,3.1,3   c0.9,0.9,1.1,1.9,1.1,2.5c0,0.6-0.2,1.6-1.1,2.5l0,0c-0.8,0.9-0.8,2.1-0.2,3c0.6,0.8,1.2,1.4,1.5,1.6c0,0,0.1,0.1,0.1,0.1   c0.2,0.2,0.4,0.5,0.8,0.8C15.8,20,17.4,20.1,18.6,19.5L18.6,19.5z"/>
                  <g>
                                  <polyline class="st4" points="26.2,1 31,5.8 26.2,10.5   "/>
                    <line class="st4" x1="31" y1="5.8" x2="19.2" y2="5.8"/>
                              </g>
                          </g>
              </svg>
            </p>
          </div>

          <div class="outContent">
            <h2>
              <span class="call-log-status">{{
                  log.direction === "outbound" ? "Outgoing Call" : "Incoming Call"
                }}</span>:
              <span class="call-log-name">{{ object.TITLE }}</span>
            </h2>
            <div class="audioMain" v-if="log.record_url !== '' ">
              <vue-audio :file="log.record_url"></vue-audio>
            </div>
            <div class="audioMain" v-if="log.record_url === '' ">
              <span> No Recording </span>
            </div>
          </div>
        </div>
        <div class="outUser">
          <div class="outUserMedia">
            <img src="/img/mike.jpg">
          </div>
          <div class="outUserContent">
            <p>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                   stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
              </svg>
              <span>{{ date_format(log.updated_at) }} |</span> <span class="userBlue">SUCCESS</span></p>
          </div>
        </div>
      </div>
    </div>
    <infinite-loading :identifier="logs.identifier" direction="bottom"
                      ref="infiniteLoading" @infinite="infiniteHandler">
    </infinite-loading>
  </div>

</template>
<script>
import InfiniteLoading from "vue-infinite-loading";
import VueAudio from "../../components/leads/VueAudio";

export default {
  name: "dial-pad",
  components: {
    VueAudio,
    InfiniteLoading,
  },
  props: {
    object: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    }
  },
  watch: {
    object: {
      handler(newValue, oldValue) {
        this.user = {};
        this.logs = {
          collection: [],
          page: 1,
          identifier: +new Date(),
        };
      },
      deep: true
    }
  },
  data() {
    return {
      user: {},
      logs: {
        collection: [],
        page: 1,
        identifier: +new Date(),
      }
    };
  },
  mounted() {
    this.init();
  },
  created(){
    if(this.$refs.InfiniteLoading){
      this.$refs.InfiniteLoading.stateChanger.reset();
    }
  },
  methods: {
    init() {
      this.user = this.$store.getters["api/user"];
    },
    fetch() {
      this.$insProgress.start();
      var data = {};
      data.parent_id = this.object.id;
      data.parent_type = this.type;
      data.page = this.logs.page;
      this.$store.dispatch("combined_notification/get_calls", data).then((response) => {
        console.log("aaa == " + response.data.data);
        this.$insProgress.finish();
        let logs = response.data.data;
        if (logs.length > 0) {
          this.logs.collection.push(...logs);
          this.logs.page++;
          this.logs.identifier = +new Date();
          this.$refs.infiniteLoading.$emit('$InfiniteLoading:loaded')
        }
        else {
          this.$refs.infiniteLoading.$emit('$InfiniteLoading:complete')
        }
      }).catch((e) => {
        this.$insProgress.finish();
      });
    },
    toggle_state(name) {
      this.state[name] = !this.state[name];
    },
    infiniteHandler($state) {
      this.fetch();
      return true;
    },
    date_format(date_string) {
      const date = new Date(date_string);
      return date.toLocaleTimeString("en-US") + " " + (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1).toString() : date.getMonth() + 1) + "-" + (date.getDate() + 1 < 10 ? "0" + (date.getDate() + 1).toString() : date.getDate() + 1) + "-" + date.getFullYear();
    },
  },
};
</script>
<style scoped>
.voice-txt {
  width: 50%;
}

.voice-cal {
  width: 50%;
}

.voice-call-header {
  display: flex;
  gap: 8px;
  align-items: center;
}

.top-cal {
  padding-top: 0rem;
  padding-bottom: 0rem;
  position: relative;
  min-height: auto;
  overflow: hidden;
  justify-content: center;
  display: block;
  flex-direction: column;
}

.top-cal h4.font-call {
  color: #bbbcc4;
  font-weight: 500;
  font-size: 15px;
  margin: 0;
}

.voice-call-header input {
  border: 1px dashed #124678;
  width: 40%;
  float: right;
}

.voice-call-header input:focus {
  outline: none;
}

.under-cal {
  max-width: 100%;
  padding-left: 0rem;
  padding-right: 0rem;
  margin-left: auto;
  margin-right: auto;
  /* box-shadow: 0 20px 25px -5px rgb(0 0 0 / 10%), 0 10px 10px -5px rgb(0 0 0 / 4%); */
  position: relative;
}

.main-cal {
  padding-top: 6px;
  padding-bottom: 1.5rem;
  overflow: hidden;
  border-radius: 0.5rem;
}

.cal-input {
  border-radius: 1rem;
  padding-left: 0rem;
  padding-right: 0rem;
}

.cal-input input {
  background-color: #b4f2ff;
  color: #2a4365;
  letter-spacing: 0.1em;
  text-align: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: 700;
  font-size: 1.5rem;
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  border-radius: 0.5rem;
  border-color: transparent;
  width: 100%;
  border: none !important;
  outline: none;
}

.cal-input input:focus {
  border: none;
  border-color: transparent !important;
}

.cal-grid {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 0.5rem;
  gap: 0.5rem;
  text-align: center;
  font-size: 1.25rem;
  display: grid;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.cal-grid .contain-grid {
  cursor: pointer;
  border-width: 1px;
  border-radius: 0.375rem;
  border: 1px solid #e2e8f0;
  font-size: 1.25rem;
  text-align: center;
}

.cal-grid .contain-grid .cont {
  padding-top: 0.25rem;
  font-weight: 600;
  font-size: 1.125rem;
  margin: 0;
}

.cal-grid .contain-grid .cont-text {
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0;
}

.cal-grid .contain-grid:hover {
  background-color: #ed8936;
  color: #fff;
}

.cal-footer {
  padding-left: 0rem;
  padding-right: 0rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 0.75rem;
  column-gap: 0.75rem;
  text-align: center;
  font-size: 1.5rem;
  display: grid;
}

.cal-footer .left-btn {
  grid-column: span 2 / span 2;
}

.cal-footer .left-btn button svg {
  width: 1.5rem;
  height: 1.5rem;
  color: #fff;
}

.cal-footer .left-btn button {
  background-color: #ed8936;
  border-radius: 0.375rem;
  width: 100%;
  border: none;
  padding: 11px;
  cursor: pointer;
}

.cal-footer .left-btn p {
  color: #a9aec0;
  font-size: 1rem;
  margin: 0;
  font-weight: 500;
  text-transform: uppercase;
}

.cal-footer .left-btn:last-child button {
  background-color: #edf2f7;
}

.cal-footer .left-btn:last-child svg {
  color: #a0aec0;
}

.font-set {
  margin: 0;
  color: #b5b6bf;
}

.cal-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #f16925;
  float: left;
  margin: 0;
}

.call-Log-main .circle-svg {
  width: 6px;
  height: 6px;
}

.call-Log-main h2 {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.call-Log-main h2 p {
  color: #bbbcc4;
  font-weight: 500;
  font-size: 15px;
  margin: 0;
}

.call-Log-main .call-log-detail {
  display: flex;
  gap: 20px;
  align-items: center;
  border: 1px solid #e2e8f0;
  padding: 5px;
  background-color: white;
  border-radius: 6px;
  margin: 10px 0px;
}

.call-Log-main .call-log-detail svg {
  width: 24px;
  height: 24px;
}

.call-Log-main .time-details {
  color: #a0a0a0;
  font-size: 11px;
}

.call-log-name {
  color: #ed8936;
}

.call-log-status {
  color: #124678;
}

.call-Log-content p {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
}

.call-log-detail .st1 {
  display: inline;
  fill: none;
  stroke: #ed8936;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.call-log-detail .st4 {
  fill: none;
  stroke: #ed8936;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
</style>
