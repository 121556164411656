<template>
  <div class="vue-message">
    <div class="content">
      <div class="content">
        <div class="header text-center">
            <i @click="open_participant_mbl" id="arrow_back" class="fa fa-arrow-left" aria-hidden="true"></i>
          <h3 class="title">Conversation</h3>
        </div>
        <div class="row">
          <div class="col-md-4" id="participants">
		        <div class="mb-3 search-input el-input el-input--prefix el-input--suffix">
              <input type="text" v-model="pagination.participant.search" @keyup.enter="search_participants" name="dealsearch" autocomplete="off" placeholder="Search records" aria-controls="datatables" class="el-input__inner"><span class="el-input__prefix"><i class="el-input__icon el-icon-search"></i></span></div>
            <div class="message-content">
              <div v-for="participant in participants" :key="participant['id']" class="card">
                <div :class="'card-body ' + ( participant['select'] ? 'msg-body' : '' ) ">
                  <div class="timeline-heading">
                    <span>
                      <span :class="'badge badge-pill ' + ( participant['type'] === 'Deal' ? ' badge-success' : 'badge-info') ">{{participant['type']}}</span>
                      <div v-if="participant['notify']" class="notification d-none d-lg-block d-xl-block " style="right:unset; display:block !important;"></div>
                    </span>
                    <span v-if="participant['call'] !== undefined" class="badge badge-pill badge-success" style="background-color : grey">{{participant['call_status']}}</span>
                  </div>
                  <div class="sider-bar-msgs">
                    <h4 class="pull-left" @click="mount_conversations(participant)">
                      {{participant['title']}}
                      <p>{{participant['email']}}</p>
                      <p>{{participant['phone']}}</p>
                    </h4>
                    <div class="pull-right">
                      <button v-if="participant['phone'] !== ''"
                        type="button"
                        @click="call_toggler(participant)"
                        class="phone btn like btn-link btn-icon btn-fab btn-info btn-sm"
                      >
                        <font-awesome-icon :icon="participant['call_class']" :flip="participant['flip']" size="lg" :style="participant['call_style']" @mouseover="call_button_handler(participant)" @mouseout="call_button_out(participant)"/>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <infinite-loading :identifier="pagination.participant.identifier" direction="down" @infinite="infinite_participant" spinner="waveDots">
                <div slot="no-more"></div>
                <div slot="no-results"></div>
              </infinite-loading>
            </div>
          </div>
          <div class="col-md-8" id="conversations">

            <div class="message" id="conversation_body">
              <div class="card card-timeline card-plain">
                <!----><!---->
                <div class="card-body">
                  <ul class="timeline">
                     <infinite-loading :identifier="pagination.conversation.identifier" direction="top" @infinite="infinite_conversation" spinner="waveDots">
                       <div slot="no-more"></div>
                       <div slot="no-results"></div>
                     </infinite-loading>
                    <li v-for="conversation in conversations" :key="conversation['id']" :class="(conversation['direction'] === 'outbound' || conversation['notes'] !== undefined  ? 'bg-color' : '')">
                      <div :class="'timeline-badge ' + (conversation['type'] === 'message' ? 'info' : 'success')">

                        <i v-if="conversation['notes'] === undefined" :class="(conversation['text'] !== undefined && conversation['notes'] === undefined ? 'fa fa-comment' : 'fa fa-phone')"></i>
                        <i v-if="conversation['notes'] !== undefined" :class="(conversation['notes'] !== undefined ? 'fa fa-comment-alt' : '')"></i>
                      </div>
                      <div class="timeline-panel">
                        <div class="timeline-body">
                          <p v-if="conversation['text'] !== undefined">
                             {{conversation['text']}}
                          </p>
                          <p v-if="conversation['notes'] !== undefined">
                             {{conversation['notes']}}
                          </p>
                          <span v-if="conversation['text'] === undefined && conversation['notes'] === undefined">
                             <p v-if="conversation['record_url'] === ''">
                                The call may be ended before it started.
                              </p>
                          </span>
                          <hr v-if="conversation['text'] === undefined" />
                          <div class="timeline-footer" v-if="conversation['text'] === undefined && conversation['notes'] === undefined" >
                            <vue-audio :file="conversation['record_url']"></vue-audio>
                          </div>
                          <div class="timeline-footer">
                            <p v-if="conversation['notes'] !== undefined">

                            </p>
                          </div>
                          <div :class="'time ' + (conversation['direction'] === 'inbound' ? 'dark-time': '' )">{{date_format(conversation['updated_at'])}}</div>
                        </div>
                        <!---->
                      </div>
                    </li>
                  </ul>
                </div>
                <!----><!---->
              </div>
            </div>
            <div data-v-4951b576="" class="vac-room-footer">
              <!----><!---->
              <div data-v-4951b576="" class="vac-box-footer">
                <!---->
                <div data-v-4951b576="" class="vac-icon-textarea-left">
                  <div data-v-4951b576="" class="vac-svg-button" >
                    <font-awesome-icon v-if="speech_loading" :icon="['fas', 'circle-notch']" :spin="true" size="lg"/>
                    <svg
                      v-if="!speech_loading"
                      @mousedown="start_record"
                      @mouseup="stop_record"
                      v-on:pointerup="touch_stop_record"
                      v-on:pointerdown="touch_start_record"
                      :style=recorder.style
                      data-v-4951b576=""
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      width="24"
                      height="24"
                      viewBox="0 0 512 512"
                      class="vac-icon-microphone"
                    >
                      <path
                        id="vac-icon-microphone"
                        d="M432.8,216.4v39.2c0,45.2-15.3,84.3-45.2,118.4c-29.8,33.2-67.3,52.8-111.6,57.9v40.9h78.4c5.1,0,10.2,1.7,13.6,6c4.3,4.3,6,8.5,6,13.6c0,5.1-1.7,10.2-6,13.6c-4.3,4.3-8.5,6-13.6,6H157.6c-5.1,0-10.2-1.7-13.6-6c-4.3-4.3-6-8.5-6-13.6c0-5.1,1.7-10.2,6-13.6c4.3-4.3,8.5-6,13.6-6H236v-40.9c-44.3-5.1-81.8-23.9-111.6-57.9s-45.2-73.3-45.2-118.4v-39.2c0-5.1,1.7-10.2,6-13.6c4.3-4.3,8.5-6,13.6-6s10.2,1.7,13.6,6c4.3,4.3,6,8.5,6,13.6v39.2c0,37.5,13.6,70.7,40,97.1s59.6,40,97.1,40s70.7-13.6,97.1-40c26.4-26.4,40-59.6,40-97.1v-39.2c0-5.1,1.7-10.2,6-13.6c4.3-4.3,8.5-6,13.6-6c5.1,0,10.2,1.7,13.6,6C430.2,206.2,432.8,211.3,432.8,216.4z M353.5,98v157.6c0,27.3-9.4,50.3-29,69c-19.6,19.6-42.6,29-69,29s-50.3-9.4-69-29c-19.6-19.6-29-42.6-29-69V98c0-27.3,9.4-50.3,29-69c19.6-19.6,42.6-29,69-29s50.3,9.4,69,29C344.2,47.7,353.5,71.6,353.5,98z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <textarea
                  recorded="false"
                  id="message_text"
                  v-on:keyup.enter="send"
                  @keyup="on_text_empty()"
                  data-v-4951b576=""
                  placeholder="Type message"
                  class="vac-textarea"
                  style="min-height: 20px; padding-left: 12px; height: 20px;"
                ></textarea>
                <div data-v-4951b576="" class="vac-icon-textarea">
                  <!----><input
                    data-v-4951b576=""
                    type="file"
                    accept="*"
                    style="display: none;"
                  />
                  <div
                    data-v-4951b576=""
                    @click="send"
                    class="vac-svg-button vac-send-disabled send-send"
                  >
				            <img src="img/Conversation-msg.png"/>
                    <font-awesome-icon v-if="message_recorded" :icon="['fas', 'comment-alt']" size="lg"/>
                    <!-- <svg -->
                      <!-- data-v-4951b576="" -->
                      <!-- xmlns="http://www.w3.org/2000/svg" -->
                      <!-- xmlns:xlink="http://www.w3.org/1999/xlink" -->
                      <!-- version="1.1" -->
                      <!-- width="24" -->
                      <!-- height="24" -->
                      <!-- viewBox="0 0 24 24" -->
                    <!-- > -->
                      <!-- <path -->
                        <!-- id="vac-icon-send-disabled" -->
                        <!-- d="M2,21L23,12L2,3V10L17,12L2,14V21Z" -->
                      <!-- ></path> -->
                    <!-- </svg> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { RingLoader } from 'vue-spinners-css';

import swal from "sweetalert2";
import config from "@/config";
import { TimeSelect, DatePicker, Select, Option } from "element-ui";
import VueAudio from "../../components/leads/VueAudio";
import phonebox from "src/components/leads/phonebox";
import chatbox from "../../components/leads/chatbox";
import InfiniteLoading from 'vue-infinite-loading';
import Recorder from 'recorder-js';
import { GoogleSpeechRecognition } from 'google-cloud-speech-webaudio';
var toWav = require('audiobuffer-to-wav');
import { WaveFile } from 'wavefile';

export default {
  components: {
    phonebox,
    chatbox,
    VueAudio,
    InfiniteLoading
  },
  data() {
    return {
      recorder : {
        style : 'fill:black;',
        status : false,
        pause : false,
        blob: null,
        audioContext : new (window.AudioContext || window.webkitAudioContext)(),
      },
      message_recorded : false,
      route_load : false,
      participants: [],
      conversations: [],
      current_call : {},
      calls : [],
      pagination: {
        conversation : {
          page : 0,
          participant : {},
          identifier : new Date()
        },
        participant  : {
          page : 0,
          search : '',
          identifier : new Date()
        }
      },
      channel : {
        sms  : {},
        call : {}
      },
      copied_recognition : null,
      speechRecognition : null,
      speech_loading : false
    };
  },
  methods: {
    on_text_empty(){
      if(document.getElementById('message_text').value === ""){
        this.message_recorded = false;
        document.getElementById('message_text').setAttribute('recorded','false');
      }
    },
    float32ToInt16(f){
      const multiplier = f < 0 ? 0x8000 : 0x7fff; // 16-bit signed range is -32768 to 32767
      return f * multiplier;
    },
    combineBuffers (a, b) {
      const result = new Uint8Array(a.byteLength + b.byteLength);
      result.set(new Uint8Array(a), 0);
      result.set(new Uint8Array(b), a.byteLength);
      return result;
    },
    init(){


        this.channel.sms = this.$pusher.subscribe('MessageEvent');
        this.channel.sms.bind('received', this.sms_event );

        this.channel.call = this.$pusher.subscribe('CallStatus');
        this.channel.call.bind('status', this.call_event );
        this.channel.call.bind('recorded', this.call_record );

        const GOOGLE_API_KEY = 'AIzaSyBpTNkBQvd04slotbEE0CD_fbTsbHmaZXg';
        this.speechRecognition = new GoogleSpeechRecognition(GOOGLE_API_KEY);
    },
    async touch_start_record(){
      var isTouchDevice = 'ontouchstart' in document.documentElement;
      if(isTouchDevice){
        //console.log("LISTENING ...");
        this.recorder.style = "fill:red;";
        await this.speechRecognition.startListening();
        this.recorder.status = true;
        this.message_recorded = true;
        document.getElementById('message_text').setAttribute("recorded",'true');
      }
    },
    async touch_stop_record(){
      var isTouchDevice = 'ontouchstart' in document.documentElement;
      if(isTouchDevice){
        if(this.recorder.status){
          //console.log("TRANSCRIBING ...");
          this.recorder.status = false;
          //console.log({SPEECH:this.speechRecognition});
          this.speech_loading = true;
          const result = await this.speechRecognition.stopListening();
          this.speech_loading = false;
          if(result.results){
            if (result.results.length > 0){
              if(result.results[0].alternatives){
                if(result.results[0].alternatives.length > 0){
                  document.getElementById("message_text").value += ' ' + result.results[0].alternatives[0].transcript;

                }
              }
            }
          }
          //console.log(result);
          const GOOGLE_API_KEY = 'AIzaSyBpTNkBQvd04slotbEE0CD_fbTsbHmaZXg';
          //console.log('STOP CALLED');
          this.recorder.style = "fill:black;";
          this.copied_recognition = this.speechRecognition;
          //this.speechRecognition = new GoogleSpeechRecognition(GOOGLE_API_KEY);
        }
      }
    },
    async start_record(){
      var isTouchDevice = 'ontouchstart' in document.documentElement;
      if(!isTouchDevice){
        //console.log("LISTENING ...");
        this.recorder.style = "fill:red;";
        await this.speechRecognition.startListening();
        this.recorder.status = true;
        this.message_recorded = true;
        document.getElementById('message_text').setAttribute("recorded",'true');
      }
    },
    async stop_record(){
      var isTouchDevice = 'ontouchstart' in document.documentElement;
      if(!isTouchDevice){
        if(this.recorder.status){
          //console.log("TRANSCRIBING ...");
          this.recorder.status = false;
          //console.log({SPEECH:this.speechRecognition});
          this.speech_loading = true;
          const result = await this.speechRecognition.stopListening();
          this.speech_loading = false;
          if(result.results){
            if (result.results.length > 0){
              if(result.results[0].alternatives){
                if(result.results[0].alternatives.length > 0){
                  document.getElementById("message_text").value += ' ' + result.results[0].alternatives[0].transcript;

                }
              }
            }
          }
          //console.log(result);
          const GOOGLE_API_KEY = 'AIzaSyBpTNkBQvd04slotbEE0CD_fbTsbHmaZXg';
          //console.log('STOP CALLED');
          this.recorder.style = "fill:black;";
          this.copied_recognition = this.speechRecognition;
          //this.speechRecognition = new GoogleSpeechRecognition(GOOGLE_API_KEY);
        }
      }
    },
    record_upload(){
      //this.recorder.object.download(this.recorder.blob, 'my-audio-file'); // downloads a .wav file
      let filter = this.participants.filter((participant)=>{ return participant.select === true; })
      if(filter.length > 0){
        let selected = filter[0];
        //console.log({selected:selected});
        let filename = Date.now().toString();
        var fd=new FormData();
        //fd.append("audio_data",this.recorder.blob, filename);
        fd.append("parent_id",selected.id);
        fd.append("parent_type",selected.type.toString().toLowerCase());
        let notes = document.getElementById('message_text').value;
        fd.append("notes",document.getElementById('message_text').value);
        document.getElementById('message_text').setAttribute('recorded',false);
        this.message_recorded = false;
        document.getElementById('message_text').value = "";
        this.conversations.push({read:1,status:'queued',notes:notes,updated_at:Date.now(),created_at:Date.now()});
        setTimeout(function(){
            var objDiv = document.getElementById("conversation_body");
            objDiv.scrollTop = objDiv.scrollHeight;
        }, 500);
        this.$store.dispatch("conversation/voice_upload", fd).then((response) => {
          //console.log(response);
        });
      }
    },
    call_record(data){
      //console.log({RECORD : data});
      if(data.call.id !== undefined){
        if(this.pagination.conversation.participant['id'] !== undefined){
          var participant = this.pagination.conversation.participant;
          //console.log(participant);
          if(participant['id'] === data.call.parent_id && data.call.parent_type.toString().includes(participant['type']) ){
            this.conversations.push(data.call);
            setTimeout(function(){
                var objDiv = document.getElementById("conversation_body");
                objDiv.scrollTop = objDiv.scrollHeight;
            }, 500);
          }
        }
      }
    },
    call_event(data) {
      //console.log({PUSHER_CALL_STATUS : data});
      if(data.call.id !== undefined){
          let twillio_verbs = [
              {
                  status : 'canceled',
                  show : '[ canceled ]'
              },
              {
                  status : 'failed',
                  show : '[ failed ]'
              },
              {
                  status : 'no-answer',
                  show : '[ no-answer ]'
              },
              {
                  status : 'busy',
                  show : '[ busy ]'
              },
              {
                  status : 'answered',
                  show : '[ answered ]'
              },
              {
                  status : 'completed',
                  show : '[ completed ]'
              }
          ];
          let twillio_status = twillio_verbs.find(t => t.status === data.call.status);
          var call = this.calls.find(c => c.object.id === data.call.id);
          if(call !== undefined){
            //console.log("STATUS",data.call.status.toString());
            //console.log({CALL_PARTICIPANT: call.participant});
            var index = this.participants.findIndex(value => value.id === call.participant.id);
            //console.log({INDEX:index});
            //console.log({ACTUAL:this.participants[index]});
            this.participants[index]['call'] = undefined;
            this.participants[index]['call'] = new Date();
            this.participants[index]['call_status'] = data.call.status.toString();
            this.call_button_handler(this.participants[index]);
            this.call_button_out(this.participants[index]);
            if(twillio_status !== undefined){
              this.hangup(call.participant);
            }
          }else{
            var participant_index = this.participants.findIndex(p => p['id'] === data.call.parent_id);
            if(participant_index !== undefined){
              if(data.call.parent_type.toString().includes(this.participants[participant_index]['type'])){
                this.participants[participant_index]['call_class'] = ['fas', 'phone-volume'];
                this.participants[participant_index]['flip'] = "both";
                this.participants[participant_index]['call_style'] = { color: 'green' };
                this.participants[participant_index]['call'] = true;
                this.participants[participant_index]['call_status'] = "initiated";
                this.calls.push({
                  object : data.call,
                  participant : this.participants[participant_index],
                  index : participant_index,
                  ongoing : true
                });
              }
            }
          }
      }
    },
    sms_event(data) {
      //console.log({ SMS_EVENT : data });
      if(data.object !== undefined){
        if(this.pagination.conversation.participant['id'] !== undefined){
          var participant = this.pagination.conversation.participant;
          //console.log({SMS_PARTICIPANT : participant});
          if(participant.id === data.object.id && data.object.parent_type === participant['type'].toString().toLowerCase() ){
            this.conversations.push(data.message);
            setTimeout(function(){
                var objDiv = document.getElementById("conversation_body");
                objDiv.scrollTop = objDiv.scrollHeight;
            }, 500);
            this.$store.dispatch(data.object.parent_type.toString().toLowerCase()+"/mark", data.object.id).then((response) => {
              var index = this.participants.findIndex(p => (p.id === participant['id'] && p.type === participant['type']));
              //console.log({MARK_REMOVE:this.participants[index]});
              if(index !== undefined){
                this.participants[index]['notify'] = false;
              }
            }).catch((response)=>{

            });
          }else{
            for(var x = 0; x < this.participants.length; x++){
              var match = false;
              if(this.participants[x].id === data.object.id && this.participants[x].type.toString().toLowerCase() === data.object.parent_type){
                  match = true;
                  this.participants[x]['notify'] = true;
              }
            }
          }
        }
      }
    },
    infinite_participant($state) {
      var payload = {};
      this.pagination.participant.page = this.pagination.participant.page + 1;
      payload.page = this.pagination.participant.page;
      if(this.pagination.participant.search !== ''){
        payload.search = this.pagination.participant.search;
      }
      this.$store
        .dispatch("conversation/participants", payload)
        .then(response => {
          this.$insProgress.finish();
          //console.log(response);
          var participants = response.data.data;
          participants = participants.map((participant,index) => {
            participant['select'] = false;
            participant['call_class'] = ['fas', 'phone'];
            participant['flip'] = "";
            participant['call_style'] = { color: '#ff8c72' };
            return participant;
          });
          var previous_length = this.participants.length;
          this.participants.unshift(...participants);
          if(this.participants.length === previous_length){
             $state.complete();
          }else{
            $state.loaded();
            if(this.$route.params.id !== undefined){
              this.mount_conversations(this.participants[0]);
            }
          }
        })
        .catch(error => {

        });
    },
    search_participants(page = false) {
      this.$insProgress.start();
      this.pagination.participant.page = 0;
      this.participants = [];
      this.pagination.participant.identifier = new Date();
    },
    infinite_conversation($state) {
      this.pagination.conversation.page = this.pagination.conversation.page + 1;
      var data = {};
      if(this.pagination.conversation.participant['id'] !== undefined){
        var participant = this.pagination.conversation.participant;
        data.parent_id = this.pagination.conversation.participant['id'];
        data.parent_type = this.pagination.conversation.participant['type'].toString().toLowerCase();
        data.page = this.pagination.conversation.page;
        this.$store.dispatch("conversation/list",data).then((response)=>{
          this.$insProgress.finish();

          this.$store.dispatch(data.parent_type+"/mark", data.parent_id).then((response) => {
            var index = this.participants.findIndex(p => (p.id === participant['id'] && p.type === participant['type']));
            //console.log({MARK_REMOVE:this.participants[index]});
            if(index !== undefined){
              this.participants[index]['notify'] = false;
            }
          }).catch((response)=>{

          });
          var previous_length = this.conversations.length;
          this.conversations.unshift(...response.data.data.reverse());
            if(this.conversations.length === previous_length){
              $state.complete();
            }else{
              $state.loaded();
            }
          //console.log(this.conversations);
        }).catch((error)=>{
          this.$insProgress.finish();
        });
      }else{
        $state.complete();
      }
    },
    mount_conversations(participant){
      if(window.innerWidth < 767){
        document.getElementById('arrow_back').style.display = "block";
        document.getElementById('participants').style.display = "none";
        document.getElementById('conversations').style.display='block';
      }
       this.participants.forEach((element,index)=>{
         if(this.$route.params.id !== undefined && this.route_load === false){
           if(element['id'] === this.$route.params.id){
            this.participants[index]['select'] = true;
            this.route_load = true;
           }
         }else{
           if(element['id'] === participant['id']){
            this.participants[index]['select'] = true;
          }else{
            this.participants[index]['select'] = false;
          }
         }
       });
       //console.log(participant);
       this.conversations = [];
       this.pagination.conversation.page = 0;
       this.pagination.conversation.participant = participant;
       this.$insProgress.start();
       this.pagination.conversation.identifier = new Date();
    },
    date_format(date_string){
      const date = new Date(date_string);
      return (((date.getMonth()+1) < 10 ?('0'+(date.getMonth()+1).toString()) : (date.getMonth()+1) )) +'-'+ date.getDate() +'-'+ date.getFullYear() + ' ' + date.toLocaleTimeString('en-US');
    },
    call_toggler(participant){
      var call = this.calls.find(c => c.participant['id'] === participant['id']);
      if(call !== undefined){
        if(!call.ongoing){
          this.call(participant);
        }else{
          this.hangup(participant);
        }
      }else{
        this.call(participant);
      }
    },
    call(participant){
      //console.log("The calling function has been triggered...");
      var participant_index = this.participants.findIndex(p => p['id'] === participant['id']);
      if(participant_index !== undefined){
        this.participants[participant_index]['call_class'] = ['fas', 'phone-volume'];
        this.participants[participant_index]['flip'] = "both";
        this.participants[participant_index]['call_style'] = { color: 'green' };
        this.participants[participant_index]['call'] = true;
        this.participants[participant_index]['call_status'] = "initiated";
        this.$store.dispatch(participant['type'].toString().toLowerCase()+"/call", participant['id']).then((response) => {
          //console.log(response);
          this.calls.push({
            object : response.data.data,
            participant : participant,
            index : participant_index,
            ongoing : true
          });
        }).catch((response)=>{

        });
      }
    },
    hangup(participant){
      var call_index = this.calls.findIndex(c => c.participant['id'] === participant['id']);
      var call = this.calls[call_index];
      this.participants[call.index]['call_class'] = ['fas', 'phone'];
      this.participants[call.index]['flip'] = "";
      this.participants[call.index]['call_style'] = { color: '#ff8c72' };
      this.participants[call.index]['call'] = undefined;
      this.$store.dispatch(participant['type'].toString().toLowerCase()+"/hangup", call.object.id ).then((response) => {
        this.calls[call_index].ongoing = false;
      }).catch((response)=>{
        this.calls[call_index].ongoing = false;
      });
    },
    send(){
      if(document.getElementById('message_text').getAttribute('recorded') === 'true'){
        this.record_upload();
      }else{
        var s_participant = {};
        this.participants.forEach((participant,index)=>{
          if(participant['select']){
            s_participant = participant;
          }
        });

        if(s_participant['id'] !== undefined){
          var message = document.getElementById('message_text').value;
          if(message !== '' && message !== undefined){
            document.getElementById('message_text').value = "";
            this.conversations.push({read:1,status:'queued',type:'message',text:message,direction:'outbound',updated_at:Date.now(),created_at:Date.now()});
            setTimeout(function(){
                var objDiv = document.getElementById("conversation_body");
                objDiv.scrollTop = objDiv.scrollHeight;
            }, 500);
            this.$store.dispatch(s_participant['type'].toString().toLowerCase() + "/conversation_send", {id: s_participant['id'],message: message }).then((response) => {
              //console.log(response);
            });
          }
        }
      }
    },
    open_participant_mbl(){
      document.getElementById('arrow_back').style.display = "none";
      document.getElementById('conversations').style.display='none';
      document.getElementById('participants').style.display='block';
    },
    call_button_handler(participant){
      //console.log("MOUSE",participant);
      if(participant['call_class'][1] === 'phone-volume'){
        var index = this.participants.findIndex(value => value['id'] === participant['id']);
        this.participants[index]['call_class'] = ['fas','phone-slash'];
        this.participants[index]['flip'] = "";
        this.participants[index]['call_style'] = { color: 'red' };
      }
    },
    call_button_out(participant){
      if(participant['call_class'][1] === 'phone-slash'){
        var index = this.participants.findIndex(value => value['id'] === participant['id']);
        this.participants[index]['call_class'] = ['fas','phone-volume'];
        this.participants[index]['flip'] = "both";
        this.participants[index]['call_style'] = { color: 'green' };
      }
    },
  },

  created() {
    this.init();
  }
};
</script>
<style>


@media only screen and (max-width: 600px) {

#conversations{
  position:relative;
}
.vac-room-footer{
      position: fixed;
    bottom: 0;

}
}




@media only screen and (max-width: 500px) {

#conversations{
  position:relative;
}
.vac-room-footer{
      position: fixed;
    bottom: 0;

}
}


@media only screen and (max-width: 300px) {

#conversations{
  position:relative;
}
.vac-room-footer{
      position: fixed;
    bottom: 0;

}
}


</style>
