<template>
<card>
    <h3 slot="" class="title">{{this.agent.username}}</h3>
    <form @submit.prevent="updateProfile">
        <div class="row mb-4">

            <div class="col-md-6">
                <base-input type="text" label="Name" placeholder="Name" v-model="agent.name">
                </base-input>
            </div>
            <div class="col-md-6">
                <base-input type="text" label="User Name" placeholder="User Name" v-model="agent.username">
                </base-input>
            </div>
        </div>

        <div class="row mb-4">

            <div class="col-md-4">
                <base-input readonly type="email" label="Email" placeholder="Email" v-model="agent.email">
                </base-input>
            </div>
            <div class="col-md-4">
                <base-input type="password" label="Password" placeholder="Password" v-model="agent.password">
                </base-input>
            </div>
            <div class="col-md-4">
                <base-input type="tel" readonly label="Assigned_Number" placeholder="Assigned_Number" v-model="agent.twillio">
                </base-input>
            </div>
        </div>

        <div class="row mb-4">
            <div class="col-md-4">
                <base-input type="text" label="Phone Number" placeholder="Phone Number" v-model="agent.phone">
                </base-input>
            </div>
            <div class="col-md-8">
                <base-input type="text" label="Address" placeholder="Home Address" v-model="agent.address">
                </base-input>
            </div>
        </div>

        <div class="row mb-4">
            <div class="col-md-4">
                <base-input type="text" label="City" placeholder="City" v-model="agent.city">
                </base-input>
            </div>
            <div class="col-md-4">
                <base-input type="text" label="Country" placeholder="Country" v-model="agent.country">
                </base-input>
            </div>
            <div class="col-md-4">
                <base-input label="Postal Code" placeholder="ZIP Code" v-model="agent.zipcode">
                </base-input>
            </div>
        </div>
        <!-- <base-button native-type="submit" type="success" class="btn-fill">
            Update
        </base-button> -->
        <base-button type="default" class="btn-fill mb-5" @click="$router.go(-1)">
            Back
        </base-button>
    </form>
</card>
</template>

<script>
export default {
    components: {

    },
    data() {
        return {
            agent: {},
            modelValidations: {

                email: {
                    required: true,
                    email: true
                },
            }
        };
    },
    methods: {
        getError(fieldName) {
            return this.errors.first(fieldName);
        },
        updateProfile() {
            this.$validator.validateAll().then(isValid => {
                if (isValid) {
                    this.$insProgress.start();
                    this.$store.dispatch("agent/update", this.agent).then((response) => {
                        //console.log(response);
                        this.$insProgress.start();
                        this.$router.push('/agents/index');
                        this.$insProgress.finish();
                        this.$notify({
                            timeout: 2000,
                            verticalAlign: 'top',
                            horizontalAlign: 'right',
                            message: 'Your record updated successfully!',
                            icon: 'tim-icons icon-check-2',
                            type: 'success',
                        });
                    });
                }
            });
        },
        Viewagent(to = null) {
            this.$insProgress.start();
            let id = null;
            if(to){
              id = to.params.id;
            }else{
              id = this.$route.params.id;
            }
            this.$store.dispatch('agent/getagent', +id).then((response) => {
                //console.log(response);
                this.agent = response.data.data;
                //console.log(this.agent);
                this.$insProgress.finish();
            });
        },

    },
    created() {
        this.param_id = this.$route.params.id;
        this.Viewagent();
    },
    beforeRouteUpdate(to, from, next) {
    // Call the API query method when the URL changes
      this.Viewagent(to);
      next()
    }

};
</script>

<style scoped>
h3.title {
  padding-top: 40px;
}
</style>
