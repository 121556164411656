<template>

  <!--Stage  Change-->

  <div class="notifications-section" style="height: 460px">
    <div class="call-log-detail" v-for="notification of data" :key="notification.id">

      <div class="outMain">
        <div class="outMainInner">
          <div class="outMedia">
            <p class="">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:="" xlink="http://www.w3.org/1999/xlink" version="1.1"
                   id="openDealStageChange" x="0px" y="0px" viewBox="0 0 32 32" xml:="" space="preserve">
                <polygon points="9.1,13.8 15.9,7 22.6,13.8 " class="st0"></polygon>
                <polygon points="22.6,18.7 15.9,25.4 9.1,18.7 " class="st0"></polygon>
                <circle cx="16" cy="16" r="14.9" class="st0"></circle>
              </svg>
            </p>
          </div>
          <div class="outContent">
            <h2>Stage CHANGE :<span> {{object.TITLE}} </span></h2>
            <div class="audioFlex">
              <div class="audioMain"><span>{{notification.oldstage.NAME}}</span></div>
              <div class="">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                </svg>
              </div>
              <div class="audioMain"><span>{{notification.newstage.NAME}}</span></div>
            </div>
          </div>
        </div>
        <div class="outUser">
          <div class="outUserMedia"><img src="/img/mike.jpg"></div>
          <div class="outUserContent">
            <p>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                   stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
              </svg>
              <span>{{ date_format(notification.created_at) }}  |</span> <span class="userBlue">Set</span></p>
          </div>
        </div>
      </div>

    </div>

    <infinite-loading :identifier="infinIdStage" direction="bottom"
                      ref="infiniteLoading" @infinite="infiniteHandler">
    </infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: "stage-tabs",
  components: {
    InfiniteLoading,
  },
  props: {
    object: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      data: [],
      page: 1,
      infinIdStage: +new Date(),
    };
  },
  watch: {
    object: {
      handler(newValue, oldValue) {
        this.page = 1
        this.data = [];
        this.infinIdStage = +new Date();
      },
      deep: true
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.user = this.$store.getters["api/user"];
    },
    fetchNotification() {
      this.$insProgress.start();
      var data = {};
      data.parent_id = this.object.id;
      data.parent_type = this.type;
      data.page = this.page;
      this.$store.dispatch("combined_notification/get_stages_list", data).then((response) => {
        console.log(response.data);
        let fetchedNotifications = response.data.data;
        if (fetchedNotifications.length > 0) {
          this.data.push(...fetchedNotifications);
          console.log(this.data);
          this.page++;
          this.infinIdStage = +new Date();
          this.$refs.infiniteLoading.$emit('$InfiniteLoading:loaded')
        } else {
          this.$refs.infiniteLoading.$emit('$InfiniteLoading:complete')
        }
        this.$insProgress.finish();
      }).catch((e) => {
        this.$insProgress.finish();
      });
    },
    infiniteHandler($state) {
      this.fetchNotification();
      return true;
    },
    date_format(date_string) {
      const date = new Date(date_string);
      return date.toLocaleTimeString("en-US") + " " + (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1).toString() : date.getMonth() + 1) + "-" + (date.getDate() + 1 < 10 ? "0" + (date.getDate() + 1).toString() : date.getDate() + 1) + "-" + date.getFullYear();
    },
    splitter(type) {
      return type.split("\\").pop();
    },
  },
};
</script>
<style scoped>

.call-Log-main .call-log-detail {
  display: flex;
  gap: 20px;
  align-items: center;
  border: 1px solid #e2e8f0;
  padding: 5px;
  background-color: white;
  border-radius: 6px;
  margin: 10px 0px;
}

.call-Log-main .call-log-detail svg {
  width: 24px;
  height: 24px;
}

.call-Log-main .time-details {
  color: #a0a0a0;
  font-size: 11px;
}

.call-log-name {
  color: #ed8936;
}

.call-log-status {
  color: #124678;
}

.call-Log-content p {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
}

.call-log-detail .st1 {
  display: inline;
  fill: none;
  stroke: #ed8936;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.call-log-detail .st4 {
  fill: none;
  stroke: #ed8936;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
</style>
