<template>

  <div class="notesMain">
    <div class="notesMainInner">
      <div class="notesMainSvg">
        <svg data-v-9ff36b54="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             version="1.1" x="0px" y="0px" viewBox="0 0 32 32" xml:space="preserve"><g data-v-9ff36b54="" id="doc_requested_leads"><polyline data-v-9ff36b54="" points="24.9,20.8 24.9,30.9 1.1,30.9 1.1,1.1 24.9,1.1 24.9,6.7  " class="st4"></polyline><line
          data-v-9ff36b54="" x1="6.8" y1="6.9" x2="19.1" y2="6.9" class="st4"></line><line data-v-9ff36b54="" x1="6.8"
                                                                                           y1="13" x2="17.7" y2="13"
                                                                                           class="st4"></line><line
          data-v-9ff36b54="" x1="6.8" y1="19.1" x2="11.1" y2="19.1" class="st4"></line><line data-v-9ff36b54="" x1="6.8"
                                                                                             y1="25.1" x2="11.1"
                                                                                             y2="25.1"
                                                                                             class="st4"></line><path
          data-v-9ff36b54=""
          d="M14.9,21v2.1c0,0.2,0.2,0.4,0.4,0.4h2.1c0.4,0,0.5-0.4,0.3-0.7c-0.6-0.6-1.5-1.5-2.1-2.1   C15.4,20.5,14.9,20.7,14.9,21z"
          class="st14"></path><path data-v-9ff36b54=""
                                    d="M18.6,22.1l-2.2-2.2c-0.2-0.2-0.2-0.4,0-0.6l8.1-8.1c0.2-0.2,0.4-0.2,0.6,0l2.2,2.2c0.2,0.2,0.2,0.4,0,0.6   l-8.1,8.1C19,22.3,18.7,22.3,18.6,22.1z"
                                    class="st14"></path><path data-v-9ff36b54=""
                                                              d="M28.1,12.6l-2.2-2.2c-0.2-0.2-0.2-0.4,0-0.6l1.3-1.3c0.2-0.2,0.4-0.2,0.6,0l2.2,2.2c0.2,0.2,0.2,0.4,0,0.6   l-1.3,1.3C28.5,12.8,28.2,12.8,28.1,12.6z"
                                                              class="st14"></path></g></svg>
        <p>Notes</p>
        <span class="notesCount">{{charactersLeft}}</span>
      </div>
      <textarea maxlength="555" v-model="message" id="message_text" v-on:keyup.enter="record_upload"
      ></textarea>
      <button type="button" @click="record_upload">Save</button>
    </div>
  </div>

</template>

<script>

export default {
  name: "notes-tab",
  props: {
    object: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    }
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      count:0,
      message:''
    };
  },
  watch: {
    object: {
      handler(newValue, oldValue) {
        this.count=0;
        this.message='';
      },
      deep: true
    }
  },
  computed: {
    charactersLeft() {
      let char = this.message.length,
        limit = 555;

      return (limit - char);
    }
  },
  methods: {
    init() {
      this.user = this.$store.getters["api/user"];
    },

    record_upload() {
      let notes = document.getElementById('message_text').value;
      if (notes.length > 0 ) {
        var fd = new FormData();
        fd.append("parent_id", this.object.id);
        fd.append("parent_type", 'deal');
        fd.append("notes", notes);
        document.getElementById('message_text').value = "";
        // this.conversations.push({read:1,status:'queued',notes:notes,updated_at:Date.now(),created_at:Date.now()});
        // setTimeout(function(){
        //   var objDiv = document.getElementById("conversation_body");
        //   objDiv.scrollTop = objDiv.scrollHeight;
        // }, 500);
        this.$store.dispatch("conversation/voice_upload", fd).then((response) => {
          console.log(response.data.data);
          this.$root.$emit('add_note',response.data.data);
          this.$notify({
            timeout: 2000,
            verticalAlign: "top",
            horizontalAlign: "right",
            message: "Note saved successfully!",
            icon: "tim-icons icon-check-2",
            type: "success",
          });
        });
      }
    },
  },
};
</script>
<style scoped>

.call-Log-main .call-log-detail {
  display: flex;
  gap: 20px;
  align-items: center;
  border: 1px solid #e2e8f0;
  padding: 5px;
  background-color: white;
  border-radius: 6px;
  margin: 10px 0px;
}

.call-Log-main .call-log-detail svg {
  width: 24px;
  height: 24px;
}

.call-Log-main .time-details {
  color: #a0a0a0;
  font-size: 11px;
}

.call-log-name {
  color: #ed8936;
}

.call-log-status {
  color: #124678;
}

.call-Log-content p {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
}

.call-log-detail .st1 {
  display: inline;
  fill: none;
  stroke: #ed8936;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.call-log-detail .st4 {
  fill: none;
  stroke: #ed8936;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
</style>

