<template>
  <div class="content leads-content">
    <div class=" leads-content" style="">
      <div class="row">
        <div class="col-12 pt-0 leads-col">
          <div class="card" card-body-classes="table-full-width">
            <!---->
            <div class="card-header top-leads responsive-leads">
              <div class="w-100">
                <div class="row w-100 m-0">
                  <div class="leads_left col-12 col-lg-6">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                         x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;"
                         xml:space="preserve">
                                    <g xmlns="http://www.w3.org/2000/svg" id="queue_inner">
                                        <line class="st4" x1="5.4" y1="1.2" x2="26.6" y2="1.2"/>
                                      <line class="st4" x1="7" y1="8.4" x2="25" y2="8.4"/>
                                      <line class="st4" x1="8.7" y1="15.6" x2="23.3" y2="15.6"/>
                                      <line class="st4" x1="10.2" y1="22.8" x2="21.8" y2="22.8"/>
                                      <polyline class="st4" points="1.1,13.5 1.1,31 30.9,31 30.9,13.5  "/>
                                    </g>
                                    </svg>
                    <h4 class="card-title pull-left">Queues </h4>
                  </div>

                  <div class="form-group col-12 col-lg-6 text-right pr-0">
                    <!---->
                    <div class="pull-right form-pull">
                      <!---->
                      <div class=" search-input el-input el-input--prefix el-input--suffix"><input type="search"
                                                                                                   name="search"
                                                                                                   autocomplete="off"
                                                                                                   placeholder="Search records"
                                                                                                   aria-controls="datatables"
                                                                                                   class="el-input__inner">
                        <span class="el-input__prefix">
<!--                          <i class="el-input__icon el-icon-search searchbarb"></i>-->
                        <div class="searchbarb">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                               stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                          </svg>
                        </div>
                      </span></div>
                      <!---->
                      <span class="add-btn-main">
                                            <div class="add-leads-btn">
                                                <router-link :to="{ name :'Add Queue'}" class="nav-item dropdown-item"><button
                                                  type="button" class="mb-3 pull-right btn btn-simple btn-linkedin"><i
                                                  class="fa fa-plus"></i> Create Queue</button></router-link>
                                            </div>
                                        </span>
                    </div>
                    <!---->
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="box-table">
                <div class="Table">
                  <div class="Heading">
                    <div class="Cell">
                      <p>Name</p>
                    </div>
                    <div class="Cell">
                      <p>Twillio_Number</p>
                    </div>
                    <div class="Cell">
                      <p>NO_of_Agents</p>
                    </div>
                    <div class="Cell">
                      <p>Action</p>
                    </div>
                  </div>
                  <div class="Row" v-for="agent in agents" :key="agent.id">
                    <div class="Cell">
                      <p>{{ agent.name }}</p>
                    </div>
                    <div class="Cell">
                      <p>{{ agent.twilio_number }}</p>
                    </div>
                    <div class="Cell">
                      <p>{{ agent.number_of_agents }}</p>
                    </div>
                    <div class="Cell">
                      <p>

                        <router-link :to="{ name :'Edit Queue', params:{id:agent.id}}">
                          <button type="button"
                                  class="btn edit btn-link btn-icon btn-fab btn-warning btn-sm">
                            <!---->
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor" stroke-width="1">
                              <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"/>
                            </svg>
                          </button>
                        </router-link>


                        <button type="button"
                                class="btn remove btn-link btn-icon btn-fab btn-danger btn-sm"
                                @click="agent_delete(agent.id)">
                          <!----><i class="tim-icons icon-simple-remove"></i>
                        </button>
                      </p>

                    </div>
                  </div>

                </div>
                <div class="el-table__column-resize-proxy" style="display: none"></div>
                <div class="card-footer">
                  <div class="row">
                    <div class="col-12">

                    </div>

                  </div>
                  <!---->
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { RingLoader } from 'vue-spinners-css';

import swal from "sweetalert2";
import config from "@/config";
import {
  Collapse,
  CollapseItem
} from 'src/components';

export default {
  components: {
    Collapse,
    CollapseItem
  },

  data() {
    return {
      agents: {},
    }
  },
  methods: {
    //this method is use is pagination

    agent_fetch() {
      this.$insProgress.start();
      this.$store.dispatch("queue/fetch").then((response) => {
        //console.log(response);
        this.agents = response.data.data;
        this.$insProgress.finish();
      }).catch((e) => {
        this.$insProgress.finish();
      });
    },

    agent_delete(id) {
      swal({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.$insProgress.start();
          this.$store.dispatch("queue/delete", id).then((response) => {
            this.$insProgress.start();
            this.agent_fetch();
            this.$insProgress.finish();
            this.$notify({
              timeout: 2000,
              verticalAlign: 'top',
              horizontalAlign: 'right',
              message: 'Your record deleted successfully!',
              icon: 'tim-icons icon-check-2',
              type: 'success',
            });
          }).catch((e) => {
            this.$insProgress.finish();
          });

        }
      });
    },

  },

  created() {

    this.agent_fetch();
  },
};
</script>

<style scoped>

input.el-input__inner {
  padding-left: 50px !important;
  padding-right: 15px !important;
  width: 250px !important;
  height: 43px !important;
}

span.el-input__prefix {
  left: 0px;
}

.add-leads-btn button.mb-3.pull-right.btn.btn-simple.btn-linkedin {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 16px;
  -moz-column-gap: 16px;
  column-gap: 16px;
  font-family: 'Inter';
  margin: 0px !important;
  color: white !important;
  background-color: #124678 !important;
}

.searchbarb {
  background-color: #245482;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  width: 45px;
  font-size: 20px;
  color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-group .el-input__inner {
  height: 100% !important;
}

.Row .Cell:nth-child(1) {
  padding-left: 22px;
}

.box-table .Cell {
  height: 60px;
}

@media screen and (max-width: 991px) {
  .search-input input {
    width: 100% !important;
  }
}

</style>
