<template>
  <div class="notesMain">
    <div class="notesMainInner">
      <div class="notesMainSvg">
        <span class="notesCount" id="timer">{{ calling.text }}</span>
      </div>
      <div class="callFlexMain">
        <a href="#" class="bigMobile" @click="conference_start(object)">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
               id="openDealPhoneBig" x="0px" y="0px" viewBox="0 0 32 32" xml:space="preserve">
              <g>
                <path
                  d="M7.1,2.1L7.1,2.1c0.6,0,1.1,0.3,1.5,0.7c0,0,0.1,0.1,0.1,0.1c1.1,1,2.4,2.3,3.6,3.5c0.5,0.5,0.7,1,0.7,1.6   c0,0.5-0.3,1.1-0.7,1.6c0,0-0.1,0.1-0.1,0.1c-1.5,1.7-1.7,4.2-0.4,6.1c0.8,1.2,1.6,1.9,2,2.3c0,0,0,0,0,0c0.2,0.3,0.6,0.7,1.2,1.2   c1.1,1,2.5,1.5,4,1.5c1,0,2-0.2,2.9-0.7c0.2-0.1,0.3-0.2,0.5-0.4c0.5-0.5,1-0.7,1.6-0.7c0.5,0,1.1,0.3,1.6,0.7l3.7,3.7   c0.9,0.9,0.9,2.1,0,3l-0.8,0.8l-0.1,0.1c-0.5,0.5-1,0.9-1.4,1.4c-0.8,0.8-1.8,1.2-3.1,1.2c-1.1,0-2.4-0.3-3.9-0.8   c-3.6-1.3-7.1-3.6-10.2-6.8c-3-2.9-5.4-6.5-6.7-10.1c-0.8-2.2-1.6-5.2,0.3-7c0,0,0.1-0.1,0.1-0.1l0.7-0.7c0.5-0.5,1-1,1.5-1.5   c0,0,0.1-0.1,0.1-0.1C6.1,2.4,6.6,2.1,7.1,2.1 M7.1,0.1c-0.8,0-1.8,0.3-2.8,1.3C3.7,1.9,3.2,2.4,2.6,3L1.9,3.7   c-3.1,2.8-1.4,7.6-0.8,9.1c1.5,3.9,4,7.8,7.2,10.9c3.3,3.3,7.1,5.8,10.9,7.2c1.7,0.6,3.3,1,4.6,1c1.9,0,3.4-0.6,4.5-1.8   c0.4-0.5,0.9-0.9,1.4-1.4l0.8-0.8c1.7-1.7,1.7-4.2,0-5.9l-3.7-3.7c-1.1-1.1-2.2-1.3-3-1.3c-0.7,0-1.9,0.2-3,1.3   c-0.6,0.3-1.3,0.5-1.9,0.5c-1,0-1.9-0.3-2.7-1c-0.5-0.4-0.8-0.8-1-1c0,0-0.1-0.1-0.1-0.1c-0.4-0.3-1-0.9-1.7-1.9   c-0.8-1.1-0.7-2.6,0.2-3.6l0,0c1.1-1.1,1.3-2.2,1.3-3c0-0.8-0.2-1.9-1.3-3c-1.2-1.2-2.5-2.5-3.6-3.5C9.3,0.6,8.3,0.2,7.2,0.1   C7.2,0.1,7.1,0.1,7.1,0.1L7.1,0.1z"
                  class="st0">
                </path>
              </g>
            </svg>
        </a>
        <a href="#" class="bigMic" @click="conference_end()">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1"
               x="0px" y="0px" viewBox="0 0 208.014 208.014" style="enable-background:new 0 0 208.014 208.014;"
               xml:space="preserve">
            <g>
              <path id="callEndSvg" style="fill:#fff;"
                    d="M61.644,139.278C15.13,92.463,7.632,65.096,10.835,38.53c0.419-3.654,1.324-7.294,2.766-11.112   c2.613-6.667,6.159-11.17,8.672-13.771l5.458-5.536c1.689-1.686,4.069-2.656,6.535-2.656c2.233,0,4.255,0.784,5.672,2.215   l31.326,31.315c3.239,3.242,3.053,8.722-0.422,12.207L51.276,70.74l-0.394,0.401l1.084,1.836c1.095,1.843,2.248,3.915,3.493,6.177   c5.615,10.117,13.31,23.892,27.718,38.609l3.887-3.901C73.179,99.685,65.72,86.311,60.27,76.469l-2.502-4.445l16.96-16.953   c5.608-5.626,5.801-14.58,0.422-19.974L43.825,3.779C41.384,1.342,37.991,0,34.266,0c-3.933,0-7.716,1.535-10.393,4.219   l-7.695,7.734l-0.723,1.181c-2.856,3.672-5.204,7.802-6.961,12.301c-1.628,4.287-2.645,8.36-3.106,12.447   c-4.015,33.273,11.198,63.865,52.369,105.29L61.644,139.278z"/>
              <path id="callEndSvg" style="fill:#fff;"
                    d="M199.333,159.305l-31.326-31.344c-2.441-2.434-5.841-3.776-9.57-3.776   c-3.933,0-7.712,1.539-10.397,4.216l-16.924,16.924l-4.574-2.53c-7.287-4.048-16.534-9.205-26.605-17.264l-3.915,3.922   c10.608,8.568,20.288,13.95,27.894,18.166c2.355,1.299,4.398,2.448,6.22,3.525l1.84,1.102l19.956-19.956   c1.671-1.671,4.041-2.63,6.503-2.63c2.248,0,4.266,0.78,5.694,2.215l31.311,31.297c3.228,3.25,3.046,8.725-0.408,12.19   l-5.544,5.476c-2.62,2.52-7.129,6.066-13.728,8.643c-3.718,1.435-7.365,2.351-11.127,2.802c-0.211,0.025-1.056,0.075-2.434,0.075   c-10.239,0-44.94-3.264-87.922-41.171l-3.89,3.887c44.546,39.435,80.703,42.814,91.357,42.814c1.979,0,3.174-0.115,3.511-0.147   c4.273-0.523,8.364-1.553,12.487-3.146c4.452-1.736,8.564-4.069,12.225-6.929l1.746-1.378l7.201-7.068   C204.526,173.635,204.709,164.684,199.333,159.305z"/>
              <path id="callEndSvg" style="fill:#fff;"
                    d="M5.538,203.923L169.103,40.362l4.091,4.091L9.629,208.014L5.538,203.923z"/>
            </g>
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "call-tab",
  props: {
    type: '',
    object: {
      type: Object,
      required: true,
    },
  },
  watch: {
    object: {
      deep: true,
      handler(new_value) {
        if (new_value.lead.id !== undefined) {
          //console.log("re-fetch");
          this.init();
        }
      }
    },
  },

  data() {
    return {
      selects: {
        simple: 'Twillio Numbers',
        numbers: [],
      },
      calling: {
        text: "Call",
        current_conference: {},
      },
      dom: {
        window_toggler: false,
        phone_body_text: "Call "
      },
      user: {},
      channel: {}
    }
  },
  methods: {
    init() {
      if (this.object.id !== undefined) {
        if (this.user.id === undefined) {
          this.user = this.$store.getters['api/user'];
          this.channel = this.$pusher.subscribe('CallStatus');
          this.channel.bind('status', this.status);
        }
      } else {
        this.exit();
      }
    },
    exit() {
      this.conference_end(this.object);
      this.object = {};
    },
    conference_start(lead) {
      //console.log("confernece_start");
      this.calling.text = "Calling...";
      //console.log(lead);
      this.$store
        .dispatch(this.type + "/call", lead.id)
        .then((response) => {
          //console.log(response);
          this.calling.current_conference = response.data.data;
          this.calling.text = "[ connecting ]";
        });
    },
    conference_end() {
      if (this.calling.current_conference.id !== undefined) {
        this.calling.text = "Interupting...";
        this.$store
          .dispatch(this.type + "/hangup", this.calling.current_conference.id)
          .then((response) => {
            //console.log(response);
            this.calling.current_conference = {};
            this.calling.text = "Call";
          });
      }
    },
    status(data) {
      //console.log(data);
      if (data.call.id === this.calling.current_conference.id) {
        let twillio_verbs = [
          {
            status: 'canceled',
            show: '[ canceled ]'
          },
          {
            status: 'failed',
            show: '[ failed ]'
          },
          {
            status: 'no-answer',
            show: '[ no-answer ]'
          },
          {
            status: 'busy',
            show: '[ busy ]'
          },
          {
            status: 'answered',
            show: '[ answered ]'
          },
          {
            status: 'completed',
            show: '[ completed ]'
          }
        ];
        let twillio_status = twillio_verbs.find(t => t.status === data.call.status);
        if (twillio_status !== undefined) {
          this.calling.text = twillio_status.status;
          setTimeout(this.reboot, 1200);
        } else {
          this.calling.text = "[ " + data.call.status.toString() + " ]";
        }
      }
    },
    reboot() {
      this.calling.text = "Call";
      this.calling.current_conference = {};
    }
  },
  created() {
    this.$store.dispatch("lead/twiilio_number_fetch", null)
      .then((response) => {
        //console.log(response);
        const numbers = response.data.data.numbers.map(function (row) {
          return {value: row.number, label: row.name}
        })
        //console.log(numbers);
        this.selects.numbers = numbers;
      });
  }

};
</script>
<style scoped>

.call-Log-main .call-log-detail {
  display: flex;
  gap: 20px;
  align-items: center;
  border: 1px solid #e2e8f0;
  padding: 5px;
  background-color: white;
  border-radius: 6px;
  margin: 10px 0px;
}

.call-Log-main .call-log-detail svg {
  width: 24px;
  height: 24px;
}

.call-Log-main .time-details {
  color: #a0a0a0;
  font-size: 11px;
}

.call-log-name {
  color: #ed8936;
}

.call-log-status {
  color: #124678;
}

.call-Log-content p {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
}

.call-log-detail .st1 {
  display: inline;
  fill: none;
  stroke: #ed8936;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.call-log-detail .st4 {
  fill: none;
  stroke: #ed8936;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.notesMainSvg {
  justify-content: center;
}
</style>

