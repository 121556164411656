<template>
  <card>
    <h3 slot="" class="title">Edit Queue</h3>
    <div class="Queue">
      <form>
        <div class="row mb-4">

          <div class="col-md-6">
            <base-input type="text" label="Name" placeholder="Name" v-model="data.name">
            </base-input>
          </div>
          <div class="col-md-6">
            <lable> Twillio Number</lable>
            <el-select class="select-primary" size="large" placeholder="Select" v-model="data.number_sid">
              <el-option v-for="option in t_numbers.numbers" class="select-primary" :value="option.number_sid"
                         :label="option.number" :key="option.number">
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-md-6">
            <lable> Assigned Agents</lable>
            <el-select multiple class="select-primary" size="large" placeholder="Select" v-model="data.agent_ids">

              <el-option v-for="option in all_agents.names" class="select-primary" :value="option.id"
                         :label="option.name" :key="option.id">
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-md-6">
            <base-button native-type="submit" @click.prevent="updatequeue" type="success" class="btn-fill mr-4">
              Save
            </base-button>
            <base-button type="default" class="btn-fill" @click="$router.go(-1)">
              Back
            </base-button>
          </div>
        </div>

      </form>
    </div>
  </card>
</template>

<script>
import swal from "sweetalert2";
import config from "@/config";
import Multiselect from 'vue-multiselect'
import {
  TimeSelect,
  DatePicker,
  Select,
  Option
} from 'element-ui';

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    Multiselect,
  },
  data() {
    return {
      data: {},
      t_numbers: {
        simple: '',
        numbers: [],
      },
      all_agents: {
        simple: '',
        names: [],
      },
      modelValidations: {

        email: {
          required: true,
          email: true
        },
        phone: {
          required: true
        }
      }
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },

    getQue() {
      this.$insProgress.start();
      this.$store.dispatch('queue/getQue', +this.$route.params.id).then((response) => {
        //console.log(response);
        this.data = response.data.data;
        this.$insProgress.finish();
      });
    },
    updatequeue() {
      this.$insProgress.start();
      this.$store.dispatch("queue/update", this.data).then((response) => {
        //console.log(response);
        this.$router.push('/queue/index');
        this.$insProgress.finish();
        this.$notify({
          timeout: 2000,
          verticalAlign: 'top',
          horizontalAlign: 'right',
          message: 'Your record updated successfully!',
          icon: 'tim-icons icon-check-2',
          type: 'success',
        });
      }).catch((e) => {
        this.$insProgress.finish();
      });
    },
    agent_fetch() {
      this.$insProgress.start();
      this.$store.dispatch("queue/fetch_all_agent").then((response) => {
        //console.log(response);
        this.all_agents.names = response.data.data;
        this.$insProgress.finish();
      }).catch((e) => {
        this.$insProgress.finish();
      });
    },
    twillio_nbr_fetch() {
      this.$insProgress.start();
      this.$store.dispatch("queue/fetch_twillio_nbr").then((response) => {
        //console.log(response);
        this.t_numbers.numbers = response.data.data.numbers;
        this.$insProgress.finish();
      }).catch((e) => {
        this.$insProgress.finish();
      });
    },
  },
  created() {
    this.agent_fetch();
    this.twillio_nbr_fetch();
    this.getQue();
  },
};
</script>

<style>

h3.title {
  font-weight: 700!important;
  color: #f16925!important;
  font-family: Inter;
  font-size: 20px;
  letter-spacing: 2px;
}
.Queue .el-select {
  width: 100%;
  margin-bottom: 10px;
}

lable {
  font-size: 0.75rem;
  margin-bottom: 5px;
}

.Queue .select-primary.el-select .el-input input {
  border-color: rgba(29, 37, 59, 0.5) !important;
  color: #222a42 !important;
  border-radius: 0.4285rem !important;
}

.Queue .select-primary.el-select .el-input:hover input::placeholder {
  color: #b2b2b2 !important;
}

.Queue .select-primary .el-tag,
.select-primary .el-tag.el-tag--info {
  background-color: transparent;
  background: linear-gradient(0deg, #ff6491 0%, #ff8d72 100%);
}


.Queue #phonechallbox .el-input__prefix,
.el-input__suffix {
  top: 0 !important;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected.select-primary {
  color: #fff !important;
}

.Queue .col-md-6 {
  margin-left: inherit;
  margin-right: inherit;
}

.alert.open.alert-with-icon.top.right.alert-primary {
  background: linear-gradient(0deg, #ff6491 0%, #ff8d72 100%);
}

span.el-tag.el-tag--info.el-tag--small.el-tag--light {
  color: #245482;
  background: #cce6ff;
  border-radius: 50px;
  margin-bottom: 0;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  padding: 14px 20px;
}
.select-primary .el-tag .el-tag__close:before, .select-primary .el-tag.el-tag--info .el-tag__close:before {
  margin-top: 5px;
  color: #245482;
  font-size: 20px;

  font-weight: 600;
}
.select-primary .el-tag .el-tag__close, .select-primary .el-tag.el-tag--info .el-tag__close{
  width: 25px !important;
  height: 25px !important;
}
i.el-tag__close.el-icon-close:hover {
  background-color: #245482 !important;
}

.el-tag .el-tag__close, span.el-tag.el-tag--info .el-tag__close:hover:before {
  color: white;
}

.el-select .el-tag__close.el-icon-close::before{
  margin-top: 2px;
  color: #245482;
  font-size: 20px;
  font-weight: 600;
}

</style>
