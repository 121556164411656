<template>
  <div class="notifications-section" style="height: 460px">
    <div v-for="notification of notifications" :key="notification.id">
      <!--outgoin sms-->
      <div class="outMain">
        <div class="outMainInner">
          <div class="outMedia">
            <p class="">
              <svg data-v-9ff36b54="" xmlns="http://www.w3.org/2000/svg" id="openDealsms_lead" viewBox="0 0 32 32">
                <path data-v-9ff36b54=""
                      d="M12.44,21.82H4.81a3.7,3.7,0,0,1-3.7-3.69V6.41a3.7,3.7,0,0,1,3.7-3.7H27.19a3.7,3.7,0,0,1,3.7,3.7V18.13a3.7,3.7,0,0,1-3.7,3.69h0a1.55,1.55,0,0,0-1.55,1.55v3.8a2.13,2.13,0,0,1-3.49,1.63l-7.3-6.1A3.73,3.73,0,0,0,12.44,21.82Z"
                      class="cls-1"></path>
                <circle data-v-9ff36b54="" cx="10.29" cy="12.67" r="1.4" class="cls-2"></circle>
                <circle data-v-9ff36b54="" cx="16" cy="12.67" r="1.4" class="cls-2"></circle>
                <circle data-v-9ff36b54="" cx="21.71" cy="12.67" r="1.4" class="cls-2"></circle>
              </svg>
            </p>
          </div>
          <div class="outContent">
            <h2>{{ splitter(notification.parent_type) }} : <span>{{ object.TITLE }} </span></h2>
            <div class="outSmsText">
              <p>{{ notification.text }}</p>
            </div>
          </div>
        </div>
        <div class="outUser">
          <div class="outUserMedia">
            <img src="/img/mike.jpg">
          </div>
          <div class="outUserContent">
            <p>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                   stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
              </svg>
              <span>{{ date_format(notification.created_at) }} |</span> <span class="userBlue">{{
                notification.status
              }}</span></p>
          </div>
        </div>
      </div>
    </div>
    <infinite-loading :identifier="infiniteId" direction="bottom"
                      ref="infiniteLoading" @infinite="infiniteHandler">
    </infinite-loading>
  </div>
</template>
<script>
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: "system-notifications",
  components: {
    InfiniteLoading,
  },
  mounted() {
    this.$root.$on('add_message', (obj) => {
      obj.type = 'message';
      this.notifications.splice(0, 0, obj);
    });
  },
  props: {
    object: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      notifications: [],
      page: 0,
      is_loading: 0,
      infiniteId: +new Date(),
    };
  },
  watch: {
    object: {
      handler(newValue, oldValue) {
        this.page = 0;
        this.is_loading = 0;
        this.notifications = [];
        this.infiniteId = +new Date();
      },
      deep: true
    }
  },
  methods: {
    fetchNotification() {
      this.$insProgress.start();
      var data = {};
      data.parent_id = this.object.id;
      data.parent_type = this.type;
      data.page = this.page + 1;
      this.$store.dispatch("combined_notification/get_messages", data).then((response) => {
        console.log("aaa == ");
        console.log(response.data.data);
        let fetchedNotifications = response.data.data;
        console.log(fetchedNotifications.length);
        if (fetchedNotifications.length > 0) {
          this.notifications.push(...fetchedNotifications);
          console.log(this.notifications);
          this.page++;
          this.infiniteId = +new Date();
          this.$refs.infiniteLoading.$emit('$InfiniteLoading:loaded')
        }
        else {
          this.$refs.infiniteLoading.$emit('$InfiniteLoading:complete')
        }

        this.$insProgress.finish();
      }).catch((e) => {
        this.$insProgress.finish();
      });
    },
    infiniteHandler($state) {
      this.fetchNotification();
      return true;
    },
    date_format(date_string) {
      const date = new Date(date_string);
      return date.toLocaleTimeString("en-US") + " " + (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1).toString() : date.getMonth() + 1) + "-" + (date.getDate() + 1 < 10 ? "0" + (date.getDate() + 1).toString() : date.getDate() + 1) + "-" + date.getFullYear();
    },
    splitter(type) {
      return type.split("\\").pop();
    },
  },
};
</script>
