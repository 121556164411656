<template>
  <section class="dealsViewMain">
    <div class="delasViewLeft">
      <div class="openDealUser">
        <p class="">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
               id="open_folder_user" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background: new 0 0 32 32"
               xml:space="preserve">
					<path class="st0"
                d="M3.8,26.9c-1.5,0-2.8-1.2-2.8-2.8V8c0-1.5,1.3-2.8,2.8-2.8h6.3c1.1,0,2.1,0.8,2.3,1.9l0.1,0.5  c0.1,0.4,0.4,0.7,0.8,0.7h8.3c1.5,0,2.8,1.2,2.8,2.8v2.9"/>
            <path class="st0"
                  d="M25.4,25.1c-0.8,1.1-2.1,1.8-3.4,1.8H3.8h0c-2,0-3.1-2.8-1.7-4.6l5-6.5C8,14.7,9.4,14,10.7,14h17.8l0,0  c2,0,3,2.8,1.7,4.5L25.4,25.1z"/> </svg>
        </p>
        <h1 class="">{{ deal.TITLE }}</h1>
      </div>
      <div class="openDealForm">
        <div class="inputFlex">
          <div class="inputFlexInner">
            <label class="">phone number</label>
            <div class="inputSvg">
              <p class="">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                     id="openDealPhone" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background: new 0 0 32 32"
                     xml:space="preserve">
								<g>
									<path class="st0"
                        d="M7.1,2.1L7.1,2.1c0.6,0,1.1,0.3,1.5,0.7c0,0,0.1,0.1,0.1,0.1c1.1,1,2.4,2.3,3.6,3.5c0.5,0.5,0.7,1,0.7,1.6   c0,0.5-0.3,1.1-0.7,1.6c0,0-0.1,0.1-0.1,0.1c-1.5,1.7-1.7,4.2-0.4,6.1c0.8,1.2,1.6,1.9,2,2.3c0,0,0,0,0,0c0.2,0.3,0.6,0.7,1.2,1.2   c1.1,1,2.5,1.5,4,1.5c1,0,2-0.2,2.9-0.7c0.2-0.1,0.3-0.2,0.5-0.4c0.5-0.5,1-0.7,1.6-0.7c0.5,0,1.1,0.3,1.6,0.7l3.7,3.7   c0.9,0.9,0.9,2.1,0,3l-0.8,0.8l-0.1,0.1c-0.5,0.5-1,0.9-1.4,1.4c-0.8,0.8-1.8,1.2-3.1,1.2c-1.1,0-2.4-0.3-3.9-0.8   c-3.6-1.3-7.1-3.6-10.2-6.8c-3-2.9-5.4-6.5-6.7-10.1c-0.8-2.2-1.6-5.2,0.3-7c0,0,0.1-0.1,0.1-0.1l0.7-0.7c0.5-0.5,1-1,1.5-1.5   c0,0,0.1-0.1,0.1-0.1C6.1,2.4,6.6,2.1,7.1,2.1 M7.1,0.1c-0.8,0-1.8,0.3-2.8,1.3C3.7,1.9,3.2,2.4,2.6,3L1.9,3.7   c-3.1,2.8-1.4,7.6-0.8,9.1c1.5,3.9,4,7.8,7.2,10.9c3.3,3.3,7.1,5.8,10.9,7.2c1.7,0.6,3.3,1,4.6,1c1.9,0,3.4-0.6,4.5-1.8   c0.4-0.5,0.9-0.9,1.4-1.4l0.8-0.8c1.7-1.7,1.7-4.2,0-5.9l-3.7-3.7c-1.1-1.1-2.2-1.3-3-1.3c-0.7,0-1.9,0.2-3,1.3   c-0.6,0.3-1.3,0.5-1.9,0.5c-1,0-1.9-0.3-2.7-1c-0.5-0.4-0.8-0.8-1-1c0,0-0.1-0.1-0.1-0.1c-0.4-0.3-1-0.9-1.7-1.9   c-0.8-1.1-0.7-2.6,0.2-3.6l0,0c1.1-1.1,1.3-2.2,1.3-3c0-0.8-0.2-1.9-1.3-3c-1.2-1.2-2.5-2.5-3.6-3.5C9.3,0.6,8.3,0.2,7.2,0.1   C7.2,0.1,7.1,0.1,7.1,0.1L7.1,0.1z"/> </g>
							</svg>
              </p>

              <input type="tel"
                     label="Phone"
                     placeholder="phone"
                     name="phone"
                     :readonly="!edit_fields"
                     v-validate="modelValidations.phone"
                     v-model="deal.phone"
                     :error="getError('phone')"/>
            </div>
          </div>
          <div class="inputFlexInner">
            <label class="">email address</label>
            <div class="inputSvg">
              <p class="">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                     id="openDealEmail" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background: new 0 0 32 32"
                     xml:space="preserve">
								<g>
									<g>
										<path class="st0"
                          d="M29.9,6.2v19.6H2.1V6.2H29.9 M30.1,4.2H1.9c-1,0-1.8,0.8-1.8,1.8v19.9c0,1,0.8,1.8,1.8,1.8h28.2    c1,0,1.8-0.8,1.8-1.8V6.1C31.9,5.1,31.1,4.2,30.1,4.2L30.1,4.2z"/> </g>
                  <path class="st1" d="M1.5,5.5L14,16.6c1.2,1.1,3.1,1.1,4.3,0L30.8,5.5"/> </g>
							</svg>
              </p>
              <input type="email" class="" name="email"
                     :readonly="!edit_fields"
                     v-validate="modelValidations.email"
                     v-model="deal.email"
                     :error="getError('email')"/>
            </div>
          </div>
        </div>
        <div class="inputFlex">
          <div class="inputFlexInner">
            <label class="">loan amount (USD)</label>
            <div class="inputSvg">
              <p class="">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                     id="openDealloan_ammount" x="0px" y="0px" viewBox="0 0 32 32"
                     style="enable-background: new 0 0 32 32" xml:space="preserve">
								<path class="st0"
                      d="M15.8,1.1c-0.1,0-0.2,0-0.4,0l-2.2,1.1c-0.1,0.1-0.3,0.1-0.4,0.1h-0.2L10.8,2h-0.1C10.1,2,9.7,2.5,9.7,3  c0,0.1,0,0.2,0.1,0.3c0.8,1.5,1.3,3.2,1.7,4.9c-0.1,0.2-0.2,0.5-0.3,0.7L3.8,19.6c-1.3,2.2-1.3,5,0,7.2c1.4,2.4,3.9,4,6.7,4.1h5.6  h6.5c2.4-0.4,4.6-1.9,5.8-4c1.2-2.5,1-5.5-0.5-7.9C26.9,17.6,21,8.9,21,8.9c-0.1-0.2-0.3-0.4-0.4-0.6c0.1-1.7,0.6-3.4,1.4-5  c0.2-0.5-0.1-1-0.6-1.2c-0.1,0-0.2,0-0.3,0H21l-1.9,0.3h-0.2c-0.1,0-0.2,0-0.4,0l-2.4-1c-0.1,0-0.2,0-0.4,0V1.1z"/>
                  <path class="st1"
                        d="M17,18.8h-2c-0.9,0.1-1.7-0.6-1.8-1.5c-0.1-0.9,0.6-1.7,1.5-1.8c0.1,0,0.2,0,0.4,0h3.6c0.4,0.1,0.7-0.2,0.8-0.5  c0.1-0.4-0.2-0.7-0.5-0.8c-0.1,0-0.1,0-0.2,0h-2v-1.3c-0.1-0.4-0.4-0.6-0.8-0.5c-0.3,0-0.5,0.3-0.5,0.5v1.3H15c-1.6,0-3,1.3-3,3  s1.3,3,3,3h2c0.9,0,1.7,0.8,1.7,1.7c0,0.9-0.8,1.7-1.7,1.7h-4.3c-0.4,0.1-0.6,0.4-0.5,0.8c0,0.3,0.3,0.5,0.5,0.5h2.6v1.3  c0.1,0.4,0.4,0.6,0.8,0.5c0.3,0,0.5-0.3,0.5-0.5v-1.3H17c1.6,0,3-1.3,3-3S18.6,18.9,17,18.8L17,18.8z"/> </svg>
              </p>
              <input type="text" label="Loan Amount" :readonly="!edit_fields"
                     v-model="return_field('UF_CRM_1590710653').value"
              />
            </div>
          </div>
          <div class="inputFlexInner">
            <label class="">placeholder</label>
            <div class="inputSvg">
              <p class="">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                     id="openDealPlaceholder" x="0px" y="0px" viewBox="0 0 32 32"
                     style="enable-background: new 0 0 32 32" xml:space="preserve">
								<g>
									<circle class="st0" cx="4" cy="16" r="2.9"/>
                  <circle class="st0" cx="16" cy="16" r="2.9"/>
                  <circle class="st0" cx="28" cy="16" r="2.9"/>
                  <circle class="st0" cx="16" cy="4" r="2.9"/>
                  <circle class="st0" cx="16" cy="28" r="2.9"/> </g>
							</svg>
              </p>
              <input type="" :readonly="!edit_fields" class=""/></div>
          </div>
          <div class="inputFlexInner">
            <label class="">Credit Score</label>
            <div class="inputSvg">
              <p class="">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                     id="openDealCreditrun" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background: new 0 0 32 32"
                     xml:space="preserve">
								<g>
									<path class="st0"
                        d="M28.5,25.5H9.4c-1.4,0-2.4-1.1-2.4-2.4V8.9c0-1.4,1.1-2.4,2.4-2.4h19.1c1.4,0,2.4,1.1,2.4,2.4v14.2   C30.9,24.4,29.8,25.5,28.5,25.5z"/>
                  <line class="st0" x1="7.3" y1="12.4" x2="30.7" y2="12.4"/>
                  <line class="st0" x1="3.7" y1="11.3" x2="2.3" y2="11.3"/>
                  <line class="st0" x1="3.8" y1="16" x2="1.1" y2="16"/>
                  <line class="st0" x1="2.6" y1="20.8" x2="1.1" y2="20.8"/>
                  <circle class="st1" cx="27.1" cy="22.4" r="1"/>
                  <circle class="st1" cx="25.8" cy="22.4" r="1"/> </g>
							</svg>
              </p>
              <input type="text" label="Credit Score" :readonly="!edit_fields"
                     v-model="return_field('UF_CRM_1590710669').value"/>
            </div>
          </div>
          <div class="inputFlexInner">
            <label class="">Loan Type</label>
            <div class="inputSvg">
              <p class="">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
                     y="0px" viewBox="0 0 32 32" xml:space="preserve">
								<g id="openDealLoan">
									<path
                    d="M14.3,1.7L1.6,10.9c-0.7,0.5-0.9,1.5-0.4,2.3c0,0,0.1,0.1,0.1,0.1l0,0c0.6,0.7,1.6,1.4,2.3,0.9l11.7-8.3   c0.3-0.3,0.8-0.3,1.1,0l11.7,8.4c0.7,0.5,1.8-0.2,2.4-0.9l0,0c0.6-0.7,0.5-1.7-0.2-2.3c0,0-0.1-0.1-0.1-0.1L17.7,1.7   C16.7,0.8,15.3,0.8,14.3,1.7z"
                    class="st16"></path>
                  <polyline points="3.9,13.6 3.9,30.9 28.1,30.9 28.1,13.7  " class="st16"></polyline>
                  <path
                    d="M17,18.6h-2c-0.9,0.1-1.7-0.6-1.8-1.5c-0.1-0.9,0.6-1.7,1.5-1.8c0.1,0,0.2,0,0.4,0h3.6   c0.4,0.1,0.7-0.2,0.8-0.5c0.1-0.4-0.2-0.7-0.5-0.8c-0.1,0-0.1,0-0.2,0h-2v-1.3c-0.1-0.4-0.4-0.6-0.8-0.5c-0.3,0-0.5,0.3-0.5,0.5   v1.3H15c-1.6,0-3,1.3-3,3s1.3,3,3,3h2c0.9,0,1.7,0.8,1.7,1.7s-0.8,1.7-1.7,1.7h-4.3c-0.4,0.1-0.6,0.4-0.5,0.8   c0,0.3,0.3,0.5,0.5,0.5h2.6v1.3c0.1,0.4,0.4,0.6,0.8,0.5c0.3,0,0.5-0.3,0.5-0.5v-1.3H17c1.6,0,3-1.3,3-3   C19.9,19.9,18.6,18.6,17,18.6L17,18.6z"
                    class="st17"></path>
								</g>
							</svg>
              </p>
              <input type="text" label="Loan Program" :readonly="!edit_fields"
                     v-model="return_field('UF_CRM_1605217925538').value"
              />
            </div>
          </div>
        </div>
        <div class="inputFlexInner2">
          <label class="">property address</label>
          <div class="inputSvg">
            <p class="">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                   id="openDealLocation" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background: new 0 0 32 32"
                   xml:space="preserve">
							<g>
								<path class="st0"
                      d="M7.8,13.8C5.4,8.2,9.1,1.6,15.2,1.2c0.3,0,0.5,0,0.8,0s0.6,0,0.8,0c6.1,0.4,9.8,7,7.4,12.6l-6.6,16   c-0.7,1.4-2.6,1.4-3.3,0L7.8,13.8z"/>
                <circle class="st0" cx="16" cy="9.4" r="2.7"/> </g>
						</svg>
            </p>
            <input type="text" label="Property Address" :readonly="!edit_fields"
                   v-model="return_field('UF_CRM_1590710727').value"/>
          </div>
        </div>
        <div class="inputFlex">
          <div class="inputFlexInner">
            <label class="">property state</label>
            <div class="inputSvg">
              <p class="">
                <svg id="openDealState" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                     version="1.1" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background: new 0 0 32 32"
                     xml:space="preserve">
								<line class="st0" x1="16" y1="13.3" x2="16" y2="1.1"/>
                  <polygon class="st0" points="25.6,7.9 16,7.9 16,1.1 25.6,1.1 22.9,4.5 "/>
                  <path class="st1" d="M1.3,30.9L16,17.5l14.9,13.4C30.9,30.9,1.8,30.9,1.3,30.9z"/> </svg>
              </p>

              <el-select :disabled="!edit_fields" :size="small"
                         class="select-primary stageNewClass" placeholder="Property State"
                         v-model="return_field('UF_CRM_1591035649').value" @change="check">
                <el-option class="select-info" value="" label="Please select" disabled></el-option>
                <el-option v-for="option in return_field('UF_CRM_1591035649').list" class="select-info"
                           :value="option.value" :label="option.value" :key="option.id"
                           :selected="option.value === return_field_value('UF_CRM_1591035649')">
                </el-option>
              </el-select>


              <!--              <input type="text" label="Property State" readonly :value="return_field_value('UF_CRM_1591035649')"/>-->
            </div>
          </div>
          <div class="inputFlexInner">
            <label class="">property Type</label>
            <div class="inputSvg">
              <p class="">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                     id="openDealPlaceholder" x="0px" y="0px" viewBox="0 0 32 32"
                     style="enable-background: new 0 0 32 32" xml:space="preserve">
								<g>
									<circle class="st0" cx="4" cy="16" r="2.9"/>
                  <circle class="st0" cx="16" cy="16" r="2.9"/>
                  <circle class="st0" cx="28" cy="16" r="2.9"/>
                  <circle class="st0" cx="16" cy="4" r="2.9"/>
                  <circle class="st0" cx="16" cy="28" r="2.9"/> </g>
							</svg>
              </p>
              <input type="text" label="Property Type" :readonly="!edit_fields"
                     v-model="return_field('UF_CRM_1591034579').value"/>
            </div>
          </div>
        </div>
        <div class="inputFlex">
          <div class="inputFlexInner">
            <label class="">qualify with</label>
            <div class="inputSvg">
              <p class="">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                     id="openDealQualify" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background: new 0 0 32 32"
                     xml:space="preserve">
								<g>
									<polyline class="st0"
                            points="23.9,15.9 31,22.9 26.2,24.2 24.6,29.3 16,20.7 15.9,20.8 7.4,29.3 5.8,24.2 1,22.9 8.1,15.9  "/>
                  <circle class="st0" cx="16" cy="11.5" r="8.8"/>
                  <circle class="st0" cx="16" cy="11.5" r="4.4"/> </g>
							</svg>
              </p>

              <el-select :disabled="!edit_fields"
                         class="select-primary stageNewClass" placeholder="Qualify With"
                         v-model="return_field('UF_CRM_1596158780').value">
                <el-option class="select-info" value="" label="Please select" disabled></el-option>
                <el-option v-for="option in return_field('UF_CRM_1596158780').list" class="select-info"
                           :value="option.value" :label="option.value" :key="option.id"
                           :selected="option.value === return_field_value('UF_CRM_1596158780')">
                </el-option>
              </el-select>

              <!--              <input type="text" readonly :value="return_field_value('UF_CRM_1596158780')"/>-->
            </div>
          </div>
          <div class="inputFlexInner">
            <label class="">placeholder</label>
            <div class="inputSvg">
              <p class="">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                     id="openDealPlaceholder" x="0px" y="0px" viewBox="0 0 32 32"
                     style="enable-background: new 0 0 32 32" xml:space="preserve">
								<g>
									<circle class="st0" cx="4" cy="16" r="2.9"/>
                  <circle class="st0" cx="16" cy="16" r="2.9"/>
                  <circle class="st0" cx="28" cy="16" r="2.9"/>
                  <circle class="st0" cx="16" cy="4" r="2.9"/>
                  <circle class="st0" cx="16" cy="28" r="2.9"/> </g>
							</svg>
              </p>
              <input type="text" :readonly="!edit_fields" class=""/></div>
          </div>
          <div class="inputFlexInner">
            <label class="">placeholder</label>
            <div class="inputSvg">
              <p class="">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                     id="openDealPlaceholder" x="0px" y="0px" viewBox="0 0 32 32"
                     style="enable-background: new 0 0 32 32" xml:space="preserve">
								<g>
									<circle class="st0" cx="4" cy="16" r="2.9"/>
                  <circle class="st0" cx="16" cy="16" r="2.9"/>
                  <circle class="st0" cx="28" cy="16" r="2.9"/>
                  <circle class="st0" cx="16" cy="4" r="2.9"/>
                  <circle class="st0" cx="16" cy="28" r="2.9"/> </g>
							</svg>
              </p>
              <input type="text" :readonly="!edit_fields" class=""/></div>
          </div>
        </div>
        <div class="inputFlex2">
          <div class="">
            <label class="">placeholder</label>
            <div class="inputSvg">
              <p class="">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                     id="openDealPlaceholder" x="0px" y="0px" viewBox="0 0 32 32"
                     style="enable-background: new 0 0 32 32"
                     xml:space="preserve">
							<g>
								<circle class="st0" cx="4" cy="16" r="2.9"/>
                <circle class="st0" cx="16" cy="16" r="2.9"/>
                <circle class="st0" cx="28" cy="16" r="2.9"/>
                <circle class="st0" cx="16" cy="4" r="2.9"/>
                <circle class="st0" cx="16" cy="28" r="2.9"/> </g>
						</svg>
              </p>
              <input type="text" :readonly="!edit_fields" class=""/></div>
          </div>
          <div class="">
            <label class="">source site</label>
            <div class="inputSvg">
              <p class="">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                     id="openDealUrl" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background: new 0 0 32 32"
                     xml:space="preserve">
							<g>
								<path class="st0" d="M25.6,19.2l2.6-2.6c3.5-3.5,3.5-9.3,0-12.8s-9.3-3.5-12.8,0l-2.6,2.6"/>
                <path class="st0" d="M19.2,25.6l-2.6,2.6c-3.5,3.5-9.3,3.5-12.8,0s-3.5-9.3,0-12.8l2.6-2.6"/>
                <line class="st0" x1="22.9" y1="9.4" x2="9.4" y2="22.9"/> </g>
						</svg>
              </p>
              <input type="text" :readonly="!edit_fields"
                     v-model="return_field('UF_CRM_1608159560870').value"/>
            </div>
          </div>
        </div>

        <div class="inputFlexInner2">

          <el-button v-if="!edit_fields" class="blue-btn btn-lg mr-2" type="button" @click="()=>{edit_fields = true;}"
                     round>Edit
          </el-button>

          <el-button v-if="edit_fields" class="btn-success btn-lg mr-2" type="button" @click="()=>{ editdeal()}"
                     round>Update
          </el-button>

          <el-button v-if="edit_fields" class="btn-dark btn-lg mr-2" type="button" @click="()=>{this.$router.go()}"
                     round>Cancel
          </el-button>
        </div>

      </div>

    </div>
    <div class="delasViewRight">
      <div class="rightBackgroundWhite">
        <div class="dateTimeMain">
          <div class="dateTimeInner">
            <label class="">Start Date</label>
            <div class="dateTimeContent">
              <div class="inputSvg">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                     id="datTimeSchedule" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background: new 0 0 32 32"
                     xml:space="preserve">
								<path class="st0"
                      d="M28,31H4.1c-1.7,0-3-1.4-3-3V7.4c0-1.7,1.4-3,3-3H28c1.7,0,3,1.4,3,3V28C31,29.6,29.6,31,28,31z"/>
                  <g>
									<line class="st0" x1="9.6" y1="1.1" x2="9.6" y2="8"/>
                    <line class="st0" x1="22.1" y1="1.1" x2="22.1" y2="8"/> </g>
                  <line class="st0" x1="1.5" y1="12.6" x2="30.5" y2="12.6"/>
                  <g>
									<circle class="st1" cx="9.2" cy="21.8" r="1"/>
                    <circle class="st1" cx="13.8" cy="21.8" r="1"/>
                    <circle class="st1" cx="18.2" cy="21.8" r="1"/>
                    <circle class="st1" cx="22.8" cy="21.8" r="1"/> </g>
                  <g>
									<circle class="st1" cx="9.2" cy="25.9" r="1"/>
                    <circle class="st1" cx="13.8" cy="25.9" r="1"/>
                    <circle class="st1" cx="18.2" cy="25.9" r="1"/>
                    <circle class="st1" cx="22.8" cy="25.9" r="1"/> </g>
                  <g>
									<circle class="st1" cx="9.2" cy="17.8" r="1"/>
                    <circle class="st1" cx="13.8" cy="17.8" r="1"/>
                    <circle class="st1" cx="18.2" cy="17.8" r="1"/>
                    <circle class="st1" cx="22.8" cy="17.8" r="1"/> </g>
							</svg>
              </div>
              <div class="">
                <p style="margin-left: 15px; white-space:pre-wrap;" v-html=" date_format(user.BEGINDATE)"/>
              </div>
            </div>
          </div>
          <div class="dateTimeInner">
            <label class="">Last Modified</label>
            <div class="dateTimeContent">
              <div class="inputSvg">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                     id="datTimeSchedule" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background: new 0 0 32 32"
                     xml:space="preserve">
								<path class="st0"
                      d="M28,31H4.1c-1.7,0-3-1.4-3-3V7.4c0-1.7,1.4-3,3-3H28c1.7,0,3,1.4,3,3V28C31,29.6,29.6,31,28,31z"/>
                  <g>
									<line class="st0" x1="9.6" y1="1.1" x2="9.6" y2="8"/>
                    <line class="st0" x1="22.1" y1="1.1" x2="22.1" y2="8"/> </g>
                  <line class="st0" x1="1.5" y1="12.6" x2="30.5" y2="12.6"/>
                  <g>
									<circle class="st1" cx="9.2" cy="21.8" r="1"/>
                    <circle class="st1" cx="13.8" cy="21.8" r="1"/>
                    <circle class="st1" cx="18.2" cy="21.8" r="1"/>
                    <circle class="st1" cx="22.8" cy="21.8" r="1"/> </g>
                  <g>
									<circle class="st1" cx="9.2" cy="25.9" r="1"/>
                    <circle class="st1" cx="13.8" cy="25.9" r="1"/>
                    <circle class="st1" cx="18.2" cy="25.9" r="1"/>
                    <circle class="st1" cx="22.8" cy="25.9" r="1"/> </g>
                  <g>
									<circle class="st1" cx="9.2" cy="17.8" r="1"/>
                    <circle class="st1" cx="13.8" cy="17.8" r="1"/>
                    <circle class="st1" cx="18.2" cy="17.8" r="1"/>
                    <circle class="st1" cx="22.8" cy="17.8" r="1"/> </g>
							</svg>
              </div>
              <div class="">
                <p style="margin-left: 15px;white-space:pre-wrap;" v-html=" date_format(user.DATE_MODIFY)"/>
              </div>
            </div>
          </div>
        </div>
        <div class="dealOpenSatgeMain">
          <label class="">Stage</label>
          <div data-v-9ff36b54="" class="openDeal_staging_main">
            <div class="staging_main" v-on:click="stageRes = !stageRes">
              <div class="inquiry_svg">
                <svg
                  v-if="deal.stage"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 32 32"
                  fill="none"
                  stroke="#f16925"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  style="enable-background:new 0 0 32 32;"
                  xml:space="preserve">
                              <g v-if="deal.stage.NAME === 'New Inquiry'">
                                <g>
                                  <path
                                    class="st4"
                                    d="M12.6,19.8c0,0,9-0.6,9,9.1c0,0,0-9.1,9.3-9.1c0,0-9.3,1.1-9.3-9.1C21.6,10.7,22.4,19.8,12.6,19.8"
                                  />
                                  <path
                                    class="st4"
                                    d="M3.8,8.6c0,0,6.5-0.4,6.5,6.6c0,0,0-6.6,6.8-6.6c0,0-6.8,0.8-6.8-6.6C10.3,2,10.8,8.6,3.8,8.6"
                                  />
                                  <path
                                    class="st4"
                                    d="M1.1,24.7c0,0,5.2-0.3,5.2,5.3c0,0,0-5.3,5.4-5.3c0,0-5.4,0.6-5.4-5.3C6.3,19.4,6.7,24.7,1.1,24.7"
                                  />
                                </g>
                              </g>

                  <g
                    v-if="deal.stage.NAME === 'Contact Attempted'"
                    id="connectionattempted_00000107590379365792513880000014066502916078176431_"
                  >
                                <path
                                  d="M16,23.9L16,23.9c-0.4,0-0.8-0.5-0.8-1.2L13.9,1.6c0-0.8,0.3-1.4,0.8-1.4h2.5c0.5,0,0.9,0.6,0.8,1.4l-1.2,21.1C16.8,23.3,16.4,23.9,16,23.9z"
                                  fill="#f16925"
                                />
                    <circle cx="16" cy="29.3" r="2.7"/>
                    <path d="M10.2,7.4c-3.5,0.7-6.7,2.5-9.1,5.2"/>
                    <path d="M10.7,16.7c-1.3,0.3-2.5,1.1-3.4,2.2"/>
                    <path d="M21.8,7.4c3.5,0.7,6.7,2.5,9.1,5.2"/>
                    <path d="M21.3,16.7c1.3,0.3,2.5,1.1,3.4,2.2"/>
                              </g>

                  <g
                    v-if="
                                  deal.stage.NAME === 'Automation Off-Board'
                                "
                  >
                                <g>
                                  <g>
                                    <line
                                      class="st4"
                                      x1="20"
                                      y1="4.9"
                                      x2="20"
                                      y2="1.5"
                                    />
                                    <line
                                      class="st4"
                                      x1="12"
                                      y1="5.1"
                                      x2="12"
                                      y2="1.5"
                                    />
                                    <line
                                      class="st4"
                                      x1="12"
                                      y1="26.9"
                                      x2="12"
                                      y2="30.3"
                                    />
                                    <line
                                      class="st4"
                                      x1="20"
                                      y1="26.6"
                                      x2="20"
                                      y2="30.3"
                                    />
                                  </g>
                                  <g>
                                    <line
                                      class="st4"
                                      x1="1.4"
                                      y1="19.8"
                                      x2="4.7"
                                      y2="19.8"
                                    />
                                    <line
                                      class="st4"
                                      x1="1.1"
                                      y1="11.8"
                                      x2="4.7"
                                      y2="11.8"
                                    />
                                    <line
                                      class="st4"
                                      x1="27.5"
                                      y1="19.8"
                                      x2="30.9"
                                      y2="19.8"
                                    />
                                    <line
                                      class="st4"
                                      x1="27.3"
                                      y1="11.8"
                                      x2="30.9"
                                      y2="11.8"
                                    />
                                  </g>
                                  <rect
                                    x="9.6"
                                    y="9.4"
                                    class="st4"
                                    width="12.9"
                                    height="13.1"
                                  />
                                </g>
                              </g>

                  <g
                    v-if="deal.stage.NAME === 'Contact Established'"
                  >
                                <circle
                                  class="st14"
                                  cx="16"
                                  cy="26.4"
                                  r="2.7"
                                />
                    <path
                      class="st4"
                      d="M7.4,16c1.1-1.5,4.7-3.1,8.8-3.1c3.8,0,7,1.7,8.4,3.1"
                    />
                    <path
                      class="st4"
                      d="M1.1,9.6C3,7,9.2,4.3,16.4,4.3c6.6,0,12.2,3,14.6,5.3"
                    />
                              </g>

                  <g
                    v-if="deal.stage.NAME === 'Active Prospects'"
                    id="activeprospects"
                  >
                                <circle
                                  class="st4"
                                  cx="10.9"
                                  cy="9.4"
                                  r="6.4"
                                />
                    <circle
                      class="st4"
                      cx="24.3"
                      cy="4.2"
                      r="3.1"
                    />
                    <path
                      class="st4"
                      d="M20.6,13.7c0.5-1.4,1.6-3.3,4.3-3.3c3.3,0,4.3,3.4,4.6,5.2c0.1,0.5,0.2,1,0.3,1.5c0.2,1.2,0,4.2-7.1,4.2"
                    />
                    <path
                      class="st4"
                      d="M2.1,30.9l0-0.4c0-2.6,0.7-5.2,2-7.3c1.3-2,3.4-3.7,6.6-3.7c3.2,0,5.4,1.7,6.8,3.7c1.4,2.1,2.1,4.8,2.1,7.5
    v0.1"
                    />
                              </g>

                  <g v-if="deal.stage.NAME === '1003 Requested'">
                                <rect
                                  x="1.1"
                                  y="1.1"
                                  class="st4"
                                  width="12.2"
                                  height="12.2"
                                />
                    <rect
                      x="18.8"
                      y="1.1"
                      class="st4"
                      width="12.2"
                      height="12.2"
                    />
                    <rect
                      x="1.1"
                      y="18.8"
                      class="st4"
                      width="12.2"
                      height="12.2"
                    />
                    <rect
                      x="18.8"
                      y="18.8"
                      class="st4"
                      width="12.2"
                      height="12.2"
                    />
                    <polyline
                      class="st4"
                      points="9.9,23.1 6.2,26.8 4.7,25.2 	"
                    />
                              </g>

                  <g v-if="deal.stage.NAME === '1003 Completed'">
                                <rect
                                  x="1.1"
                                  y="1.1"
                                  width="12.2"
                                  height="12.2"
                                />
                    <rect
                      x="18.8"
                      y="1.1"
                      width="12.2"
                      height="12.2"
                    />
                    <rect
                      x="1.1"
                      y="18.8"
                      width="12.2"
                      height="12.2"
                    />
                    <rect
                      x="18.8"
                      y="18.8"
                      width="12.2"
                      height="12.2"
                    />
                    <polyline
                      points="9.9,23.1 6.2,26.8 4.7,25.2 	"
                    />
                    <polyline
                      points="27.3,23.1 23.7,26.8 22.1,25.2 	"
                    />
                    <polyline points="9.9,5.4 6.2,9.1 4.7,7.5 	"/>
                    <polyline
                      points="27.4,5.4 23.7,9.1 22.2,7.5 	"
                    />
                              </g>

                  <g v-if="deal.stage.NAME === 'Credit Run'">
                                <g>
                                  <path
                                    class="st4"
                                    d="M28.5,25.5H9.4c-1.4,0-2.4-1.1-2.4-2.4V8.9c0-1.4,1.1-2.4,2.4-2.4h19.1c1.4,0,2.4,1.1,2.4,2.4v14.2
			C30.9,24.4,29.8,25.5,28.5,25.5z"
                                  />
                                  <line
                                    class="st4"
                                    x1="7.3"
                                    y1="12.4"
                                    x2="30.7"
                                    y2="12.4"
                                  />
                                  <line
                                    class="st4"
                                    x1="3.7"
                                    y1="11.3"
                                    x2="2.3"
                                    y2="11.3"
                                  />
                                  <line
                                    class="st4"
                                    x1="3.8"
                                    y1="16"
                                    x2="1.1"
                                    y2="16"
                                  />
                                  <line
                                    class="st4"
                                    x1="2.6"
                                    y1="20.8"
                                    x2="1.1"
                                    y2="20.8"
                                  />
                                  <circle
                                    class="st14"
                                    cx="27.1"
                                    cy="22.4"
                                    r="1"
                                  />
                                  <circle
                                    class="st14"
                                    cx="25.8"
                                    cy="22.4"
                                    r="1"
                                  />
                                </g>
                              </g>
                  <g v-if="deal.stage.NAME === 'Pre-Approval'">
                                <circle class="st4" cx="16" cy="10" r="4.4"/>
                    <polygon
                      class="st4"
                      points="15.9,1.1 11.1,2.5 7.8,6.3 7.1,11.3 9.2,15.8 13.4,18.6 18.5,18.6 22.7,15.8 24.8,11.3 24.1,6.3
		20.8,2.5 	"
                    />
                    <polyline
                      class="st4"
                      points="10,17.6 3.3,26.8 7.2,27.7 9.1,30.9 15.7,19.5 	"
                    />
                    <polyline
                      class="st4"
                      points="22,17.6 28.7,26.8 24.8,27.7 22.9,30.9 16.3,19.5 	"
                    />
                              </g>
                  <g v-if="deal.stage.NAME === 'Doc Requested'">
                                <polyline
                                  class="st4"
                                  points="24.9,20.8 24.9,30.9 1.1,30.9 1.1,1.1 24.9,1.1 24.9,6.7 	"
                                />
                    <line
                      class="st4"
                      x1="6.8"
                      y1="6.9"
                      x2="19.1"
                      y2="6.9"
                    />
                    <line
                      class="st4"
                      x1="6.8"
                      y1="13"
                      x2="17.7"
                      y2="13"
                    />
                    <line
                      class="st4"
                      x1="6.8"
                      y1="19.1"
                      x2="11.1"
                      y2="19.1"
                    />
                    <line
                      class="st4"
                      x1="6.8"
                      y1="25.1"
                      x2="11.1"
                      y2="25.1"
                    />
                    <path
                      class="st14"
                      d="M14.9,21v2.1c0,0.2,0.2,0.4,0.4,0.4h2.1c0.4,0,0.5-0.4,0.3-0.7c-0.6-0.6-1.5-1.5-2.1-2.1
		C15.4,20.5,14.9,20.7,14.9,21z"
                    />
                    <path
                      class="st14"
                      d="M18.6,22.1l-2.2-2.2c-0.2-0.2-0.2-0.4,0-0.6l8.1-8.1c0.2-0.2,0.4-0.2,0.6,0l2.2,2.2c0.2,0.2,0.2,0.4,0,0.6
		l-8.1,8.1C19,22.3,18.7,22.3,18.6,22.1z"
                    />
                    <path
                      class="st14"
                      d="M28.1,12.6l-2.2-2.2c-0.2-0.2-0.2-0.4,0-0.6l1.3-1.3c0.2-0.2,0.4-0.2,0.6,0l2.2,2.2c0.2,0.2,0.2,0.4,0,0.6
		l-1.3,1.3C28.5,12.8,28.2,12.8,28.1,12.6z"
                    />
                              </g>
                  <g v-if="deal.stage.NAME === 'Docs Back'">
                                <polyline
                                  class="st4"
                                  points="12.9,30.9 1.1,30.9 1.1,1.1 24.9,1.1 24.9,9.7 	"
                                />
                    <line
                      class="st4"
                      x1="6.8"
                      y1="6.9"
                      x2="19.1"
                      y2="6.9"
                    />
                    <line
                      class="st4"
                      x1="6.8"
                      y1="13"
                      x2="13.7"
                      y2="13"
                    />
                    <line
                      class="st4"
                      x1="6.8"
                      y1="19.1"
                      x2="10.1"
                      y2="19.1"
                    />
                    <line
                      class="st4"
                      x1="6.8"
                      y1="25.1"
                      x2="10.1"
                      y2="25.1"
                    />
                    <circle
                      class="st4"
                      cx="22.2"
                      cy="22.2"
                      r="8.8"
                    />
                    <polyline
                      class="st4"
                      points="25,20.5 20.7,24.8 18.9,22.9 	"
                    />
                              </g>
                  <g v-if="deal.stage.NAME === 'Submitted'">
                                <g>
                                  <polyline
                                    class="st4"
                                    points="7.5,9.6 16,1.1 24.5,9.6 		"
                                  />
                                  <line
                                    class="st4"
                                    x1="16"
                                    y1="1.1"
                                    x2="16"
                                    y2="22.2"
                                  />
                                </g>
                    <polyline
                      class="st4"
                      points="24.3,26.8 24.3,30.9 7.3,30.9 7.3,26.8 	"
                    />
                              </g>
                  <g v-if="deal.stage.NAME === 'Archive'">
                                <path
                                  class="st4"
                                  d="M30,16H2c-0.5,0-0.9-0.4-0.9-0.9V6.1c0-0.5,0.4-0.9,0.9-0.9h28c0.5,0,0.9,0.4,0.9,0.9v8.9
		C30.9,15.6,30.5,16,30,16z"
                                />
                    <path
                      class="st4"
                      d="M30.9,14.4v11.6c0,0.4-0.4,0.7-0.9,0.7H2c-0.5,0-0.9-0.3-0.9-0.7V14.4"
                    />
                    <polyline
                      class="st4"
                      points="23.1,9.6 23.1,11.5 9,11.5 9,9.6 	"
                    />
                    <polyline
                      class="st4"
                      points="23.1,20.4 23.1,22.3 9,22.3 9,20.4 	"
                    />
                              </g>
                  <g v-if="deal.stage.NAME === 'Recycled Stage'">
                                <g>
                                  <path
                                    class="st4"
                                    d="M15.8,5.3c-6,0-11,4.2-12.2,9.7"
                                  />
                                  <path
                                    class="st4"
                                    d="M27,23.8c0.9-1.7,1.4-3.7,1.4-5.9c0-4.6-2.5-8.6-6.2-10.8"
                                  />
                                  <path
                                    class="st4"
                                    d="M3.6,20.7c1.3,5.6,6.3,9.8,12.2,9.8c2.7,0,5.2-0.8,7.2-2.3"
                                  />
                                  <polyline
                                    class="st4"
                                    points="14.1,1.5 17.6,5.1 14.1,8.6 		"
                                  />
                                  <polyline
                                    class="st4"
                                    points="30.9,22.6 26.2,24.3 24.6,19.6 		"
                                  />
                                  <polyline
                                    class="st4"
                                    points="1.1,24.4 3.3,19.9 7.7,22.1 		"
                                  />
                                </g>
                              </g>
                  <g v-if="deal.stage.NAME === 'Deal lost'">
                                <path
                                  d="M30.5,12.3l-9.9-1.1c-0.2,0-0.2-0.1-0.3-0.2l-4-9.1c-0.2-0.3-0.6-0.3-0.7,0l-4,9.1c-0.1,0.2-0.2,0.2-0.3,0.2
		l-9.8,1.1c-0.3,0-0.5,0.5-0.2,0.7l7.3,6.6c0.1,0.1,0.2,0.2,0.2,0.4l-2,9.6c-0.1,0.3,0.3,0.6,0.6,0.5l8.6-4.9c0.2-0.1,0.3-0.1,0.4,0
		l8.6,4.9c0.3,0.2,0.7-0.1,0.6-0.5l-2-9.6c0-0.2,0-0.3,0.2-0.4l7.3-6.6C31.1,12.8,30.9,12.3,30.5,12.3z"
                                />
                              </g>
                  <g v-if="deal.stage.NAME === 'Deal won'">
                                <path
                                  fill="#f16925"
                                  d="M30.5,12.3l-9.9-1.1c-0.2,0-0.2-0.1-0.3-0.2l-4-9.1c-0.2-0.3-0.6-0.3-0.7,0l-4,9.1c-0.1,0.2-0.2,0.2-0.3,0.2
		l-9.8,1.1c-0.3,0-0.5,0.5-0.2,0.7l7.3,6.6c0.1,0.1,0.2,0.2,0.2,0.4l-2,9.6c-0.1,0.3,0.3,0.6,0.6,0.5l8.6-4.9c0.2-0.1,0.3-0.1,0.4,0
		l8.6,4.9c0.3,0.2,0.7-0.1,0.6-0.5l-2-9.6c0-0.2,0-0.3,0.2-0.4l7.3-6.6C31.1,12.8,30.9,12.3,30.5,12.3z"
                                />
                              </g>
                            </svg>
              </div>
              <div class="Inquiry_context">
                <div class="inquiry_rating">
                  <svg
                    v-for="stage of deal.stages"
                    :key="stage"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 15 15"
                    xml:space="preserve">
                    <g :id="parseInt(stage.SORT) <= parseInt(deal.stage ? deal.stage.SORT : null)
                                      ? 'stage_rate_box1'
                                      : 'stage_rate_box'
                                  ">
                      <rect
                        x="1.1"
                        y="1.1"
                        width="12.2"
                        height="12.2"
                        class="st4"></rect>
                    </g>
                  </svg>
                </div>
                <p class="inquiry">
                  {{ deal.stage ? deal.stage.NAME : "" }}
                </p>
              </div>
            </div>
          </div>

          <div v-if="stageRes" data-v-41552ae3="" tabindex="-1" role="dialog" class="DealStageDropResult"
               id="DealStageDropResult" style="">
            <div role="document" class="modal-dialog">
              <div class="modal-content"><!---->
                <div class="modal-body">
                  <div class="inputFlexInner">
                    <a href="javascript:void(0);" @click="()=>{stageRes = !stageRes; deal.stage = old_stage}"
                       class="closebtn-serch stageCloseBtn">×</a>
                    <label>Set Stage To</label>
                    <div class="inputSvg">
                      <p>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                             id="openDealleads" x="0px" y="0px" viewBox="0 0 32 32" xml:space="preserve">
                          <path
                            d="M3.1,13.8V7.6h25.8v6.1c0,0.5-0.2,0.9-0.5,1.3l-7.2,7.1c-0.2,0.2-0.3,0.4-0.3,0.6v5c0,0.4-0.3,0.7-0.6,0.8  l-8.2,2.2c-0.5,0.1-1.1-0.3-1.1-0.8v-7.1c0-0.2-0.1-0.4-0.2-0.6L3.7,15C3.3,14.7,3.1,14.2,3.1,13.8z"
                            class="st0">
                          </path>
                          <line x1="3.4" y1="11.9" x2="28.6" y2="11.9" class="st0"></line>
                          <circle cx="10.9" cy="2.7" r="1.5" class="st1"></circle>
                          <circle cx="15.9" cy="1.7" r="1.5" class="st1"></circle>
                          <circle cx="20.2" cy="3.8" r="1.5" class="st1"></circle>
                        </svg>
                      </p>
                      <el-select class="select-primary stageNewClass" size="large" placeholder="Stage"
                                 v-model="status.selected.stage">
                        <el-option class="select-info" value="" label="Stages"></el-option>
                        <el-option
                          v-for="option in selected_stages()" class="select-info"
                          :value="option.id" :label="option.NAME" :key="option.id">
                        </el-option>
                      </el-select>
                      <button v-if="status.selected.stage!==''" @click="()=>{dropdown_status_change()}"
                              class="submotdr">update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dealOpenResponsibleMain" :key="componentKey">
          <label class=""> Responsible </label>
          <div class="ResponsibleMainInner">
            <div v-for="responsible of selected_responsibles" :key="responsible.id">
              <div class="outUser">
                <div class="userShrink">
                  <img
                    :src="responsible !== undefined && responsible.image !== null ? responsible.image : '/img/mike.jpg'">
                </div>
                <div class="">
                  <h2 class="">{{ responsible != null ? responsible.name : '' }}</h2>
                  <p class="">{{ responsible != null ? responsible.email : '' }}</p>
                </div>
                <div class="">
                  <p class="FourSvg" @click="()=>{add_user(responsible)}">
                    <span class="cross-hid">X</span>
                  </p>
                </div>
              </div>
            </div>

            <div class="outUser">
              <div class="">
                <p class="FourSvg" @click="()=>{dealViewSearchModal = true;focus_search()}">
                  <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                       xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1"
                          d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                  </svg>
                </p>

              </div>
              <div class="">
                <h2 class="">Additional Party</h2>
                <p class="">Job Title</p>
              </div>
            </div>
          </div>
          <div v-if="dealViewSearchModal" data-v-41552ae3="" tabindex="-1" role="dialog" class="searchthis"
               id="search" style="">
            <div role="document" class="modal-dialog">
              <div class="modal-content">
                <div class="modal-body">
                  <div class="relative-d">
                    <input placeholder="Search" id="search_popup_input_view" @keyup="searching()"
                           v-model="search_query">


                    <svg xmlns="http://www.w3.org/2000/svg" class="search-svg seers" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                    </svg>


                    <a href="javascript:void(0);" class="closebtn-serch" @click="dealViewSearchModal = false">×</a>
                  </div>
                  <progress-bar class="fr-mr" :bar-color="progress_bar.bar_color" :val="progress_bar.val"
                                :max="progress_bar.max"
                                :spacing="progress_bar.spacing" :text="progress_bar.text"
                                :text-align="progress_bar.text_align"
                                :text-position="progress_bar.text_position"
                                :bar-border-radius="progress_bar.bar_border_radius"
                                :text-fg-color="progress_bar.text_fg_color"></progress-bar>
                  <div class="main-drop-wrap" :style="search_height">
                    <div class="Employess-main" v-if="search_results.employees.length > 0">
                      <h2>Employees</h2>
                      <div class="allData">
                        <div class="employeeDataActive" v-for="employee of search_results.employees" :key="employee.id"
                             :style="{'background-color': is_responsible(employee) ? '#f16925' :'#fff'}">
                          <a @click="add_user(employee)" href="javascript:return(0);">
                            <img src="../../../public/img/svg/agents.svg">
                            <span style="color : #222a42;">
                      {{ employee.name }}
                    </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="inputFlexInner">
          <label>Pipeline</label>
          <div class="inputSvg">
            <p>
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                   id="openDealleads" x="0px" y="0px" viewBox="0 0 32 32" xml:space="preserve">
            <path
              d="M3.1,13.8V7.6h25.8v6.1c0,0.5-0.2,0.9-0.5,1.3l-7.2,7.1c-0.2,0.2-0.3,0.4-0.3,0.6v5c0,0.4-0.3,0.7-0.6,0.8  l-8.2,2.2c-0.5,0.1-1.1-0.3-1.1-0.8v-7.1c0-0.2-0.1-0.4-0.2-0.6L3.7,15C3.3,14.7,3.1,14.2,3.1,13.8z"
              class="st0"></path>
                <line x1="3.4" y1="11.9" x2="28.6" y2="11.9" class="st0"></line>
                <circle cx="10.9" cy="2.7" r="1.5" class="st1"></circle>
                <circle cx="15.9" cy="1.7" r="1.5" class="st1"></circle>
                <circle cx="20.2" cy="3.8" r="1.5" class="st1"></circle>
            </svg>
            </p>

            <el-select class="select-primary stageNewClass pipelineStage" placeholder="Pipeline"
                       v-model="status.selected.pipline" @change="pipeline_change">
              <el-option class="select-info" value="" label="Pipelines"></el-option>
              <el-option v-for="option in status.pipline" class="select-info"
                         :value="option.id" :label="option.NAME" :key="option.id"
                         :selected="option.id === old_pipeline.id">
              </el-option>
            </el-select>
            <button class="submotdr" @click="()=>{dropdown_status_change()}"
                    v-if="pipeline_changed && status.selected.pipline!==''">update
            </button>
          </div>
        </div>
        <div class="inputFlexInner TagsMain">
          <div class="inputSvg updateFlex">
            <label>Tags Preset</label>
            <button class="submotdr" @click="()=>{editdealtags()}"
                    v-if="tags_changed">update
            </button>
          </div>
          <div class="inputSvg tags-svg">
            <p>
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                   id="openDealtags" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;"
                   xml:space="preserve">
            <circle class="st0" cx="23.1" cy="8.7" r="2.5"/>
                <path class="st0"
                      d="M28.8,16.7L15.3,30.2c-1,1-2.5,1-3.5,0l-10-10c-1-1-1-2.5,0-3.5L15.3,3.2l0,0c1.4-1.4,3.2-2.2,5.2-2.2h7.8  c1.5,0,2.6,1.2,2.6,2.6v7.8C31,13.5,30.2,15.3,28.8,16.7L28.8,16.7z"/>
            </svg>
            </p>

            <div class="inputFlexInner">
              <multiselect class="select-icon" :option-height="60" v-model="value" :options="options" :multiple="true"
                           group-values="polus_tags" @tag="addTag" :taggable="true" :allow-empty="false"
                           group-label="name" :group-select="false" @input="()=>{tags_changed=true}"
                           placeholder="Type to search" track-by="name"
                           label="name">
              </multiselect>

            </div>


          </div>
        </div>

        <div class="inputFlexInner TagsMain">
          <div class="inputSvg updateFlex">
            <label>Extra Tags</label>
            <button class="submotdr" @click="()=>{editdealextratags()}"
                    v-if="extra_tags_changed">update
            </button>
          </div>
          <div class="inputSvg heightSvg">
            <p>
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                   id="openDealtags" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;"
                   xml:space="preserve">
            <circle class="st0" cx="23.1" cy="8.7" r="2.5"/>
                <path class="st0"
                      d="M28.8,16.7L15.3,30.2c-1,1-2.5,1-3.5,0l-10-10c-1-1-1-2.5,0-3.5L15.3,3.2l0,0c1.4-1.4,3.2-2.2,5.2-2.2h7.8  c1.5,0,2.6,1.2,2.6,2.6v7.8C31,13.5,30.2,15.3,28.8,16.7L28.8,16.7z"/>
            </svg>
            </p>

            <div class="inputFlexInner extraTagsSelect">
              <multiselect :option-height="60" v-model="extra_tag_value" :options="extra_tag_options" :multiple="true"
                           group-values="polus_tags" @tag="addExtraNewTag" :taggable="true" :allow-empty="false"
                           group-label="name" :group-select="false" @input="()=>{extra_tags_changed=true}"
                           placeholder="Type to search" track-by="name"
                           label="name">
              </multiselect>

            </div>


          </div>
        </div>

      </div>
      <div class="rightBackgroundWhite">
        <div class="FourSvgFlex">
          <p class="FourSvg" @click="newToggleTabs('new_call_tab')"
             :style="(newActiveTab('new_call_tab') ? 'background-color: #f16925; ': '')">
            <!--             v-on:click="toggle_phone(deal)">-->
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                 id="openDealPhone" x="0px" y="0px" viewBox="0 0 32 32" xml:space="preserve">
						<g>
							<path :style="(newActiveTab('new_call_tab') ? 'fill: #fff; ': '')"
                    d="M7.1,2.1L7.1,2.1c0.6,0,1.1,0.3,1.5,0.7c0,0,0.1,0.1,0.1,0.1c1.1,1,2.4,2.3,3.6,3.5c0.5,0.5,0.7,1,0.7,1.6   c0,0.5-0.3,1.1-0.7,1.6c0,0-0.1,0.1-0.1,0.1c-1.5,1.7-1.7,4.2-0.4,6.1c0.8,1.2,1.6,1.9,2,2.3c0,0,0,0,0,0c0.2,0.3,0.6,0.7,1.2,1.2   c1.1,1,2.5,1.5,4,1.5c1,0,2-0.2,2.9-0.7c0.2-0.1,0.3-0.2,0.5-0.4c0.5-0.5,1-0.7,1.6-0.7c0.5,0,1.1,0.3,1.6,0.7l3.7,3.7   c0.9,0.9,0.9,2.1,0,3l-0.8,0.8l-0.1,0.1c-0.5,0.5-1,0.9-1.4,1.4c-0.8,0.8-1.8,1.2-3.1,1.2c-1.1,0-2.4-0.3-3.9-0.8   c-3.6-1.3-7.1-3.6-10.2-6.8c-3-2.9-5.4-6.5-6.7-10.1c-0.8-2.2-1.6-5.2,0.3-7c0,0,0.1-0.1,0.1-0.1l0.7-0.7c0.5-0.5,1-1,1.5-1.5   c0,0,0.1-0.1,0.1-0.1C6.1,2.4,6.6,2.1,7.1,2.1 M7.1,0.1c-0.8,0-1.8,0.3-2.8,1.3C3.7,1.9,3.2,2.4,2.6,3L1.9,3.7   c-3.1,2.8-1.4,7.6-0.8,9.1c1.5,3.9,4,7.8,7.2,10.9c3.3,3.3,7.1,5.8,10.9,7.2c1.7,0.6,3.3,1,4.6,1c1.9,0,3.4-0.6,4.5-1.8   c0.4-0.5,0.9-0.9,1.4-1.4l0.8-0.8c1.7-1.7,1.7-4.2,0-5.9l-3.7-3.7c-1.1-1.1-2.2-1.3-3-1.3c-0.7,0-1.9,0.2-3,1.3   c-0.6,0.3-1.3,0.5-1.9,0.5c-1,0-1.9-0.3-2.7-1c-0.5-0.4-0.8-0.8-1-1c0,0-0.1-0.1-0.1-0.1c-0.4-0.3-1-0.9-1.7-1.9   c-0.8-1.1-0.7-2.6,0.2-3.6l0,0c1.1-1.1,1.3-2.2,1.3-3c0-0.8-0.2-1.9-1.3-3c-1.2-1.2-2.5-2.5-3.6-3.5C9.3,0.6,8.3,0.2,7.2,0.1   C7.2,0.1,7.1,0.1,7.1,0.1L7.1,0.1z"
                    class="st0"></path>
						</g>
					</svg>
          </p>
          <p class="FourSvg" @click="newToggleTabs('new_message_tab')"
             :style="(newActiveTab('new_message_tab') ? 'background-color: #f16925; ': '')">
            <svg data-v-9ff36b54="" xmlns="http://www.w3.org/2000/svg" id="sms_lead" viewBox="0 0 32 32">
              <path :style="(newActiveTab('new_message_tab') ? 'stroke: #fff; ': '')" data-v-9ff36b54=""
                    d="M12.44,21.82H4.81a3.7,3.7,0,0,1-3.7-3.69V6.41a3.7,3.7,0,0,1,3.7-3.7H27.19a3.7,3.7,0,0,1,3.7,3.7V18.13a3.7,3.7,0,0,1-3.7,3.69h0a1.55,1.55,0,0,0-1.55,1.55v3.8a2.13,2.13,0,0,1-3.49,1.63l-7.3-6.1A3.73,3.73,0,0,0,12.44,21.82Z"
                    class="cls-1"></path>
              <circle :style="(newActiveTab('new_message_tab') ? 'fill: #fff; ': '')" data-v-9ff36b54="" cx="10.29"
                      cy="12.67" r="1.4" class="cls-2"></circle>
              <circle :style="(newActiveTab('new_message_tab') ? 'fill: #fff; ': '')" data-v-9ff36b54="" cx="16"
                      cy="12.67" r="1.4" class="cls-2"></circle>
              <circle :style="(newActiveTab('new_message_tab') ? 'fill: #fff; ': '')" data-v-9ff36b54="" cx="21.71"
                      cy="12.67" r="1.4" class="cls-2"></circle>
            </svg>
          </p>
          <p class="FourSvg" @click="newToggleTabs('new_notes_tab')"
             :style="(newActiveTab('new_notes_tab') ? 'background-color: #f16925; ': '')">
            <svg data-v-9ff36b54="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                 version="1.1" x="0px" y="0px" viewBox="0 0 32 32" xml:space="preserve">
						<g data-v-9ff36b54="" id="doc_requested_leads">
							<polyline :style="(newActiveTab('new_notes_tab') ? 'stroke: #fff; ': '')" data-v-9ff36b54=""
                        points="24.9,20.8 24.9,30.9 1.1,30.9 1.1,1.1 24.9,1.1 24.9,6.7  "
                        class="st4"></polyline>
              <line :style="(newActiveTab('new_notes_tab') ? 'stroke: #fff; ': '')" data-v-9ff36b54="" x1="6.8" y1="6.9"
                    x2="19.1" y2="6.9" class="st4"></line>
              <line :style="(newActiveTab('new_notes_tab') ? 'stroke: #fff; ': '')" data-v-9ff36b54="" x1="6.8" y1="13"
                    x2="17.7" y2="13" class="st4"></line>
              <line :style="(newActiveTab('new_notes_tab') ? 'stroke: #fff; ': '')" data-v-9ff36b54="" x1="6.8"
                    y1="19.1" x2="11.1" y2="19.1" class="st4"></line>
              <line :style="(newActiveTab('new_notes_tab') ? 'stroke: #fff; ': '')" data-v-9ff36b54="" x1="6.8"
                    y1="25.1" x2="11.1" y2="25.1" class="st4"></line>
              <path :style="(newActiveTab('new_notes_tab') ? 'fill: #fff; ': '')" data-v-9ff36b54=""
                    d="M14.9,21v2.1c0,0.2,0.2,0.4,0.4,0.4h2.1c0.4,0,0.5-0.4,0.3-0.7c-0.6-0.6-1.5-1.5-2.1-2.1   C15.4,20.5,14.9,20.7,14.9,21z"
                    class="st14"></path>
              <path :style="(newActiveTab('new_notes_tab') ? 'fill: #fff; ': '')" data-v-9ff36b54=""
                    d="M18.6,22.1l-2.2-2.2c-0.2-0.2-0.2-0.4,0-0.6l8.1-8.1c0.2-0.2,0.4-0.2,0.6,0l2.2,2.2c0.2,0.2,0.2,0.4,0,0.6   l-8.1,8.1C19,22.3,18.7,22.3,18.6,22.1z"
                    class="st14"></path>
              <path :style="(newActiveTab('new_notes_tab') ? 'fill: #fff; ': '')" data-v-9ff36b54=""
                    d="M28.1,12.6l-2.2-2.2c-0.2-0.2-0.2-0.4,0-0.6l1.3-1.3c0.2-0.2,0.4-0.2,0.6,0l2.2,2.2c0.2,0.2,0.2,0.4,0,0.6   l-1.3,1.3C28.5,12.8,28.2,12.8,28.1,12.6z"
                    class="st14"></path>
						</g>
					</svg>
          </p>
          <p class="FourSvg" @click="newToggleTabs('new_email_tab')"
             :style="(newActiveTab('new_email_tab') ? 'background-color: #f16925; ': '')">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                 id="openDealEmail" x="0px" y="0px" viewBox="0 0 32 32" xml:space="preserve">
						<g>
							<g>
								<path :style="(newActiveTab('new_email_tab') ? 'fill: #fff; ': '')"
                      d="M29.9,6.2v19.6H2.1V6.2H29.9 M30.1,4.2H1.9c-1,0-1.8,0.8-1.8,1.8v19.9c0,1,0.8,1.8,1.8,1.8h28.2    c1,0,1.8-0.8,1.8-1.8V6.1C31.9,5.1,31.1,4.2,30.1,4.2L30.1,4.2z"
                      class="st0"></path>
							</g>
              <path :style="(newActiveTab('new_email_tab') ? 'stroke: #fff; ': '')"
                    d="M1.5,5.5L14,16.6c1.2,1.1,3.1,1.1,4.3,0L30.8,5.5" class="st1"></path>
						</g>
					</svg>
          </p>
        </div>
        <CallTab :object="deal" :type="'deal'" v-if="newActiveTab('new_call_tab')"></CallTab>
        <MessageTab :object="deal" :type="'deal'" v-if="newActiveTab('new_message_tab')"></MessageTab>
        <NotesTab :object="deal" :type="'deal'" v-if="newActiveTab('new_notes_tab')"></NotesTab>
        <EmailTab :object="deal" :type="'deal'" v-if="newActiveTab('new_email_tab')"></EmailTab>
      </div>
      <div class="rightBackgroundWhite">
        <div class="tab">
          <button class="tablinks b-nth-child" @click="toggleTabs('combined_tab')"
                  :style="(activeTab('combined_tab') ? 'background-color: #f16925; ': '')">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                 id="openDealStageAll" x="0px" y="0px" viewBox="0 0 32 32"
                 xml:space="preserve">
						<g>
							<polygon :style="(activeTab('combined_tab') ? 'stroke: #fff; ': '')"
                       class="st0" points="31,9.7 16,18.1 1,9.7 16,1.3  "/>
              <polyline :style="(activeTab('combined_tab') ? 'stroke: #fff; ': '')" class="st0"
                        points="31,16 16,24.4 1,16  "/>
              <polyline :style="(activeTab('combined_tab') ? 'stroke: #fff; ': '')" class="st0"
                        points="31,22.3 16,30.7 1,22.3  "/> </g>
					</svg>
          </button>
          <button class="tablinks b-nth-child" @click="toggleTabs( 'stage_tab')"
                  :style="(activeTab('stage_tab') ? 'background-color: #f16925;': '')">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns: xlink="http://www.w3.org/1999/xlink" version="1.1"
                 id="openDealStageChange" x="0px" y="0px" viewBox="0 0 32 32"
                 xml: space="preserve">
              <polygon :style="(activeTab('stage_tab') ? 'stroke: #fff; ': 'stroke: #245482; ')" class="st0"
                       points="9.1,13.8 15.9,7 22.6,13.8 "/>
              <polygon :style="(activeTab('stage_tab') ? 'stroke: #fff; ': 'stroke: #245482; ')" class="st0"
                       points="22.6,18.7 15.9,25.4 9.1,18.7 "/>
              <circle :style="(activeTab('stage_tab') ? 'stroke: #fff; ': 'stroke: #245482; ')" class="st0" cx="16"
                      cy="16" r="14.9"/>
            </svg>
          </button>
          <button class="tablinks b-nth-child" @click="toggleTabs('deal_notes_tab')"
                  :style="(activeTab('deal_notes_tab') ? 'background-color: #f16925;': '')">
            <svg data-v-9ff36b54="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                 version="1.1" x="0px" y="0px" viewBox="0 0 32 32" xml:space="preserve"
            >
						<g data-v-9ff36b54="" id="openDealDocs">
							<polyline :style="(activeTab('deal_notes_tab') ? 'stroke: #fff; ': 'stroke: #245482; ')"
                        data-v-9ff36b54="" points="24.9,20.8 24.9,30.9 1.1,30.9 1.1,1.1 24.9,1.1 24.9,6.7  "
                        class="st4"></polyline>
              <line :style="(activeTab('deal_notes_tab') ? 'stroke: #fff; ': 'stroke: #245482; ')" data-v-9ff36b54=""
                    x1="6.8" y1="6.9" x2="19.1" y2="6.9" class="st4"></line>
              <line :style="(activeTab('deal_notes_tab') ? 'stroke: #fff; ': 'stroke: #245482; ')" data-v-9ff36b54=""
                    x1="6.8" y1="13" x2="17.7" y2="13" class="st4"></line>
              <line :style="(activeTab('deal_notes_tab') ? 'stroke: #fff; ': 'stroke: #245482; ')" data-v-9ff36b54=""
                    x1="6.8" y1="19.1" x2="11.1" y2="19.1" class="st4"></line>
              <line :style="(activeTab('deal_notes_tab') ? 'stroke: #fff; ': 'stroke: #245482; ')" data-v-9ff36b54=""
                    x1="6.8" y1="25.1" x2="11.1" y2="25.1" class="st4"></line>
              <path :style="(activeTab('deal_notes_tab') ? 'fill: #fff; ': 'fill: #245482; ')" data-v-9ff36b54=""
                    d="M14.9,21v2.1c0,0.2,0.2,0.4,0.4,0.4h2.1c0.4,0,0.5-0.4,0.3-0.7c-0.6-0.6-1.5-1.5-2.1-2.1   C15.4,20.5,14.9,20.7,14.9,21z"
                    class="st14"></path>
              <path :style="(activeTab('deal_notes_tab') ? 'fill: #fff; ': 'fill: #245482; ')" data-v-9ff36b54=""
                    d="M18.6,22.1l-2.2-2.2c-0.2-0.2-0.2-0.4,0-0.6l8.1-8.1c0.2-0.2,0.4-0.2,0.6,0l2.2,2.2c0.2,0.2,0.2,0.4,0,0.6   l-8.1,8.1C19,22.3,18.7,22.3,18.6,22.1z"
                    class="st14"></path>
              <path :style="(activeTab('deal_notes_tab') ? 'fill: #fff; ': 'fill: #245482; ')" data-v-9ff36b54=""
                    d="M28.1,12.6l-2.2-2.2c-0.2-0.2-0.2-0.4,0-0.6l1.3-1.3c0.2-0.2,0.4-0.2,0.6,0l2.2,2.2c0.2,0.2,0.2,0.4,0,0.6   l-1.3,1.3C28.5,12.8,28.2,12.8,28.1,12.6z"
                    class="st14"></path>
						</g>
					</svg>
          </button>
          <button class="tablinks b-nth-child" @click="toggleTabs('call_tab')"
                  :style="(activeTab('call_tab') ? 'background-color: #f16925;': '')">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns: xlink="http://www.w3.org/1999/xlink" version="1.1"
                 id="openDealPhone" x="0px" y="0px" viewBox="0 0 32 32" xml: space="preserve"
            >
              <g>
                <path :style="(activeTab('call_tab') ? 'fill: #fff; ': 'fill: #245482; ')"
                      d="M7.1,2.1L7.1,2.1c0.6,0,1.1,0.3,1.5,0.7c0,0,0.1,0.1,0.1,0.1c1.1,1,2.4,2.3,3.6,3.5c0.5,0.5,0.7,1,0.7,1.6   c0,0.5-0.3,1.1-0.7,1.6c0,0-0.1,0.1-0.1,0.1c-1.5,1.7-1.7,4.2-0.4,6.1c0.8,1.2,1.6,1.9,2,2.3c0,0,0,0,0,0c0.2,0.3,0.6,0.7,1.2,1.2   c1.1,1,2.5,1.5,4,1.5c1,0,2-0.2,2.9-0.7c0.2-0.1,0.3-0.2,0.5-0.4c0.5-0.5,1-0.7,1.6-0.7c0.5,0,1.1,0.3,1.6,0.7l3.7,3.7   c0.9,0.9,0.9,2.1,0,3l-0.8,0.8l-0.1,0.1c-0.5,0.5-1,0.9-1.4,1.4c-0.8,0.8-1.8,1.2-3.1,1.2c-1.1,0-2.4-0.3-3.9-0.8   c-3.6-1.3-7.1-3.6-10.2-6.8c-3-2.9-5.4-6.5-6.7-10.1c-0.8-2.2-1.6-5.2,0.3-7c0,0,0.1-0.1,0.1-0.1l0.7-0.7c0.5-0.5,1-1,1.5-1.5   c0,0,0.1-0.1,0.1-0.1C6.1,2.4,6.6,2.1,7.1,2.1 M7.1,0.1c-0.8,0-1.8,0.3-2.8,1.3C3.7,1.9,3.2,2.4,2.6,3L1.9,3.7   c-3.1,2.8-1.4,7.6-0.8,9.1c1.5,3.9,4,7.8,7.2,10.9c3.3,3.3,7.1,5.8,10.9,7.2c1.7,0.6,3.3,1,4.6,1c1.9,0,3.4-0.6,4.5-1.8   c0.4-0.5,0.9-0.9,1.4-1.4l0.8-0.8c1.7-1.7,1.7-4.2,0-5.9l-3.7-3.7c-1.1-1.1-2.2-1.3-3-1.3c-0.7,0-1.9,0.2-3,1.3   c-0.6,0.3-1.3,0.5-1.9,0.5c-1,0-1.9-0.3-2.7-1c-0.5-0.4-0.8-0.8-1-1c0,0-0.1-0.1-0.1-0.1c-0.4-0.3-1-0.9-1.7-1.9   c-0.8-1.1-0.7-2.6,0.2-3.6l0,0c1.1-1.1,1.3-2.2,1.3-3c0-0.8-0.2-1.9-1.3-3c-1.2-1.2-2.5-2.5-3.6-3.5C9.3,0.6,8.3,0.2,7.2,0.1   C7.2,0.1,7.1,0.1,7.1,0.1L7.1,0.1z"
                      class="st0"></path>
              </g>
            </svg>
          </button>
          <button class="tablinks b-nth-child" @click="toggleTabs('messages_tab')"
                  :style="(activeTab('messages_tab') ? 'background-color: #f16925;': '')">
            <svg data-v-9ff36b54="" xmlns="http://www.w3.org/2000/svg" id="openDealsms_lead" viewBox="0 0 32 32"
            >
              <path :style="(activeTab('messages_tab') ? 'stroke: #fff; ': 'stroke: #245482; ')" data-v-9ff36b54=""
                    d="M12.44,21.82H4.81a3.7,3.7,0,0,1-3.7-3.69V6.41a3.7,3.7,0,0,1,3.7-3.7H27.19a3.7,3.7,0,0,1,3.7,3.7V18.13a3.7,3.7,0,0,1-3.7,3.69h0a1.55,1.55,0,0,0-1.55,1.55v3.8a2.13,2.13,0,0,1-3.49,1.63l-7.3-6.1A3.73,3.73,0,0,0,12.44,21.82Z"
                    class="cls-1"></path>
              <circle :style="(activeTab('messages_tab') ? 'fill: #fff; ': 'fill: #245482; ')" data-v-9ff36b54=""
                      cx="10.29" cy="12.67" r="1.4" class="cls-2"></circle>
              <circle :style="(activeTab('messages_tab') ? 'fill: #fff; ': 'fill: #245482; ')" data-v-9ff36b54=""
                      cx="16" cy="12.67" r="1.4" class="cls-2"></circle>
              <circle :style="(activeTab('messages_tab') ? 'fill: #fff; ': 'fill: #245482; ')" data-v-9ff36b54=""
                      cx="21.71" cy="12.67" r="1.4" class="cls-2"></circle>
            </svg>
          </button>
          <button class="tablinks b-nth-child" @click="toggleTabs( 'outgoing_email_tab')"
                  :style="(activeTab('outgoing_email_tab') ? 'background-color: #f16925;': '')">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                 id="openDealEmail" x="0px" y="0px" viewBox="0 0 32 32" xml:space="preserve"
            >
						<g>
							<g>
								<path :style="(activeTab('outgoing_email_tab') ? 'fill: #fff; ': 'fill: #245482; ')"
                      d="M29.9,6.2v19.6H2.1V6.2H29.9 M30.1,4.2H1.9c-1,0-1.8,0.8-1.8,1.8v19.9c0,1,0.8,1.8,1.8,1.8h28.2    c1,0,1.8-0.8,1.8-1.8V6.1C31.9,5.1,31.1,4.2,30.1,4.2L30.1,4.2z"
                      class="st0"></path>
							</g>
              <path :style="(activeTab('outgoing_email_tab') ? 'stroke: #fff; ': 'stroke: #245482; ')"
                    d="M1.5,5.5L14,16.6c1.2,1.1,3.1,1.1,4.3,0L30.8,5.5" class="st1"></path>
						</g>
					</svg>
          </button>
        </div>

        <div>
          <combined-tab :object="deal" :type="'deal'" v-if="activeTab('combined_tab')"></combined-tab>
          <stage-tab :object="deal" :type="'deal'" v-if="activeTab('stage_tab')"></stage-tab>
          <deal-notes-tab :object="deal" :type="'deal'" v-if="activeTab('deal_notes_tab')"></deal-notes-tab>
          <dial-pad :object="deal" :type="'deal'" v-if="activeTab('call_tab')"></dial-pad>
          <system-notification :object="deal" :type="'deal'" v-if="activeTab('messages_tab')"></system-notification>
          <outgoing-email-tab v-if="activeTab('outgoing_email_tab')"></outgoing-email-tab>
        </div>
        <phonebox :object="lead_phone" :type="'deal'"></phonebox>
        <chatbox :object="lead_chat" :type="'deal'"></chatbox>
      </div>
    </div>
  </section>
</template>
<script>

import {CollapseTransition} from 'vue2-transitions';
import {Modal} from '@/components';
import {Select, Option, Button} from "element-ui";

const debounce = {
  interval: null,
  wait: 500,
  func: () => {
  },
  __: function (f, w) {
    if (this.interval) {
      clearTimeout(this.interval);
    }
    this.wait = w;
    this.func = f;
    this.interval = setTimeout(debounce.func, debounce.wait);
  }
};

import phonebox from "src/components/leads/phonebox";
import chatbox from "../../components/leads/chatbox";
import SystemNotification from './SystemNotification.vue';
import DialPad from './DialPad.vue';
import StageTab from './StageTab.vue';
import DealNotesTab from './DealNotesTab.vue';
import CallTab from "@/pages/Deals/CallTab";
import MessageTab from "@/pages/Deals/MessageTab";
import EmailTab from "@/pages/Deals/EmailTab";
import NotesTab from "@/pages/Deals/NotesTab";
import OutgoingEmailTab from './OutgoingEmailTab.vue';
import CombinedTab from './CombinedTab.vue';
import PerfectScrollbar from 'perfect-scrollbar';
import Multiselect from 'vue-multiselect';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    CombinedTab,
    CollapseTransition,
    Modal,
    Multiselect,
    DialPad,
    MessageTab,
    CallTab,
    EmailTab,
    NotesTab,
    OutgoingEmailTab,
    DealNotesTab,
    StageTab,
    phonebox,
    chatbox,
    SystemNotification,
    Button,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      edit_fields: false,
      options: [],
      value: [],
      extra_tag_options: [],
      extra_tag_value: [],
      pipeline_changed: false,
      tags_changed: false,
      extra_tags_changed: false,
      componentKey: 0,
      user: {},
      deal: {},
      status: {
        pipline: [],
        selected: {
          pipline: "",
          stage: "",
        },
      },
      old_stage: {},
      old_pipeline: {},
      selected_responsibles: [],
      responsRes: false,
      stageRes: false,
      modelValidations: {
        email: {
          required: true,
        },
        phone: {
          required: true,
        },
      },
      fields: [],
      lead_phone: {
        lead: {},
        show: false
      },

      lead_chat: {
        lead: {},
        show: false
      },
      notifications: [],
      tabs: [
        {
          name: 'combined_tab',
          display: false,
        },
        {
          name: 'stage_tab',
          display: false,
        },
        {
          name: 'deal_notes_tab',
          display: false,
        },
        {
          name: 'call_tab',
          display: false,
        },
        {
          name: 'messages_tab',
          display: false,
        },
        {
          name: 'outgoing_email_tab',
          display: false,
        }
      ],
      new_tabs: [
        {
          name: 'new_call_tab',
          display: false,
        },
        {
          name: 'new_message_tab',
          display: false,
        },
        {
          name: 'new_notes_tab',
          display: false,
        },
        {
          name: 'new_email_tab',
          display: false,
        },
      ],
      dealViewSearchModal: false,
      searchQuery: '',
      search_query: '',
      search_results: {
        employees: []
      },
      search_height: '',
      progress_bar: {
        intervals: [],
        val: 0,
        max: 100,
        size: 3,
        bg_color: "#eee",
        bar_color: "#f16925",
        bar_transition: "all 0.5s ease",
        bar_border_radius: 0,
        spacing: 4,
        text: "",
        text_align: "right",
        text_position: "bottom",
        font_size: 13,
        text_fg_color: "#222",
      }
    };
  },
  methods: {
    check() {
      console.log(this.return_field('UF_CRM_1591035649').value);
    },
    addTag(newTag) {
      if (newTag !== undefined && newTag !== '') {
        const tag = {
          id: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
          name: newTag,
          parent_id: 7,
        }
        this.value.push(tag)
        this.options[this.options.length - 1]['polus_tags'].push(tag);
      }
    },
    addExtraNewTag(newTag) {
      if (newTag !== undefined && newTag !== '') {
        this.extra_tags_changed = true;
        const tag = {
          id: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
          name: newTag,
          parent_id: 7,
        }
        this.extra_tag_value.push(tag)
        this.extra_tag_options[this.extra_tag_options.length - 1]['polus_tags'].push(tag);
      }
    },
    return_field_value(title) {
      if (this.fields.length > 0) {
        let feild = this.fields.find((r) => r.title === title);
        if (feild.value !== undefined && feild.value !== null) {
          return feild.value;
        }
      }
      return '';
    },
    return_field(title) {
      if (this.fields.length > 0) {
        let feild = this.fields.find((r) => r.title === title);
        if (feild !== undefined && feild !== null) {
          return feild;
        }
      }else{
        return {
          value : '',
          list : []
        }
      }
    },
    forceRerender() {
      this.componentKey += 1;
    },
    startprogressbar() {
      let interval = setInterval(() => {
        if (this.progress_bar.text == '') {
          this.progress_bar.text = 'Searching...';
        }
        this.progress_bar.val += parseInt((Math.random() * 10));
        if (this.progress_bar.val > this.progress_bar.max) {
          this.progress_bar.max += parseInt(this.progress_bar.val / 2);
        }
      }, 150);
      this.progress_bar.intervals.push(interval);
      return interval;
    },
    finishprogressbar() {
      this.progress_bar.intervals.forEach(x => {
        clearInterval(x);
      });
      this.progress_bar.val = 0;
      this.progress_bar.max = 100;
      this.progress_bar.text = '';
    },
    is_responsible(employee) {
      return this.selected_responsibles.find(e => e.id == employee.id) ? true : false;
    },
    searching() {
      debounce.__(() => {
        if (this.search_query != '') {
          let interval = this.startprogressbar();
          this.$store.dispatch("search/query", {query: this.search_query}).then((response) => {
            this.finishprogressbar();
            if (interval == this.progress_bar.intervals.pop()) {
              this.search_height = 'height : 300px;'
              this.search_results = response.data.data;
            }
          });
        } else {
          this.finishprogressbar();
          this.progress_bar.intervals.push(setInterval(() => {
            this.progress_bar.val += 10;
            if (this.progress_bar.val > this.progress_bar.max) {
              this.finishprogressbar();
            }
          }, 40))
          this.search_results = {
            employees: []
          };
          this.search_height = '';
        }
      }, 500)
    },
    focus_search() {
      document.getElementById('search_popup_input_view').focus();
    },
    dropdown_status_change() {
      let indexPipe = this.status.pipline.findIndex((p) => p.id === this.status.selected.pipline);
      let newPipeline = this.status.pipline[indexPipe];
      let indexStage = newPipeline.stages.findIndex((p) => p.id === this.status.selected.stage);
      let newStage;

      if (indexStage >= 0) {
        newStage = newPipeline.stages[indexStage];
      } else {
        newStage = newPipeline.stages[0];
      }
      if (newStage.id) {
        let user = this.$store.getters['api/user'];
        this.$insProgress.start();
        this.$store
          .dispatch("deal/status_change", {
            id: this.deal.id,
            parent_type: 'deal',
            user_id: user.id,
            old_stage_id: this.old_stage.id,
            status_id: newStage.id,
            old_pipeline_id: this.old_pipeline.id,
            new_pipeline_id: newPipeline.id,
          })
          .then((response) => {
            this.old_stage = newStage;
            this.old_pipeline = newPipeline;
            this.status.selected.pipline = newPipeline.id;
            this.status.selected.stage = newStage.id;

            this.deal.stage = newStage;
            console.log(response.data);
            this.$insProgress.finish();
          });
      }
    },
    add_user(user) {
      if (!this.is_responsible(user)) {
        this.selected_responsibles.push(user);
        this.create_responsible(user.id)
        this.forceRerender();
      } else {
        this.delete_responsible(user.id);
        let index = this.selected_responsibles.findIndex(e => e.id == user.id);
        this.selected_responsibles.splice(index, 1);
      }
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    date_format(date_string) {
      const date = new Date(date_string);
      date_string = (date.toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
        day: '2-digit', // numeric, 2-digit
        year: 'numeric', // numeric, 2-digit
        month: 'short', // numeric, 2-digit, long, short, narrow
        hour: '2-digit', // numeric, 2-digit
        minute: '2-digit', // numeric, 2-digit
        timeZoneName: 'short', // numeric, 2-digit
      }));
      var nameArr = date_string.split(', ');
      return ('<b>' + nameArr[0] + ', ' + nameArr[1] + '</b>\n' + nameArr[2]);
    },
    fetch_pipelines() {
      this.$store.dispatch("deal/fetch_pipelines").then(response => {
        if (response.data.data !== undefined) {
          //console.log({PIPELINES:response.data.data});
          this.status.pipline = response.data.data;
        }
      });
    },
    pipeline_change() {
      this.pipeline_changed = true;
      let index = this.status.pipline.findIndex((p) => p.id === this.status.selected.pipline);
      console.log(index);

      if (index >= 0) {
        this.status.selected.stage = this.status.pipline[index].stages[0].id;
      } else {
        this.status.selected.stage = '';
      }
    },
    selected_stages() {
      if (this.status.selected.pipline !== "") {
        let index = this.status.pipline.findIndex((p) => p.id === this.status.selected.pipline);
        return this.status.pipline[index].stages;
      }
      return [];
    },
    editlead() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          var data = {
            id: this.user.id,
            title: this.user.title,
            terms: [
              {
                type_id: "PHONE",
                value: this.user.phone,
                value_type: "WORK",
              },
              {
                type_id: "EMAIL",
                value: this.user.email,
                value_type: "WORK",
              },
            ],
          };
          this.$insProgress.start();
          this.$store.dispatch("lead/update", data).then((response) => {
            //console.log(response);
            this.$router.push("/leads/index");
            this.$insProgress.finish();
            this.$notify({
              timeout: 2000,
              verticalAlign: "top",
              horizontalAlign: "right",
              message: "Your record Updated successfully!",
              icon: "tim-icons icon-check-2",
              type: "success",
            });
          });
        }
      });
    },
    editdeal() {
      this.edit_fields = false;
      this.$validator.validateAll().then(isValid => {
        if (isValid) {
          var data = {
            "id": this.deal.id,
            "TITLE": this.deal.TITLE,
            "terms": [
              {
                "type_id": "PHONE",
                "value": this.deal.phone,
                "value_type": "WORK"
              },
              {
                "type_id": "EMAIL",
                "value": this.deal.email,
                "value_type": "WORK"
              }
            ]
          };
          this.fields.map((field) => {
            if (field.title === 'UF_CRM_1596158780') {
              let it = this.return_field('UF_CRM_1596158780').list.find((p) => p.value === this.return_field_value('UF_CRM_1596158780'));
              data[field.title] = it.id;
            } else if (field.title === 'UF_CRM_1591035649') {
              let it = this.return_field('UF_CRM_1591035649').list.find((p) => p.value === this.return_field_value('UF_CRM_1591035649'));
              data[field.title] = it.id;
            } else
              data[field.title] = field.value;
          });
          this.$insProgress.start();
          this.$store.dispatch("deal/update", data).then((response) => {
            //console.log(response);
            this.$insProgress.finish();
            this.$notify({
              timeout: 2000,
              verticalAlign: 'top',
              horizontalAlign: 'right',
              message: 'Your record Updated successfully!',
              icon: 'tim-icons icon-check-2',
              type: 'success',
            });
            this.$router.go();
            // this.$router.push('/deals/view/' + this.deal.id);
          });
        }
      });
    },
    editdealtags() {

      let result = this.value.map(a => a.name);
      if (result !== undefined) {
        var data = {
          "id": this.deal.id,
          "UF_CRM_POLUS_TAGS": result,
          "is_tags_update": true
        };

        this.$insProgress.start();
        this.$store.dispatch("deal/update", data).then((response) => {
          //console.log(response);
          this.$router.push('/deals/view/' + this.deal.id);
          this.$insProgress.finish();
          this.$notify({
            timeout: 2000,
            verticalAlign: 'top',
            horizontalAlign: 'right',
            message: 'Your record Updated successfully!',
            icon: 'tim-icons icon-check-2',
            type: 'success',
          });
        });
      }
    },
    editdealextratags() {
      let result = this.extra_tag_value.map(a => a.name);
      if (result !== undefined) {
        var data = {
          "parent_id": this.deal.id,
          "parent_type": 'deal',
          "value": result
        };
        this.$insProgress.start();
        this.$store.dispatch("deal/update_extra_tags", data).then((response) => {
          //console.log(response);
          this.$router.push('/deals/view/' + this.deal.id);
          this.$insProgress.finish();
          this.$notify({
            timeout: 2000,
            verticalAlign: 'top',
            horizontalAlign: 'right',
            message: 'Your record Updated successfully!',
            icon: 'tim-icons icon-check-2',
            type: 'success',
          });
        });
      }
    },
    ViewDeal(to = null) {
      this.$insProgress.start();
      let id = null;
      if (to) {
        id = to.params.id;
      } else {
        id = this.$route.params.id;
      }
      this.$store
        .dispatch("deal/getdeal", +id)
        .then((response) => {
          this.$insProgress.finish();
          var deal = response.data.data;
          console.log(deal);
          var phone = deal.terms.find((term) => term.type_id === "PHONE");
          var email = deal.terms.find((term) => term.type_id === "EMAIL");
          this.user = deal;
          this.deal = deal;
          this.old_stage = deal.stage;
          this.status.selected.pipline = deal.pipeline.id;
          this.status.selected.stage = deal.stage.id;
          this.old_pipeline = deal.pipeline;
          this.selected_responsibles = [];
          if (deal.responsible !== undefined && deal.responsible !== null) {
            this.selected_responsibles.push(deal.responsible)
          }
          this.deal.responsibles.forEach((value, index) => {
            // this.add_user(value.user);
            this.selected_responsibles.push(value.user);
            console.log(index);
          });
          this.options = deal.all_tags;
          this.extra_tag_options = deal.all_tags;
          this.value = deal.selected_tags;
          this.extra_tag_value = deal.selected_extra_tags;
          this.fields = deal.fields;
          this.deal.phone = phone.value;
          this.deal.email = email.value;
        })
        .catch((response) => {
          this.$insProgress.finish();
        });
    },
    toggle_phone(lead) {
      //console.log(lead);
      if (!this.lead_phone.show) {
        this.lead_phone.lead = lead;
        this.lead_phone.show = true;
      } else {
        if (this.lead_phone.lead.id === lead.id) {
          this.lead_phone.lead = {};
          this.lead_phone.show = false;
        } else {
          this.lead_phone.lead = lead;
          this.lead_phone.show = true;
        }
      }
    },
    toggle_chat(lead) {
      //console.log(lead);
      if (!this.lead_chat.show) {
        this.lead_chat.lead = lead;
        this.lead_chat.show = true;
      } else {
        if (this.lead_chat.lead.id === lead.id) {
          this.lead_chat.lead = {};
          this.lead_chat.show = false;
        } else {
          this.lead_chat.lead = lead;
          this.lead_chat.show = true;
        }
      }
    },
    fetchNotification() {
      this.$store.dispatch("notification/fetch").then((response) => {
        this.notifications = response.data.data.data;
        if (this.user.id > 2) {
          let unread = this.notifications.filter((element) => {
            return element.read === 0;
          });
        } else {
          let unread = this.notifications.filter((element) => {
            return element.admin_read === 0;
          });
        }
      }).catch((e) => {
        //this.$insProgress.finish();
      });
    },
    create_responsible(user_id) {
      var data = {
        user_id: user_id,
        parent_id: this.deal.id,
        parent_type: 'deal',
      };

      this.$store.dispatch("deal/responsible_create", data).then((response) => {
        console.log(response.data.data);

      }).catch((e) => {
        //this.$insProgress.finish();
      });
    },
    delete_responsible(user_id) {
      var data = {
        user_id: user_id,
        parent_id: this.deal.id,
        parent_type: 'deal',
      };
      this.$store.dispatch("deal/responsible_delete", data).then((response) => {
        console.log(response.data.data);
      }).catch((e) => {
        //this.$insProgress.finish();
      });
    },
    toggleTabs(name) {
      let index = this.tabs.findIndex(t => t.name === name);
      this.tabs = this.tabs.map(t => {
        t.display = false;
        return t;
      });
      if (index > -1) {
        this.tabs[index].display = !this.tabs[index].display;
      }
    },
    activeTab(name) {
      let index = this.tabs.findIndex(t => t.name === name);
      if (index > -1) {
        return this.tabs[index].display;
      }
      return false;
    },
    newToggleTabs(name) {
      let index = this.new_tabs.findIndex(t => t.name === name);
      this.new_tabs = this.new_tabs.map(t => {
        t.display = false;
        return t;
      });
      if (index > -1) {
        this.new_tabs[index].display = !this.new_tabs[index].display;
      }
    },
    newActiveTab(name) {
      let index = this.new_tabs.findIndex(t => t.name === name);
      if (index > -1) {
        return this.new_tabs[index].display;
      }
      return false;
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('sidebar');
        initScrollbar('main-panel');
        initScrollbar('sidebar-wrapper');

        docClasses.add('perfect-scrollbar-on');
      } else {
        docClasses.add('perfect-scrollbar-off');
      }
    },
  },
  created() {
    this.$insProgress.start();
    this.ViewDeal();
    this.fetchNotification();
    this.toggleTabs('combined_tab');
    this.fetch_pipelines();
  },
  beforeRouteUpdate(to, from, next) {
    // Call the API query method when the URL changes
    this.$insProgress.start();
    this.ViewDeal(to);
    next();
  },
  openCity(evt, cityName) {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
  },
  mounted() {
    let docClasses = document.body.classList;
    docClasses.add('white-content');
    this.initScrollbar();
    this.user = this.$store.getters['api/user'];
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"/>

<style>

.el-input {
  height: 100%;
}

.el-input.el-input--suffix .select-primary.el-select .el-input input {
  height: 100%;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.blue-btn {
  background-color: #245482;
  color: #fff;
}

.col-md-4 {
  margin-top: 10px;
}

.outerNew .FourSvg {
  display: none;

}

.seers {
  color: #f16925;
}

.searchthis .search-svg {
  width: 24px;
  top: 18px;
  height: 24px;
  position: absolute;
  left: 0px;
}

#search input {
  width: 100%;
  opacity: 1 !important;
  outline: none;
  font-size: 22px;
  padding: 12px 40px !important;
  color: #f16925;
  border: none;
}

.searchthis {
  z-index: 10;
  padding: 15px 30px;
  position: absolute;
  background-color: white !important;
  border: 1px solid #f16925;
  border-radius: 12px;
  width: 530px;
}

.searchthis h2 {
  font-size: 18px;
  color: #124678;
  font-weight: 500;
  margin: 14px 0px;
}

.outerNew:hover .FourSvg {
  display: block !important;
}

span.cross-hid {
  font-size: 12px;
  padding: 2px 6px;
  width: 20px;
  font-weight: 600;
  height: 20px;
}

.outUser.outerNew {
  padding: 20px 0 0px;
}

.white-content .form-control[readonly] {
  background: white !important;
  cursor: pointer !important;
}

a.closebtn-serch {
  font-size: 30px;
  position: absolute;
  top: 4px;
  right: -6px;
  color: #f16925;
}

.relative-d {
  position: relative;
}

.main-drop-wrap {
  overflow-y: scroll;
  padding: 0 10px 0 0;
}

.fr-mr {
  margin-right: 18px;
}

.allData .employeeDataActive {
  margin-bottom: 18px;
}

.main-drop-wrap::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.main-drop-wrap::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.main-drop-wrap::-webkit-scrollbar-thumb {
  background: #f16925;
}

/* Handle on hover */
.main-drop-wrap::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.submotdr {
  background-color: #245482;
  border: none;
  color: #fff;
  padding: 10px;
  height: 51px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

@media only screen and (max-width: 992px) {
  div#SearchResult {
    position: absolute;
    left: 0;
    max-width: 96%;
    padding: 4px;
    margin: 0 auto;
    right: 0;
  }

  .main-drop-wrap[data-v-41552ae3] {
    padding: 10px;
  }

  a.closebtn-serch[data-v-41552ae3] {
    top: 2px;
    right: 2px;
  }

}

.stageCloseBtn {
  top: -18px !important;
  right: -30px !important;
}

.dealsViewMain .stageSelect {
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.userShrink {
  flex-shrink: 0;
}

.stageNewClass {
  padding-left: 0 !important;
  margin-left: 0 !important;
  width: 100%;
  height: 50px;
}

.stageNewClass span.el-input__suffix {
  top: 0 !important;
}

.stageNewClass .el-input--suffix .el-input__inner {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.modal-body .stageNewClass .el-input--suffix .el-input__inner {
  border: none !important;
}

.modal-body .el-select.select-primary.el-select--large {
  border-radius: 0px !important;
}

.multiselect__content-wrapper {
  max-height: 500px !important;
}


.multiselect__tags {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 12px !important;
  padding: 6px 40px 0 8px;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 12px !important;
}

.multiselect__tag span {
  font-size: 16px;
}

.multiselect__tag {
  padding: 12px 45px 12px 30px;
  color: #245482;
  background: #cce6ff;
  border-radius: 50px;
  margin-bottom: 0px;
  transition: all ease-in-out 0.3s;
}

.inputFlex2 {
  display: block;
  width: 100%;
  border-bottom: 4px solid #f5f6fa;
  padding-bottom: 40px;
  margin-bottom: 40px;
}

.multiselect__tag-icon:after {
  content: "\D7";
  color: #245482;
  font-size: 22px;
}

.multiselect__tag-icon {
  top: 7px;
  width: 24px;
  height: 24px;
  right: 7px;
  border-radius: 100px;
}

.multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
  background: #245482;
}

.multiselect__tag:hover {
  background: #24548252;
  transition: all ease-in-out 0.3s;
}

.updateFlex {
  justify-content: space-between;
  margin-bottom: 15px;
}

.updateFlex .submotdr {
  height: 40px;
  width: 100px;
  border-radius: 12px;
}

.dealsViewMain div.inputSvg p {
  align-self: normal;
  width: 58px !important;
  height: auto;
}

.modal-body .el-select.select-primary.stageNewClass.el-select--large {
  height: 51px;
  border-top: 1px solid #222a42;
  border-bottom: 1px solid #222a42;
  border-right: 1px solid #222a42;
}

input.multiselect__input {
  height: 30px;
}

.dealsIndexFilter p {
  width: 60px;
}

div.inputSvg.heightSvg p {
  height: auto !important;
}

div.inputSvg.tags-svg p {
  height: auto !important;
}


.row.head-row-cons {
  padding: 24px 0 30px;
}

.conversation-title h3 {
  color: #f16925;
  font-weight: 700;
  letter-spacing: 3px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  margin-top: 6px;
}

span.shap-date {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #f3f3f3;
  display: flex;
  margin: 0 auto;
}

.conversation-title h3 img {
  max-width: 28px;
  margin-right: 8px;
}

.msg-icon a {
  background: white;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 20px 0 0;
  border-radius: 100%;
  padding: 12px;
  border: 1px solid #ebebeb;
}

.msg-icon {
  display: flex;
  justify-content: end;
}

.msg-icon a.chats-icon {
  background: #f16925;
}

.msg-icon a img {
  max-width: 24px;
}

.sms-number {
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid #eeecec;
  max-width: 220px;
  justify-content: space-between;
  padding: 10px 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.sms-number h3,
.sms-number h5 {
  margin-bottom: 0;
}

.sms-number h3 {
  font-size: 18px;
  color: #2d5b87;
  font-weight: 500;
}

.sms-number h3 img {
  margin-right: 6px;
}

.sms-number h5 {
  background: #d3dde6;
  padding: 2px 8px;
  border-radius: 6px;
  color: #2d5b87;
}

.left-notify-chat-wrap {
  background: #fff;
  border: 1px solid #f3f3f3;
  border-radius: 8px;
}

.items-notify {
  border-bottom: 1px solid #e7e4e4;
  padding: 18px;
}

.title-name-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.title-name-head h4 {
  margin-bottom: 0;
}

.title-name-head h4 {
  margin-bottom: 0;
  color: #f16925;
  font-weight: 500;
  font-size: 16px;
}

.clr-up {
  color: #f16925 !important;
}

.title-name-head p.flex-time {
  font-size: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  color: #bab7b7;
}

.title-name-head p.flex-time img {
  max-width: 12px;
  margin-right: 4px;
  margin-top: -1px;
}

.notify-short-msg p {
  margin-bottom: 0;
  font-size: 13px;
  line-height: 14px;
  color: #7d7d7d;
}

.reverse-head {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  max-width: 400px;
  margin: 0 auto;
  padding: 6px 0 25px;
}

.reverse-head h4 {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 500;
  color: #2d5b87;
}

.reverse-head a {
  background: white;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  border: 1px solid #ebebeb;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reverse-head a img {
  max-width: 18px;
}

.row.main-conversations {
  padding-bottom: 40px;
}

.conversation-wrap-right {
  background: #fff;
  border: 1px solid #f3f3f3;
  border-radius: 8px;
}

.conversation-wrap-inner {
  padding: 20px 20px 20px;
}

.conversa-date p {
  background: #f3f3f3;
  font-size: 12px;
  padding: 4px 10px 6px;
  color: #c1bbbb;
  border-radius: 10px;
  max-width: 140px;
  text-align: center;
  margin: 0 auto;
}

.conversa-date {
  margin-bottom: 18px;
}

.sender-msges .inner-sender {
  background: #ee804a;
  display: inline-block;
  padding: 6px 14px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  text-align: left;
  margin-bottom: 6px;
}

.sender-msges {
  text-align: end;
  margin-bottom: 18px;
}

.sender-msges .inner-sender p {
  margin-bottom: 0;
  color: #fff;
  font-size: 13px;
  line-height: 18px;
}

.sender-msges p.flex-time {
  font-size: 10px;
  margin-bottom: 0;
  color: #bab7b7;
}

.sender-msges p.flex-time img {
  max-width: 12px;
  margin-right: 4px;
  margin-top: -1px;
}

.recivers-msges .inner-sender {
  background: #2c669d;
  display: inline-block;
  padding: 6px 14px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  text-align: left;
  margin-bottom: 6px;
}

.recivers-msges .inner-sender p {
  margin-bottom: 0;
  color: #fff;
  font-size: 13px;
  line-height: 18px;
}

.recivers-msges p.flex-time {
  font-size: 10px;
  margin-bottom: 0;
  color: #bab7b7;
}

.recivers-msges p.flex-time img {
  max-width: 12px;
  margin-right: 4px;
  margin-top: -1px;
}

.recivers-msges {
  margin-bottom: 18px;
}

.send-typing-msg-wrap {
  background: #f8f8fb;
  border-top: 1px solid #d9d9d9;
}

.input-send {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.input-send .from-group {
  width: 90%;
}

.un-seen-msg {
  background: #fcfcfc;
}

.un-seen-msg h4 {
  color: #2e5d89 !important;
}

.from-group input {
  width: 100%;
  border: none;
  background: transparent;
}

.from-group input:focus-visible {
  outline-color: #f3f3f3 !important;
}

.btn-send-icon a {
  background: #8c8d97;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-notify-scroll-bar {
  overflow-y: auto;
  height: 72vh;
  padding-right: 20px;
}

.conversation-scroll-bar {
  overflow-y: auto;
  height: 64vh;
}

.left-notify-scroll-bar::-webkit-scrollbar, .conversation-scroll-bar::-webkit-scrollbar {
  width: 6px;
}

.left-notify-scroll-bar::-webkit-scrollbar-track, .conversation-scroll-bar::-webkit-scrollbar-track {
  background: #d3dde6;
  border-radius: 2px;
}

.left-notify-scroll-bar::-webkit-scrollbar-thumb, .conversation-scroll-bar::-webkit-scrollbar-thumb {
  background: #245482;
  border-radius: 2px;
}

.left-notify-scroll-bar::-webkit-scrollbar-thumb:hover, .conversation-scroll-bar::-webkit-scrollbar-thumb {
  background: #245482;
}

@media (min-width: 1800px) {
  .col-2xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-2xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
}

@media (max-width: 992px) {
  .right-bar-conversation {
    display: none;
  }

  .on-none {
    display: none;
  }

  .right-bar-conversation.classone {
    display: block;
  }

  .reverse-head {
    padding: 6px 0 25px;
    width: 100%;
    max-width: 100%;
    justify-content: end;
    position: relative;
  }

  .reverse-head a {
    margin: 0 10px;
  }

  .cross-icon-chat {
    position: absolute;
    left: 0;
    z-index: 999;
  }

  .main-conversations .col-sm-12 {
    max-width: 100% !IMPORTANT;
    flex: 0 0 100%;
  }
}

@media (min-width: 992px) {
  div#iconChatCross {
    display: none;
  }
}


.messageMain .messageHeader {
  background-color: #f3f3f3;
  padding: 10px 15px;
  border-bottom: 1px solid #dedede;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.messageMain .messageBox {
  border: 1px solid #dedede;
  border-radius: 12px;
}

.messageMain .messageBody {
  padding: 30px 15px;
}

.messageMain .messageHeader svg {
  width: 20px;
  height: 20px;
}

.messageMain {
  margin-top: 20px;
}

.messageBody p {
  margin-bottom: 15px;
}

.messageBody svg#Layer_1 {
  width: 70%;
  height: 100%;
}

.messageBody svg#Layer_1 path.st0 {
  fill: #f16925;
}

.messageBody svg#Layer_1 path.st1 {
  fill: #245482;
}

.notesMain .inputSvg input[type="text"]::placeholder {
  color: #245482;
}

.messageHeader #bold .st0 {
  fill: none;
  stroke: #afb1ba;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.messageHeader #bold .st1 {
  fill: #afb1ba;
}

.messageHeader #italic .st0 {
  fill: none;
  stroke: #afb1ba;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.messageHeader #italic .st1 {
  fill: #afb1ba;
}

.messageHeader #underline .st0 {
  fill: none;
  stroke: #afb1ba;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.messageHeader #underline .st1 {
  fill: #afb1ba;
}

.messageHeader #format_x5F_list_x5F_numbers .st0 {
  fill: none;
  stroke: #afb1ba;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.messageHeader #format_x5F_list_x5F_numbers .st1 {
  fill: #afb1ba;
}

.messageHeader #format_x5F_list_x5F_bullets .st0 {
  fill: none;
  stroke: #afb1ba;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.messageHeader #format_x5F_list_x5F_bullets .st1 {
  fill: none;
  stroke: #afb1ba;
  stroke-width: 2;
  stroke-miterlimit: 10;
}

.messageHeader #left .st0 {
  fill: none;
  stroke: #245482;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.messageHeader #center .st0 {
  fill: none;
  stroke: #afb1ba;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.messageHeader #justify .st0 {
  fill: none;
  stroke: #afb1ba;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.messageHeader #url .st0 {
  fill: none;
  stroke: #afb1ba;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.messageHeader #image .st0 {
  fill: none;
  stroke: #afb1ba;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.messageHeader #image .st1 {
  fill: none;
  stroke: #afb1ba;
  stroke-width: 2;
  stroke-miterlimit: 10;
}

.messageHeader #table .st0 {
  fill: none;
  stroke: #afb1ba;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.messageHeader #table .st1 {
  fill: none;
  stroke: #afb1ba;
  stroke-width: 2;
  stroke-miterlimit: 10;
}


.notesMain #mail_subject .st0 {
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}


.messageButtonsMain {
  display: grid;
  margin-top: 20px;
  align-items: center;
  grid-template-columns: 1fr auto;
  gap: 20px;
  justify-content: space-between;
}

.messageButtonsMain #attachment .st0 {
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.messageButtonsMain #discard .st0 {
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.fileName #attachment .st0 {
  fill: none;
  stroke: #8c8d97;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.linkBin {
  background-color: #8c8d97;
  height: 50px;
  width: 50px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.messageButtonsMain svg {
  width: 26px;
  height: 25px;
}

.fileName svg {
  width: 18px;
  height: 18px;
}

.fileName {
  display: flex;
  align-items: center;
  gap: 10px;
}

.messageButtonInner button {
  background-color: #124678;
  height: 50px;
  width: 120px;
  gap: 10px;
  font-size: 18px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.messageButtonInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#email_send .st0 {
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.messageFileInfo {
  display: flex;
  gap: 20px;
  align-items: center;
}

.inputSvg svg {
  width: 22px;
  height: 22px;
}


.dealsViewMain input {
  height: 50px;
}

div.stageNewClass input {
  border-bottom-right-radius: 12px !important;
  border-top-right-radius: 12px !important;
  border: 1px solid;
  padding: 0px 15px;
  outline: none;
  font-weight: 500;
  border-color: #f3f3f3 !important;
  color: #245482 !important;
  font-size: 16px;
  width: 100%;
  height: 100% !important;
}

.multiselect__tags {
  padding: 10px;
}

.multiselect__select:before {
  top: 90%;
}


.call-Log-main .call-log-detail {
  display: flex;
  gap: 20px;
  align-items: center;
  border: 1px solid #e2e8f0;
  padding: 5px;
  background-color: white;
  border-radius: 6px;
  margin: 10px 0px;
}

.call-Log-main .call-log-detail svg {
  width: 24px;
  height: 24px;
}

.call-Log-main .time-details {
  color: #a0a0a0;
  font-size: 11px;
}

.call-log-name {
  color: #ed8936;
}

.call-log-status {
  color: #124678;
}

.call-Log-content p {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
}

.call-log-detail .st1 {
  display: inline;
  fill: none;
  stroke: #ed8936;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.call-log-detail .st4 {
  fill: none;
  stroke: #ed8936;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}


.multiselect__tag {
  padding: 12px 45px 12px 30px;
  color: #245482;
  background: #cce6ff;
  border-radius: 50px;
  margin-bottom: 0;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.multiselect__tag:hover {
  background: rgba(36, 84, 130, .3215686274509804);
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.multiselect__tag-icon {
  top: 7px;
  width: 24px;
  height: 24px;
  right: 7px;
  border-radius: 100px;
}

.multiselect__tag span {
  font-size: 16px;
}

.multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
  background: #245482;
}

.multiselect__tag-icon:focus:after, .multiselect__tag-icon:hover:after {
  color: #fff;
}

.multiselect__tag-icon:after {
  content: "\D7";
  color: #245482;
  font-size: 22px;
}

div.el-select.select-primary.stageNewClass.pipelineStage input {
  border-radius: 0px !important;
}


</style>
