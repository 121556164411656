<template>
<div class="content">
    <div class="content" style="">
        <div class="row">
            <div class="col-12 pt-0">
                <div class="card" card-body-classes="table-full-width">
                    <!---->
                    <div class="card-header top-leads">
                        <div class="row">
                            <div class="col-12 pt-0">
                                <h4 class="card-title pull-left mt-2">Records </h4>

                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="box-table">
                            <div class="Table">
                                <div class="Heading">
                                    <div class="Cell">
                                        <p>Records</p>
                                    </div>
                                    <div class="Cell">
                                        <p>Moderator</p>
                                    </div>
                                    <div class="Cell">
                                        <p>Participant</p>
                                    </div>
                                    <div class="Cell">
                                        <p>Direction</p>
                                    </div>
                                    <div class="Cell">
                                        <p>Actions</p>
                                    </div>

                                </div>
                                <div class="Row" v-for="rec in records" :key="rec.id">
                                    <div class="Cell">
                                        <p>
                                            <vue-audio :file="rec.record_url"></vue-audio>
                                        </p>
                                    </div>
                                    <div class="Cell">
                                        <p>{{ rec.moderator }}</p>
                                    </div>
                                    <div class="Cell">
                                        <p>{{ rec.participant}}</p>
                                    </div>
                                    <div class="Cell">
                                        <p>{{ rec.direction}}</p>
                                    </div>
                                    <div class="Cell">
                                        <p><button data-v-01e1f50f="" type="button" class="btn remove btn-link btn-icon btn-fab btn-danger btn-sm" @click="recording_delete(rec.id)">
                                                <!----><i data-v-01e1f50f="" class="tim-icons icon-simple-remove"></i>
                                            </button></p>
                                    </div>

                                </div>

                            </div>
                            <div class="el-table__column-resize-proxy" style="display: none"></div>
                            <div class="card-footer">
                                <div class="row">
                                    <div class="col-12">
                                        <p class="card-category" style="float:left;">
                                            Showing {{ result.from }} to {{result.to }} of {{ result.total }} entries
                                        </p>
                                        <pagination style="float:right;" :data="result" @pagination-change-page="recordings" :limit="1">
                                            <span slot="prev-nav">&lt; Previous</span>
                                            <span slot="next-nav">Next &gt;</span>
                                        </pagination>
                                    </div>

                                </div>

                                <!---->
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
// import { RingLoader } from 'vue-spinners-css';

import swal from "sweetalert2";
import config from "@/config";
import VueAudio from '../../components/leads/VueAudio';
export default {
    components: {
        VueAudio
    },

    data() {
        return {
            records: [],
            result: {}
        }
    },
    methods: {
        recordings(page = 1) {
            this.$insProgress.start();
            this.$store.dispatch('lead/conversation_records', {
                "id": this.$route.params.id,
                "page": page
            }).then((response) => {
                //console.log(response);
                this.records = response.data.data.data;
                this.result = response.data.data;
                this.$insProgress.finish();
            });
        },
        recording_delete(id) {
            swal({
                title: 'Are you sure?',
                text: `You won't be able to revert this!`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'btn btn-success btn-fill',
                cancelButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'Yes, delete it!',
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$insProgress.start();
                    this.$store.dispatch("lead/conversation_records_delete", id).then((response) => {
                        this.recordings();
                        this.$insProgress.finish();
                        this.$notify({
                            timeout: 2000,
                            verticalAlign: 'top',
                            horizontalAlign: 'right',
                            message: 'Your record deleted successfully!',
                            icon: 'tim-icons icon-check-2',
                            type: 'success',
                        });
                    });

                }
            });
        },
    },

    created() {
        this.$insProgress.start();
        this.recordings();
    },
};
</script>

