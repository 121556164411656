<template>
  <div class="content leads-content">
    <div class=" leads-content" style="">
      <div class="row">
        <div class="col-12 pt-0 leads-col">
          <div class="card" card-body-classes="table-full-width">
            <!---->
            <div class="card-header top-leads responsive-leads">
              <div class="w-100">
                <div class="row w-100 m-0">
                  <div class="leads_left col-12 col-lg-6">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                         x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;"
                         xml:space="preserve">
                                    <g xmlns="http://www.w3.org/2000/svg" id="scheduler_inner">
                                        <path class="st4"
                                              d="M6.9,19.7H2.8c-0.9,0-1.7-0.7-1.7-1.7V4.6C1.1,3.7,1.9,3,2.8,3h17.5C21.2,3,22,3.7,22,4.6v3.3"/>
                                      <line class="st4" x1="8" y1="1.1" x2="7.9" y2="4.8"/>
                                      <line class="st4" x1="14.8" y1="1.1" x2="14.7" y2="4.8"/>
                                      <circle class="st4" cx="21.1" cy="21.4" r="9.7"/>
                                      <polyline class="st4" points="21.1,16 21.1,21.4 26.2,21.4  "/>
                                    </g>
                                    </svg>
                    <h4 class="card-title pull-left">Schedule </h4>
                  </div>

                  <div class="form-group col-12 col-lg-6 pr-0 text-right">
                    <!---->
                    <div class="pull-right form-pull">
                      <!---->
                      <div class=" search-input el-input el-input--prefix el-input--suffix"><input type="search"
                                                                                                   name="search"
                                                                                                   autocomplete="off"
                                                                                                   placeholder="Search records"
                                                                                                   aria-controls="datatables"
                                                                                                   class="el-input__inner">
                        <span class="el-input__prefix">
<!--                          <i class="el-input__icon el-icon-search searchbarb"></i>-->
                        <div class="searchbarb">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                               stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                          </svg>
                        </div>
                      </span></div>
                      <!---->
                      <span>
                                            <div class="add-leads-btn">
                                                <router-link :to="{ name :'Add_Scheduler'}"
                                                             class="nav-item dropdown-item"><button type="button"
                                                                                                    class="mb-3 pull-right btn btn-simple btn-linkedin"><i
                                                  class="fa fa-plus"></i> Create Schedule</button></router-link>
                                            </div>
                                        </span>
                    </div>
                    <!---->
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="box-table">
                <div class="Table">
                  <div class="Heading">
                    <div class="Cell">
                      <p>Task_Id</p>
                    </div>
                    <div class="Cell">
                      <p>Message</p>
                    </div>
                    <div class="Cell">
                      <p>Time</p>
                    </div>
                    <div class="Cell">
                      <p>Status</p>
                    </div>
                    <div class="Cell">
                      <p>Type</p>
                    </div>
                    <div class="Cell">
                      <p>Actions</p>
                    </div>
                  </div>
                  <div class="Row" v-for="rec in data" :key="rec.id">
                    <div class="Cell schedule_page_cell">
                      <p>{{ rec.id }}</p>
                    </div>
                    <div class="Cell">
                      <p>{{ (rec.message.length > 25 ? rec.message.substring(0, 25) + "..." : rec.message) }}</p>
                    </div>
                    <div class="Cell">
                      <p>{{ rec.time }}</p>
                    </div>
                    <div class="Cell">
                      <p>{{ rec.status }}</p>
                    </div>
                    <div class="Cell">
                      <p>{{ rec.type }}</p>
                    </div>
                    <div class="Cell">
                      <p>

                        <router-link :to="{ name :'Edit Scheduler', params:{id:rec.id}}">
                          <button type="button"
                                  class="btn edit btn-link btn-icon btn-fab btn-warning btn-sm">
                            <!---->
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor" stroke-width="1">
                              <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"/>
                            </svg>
                          </button>
                        </router-link>

                        <button data-v-01e1f50f="" type="button"
                                class="btn remove btn-link btn-icon btn-fab btn-danger btn-sm"
                                @click="schedule_delete(rec.id)">
                          <!----><i data-v-01e1f50f="" class="tim-icons icon-simple-remove"></i>
                        </button>
                      </p>
                    </div>
                  </div>

                </div>
                <div class="el-table__column-resize-proxy" style="display: none"></div>
                <div class="card-footer">
                  <div class="row">
                    <div class="col-12">

                    </div>

                  </div>
                  <!---->
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { RingLoader } from 'vue-spinners-css';

import swal from "sweetalert2";
import config from "@/config";
import {
  Collapse,
  CollapseItem
} from 'src/components';

export default {
  components: {
    Collapse,
    CollapseItem
  },

  data() {
    return {
      data: {},
    }
  },
  methods: {
    //this method is use is pagination
    schedule_fetch() {
      this.$insProgress.start();
      this.$store.dispatch("general/fetchschedule").then((response) => {
        //console.log(response);
        this.data = response.data.data;
        // //console.log(this.t_numbers.numbers);
        this.$insProgress.finish();
      }).catch((e) => {
        this.$insProgress.finish();
      });
    },
    schedule_delete(id) {
      swal({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.$insProgress.start();
          this.$store.dispatch("general/delete", id).then((response) => {
            this.schedule_fetch();
            this.$insProgress.finish();
            this.$notify({
              timeout: 2000,
              verticalAlign: 'top',
              horizontalAlign: 'right',
              message: 'Your record deleted successfully!',
              icon: 'tim-icons icon-check-2',
              type: 'success',
            });
          }).catch((e) => {
            this.$insProgress.finish();
          });

        }
      });
    },

  },

  created() {

    this.schedule_fetch();
  },
};
</script>

<style scoped>

input.el-input__inner {
  padding-left: 50px !important;
  padding-right: 15px !important;
  width: 250px !important;
  height: 43px !important;
}

span.el-input__prefix {
  left: 0px;
}

.add-leads-btn button.mb-3.pull-right.btn.btn-simple.btn-linkedin {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 16px;
  -moz-column-gap: 16px;
  column-gap: 16px;
  font-family: 'Inter';
  margin: 0px !important;
  color: white !important;
  background-color: #124678 !important;
}

.searchbarb {
  background-color: #245482;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  width: 45px;
  font-size: 20px;
  color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
