<template>
  <card>
    <h3 slot="" class="title">Edit Schedule</h3>
    <div class="Queue">
      <form>
        <div class="row mb-4">

          <div class="col-md-6">
            <lable> Type</lable>
            <el-select class="select-primary" size="large" placeholder="Select" v-model="data.type"
                       @change="status_update">
              <el-option v-for="option in type" class="select-primary" :value="option.value" :label="option.title"
                         :key="option.id">
              </el-option>
            </el-select>
          </div>
          <div class="col-md-6">
            <lable> Status</lable>
            <el-select class="select-primary" size="large" placeholder="Select" v-model="data.status">
              <el-option v-for="option in status.name" class="select-primary" :value="option.STATUS_ID"
                         :label="option.NAME" :key="option.id">
              </el-option>
            </el-select>
          </div>


        </div>

        <div class="row mb-4">
          <div class="col-md-6">
            <lable> Message</lable>
            <br>
            <textarea v-model="data.message" rows="5" cols="70"></textarea>
          </div>
          <div class="col-md-6">
            <div class="row check-box-lable">
              <div class="col-md-6">
                <input type="checkbox" id="jack" value="Jack" v-model="day.m">
                <label for="jack">Monday</label>
                <br>
                <input type="checkbox" id="john" value="John" v-model="day.t">
                <label for="john">Tuesday</label>
                <br>
                <input type="checkbox" id="mike" value="Mike" v-model="day.w">
                <label for="mike">Wednesday</label>
                <br>
                <input type="checkbox" id="hack" value="Jack" v-model="day.th">
                <label for="hack">Thursday</label>
              </div>
              <div class="col-md-6">

                <input type="checkbox" id="johny" value="John" v-model="day.fr">
                <label for="johny">Friday</label>
                <br>
                <input type="checkbox" id="miky" value="Mike" v-model="day.sa">
                <label for="miky">Saturday</label>
                <br>
                <input type="checkbox" id="neny" value="Mike" v-model="day.su">
                <label for="neny">Sunday</label>
              </div>
            </div>
          </div>

        </div>

        <div class="row mb-4">
          <div class="col-md-6">
            <base-input label="Time">
              <el-time-select placeholder="Time Picker" v-model="data.time" :picker-options="{start: '00:00', step: '00:30', end: '23:59'
        }">
              </el-time-select>
            </base-input>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-6 ">
            <base-button native-type="submit" @click.prevent="updateschedule" type="success" class="btn-fill mr-4">
              Save
            </base-button>
            <base-button type="default" class="btn-fill" @click="$router.go(-1)">
              Back
            </base-button>
          </div>
        </div>


      </form>
    </div>
  </card>
</template>

<script>
import swal from "sweetalert2";
import config from "@/config";
import Multiselect from 'vue-multiselect'
import {
  TimeSelect,
  DatePicker,
  Select,
  Option
} from 'element-ui';

export default {
  components: {
    [TimeSelect.name]: TimeSelect,
    [Select.name]: Select,
    [Option.name]: Option,

    Multiselect,
  },
  data() {
    return {
      data: {},
      day: {},
      status: {
        simple: '',
        name: [],
        orig: [],
      },

      type: [
        {
          value: 'lead',
          title: 'Lead'
        },
        {
          value: 'deal',
          title: 'Deal'
        }
      ],
      modelValidations: {

        email: {
          required: true,
          email: true
        },
        phone: {
          required: true
        }
      }
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    getSch() {
      this.$insProgress.start();
      this.$store.dispatch('general/getscheduler', +this.$route.params.id).then((response) => {
        //console.log(response);
        this.data = response.data.data;
        this.$insProgress.finish();

        const myArray = this.data.days.split(",");
        myArray.forEach((value, index) => {
          index === 0 && value === '1' ? this.day.m = 1 : this.day.m = 0;
          index === 1 && value === '1' ? this.day.t = 1 : this.day.t = 0;
          index === 2 && value === '1' ? this.day.w = 1 : this.day.w = 0;
          index === 3 && value === '1' ? this.day.th = 1 : this.day.th = 0;
          index === 4 && value === '1' ? this.day.fr = 1 : this.day.fr = 0;
          index === 5 && value === '1' ? this.day.sa = 1 : this.day.sa = 0;
          index === 6 && value === '1' ? this.day.su = 1 : this.day.su = 0;
        });

        this.status_update();

      });
    },
    updateschedule() {
      this.$insProgress.start();

      var str = "";
      str = str + (this.day.m ? '1,' : '0,');
      str = str + (this.day.t ? '1,' : '0,');
      str = str + (this.day.w ? '1,' : '0,');
      str = str + (this.day.th ? '1,' : '0,');
      str = str + (this.day.fr ? '1,' : '0,');
      str = str + (this.day.sa ? '1,' : '0,');
      str = str + (this.day.su ? '1' : '0');

      this.data.days = str;
      this.$store.dispatch("general/update", this.data).then((response) => {
        //console.log(response);
        this.$router.push('/scheduler/index');
        this.$insProgress.finish();
        this.$notify({
          timeout: 2000,
          verticalAlign: 'top',
          horizontalAlign: 'right',
          message: 'Your record updated successfully!',
          icon: 'tim-icons icon-check-2',
          type: 'success',
        });
      }).catch((e) => {
        this.$insProgress.finish();
      });
    },
    status_fetch() {
      this.$insProgress.start();
      this.$store.dispatch("general/fetch_status").then((response) => {
        //console.log(response);
        this.status.orig = response.data.data;
        this.$insProgress.finish();
        this.status_update();
      }).catch((e) => {
        this.$insProgress.finish();
      });
    },
    status_update() {
      this.status.name = [];
      for (var x = 0; x < this.status.orig.length; x++) {
        if (this.data.type === "lead") {
          if (this.status.orig[x].ENTITY_ID === "STATUS") {
            this.status.name.push(this.status.orig[x]);
          }
        }
        if (this.data.type === "deal") {
          if (this.status.orig[x].ENTITY_ID === "DEAL_STAGE") {
            this.status.name.push(this.status.orig[x]);
          }
        }
      }
      const filteredArr = this.status.name.reduce((acc, current) => {
        const x = acc.find((item) => item.NAME === current.NAME);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      this.status.name = filteredArr;
      //console.log(this.status.name);
    }
  },
  created() {
    this.$insProgress.start();
    this.status_fetch();
    this.getSch();
  },
};
</script>

<style>
h3.title {
  font-weight: 700 !important;
  color: #f16925 !important;
  font-family: Inter;
  font-size: 20px;
  letter-spacing: 2px;
}

.card label {
  margin-left: 7px;
}

.check-box-lable {
  margin-bottom: 10px;
  margin-top: 10px;
}

.check-box-lable input {
  display: block;
  float: left;
  margin-top: 5px;
}

.Queue .el-select {
  width: 100%;
  margin-bottom: 10px;
}

lable {
  font-size: 0.75rem;
  margin-bottom: 5px;
}

.Queue .select-primary.el-select .el-input input {
  border-color: rgba(29, 37, 59, 0.5);
  color: #222a42;
  border-radius: 0.4285rem;
}

.Queue div.select-primary.el-select .el-input:hover input {
  border-color: rgba(29, 37, 59, 0.5) !important;
  color: #222a42 !important;
}

.Queue .select-primary .el-tag,
.select-primary .el-tag.el-tag--info {
  background: transparent linear-gradient(0deg, #ff6491 0%, #ff8d72 100%);
}


.Queue #phonechallbox .el-input__prefix,
.el-input__suffix {
  top: 0 !important;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected.select-primary {
  color: #fff !important;
}

.Queue .col-md-6 {
  margin-left: inherit;
  margin-right: inherit;
}

.Queue textarea {
  width: 100%;
}

.alert.open.alert-with-icon.top.right.alert-primary {
  background: linear-gradient(0deg, #ff6491 0%, #ff8d72 100%);
}

div.select-primary.el-select .el-input:hover input::placeholder {
  color: #b2b2b2;
}

div.select-primary.el-select .el-input:hover input {
  border-color: #b2b2b2 !important;
}

.select-primary.el-select input {
  border-color: #b2b2b2 !important;
}

</style>
