<template >
    <div class="PhoneBox" id="smschatlbox" v-if="object.show">
	
        <div class="PhoneBoxHeader">
            <h2>Message - {{(object.lead.NAME !== undefined ? object.lead.NAME : object.lead.TITLE)}}</h2>
            <span class="PhoneBox-control">
                <a href="#" class="min-phone" @click="toggle()"><i id="window_toggler" class="tim-icons icon-simple-delete"></i></a>
                <a  class="closePhone" v-on:click="exit()"><i class="tim-icons icon-simple-remove"></i></a>
            </span>
        </div>
        <div class="PhoneBoxBody" id="phone_body">
        <div  v-if="loading">
            <infinite-loading direction="top" @infinite="infiniteHandler"></infinite-loading>
        </div>
        <span v-for="message in messages" :key="message.id" :data-num="messages.length - message.id" :class="message.xclass">{{message.message}} <span  class="pull-right">{{date_format(message.created_at)}}</span></span>
        </div>
        <div class="PhoneBoxFooter" id="phone_footer">
        <div class="grid-footer">
            <div class="grid-item1">
            <textarea v-on:keyup.enter="enter_message" id="message_text" placeholder="Type Your Message..."></textarea></div>
            <div class="grid-item2">
            <i class="fa fa-paper-plane" @click="send()" aria-hidden="true"></i>
            </div>
            </div>
        </div>
    </div>
    
</template>
<script>
// import { RingLoader } from 'vue-spinners-css';
import swal from "sweetalert2";
import config from "@/config";
import { Loading } from 'element-ui';
import InfiniteLoading from 'vue-infinite-loading';
export default {
    components: {
        InfiniteLoading,
    },
    props: {
        object: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            required: true,
        }
    },
    watch: {
        object: {
            deep: true,
            handler(new_value){
                if(new_value.lead.id !== undefined){
                    //console.log("re-fetch");
                    this.init();
                }
            }
        },
    },

    data() {
        return {
            load:this.display,
            conversation : {
                id:"",
                message:""
            },
            messages :[],
            dom : {
                window_toggler : false,
                chat_body_text : "Message "
            },
            user:{},
            loading: true,
            page: 4,
            channel : {}
        }
    },
    methods: {
        init(){
            if(this.object.lead.id !== undefined){
                //console.log(this.object);
                if(this.user.id === undefined){
                    var auth = this.$store.getters['api/auth'];
                    this.user = auth.user;
                }
                this.conversation_read(this.object.lead);
                //console.log(this.$pusher);
                this.channel = this.$pusher.subscribe('MessageEvent');
                this.channel.bind('received', this.recieved_event );
            }else{
                this.exit();
            }
            
        },
        exit(){
            this.messages = [];
            this.window_toggler = false;
            this.object.lead = {};
            this.object.show = false;
        },
        toggle(){
            if(this.window_toggler){
                this.maximize();
            }else{
                this.minimize();
            }
        },
        infiniteHandler () {
            return true;
        },
        maximize() {
            var window_toggler = document.getElementById('window_toggler');
            window_toggler.classList.remove("icon-simple-add");
            window_toggler.classList.add("icon-simple-delete");
            document.getElementById('phone_body').style.display = "inherit";
            document.getElementById('phone_footer').style.display = "inherit";
            this.window_toggler = false;
        },
        minimize() {
            var window_toggler = document.getElementById('window_toggler');
            window_toggler.classList.remove("icon-simple-delete");
            window_toggler.classList.add("icon-simple-add");
            document.getElementById('phone_body').style.display = "none";
            document.getElementById('phone_footer').style.display = "none";
            this.window_toggler = true;
        },
        conversation_read(lead){
            this.loading = true;
            this.$store
            .dispatch(this.type + "/conversation_read", lead.id)
            .then((response) => {
                //console.log(response);
                this.messages = response.data.data.map((row)=>{
                    var xclass = "";
                    if(row.direction === 'inbound'){
                        xclass = "send-msgs";
                    }else{
                        xclass = "receive-msgs";
                    }
                    return {id:row.id,message:row.text,created_at:row.created_at,xclass:xclass};
                });
                
                setTimeout(function(){ 
                    var objDiv = document.getElementById("phone_body");
                    objDiv.scrollTop = objDiv.scrollHeight; 
                }, 500);

                this.loading = false;
                //this.calling.current_conference = response.data.data.conversation;
                //this.calling.text = "[ connected ]";
            });
        },
        conversation_send(message){
            //console.log(message);
            var today = new Date();
            var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            var dateTime = date+' '+time;
            var id = 1;
            if(this.messages.length > 0){
                id = this.messages[this.messages.length -1].id + 1;
            }
            document.getElementById('message_text').value = "";
            this.messages.push({id:id,message:message.message,created_at:dateTime,xclass:"receive-msgs"});
            setTimeout(function(){ 
                    var objDiv = document.getElementById("phone_body");
                    objDiv.scrollTop = objDiv.scrollHeight; 
                }, 500);

            this.$store
            .dispatch(this.type + "/conversation_send", message)
            .then((response) => {
                //console.log(response);
            });
            
        },
        send(){
            this.conversation.id = this.object.lead.id;
            this.conversation.message = document.getElementById('message_text').value;
            if(this.conversation.message !== ''){
                //console.log(this.conversation);
                if(this.conversation.message !== '' && this.conversation.message !== null && this.conversation.message !== undefined){
                    this.conversation_send(this.conversation);
                }
            }
        },
        enter_message(){
            this.conversation.id = this.object.lead.id;
            this.conversation.message = document.getElementById('message_text').value;
            this.conversation.message = this.conversation.message.substring(0, this.conversation.message.length - 1);
            document.getElementById('message_text').value = this.conversation.message;
            //console.log(this.conversation.message.length);
            if(this.conversation.message.length > 0){
                this.send();
            }
        },
        opensmsbox() {
            document.getElementById('smschatlbox').style.display = "inherit";
        },
        closechatbox() {
            document.getElementById('smschatlbox').style.display = "none";
        },
        recieved_event(data){
            if(data.object.id !== undefined){
               if(data.object.parent_type === this.type){
                     if(data.object.id === this.object.lead.id){
                        this.messages.push({id:data.message.id,message:data.message.text,created_at:data.message.created_at,xclass:"send-msgs"});
                        setTimeout(function(){ 
                            var objDiv = document.getElementById("phone_body");
                            objDiv.scrollTop = objDiv.scrollHeight; 
                        }, 500);
                    }
                }
            }
        },
        date_format(date_string){
            const date = new Date(date_string);
            return date.getDate() +'-'+ (((date.getMonth()+1) < 10 ?('0'+(date.getMonth()+1).toString()) : (date.getMonth()+1) )) +'-'+ date.getFullYear() + ' ' + date.toLocaleTimeString('en-US');
        }
    },

    created() {
    },
};
</script>
