<template>
<card>
    <h3 slot="" class="title">{{this.user.TITLE}}</h3>
    <form>
        <div class="row mb-4">
            <div class="col-md-6">
                <base-input type="text" label="Title" placeholder="Title" v-model="user.TITLE">
                </base-input>
            </div>
            <div class="col-md-6">
                <base-input type="text" label="FullName" placeholder="FullName" v-model="user.NAME">
                </base-input>
            </div>
        </div>

        <div class="row mb-4">
            <div class="col-md-6">
                <base-input type="email" label="Email address" placeholder="email" name="email" v-validate="modelValidations.email" v-model="user.email" :error="getError('email')">
                </base-input>
            </div>
            <div class="col-md-6">
                <base-input type="tel" label="Phone" placeholder="phone" name="phone" v-validate="modelValidations.phone" v-model="user.phone" :error="getError('phone')">
                </base-input>
            </div>
        </div>

        <div class="row mb-4">
            <div class="col-md-12">
                <base-input type="text" label="Address" placeholder="Home Address" v-model="user.ADDRESS">
                </base-input>
            </div>
        </div>

        <div class="row mb-4">
            <div class="col-md-4">
                <base-input type="text" label="City" placeholder="City" v-model="user.ADDRESS_CITY">
                </base-input>
            </div>
            <div class="col-md-4">
                <base-input type="text" label="Country" placeholder="Country" v-model="user.ADDRESS_COUNTRY">
                </base-input>
            </div>
            <div class="col-md-4">
                <base-input label="Postal Code" placeholder="ZIP Code" v-model="user.ADDRESS_POSTAL_CODE">
                </base-input>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-md-6">
                <base-input type="text" label="Opportunity" placeholder="opportunity" name="opportunity" v-model="user.OPPORTUNITY">
                </base-input>
            </div>
            <div class="col-md-6">
                <base-input type="text" label="Status" placeholder="Status" name="Status" v-model="user.STATUS_ID">
                </base-input>
            </div>
        </div>
        <div class="row mb-4">
            <div v-for="field in fields" :key="field.id" class="col-md-4">
                <base-input :label="field.label" :placeholder="field.label" v-model="field.value">
                </base-input>
            </div>
        </div>
        <!-- <base-button native-type="submit" @click.prevent="editlead" type="success" class="btn-fill">
            Update
        </base-button> -->
        <base-button type="default" class="btn-fill mb-5" @click="$router.go(-1)">
            Back
        </base-button>
    </form>
</card>
</template>

<script>
export default {
    data() {
        return {
            user: {

            },
            modelValidations: {

                email: {
                    required: true
                },
                phone: {
                    required: true
                }
            },
            fields: []
        };
    },
    methods: {

        getError(fieldName) {
            return this.errors.first(fieldName);
        },

        editlead() {
            this.$validator.validateAll().then(isValid => {
                if (isValid) {
                    var data = {
                        "id": this.user.id,
                        "title": this.user.title,
                        "name": this.user.name,
                        "address": this.user.address,
                        "address_city": this.user.address_city,
                        "address_postal_code": this.user.address_postal_code,
                        "address_country": this.user.address_country,
                        "terms": [{
                                "type_id": "PHONE",
                                "value": this.user.phone,
                                "value_type": "WORK"
                            },
                            {
                                "type_id": "EMAIL",
                                "value": this.user.email,
                                "value_type": "WORK"
                            }
                        ]
                    };
                    this.$insProgress.start();
                    this.$store.dispatch("lead/update", data).then((response) => {
                        //console.log(response);
                        this.$router.push('/leads/index');
                        this.$insProgress.finish();
                        this.$notify({
                            timeout: 2000,
                            verticalAlign: 'top',
                            horizontalAlign: 'right',
                            message: 'Your record Updated successfully!',
                            icon: 'tim-icons icon-check-2',
                            type: 'success',
                        });
                    });
                }
            });
        },

        ViewLead(to) {
          let id = null;
          if(to){
              id = to.params.id;
            }else{
              id = this.$route.params.id;
            }
            this.$store.dispatch('lead/getlead', +id).then((response) => {
                this.$insProgress.finish();
                var lead = response.data.data;
                var phone = lead.terms.find(term => term.type_id === 'PHONE');
                var email = lead.terms.find(term => term.type_id === 'EMAIL');
                //console.log("Email", email);
                this.user = lead;
                this.fields = lead.fields;
                this.user.phone = phone.value;
                this.user.email = email.value;
                // //console.log(this.user);

            });
        },
    },
    created() {
        this.$insProgress.start();
        this.ViewLead();
    },
    beforeRouteUpdate(to, from, next) {
    // Call the API query method when the URL changes
      this.$insProgress.start();
      this.ViewLead(to);
      next()
    }
};
</script>
