<template>

  <div id="dashboard_main" class="row">

    <div v-if="false" class="col-lg-4 col-md-6">
      <div class="card card-stats">
        <div class="card-body">
          <div class="row">
            <div class="mr-3">
              <div class="text-center info-icon text-center icon-warning d-block ml-auto ">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
                     y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                                <g id="deals_dashboard">
                                    <path class="st16"
                                          d="M14.3,1.7L1.6,10.9c-0.7,0.5-0.9,1.5-0.4,2.3c0,0,0.1,0.1,0.1,0.1l0,0c0.6,0.7,1.6,1.4,2.3,0.9l11.7-8.3   c0.3-0.3,0.8-0.3,1.1,0l11.7,8.4c0.7,0.5,1.8-0.2,2.4-0.9l0,0c0.6-0.7,0.5-1.7-0.2-2.3c0,0-0.1-0.1-0.1-0.1L17.7,1.7   C16.7,0.8,15.3,0.8,14.3,1.7z"/>
                                  <polyline class="st16" points="3.9,13.6 3.9,30.9 28.1,30.9 28.1,13.7  "/>
                                  <path class="st17"
                                        d="M17,18.6h-2c-0.9,0.1-1.7-0.6-1.8-1.5c-0.1-0.9,0.6-1.7,1.5-1.8c0.1,0,0.2,0,0.4,0h3.6   c0.4,0.1,0.7-0.2,0.8-0.5c0.1-0.4-0.2-0.7-0.5-0.8c-0.1,0-0.1,0-0.2,0h-2v-1.3c-0.1-0.4-0.4-0.6-0.8-0.5c-0.3,0-0.5,0.3-0.5,0.5   v1.3H15c-1.6,0-3,1.3-3,3s1.3,3,3,3h2c0.9,0,1.7,0.8,1.7,1.7s-0.8,1.7-1.7,1.7h-4.3c-0.4,0.1-0.6,0.4-0.5,0.8   c0,0.3,0.3,0.5,0.5,0.5h2.6v1.3c0.1,0.4,0.4,0.6,0.8,0.5c0.3,0,0.5-0.3,0.5-0.5v-1.3H17c1.6,0,3-1.3,3-3   C19.9,19.9,18.6,18.6,17,18.6L17,18.6z"/>
                                </g>
                            </svg>
              </div>
            </div>
            <div class="">
              <div class="numbers">
                <h3 class="card-title font-weight-600 mb-1">{{ counter.leads }}</h3>
                <h5> Total Number of Leads</h5>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="card-footer"> -->
        <!-- <hr> -->
        <!-- <div class="stats"> -->
        <!-- <div> -->
        <!-- <h5> Total Number of Leads</h5> -->
        <!-- </div> -->
        <!-- </div> -->
        <!-- </div> -->
      </div>
    </div>
    <div v-if="false" class="col-lg-4 col-md-6">
      <div class="card card-stats">
        <!---->
        <!---->
        <div class="card-body">
          <div class="row">
            <div class="mr-3">
              <div class="text-center info-icon text-center icon-warning d-block ml-auto ">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
                     y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                            <g id="leads_dashboard">
                                <polygon class="st18"
                                         points="3.1,14.5 3.1,7.6 28.9,7.6 28.9,14.5 20.9,22.4 20.9,28.4 11,31 11,22.4  "/>
                              <line class="st18" x1="3.4" y1="11.9" x2="28.6" y2="11.9"/>
                              <circle class="st19" cx="10.9" cy="2.7" r="1.5"/>
                              <circle class="st19" cx="15.9" cy="1.7" r="1.5"/>
                              <circle class="st19" cx="20.2" cy="3.8" r="1.5"/>
                            </g>
                            </svg>
              </div>
            </div>
            <div class="">
              <div class="numbers">
                <h3 class="card-title font-weight-600 mb-1">{{ counter.deals }}</h3>
                <h5>Total Number of Deals</h5>
              </div>
            </div>
          </div>
        </div>
        <!----
        <div class="card-footer">
            <hr>
            <div class="stats">
                <div>

                </div>
            </div>
        </div>-->
      </div>
    </div>
    <div v-if="false" class="col-lg-4 col-md-6">
      <div class="card card-stats">
        <!---->
        <!---->
        <div class="card-body">
          <div class="row">
            <div class="mr-3">
              <div class="text-center info-icon text-center icon-warning d-block ml-auto ">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
                     y="0px" viewBox="0 0 32 32">
                  <g id="agents_dashboard">
                    <g>
                      <ellipse class="st4" cx="10.6" cy="6.7" rx="6.1" ry="5.6"/>
                      <ellipse class="st4" cx="24.5" cy="7.5" rx="2.9" ry="2.7"/>
                      <path class="st4"
                            d="M20.9,15.9c0.4-1.3,1.5-2.9,4.1-2.9c3.2,0,4.1,3,4.4,4.5c0.1,0.4,0.2,0.9,0.3,1.3c0.2,1.1,0,3.7-6.8,3.7"/>
                      <path class="st4"
                            d="M2.2,25.6l0-0.3c0-2.3,0.6-4.6,1.9-6.4c1.3-1.8,3.2-3.2,6.3-3.2c3.1,0,5.1,1.5,6.4,3.3c1.4,1.8,2,4.2,2,6.6v0    c0,1.5-0.8,3-2,3.6c-2.7,1.5-7.7,3.2-12.9,0C2.9,28.4,2.2,27,2.2,25.6z"/>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            <div class="">
              <div class="numbers">
                <h3 class="card-title font-weight-600 mb-1">{{ counter.agents }}</h3>
                <h5>Total Number of Agents</h5>
              </div>
            </div>
          </div>
        </div>
        <!----
        <div class="card-footer">
            <hr>
            <div class="stats">
                <div>

                </div>
            </div>
        </div>-->
      </div>
    </div>
    <div v-if="false" class="col-lg-4 col-md-6">
      <div class="card card-stats">
        <!---->
        <!---->
        <div class="card-body">
          <div class="row">
            <div class="mr-3">
              <div class="text-center info-icon text-center icon-warning d-block ml-auto ">
                <svg xmlns="http://www.w3.org/2000/svg" id="sms_dashboard" viewBox="0 0 32 32">
                  <path class="cls-1"
                        d="M12.44,21.82H4.81a3.7,3.7,0,0,1-3.7-3.69V6.41a3.7,3.7,0,0,1,3.7-3.7H27.19a3.7,3.7,0,0,1,3.7,3.7V18.13a3.7,3.7,0,0,1-3.7,3.69h0a1.55,1.55,0,0,0-1.55,1.55v3.8a2.13,2.13,0,0,1-3.49,1.63l-7.3-6.1A3.73,3.73,0,0,0,12.44,21.82Z"/>
                  <circle class="cls-2" cx="10.29" cy="12.67" r="1.4"/>
                  <circle class="cls-2" cx="16" cy="12.67" r="1.4"/>
                  <circle class="cls-2" cx="21.71" cy="12.67" r="1.4"/>
                </svg>
              </div>
            </div>
            <div class="">
              <div class="numbers">
                <h3 class="card-title font-weight-600 mb-1">{{ counter.message_sent + counter.message_received }}</h3>
                <h5>Total Number of Messages</h5>
              </div>
            </div>
          </div>
        </div>
        <!---
        <div class="card-footer">
            <hr>
            <div class="stats">
                <div>

                </div>
            </div>
        </div>-->
      </div>
    </div>
    <div v-if="false" class="col-lg-4 col-md-6">
      <div class="card card-stats">
        <!---->
        <!---->
        <div class="card-body">
          <div class="row">
            <div class="mr-3">
              <div class="text-center info-icon text-center icon-warning d-block ml-auto ">
                <svg xmlns="http://www.w3.org/2000/svg" id="sms_dashboard" viewBox="0 0 32 32">
                  <path class="cls-1"
                        d="M12.44,21.82H4.81a3.7,3.7,0,0,1-3.7-3.69V6.41a3.7,3.7,0,0,1,3.7-3.7H27.19a3.7,3.7,0,0,1,3.7,3.7V18.13a3.7,3.7,0,0,1-3.7,3.69h0a1.55,1.55,0,0,0-1.55,1.55v3.8a2.13,2.13,0,0,1-3.49,1.63l-7.3-6.1A3.73,3.73,0,0,0,12.44,21.82Z"/>
                  <circle class="cls-2" cx="10.29" cy="12.67" r="1.4"/>
                  <circle class="cls-2" cx="16" cy="12.67" r="1.4"/>
                  <circle class="cls-2" cx="21.71" cy="12.67" r="1.4"/>
                </svg>
              </div>
            </div>
            <div class="">
              <div class="numbers">
                <h3 class="card-title font-weight-600 mb-1">{{ counter.message_sent }}</h3>
                <h5> Total Number of Message Sent</h5>
              </div>
            </div>
          </div>
        </div>
        <!--
        <div class="card-footer">
            <hr>
            <div class="stats">
                <div>

                </div>
            </div>
        </div>-->
      </div>
    </div>
    <div v-if="false" class="col-lg-4 col-md-6">
      <div class="card card-stats">
        <!---->
        <!---->
        <div class="card-body">
          <div class="row">
            <div class="mr-3">
              <div class="text-center info-icon text-center icon-warning d-block ml-auto ">
                <svg xmlns="http://www.w3.org/2000/svg" id="sms_dashboard" viewBox="0 0 32 32">
                  <path class="cls-1"
                        d="M12.44,21.82H4.81a3.7,3.7,0,0,1-3.7-3.69V6.41a3.7,3.7,0,0,1,3.7-3.7H27.19a3.7,3.7,0,0,1,3.7,3.7V18.13a3.7,3.7,0,0,1-3.7,3.69h0a1.55,1.55,0,0,0-1.55,1.55v3.8a2.13,2.13,0,0,1-3.49,1.63l-7.3-6.1A3.73,3.73,0,0,0,12.44,21.82Z"/>
                  <circle class="cls-2" cx="10.29" cy="12.67" r="1.4"/>
                  <circle class="cls-2" cx="16" cy="12.67" r="1.4"/>
                  <circle class="cls-2" cx="21.71" cy="12.67" r="1.4"/>
                </svg>
              </div>
            </div>
            <div class="">
              <div class="numbers">
                <h3 class="card-title font-weight-600 mb-1">{{ counter.message_received }}</h3>
                <h5>Total Number of Message Received</h5>
              </div>
            </div>
          </div>
        </div>
        <!--
        <div class="card-footer">
            <hr>
            <div class="stats">
                <div>

                </div>
            </div>
        </div>-->
      </div>
    </div>
    <div v-if="false" class="col-lg-4 col-md-6">
      <div class="card card-stats">
        <!---->
        <!---->
        <div class="card-body">
          <div class="row">
            <div class="mr-3">
              <div class="text-center info-icon text-center icon-warning d-block ml-auto ">
                <svg xmlns="http://www.w3.org/2000/svg" id="phone_dashboard" viewBox="0 0 32 32">
                  <path class="cls-1"
                        d="M7.08,2.14h.08a2,2,0,0,1,1.45.74L8.73,3c1.11,1,2.37,2.28,3.58,3.49a2.24,2.24,0,0,1,.74,1.57,2.24,2.24,0,0,1-.74,1.56l-.1.1a4.92,4.92,0,0,0-.35,6.11,12.56,12.56,0,0,0,2,2.27h0a12.25,12.25,0,0,0,1.16,1.16,6,6,0,0,0,4,1.48,6.32,6.32,0,0,0,2.86-.69,2,2,0,0,0,.49-.37,2.24,2.24,0,0,1,1.57-.74,2.21,2.21,0,0,1,1.56.75l3.69,3.69a2,2,0,0,1,0,3l-.8.78-.07.06c-.47.46-.95.92-1.42,1.44a3.94,3.94,0,0,1-3.06,1.18A11.39,11.39,0,0,1,19.93,29,28.49,28.49,0,0,1,9.69,22.26,28,28,0,0,1,3,12.15c-.78-2.16-1.6-5.24.3-7l.1-.09.68-.71c.49-.51,1-1,1.51-1.49l.08-.08a2,2,0,0,1,1.43-.67m0-2A4,4,0,0,0,4.24,1.4c-.56.5-1.09,1-1.62,1.59l-.68.71C-1.17,6.52.53,11.26,1.1,12.83A30.08,30.08,0,0,0,8.3,23.7a30.55,30.55,0,0,0,10.94,7.19,13.29,13.29,0,0,0,4.62,1A6,6,0,0,0,28.39,30c.44-.49.92-.95,1.4-1.42l.82-.79a4,4,0,0,0,0-5.87l-3.7-3.69a4,4,0,0,0-5.95,0,4.17,4.17,0,0,1-1.94.47,4,4,0,0,1-2.65-1,13.06,13.06,0,0,1-1-1,.93.93,0,0,0-.14-.14,11.3,11.3,0,0,1-1.73-1.94,2.93,2.93,0,0,1,.21-3.63v0a4.14,4.14,0,0,0,1.33-3,4.15,4.15,0,0,0-1.33-3c-1.25-1.25-2.49-2.5-3.64-3.55A4,4,0,0,0,7.21.14Z"/>
                </svg>
              </div>
            </div>
            <div class="">
              <div class="numbers">
                <h3 class="card-title font-weight-600 mb-1">{{ counter.conference }}</h3>
                <h5>Total Number of Calls</h5>
              </div>
            </div>
          </div>
        </div>
        <!--
        <div class="card-footer">
            <hr>
            <div class="stats">
                <div>

                </div>
            </div>
        </div>-->
      </div>
    </div>
    <div v-if="false" class="col-lg-4 col-md-6">
      <div class="card card-stats">
        <!---->
        <!---->
        <div class="card-body">
          <div class="row">
            <div class="mr-3">
              <div class="text-center info-icon text-center icon-warning d-block ml-auto ">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
                     y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                            <g id="phone_incoming_dashboard">
                                <path class="st4"
                                      d="M18.6,19.5c0.9-0.9,1.9-1.1,2.5-1.1c0.6,0,1.6,0.2,2.5,1.1l3.1,3.1c1.4,1.4,1.4,3.5,0,4.9L26,28.2   c-0.4,0.4-0.8,0.8-1.2,1.2c-1,1-2.2,1.5-3.8,1.5c-1.1,0-2.4-0.3-3.9-0.8c-3.2-1.2-6.4-3.3-9.2-6c-2.7-2.6-4.8-5.8-6-9.1   c-0.5-1.3-1.9-5.3,0.7-7.7l0.6-0.6c0.4-0.5,0.9-0.9,1.4-1.3c0.9-0.9,1.8-1.1,2.5-1.1c0.9,0,1.7,0.4,2.4,1.2c1,0.9,2,1.9,3.1,3   c0.9,0.9,1.1,1.9,1.1,2.5c0,0.6-0.2,1.6-1.1,2.5l0,0c-0.8,0.9-0.8,2.1-0.2,3c0.6,0.8,1.2,1.4,1.5,1.6c0,0,0.1,0.1,0.1,0.1   c0.2,0.2,0.4,0.5,0.8,0.8C15.8,20,17.4,20.1,18.6,19.5L18.6,19.5z"/>
                              <g>
                                    <polyline class="st4" points="24,1 19.2,5.8 24,10.5   "/>
                                <line class="st4" x1="19.2" y1="5.8" x2="31" y2="5.8"/>
                                </g>
                            </g>
                            </svg>
              </div>
            </div>
            <div class="">
              <div class="numbers">
                <h3 class="card-title font-weight-600 mb-1">{{ counter.conference_inbound }}</h3>
                <h5>Total Number of Incoming Calls</h5>
              </div>
            </div>
          </div>
        </div>
        <!--
        <div class="card-footer">
            <hr>
            <div class="stats">
                <div>

                </div>
            </div>
        </div>-->
      </div>
    </div>
    <div v-if="false" class="col-lg-4 col-md-6">
      <div class="card card-stats">
        <!---->
        <!---->
        <div class="card-body">
          <div class="row">
            <div class="mr-3">
              <div class="text-center text-center info-icon text-center icon-warning d-block ml-auto  d-block ml-auto ">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
                     y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                            <g id="phone_outgoing_dashboard">
                                <path class="st4"
                                      d="M18.6,19.5c0.9-0.9,1.9-1.1,2.5-1.1c0.6,0,1.6,0.2,2.5,1.1l3.1,3.1c1.4,1.4,1.4,3.5,0,4.9L26,28.2   c-0.4,0.4-0.8,0.8-1.2,1.2c-1,1-2.2,1.5-3.8,1.5c-1.1,0-2.4-0.3-3.9-0.8c-3.2-1.2-6.4-3.3-9.2-6c-2.7-2.6-4.8-5.8-6-9.1   c-0.5-1.3-1.9-5.3,0.7-7.7l0.6-0.6c0.4-0.5,0.9-0.9,1.4-1.3c0.9-0.9,1.8-1.1,2.5-1.1c0.9,0,1.7,0.4,2.4,1.2c1,0.9,2,1.9,3.1,3   c0.9,0.9,1.1,1.9,1.1,2.5c0,0.6-0.2,1.6-1.1,2.5l0,0c-0.8,0.9-0.8,2.1-0.2,3c0.6,0.8,1.2,1.4,1.5,1.6c0,0,0.1,0.1,0.1,0.1   c0.2,0.2,0.4,0.5,0.8,0.8C15.8,20,17.4,20.1,18.6,19.5L18.6,19.5z"/>
                              <g>
                                    <polyline class="st4" points="26.2,1 31,5.8 26.2,10.5   "/>
                                <line class="st4" x1="31" y1="5.8" x2="19.2" y2="5.8"/>
                                </g>
                            </g>
                            </svg>
              </div>
            </div>
            <div class="">
              <div class="numbers">
                <h3 class="card-title font-weight-600 mb-1">{{ counter.conference_outbound }}</h3>
                <h5>Total Number of Outgoing Calls</h5>
              </div>
            </div>
          </div>
        </div>
        <!--
        <div class="card-footer">
            <hr>
            <div class="stats">
                <div>

                </div>
            </div>
        </div>-->
      </div>
    </div>
    <div class="col-12 pt-0">
      <div class="card" card-body-classes="table-full-width">
        <!---->
        <div class="card-header top-leads">
          <div class="row">
            <div class="col-12 pt-0">
              <div class="leads_left">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
                     y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                            <g id="leads_heading_svg">
                                <polygon class="st18"
                                         points="3.1,14.5 3.1,7.6 28.9,7.6 28.9,14.5 20.9,22.4 20.9,28.4 11,31 11,22.4  "/>
                              <line class="st18" x1="3.4" y1="11.9" x2="28.6" y2="11.9"/>
                              <circle class="st19" cx="10.9" cy="2.7" r="1.5"/>
                              <circle class="st19" cx="15.9" cy="1.7" r="1.5"/>
                              <circle class="st19" cx="20.2" cy="3.8" r="1.5"/>
                            </g>
                        </svg>
                <h4 class="card-title pull-left">New Deals </h4>
              </div>
              <div class="pull-right form-pull" v-if="false">
                <!---->
                <div class=" search-input el-input el-input--prefix el-input--suffix">
                  <!---->
                  <input type="search" name="search" autocomplete="off" @keyup.enter="lead_fetch"
                         placeholder="Search records" aria-controls="datatables" class="el-input__inner"
                         v-model="search"/><span class="el-input__prefix"><i
                  class="el-input__icon el-icon-search searchbarb"></i></span>
                  <!---->
                  <!---->
                  <!---->
                </div>
                <!---->
                <!--  <div class="add-leads-btn ml-2">
                      <button type="button" class=" pull-right btn btn-simple btn-linkedin mt-0" @click="deal_fetch">Search</button>
                  </div> -->

                <div class="dealFilter inputSvg">
                  <p>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns: xlink="http://www.w3.org/1999/xlink" version="1.1"
                         id="openDealleads" x="0px" y="0px" viewBox="0 0 32 32" xml: space="preserve">
                      <path
                        d="M3.1,13.8V7.6h25.8v6.1c0,0.5-0.2,0.9-0.5,1.3l-7.2,7.1c-0.2,0.2-0.3,0.4-0.3,0.6v5c0,0.4-0.3,0.7-0.6,0.8  l-8.2,2.2c-0.5,0.1-1.1-0.3-1.1-0.8v-7.1c0-0.2-0.1-0.4-0.2-0.6L3.7,15C3.3,14.7,3.1,14.2,3.1,13.8z"
                        class="st0"></path>
                      <line x1="3.4" y1="11.9" x2="28.6" y2="11.9" class="st0"></line>
                      <circle cx="10.9" cy="2.7" r="1.5" class="st1"></circle>
                      <circle cx="15.9" cy="1.7" r="1.5" class="st1"></circle>
                      <circle cx="20.2" cy="3.8" r="1.5" class="st1"></circle>
                    </svg>
                  </p>

                  <el-select class="select-primary" size="large" placeholder="Select" v-model="deal_status.simple"
                             @change="deal_status_change">
                    <el-option v-for="option in deal_status.name" class="select-info" :value="option.STATUS_ID"
                               :label="option.NAME" :key="option.id">
                    </el-option>
                  </el-select>
                </div>

                <div class="add-leads-btn ml-2">
                  <button type="button" class=" pull-right btn btn-simple btn-linkedin mt-0" @click="deal_fetch">
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="box-table">
            <div class="Table">
              <div class="Heading">
                <div class="Cell">
                  <p>Deal</p>
                </div>
                <div class="Cell">
                  <p>Stage</p>
                </div>
                <div class="Cell">
                  <p>Amount</p>
                </div>
                <div class="Cell">
                  <p>Email</p>
                </div>
                <div class="Cell">
                  <p>Phone</p>
                </div>
                <div class="Cell leads_svg">
                  <svg data-v-9ff36b54="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                       version="1.1" x="0px" y="0px" viewBox="0 0 32 32" xml:space="preserve"
                       @click="deal_orderby_toggle('created_at')"><g data-v-9ff36b54="" id="filter_leads"><polyline data-v-9ff36b54="" points="30.9,8.5 16,23.5 1.1,8.5 " class="st4"></polyline></g></svg>
                  <p>Created <!--<font-awesome-icon style="" :icon="deal_orderby.icon" ></font-awesome-icon>--> </p>
                </div>
                <div class="Cell">
                  <p>Action</p>
                </div>
              </div>
              <div class="Row" v-for="deal in deals" :key="deal.id">
                <div class="Cell deals_first_cell">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                         x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;"
                         xml:space="preserve">
                                <g id="open_deals_dash">
                                <path class="st4"
                                      d="M3.8,26.9c-1.5,0-2.8-1.2-2.8-2.8V8c0-1.5,1.3-2.8,2.8-2.8h6.3c1.1,0,2.1,0.8,2.3,1.9l0.1,0.5   c0.1,0.4,0.4,0.7,0.8,0.7h8.3c1.5,0,2.8,1.2,2.8,2.8v2.9"/>
                                  <path class="st4"
                                        d="M25.4,25.1c-0.8,1.1-2.1,1.8-3.4,1.8H3.8h0c-2,0-3.1-2.8-1.7-4.6l5-6.5C8,14.7,9.4,14,10.7,14h17.8l0,0   c2,0,3,2.8,1.7,4.5L25.4,25.1z"/>
                                </g>
                                </svg>
                  </div>
                  <p>{{ deal.TITLE }}</p>
                </div>
                <div class="Cell">
                  <div class="staging_main">
                    <div class="inquiry_svg">
                      <svg
                        v-if="deal.stage"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 32 32"
                        fill="none"
                        stroke="#f16925"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-miterlimit="10"
                        style="enable-background:new 0 0 32 32;"
                        xml:space="preserve"
                      >
                              <g v-if="deal.stage.NAME === 'New Inquiry'">
                                <g>
                                  <path
                                    class="st4"
                                    d="M12.6,19.8c0,0,9-0.6,9,9.1c0,0,0-9.1,9.3-9.1c0,0-9.3,1.1-9.3-9.1C21.6,10.7,22.4,19.8,12.6,19.8"
                                  />
                                  <path
                                    class="st4"
                                    d="M3.8,8.6c0,0,6.5-0.4,6.5,6.6c0,0,0-6.6,6.8-6.6c0,0-6.8,0.8-6.8-6.6C10.3,2,10.8,8.6,3.8,8.6"
                                  />
                                  <path
                                    class="st4"
                                    d="M1.1,24.7c0,0,5.2-0.3,5.2,5.3c0,0,0-5.3,5.4-5.3c0,0-5.4,0.6-5.4-5.3C6.3,19.4,6.7,24.7,1.1,24.7"
                                  />
                                </g>
                              </g>

                        <g
                          v-if="deal.stage.NAME === 'Contact Attempted'"
                          id="connectionattempted_00000107590379365792513880000014066502916078176431_"
                        >
                                <path
                                  d="M16,23.9L16,23.9c-0.4,0-0.8-0.5-0.8-1.2L13.9,1.6c0-0.8,0.3-1.4,0.8-1.4h2.5c0.5,0,0.9,0.6,0.8,1.4l-1.2,21.1C16.8,23.3,16.4,23.9,16,23.9z"
                                  fill="#f16925"
                                />
                          <circle cx="16" cy="29.3" r="2.7"/>
                          <path d="M10.2,7.4c-3.5,0.7-6.7,2.5-9.1,5.2"/>
                          <path d="M10.7,16.7c-1.3,0.3-2.5,1.1-3.4,2.2"/>
                          <path d="M21.8,7.4c3.5,0.7,6.7,2.5,9.1,5.2"/>
                          <path d="M21.3,16.7c1.3,0.3,2.5,1.1,3.4,2.2"/>
                              </g>

                        <g
                          v-if="
                                  deal.stage.NAME === 'Automation Off-Board'
                                "
                        >
                                <g>
                                  <g>
                                    <line
                                      class="st4"
                                      x1="20"
                                      y1="4.9"
                                      x2="20"
                                      y2="1.5"
                                    />
                                    <line
                                      class="st4"
                                      x1="12"
                                      y1="5.1"
                                      x2="12"
                                      y2="1.5"
                                    />
                                    <line
                                      class="st4"
                                      x1="12"
                                      y1="26.9"
                                      x2="12"
                                      y2="30.3"
                                    />
                                    <line
                                      class="st4"
                                      x1="20"
                                      y1="26.6"
                                      x2="20"
                                      y2="30.3"
                                    />
                                  </g>
                                  <g>
                                    <line
                                      class="st4"
                                      x1="1.4"
                                      y1="19.8"
                                      x2="4.7"
                                      y2="19.8"
                                    />
                                    <line
                                      class="st4"
                                      x1="1.1"
                                      y1="11.8"
                                      x2="4.7"
                                      y2="11.8"
                                    />
                                    <line
                                      class="st4"
                                      x1="27.5"
                                      y1="19.8"
                                      x2="30.9"
                                      y2="19.8"
                                    />
                                    <line
                                      class="st4"
                                      x1="27.3"
                                      y1="11.8"
                                      x2="30.9"
                                      y2="11.8"
                                    />
                                  </g>
                                  <rect
                                    x="9.6"
                                    y="9.4"
                                    class="st4"
                                    width="12.9"
                                    height="13.1"
                                  />
                                </g>
                              </g>

                        <g
                          v-if="deal.stage.NAME === 'Contact Established'"
                        >
                                <circle
                                  class="st14"
                                  cx="16"
                                  cy="26.4"
                                  r="2.7"
                                />
                          <path
                            class="st4"
                            d="M7.4,16c1.1-1.5,4.7-3.1,8.8-3.1c3.8,0,7,1.7,8.4,3.1"
                          />
                          <path
                            class="st4"
                            d="M1.1,9.6C3,7,9.2,4.3,16.4,4.3c6.6,0,12.2,3,14.6,5.3"
                          />
                              </g>

                        <g
                          v-if="deal.stage.NAME === 'Active Prospects'"
                          id="activeprospects"
                        >
                                <circle
                                  class="st4"
                                  cx="10.9"
                                  cy="9.4"
                                  r="6.4"
                                />
                          <circle
                            class="st4"
                            cx="24.3"
                            cy="4.2"
                            r="3.1"
                          />
                          <path
                            class="st4"
                            d="M20.6,13.7c0.5-1.4,1.6-3.3,4.3-3.3c3.3,0,4.3,3.4,4.6,5.2c0.1,0.5,0.2,1,0.3,1.5c0.2,1.2,0,4.2-7.1,4.2"
                          />
                          <path
                            class="st4"
                            d="M2.1,30.9l0-0.4c0-2.6,0.7-5.2,2-7.3c1.3-2,3.4-3.7,6.6-3.7c3.2,0,5.4,1.7,6.8,3.7c1.4,2.1,2.1,4.8,2.1,7.5
    v0.1"
                          />
                              </g>

                        <g v-if="deal.stage.NAME === '1003 Requested'">
                                <rect
                                  x="1.1"
                                  y="1.1"
                                  class="st4"
                                  width="12.2"
                                  height="12.2"
                                />
                          <rect
                            x="18.8"
                            y="1.1"
                            class="st4"
                            width="12.2"
                            height="12.2"
                          />
                          <rect
                            x="1.1"
                            y="18.8"
                            class="st4"
                            width="12.2"
                            height="12.2"
                          />
                          <rect
                            x="18.8"
                            y="18.8"
                            class="st4"
                            width="12.2"
                            height="12.2"
                          />
                          <polyline
                            class="st4"
                            points="9.9,23.1 6.2,26.8 4.7,25.2 	"
                          />
                              </g>

                        <g v-if="deal.stage.NAME === '1003 Completed'">
                                <rect
                                  x="1.1"
                                  y="1.1"
                                  width="12.2"
                                  height="12.2"
                                />
                          <rect
                            x="18.8"
                            y="1.1"
                            width="12.2"
                            height="12.2"
                          />
                          <rect
                            x="1.1"
                            y="18.8"
                            width="12.2"
                            height="12.2"
                          />
                          <rect
                            x="18.8"
                            y="18.8"
                            width="12.2"
                            height="12.2"
                          />
                          <polyline
                            points="9.9,23.1 6.2,26.8 4.7,25.2 	"
                          />
                          <polyline
                            points="27.3,23.1 23.7,26.8 22.1,25.2 	"
                          />
                          <polyline points="9.9,5.4 6.2,9.1 4.7,7.5 	"/>
                          <polyline
                            points="27.4,5.4 23.7,9.1 22.2,7.5 	"
                          />
                              </g>

                        <g v-if="deal.stage.NAME === 'Credit Run'">
                                <g>
                                  <path
                                    class="st4"
                                    d="M28.5,25.5H9.4c-1.4,0-2.4-1.1-2.4-2.4V8.9c0-1.4,1.1-2.4,2.4-2.4h19.1c1.4,0,2.4,1.1,2.4,2.4v14.2
			C30.9,24.4,29.8,25.5,28.5,25.5z"
                                  />
                                  <line
                                    class="st4"
                                    x1="7.3"
                                    y1="12.4"
                                    x2="30.7"
                                    y2="12.4"
                                  />
                                  <line
                                    class="st4"
                                    x1="3.7"
                                    y1="11.3"
                                    x2="2.3"
                                    y2="11.3"
                                  />
                                  <line
                                    class="st4"
                                    x1="3.8"
                                    y1="16"
                                    x2="1.1"
                                    y2="16"
                                  />
                                  <line
                                    class="st4"
                                    x1="2.6"
                                    y1="20.8"
                                    x2="1.1"
                                    y2="20.8"
                                  />
                                  <circle
                                    class="st14"
                                    cx="27.1"
                                    cy="22.4"
                                    r="1"
                                  />
                                  <circle
                                    class="st14"
                                    cx="25.8"
                                    cy="22.4"
                                    r="1"
                                  />
                                </g>
                              </g>
                        <g v-if="deal.stage.NAME === 'Pre-Approval'">
                                <circle class="st4" cx="16" cy="10" r="4.4"/>
                          <polygon
                            class="st4"
                            points="15.9,1.1 11.1,2.5 7.8,6.3 7.1,11.3 9.2,15.8 13.4,18.6 18.5,18.6 22.7,15.8 24.8,11.3 24.1,6.3
		20.8,2.5 	"
                          />
                          <polyline
                            class="st4"
                            points="10,17.6 3.3,26.8 7.2,27.7 9.1,30.9 15.7,19.5 	"
                          />
                          <polyline
                            class="st4"
                            points="22,17.6 28.7,26.8 24.8,27.7 22.9,30.9 16.3,19.5 	"
                          />
                              </g>
                        <g v-if="deal.stage.NAME === 'Doc Requested'">
                                <polyline
                                  class="st4"
                                  points="24.9,20.8 24.9,30.9 1.1,30.9 1.1,1.1 24.9,1.1 24.9,6.7 	"
                                />
                          <line
                            class="st4"
                            x1="6.8"
                            y1="6.9"
                            x2="19.1"
                            y2="6.9"
                          />
                          <line
                            class="st4"
                            x1="6.8"
                            y1="13"
                            x2="17.7"
                            y2="13"
                          />
                          <line
                            class="st4"
                            x1="6.8"
                            y1="19.1"
                            x2="11.1"
                            y2="19.1"
                          />
                          <line
                            class="st4"
                            x1="6.8"
                            y1="25.1"
                            x2="11.1"
                            y2="25.1"
                          />
                          <path
                            class="st14"
                            d="M14.9,21v2.1c0,0.2,0.2,0.4,0.4,0.4h2.1c0.4,0,0.5-0.4,0.3-0.7c-0.6-0.6-1.5-1.5-2.1-2.1
		C15.4,20.5,14.9,20.7,14.9,21z"
                          />
                          <path
                            class="st14"
                            d="M18.6,22.1l-2.2-2.2c-0.2-0.2-0.2-0.4,0-0.6l8.1-8.1c0.2-0.2,0.4-0.2,0.6,0l2.2,2.2c0.2,0.2,0.2,0.4,0,0.6
		l-8.1,8.1C19,22.3,18.7,22.3,18.6,22.1z"
                          />
                          <path
                            class="st14"
                            d="M28.1,12.6l-2.2-2.2c-0.2-0.2-0.2-0.4,0-0.6l1.3-1.3c0.2-0.2,0.4-0.2,0.6,0l2.2,2.2c0.2,0.2,0.2,0.4,0,0.6
		l-1.3,1.3C28.5,12.8,28.2,12.8,28.1,12.6z"
                          />
                              </g>
                        <g v-if="deal.stage.NAME === 'Docs Back'">
                                <polyline
                                  class="st4"
                                  points="12.9,30.9 1.1,30.9 1.1,1.1 24.9,1.1 24.9,9.7 	"
                                />
                          <line
                            class="st4"
                            x1="6.8"
                            y1="6.9"
                            x2="19.1"
                            y2="6.9"
                          />
                          <line
                            class="st4"
                            x1="6.8"
                            y1="13"
                            x2="13.7"
                            y2="13"
                          />
                          <line
                            class="st4"
                            x1="6.8"
                            y1="19.1"
                            x2="10.1"
                            y2="19.1"
                          />
                          <line
                            class="st4"
                            x1="6.8"
                            y1="25.1"
                            x2="10.1"
                            y2="25.1"
                          />
                          <circle
                            class="st4"
                            cx="22.2"
                            cy="22.2"
                            r="8.8"
                          />
                          <polyline
                            class="st4"
                            points="25,20.5 20.7,24.8 18.9,22.9 	"
                          />
                              </g>
                        <g v-if="deal.stage.NAME === 'Submitted'">
                                <g>
                                  <polyline
                                    class="st4"
                                    points="7.5,9.6 16,1.1 24.5,9.6 		"
                                  />
                                  <line
                                    class="st4"
                                    x1="16"
                                    y1="1.1"
                                    x2="16"
                                    y2="22.2"
                                  />
                                </g>
                          <polyline
                            class="st4"
                            points="24.3,26.8 24.3,30.9 7.3,30.9 7.3,26.8 	"
                          />
                              </g>
                        <g v-if="deal.stage.NAME === 'Archive'">
                                <path
                                  class="st4"
                                  d="M30,16H2c-0.5,0-0.9-0.4-0.9-0.9V6.1c0-0.5,0.4-0.9,0.9-0.9h28c0.5,0,0.9,0.4,0.9,0.9v8.9
		C30.9,15.6,30.5,16,30,16z"
                                />
                          <path
                            class="st4"
                            d="M30.9,14.4v11.6c0,0.4-0.4,0.7-0.9,0.7H2c-0.5,0-0.9-0.3-0.9-0.7V14.4"
                          />
                          <polyline
                            class="st4"
                            points="23.1,9.6 23.1,11.5 9,11.5 9,9.6 	"
                          />
                          <polyline
                            class="st4"
                            points="23.1,20.4 23.1,22.3 9,22.3 9,20.4 	"
                          />
                              </g>
                        <g v-if="deal.stage.NAME === 'Recycled Stage'">
                                <g>
                                  <path
                                    class="st4"
                                    d="M15.8,5.3c-6,0-11,4.2-12.2,9.7"
                                  />
                                  <path
                                    class="st4"
                                    d="M27,23.8c0.9-1.7,1.4-3.7,1.4-5.9c0-4.6-2.5-8.6-6.2-10.8"
                                  />
                                  <path
                                    class="st4"
                                    d="M3.6,20.7c1.3,5.6,6.3,9.8,12.2,9.8c2.7,0,5.2-0.8,7.2-2.3"
                                  />
                                  <polyline
                                    class="st4"
                                    points="14.1,1.5 17.6,5.1 14.1,8.6 		"
                                  />
                                  <polyline
                                    class="st4"
                                    points="30.9,22.6 26.2,24.3 24.6,19.6 		"
                                  />
                                  <polyline
                                    class="st4"
                                    points="1.1,24.4 3.3,19.9 7.7,22.1 		"
                                  />
                                </g>
                              </g>
                        <g v-if="deal.stage.NAME === 'Deal lost'">
                                <path
                                  d="M30.5,12.3l-9.9-1.1c-0.2,0-0.2-0.1-0.3-0.2l-4-9.1c-0.2-0.3-0.6-0.3-0.7,0l-4,9.1c-0.1,0.2-0.2,0.2-0.3,0.2
		l-9.8,1.1c-0.3,0-0.5,0.5-0.2,0.7l7.3,6.6c0.1,0.1,0.2,0.2,0.2,0.4l-2,9.6c-0.1,0.3,0.3,0.6,0.6,0.5l8.6-4.9c0.2-0.1,0.3-0.1,0.4,0
		l8.6,4.9c0.3,0.2,0.7-0.1,0.6-0.5l-2-9.6c0-0.2,0-0.3,0.2-0.4l7.3-6.6C31.1,12.8,30.9,12.3,30.5,12.3z"
                                />
                              </g>
                        <g v-if="deal.stage.NAME === 'Deal won'">
                                <path
                                  fill="#f16925"
                                  d="M30.5,12.3l-9.9-1.1c-0.2,0-0.2-0.1-0.3-0.2l-4-9.1c-0.2-0.3-0.6-0.3-0.7,0l-4,9.1c-0.1,0.2-0.2,0.2-0.3,0.2
		l-9.8,1.1c-0.3,0-0.5,0.5-0.2,0.7l7.3,6.6c0.1,0.1,0.2,0.2,0.2,0.4l-2,9.6c-0.1,0.3,0.3,0.6,0.6,0.5l8.6-4.9c0.2-0.1,0.3-0.1,0.4,0
		l8.6,4.9c0.3,0.2,0.7-0.1,0.6-0.5l-2-9.6c0-0.2,0-0.3,0.2-0.4l7.3-6.6C31.1,12.8,30.9,12.3,30.5,12.3z"
                                />
                              </g>
                            </svg>
                    </div>
                    <div class="Inquiry_context">
                      <div class="inquiry_rating">
                        <svg
                          v-for="stage of deal.stages"
                          :key="stage.id"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          version="1.1"
                          x="0px"
                          y="0px"
                          viewBox="0 0 15 15"
                          xml:space="preserve"
                        >
                                <g
                                  :id="
                                    parseInt(stage.SORT) <=
                                    parseInt(deal.stage ? deal.stage.SORT : null)
                                      ? 'stage_rate_box1'
                                      : 'stage_rate_box'
                                  "
                                >
                                  <rect
                                    x="1.1"
                                    y="1.1"
                                    width="12.2"
                                    height="12.2"
                                    class="st4"
                                  ></rect>
                                </g>
                              </svg>
                      </div>
                      <p class="inquiry">
                        {{ deal.stage ? deal.stage.NAME : "" }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="Cell">
                  <p>{{ number_format(deal.OPPORTUNITY) }}</p>
                </div>
                <div class="Cell">
                  <p>{{
                      (deal.terms.find(t => t.type_id === 'EMAIL') !== undefined ? deal.terms.find(t => t.type_id === 'EMAIL').value : '')
                    }}</p>
                </div>
                <div class="Cell">
                  <p>{{
                      (deal.terms.find(t => t.type_id === 'PHONE') !== undefined ? deal.terms.find(t => t.type_id === 'PHONE').value : '') | VMask('+1 (###) ###-####')
                    }}</p>
                </div>
                <div class="Cell">
                  <p>{{ formatter(deal.DATE_CREATE,'h:mm:ss a MM-DD-YYYY') }}</p>
                </div>
                <div class="Cell">
                  <p>
                    <router-link :to="{ name :'View Deal', params:{id:deal.id}}">
                      <button data-v-01e1f50f="" type="button"
                              class="btn edit btn-link btn-icon btn-fab btn-warning btn-sm">
                        <i data-v-01e1f50f="" class="fas fa-eye"></i>
                      </button>
                    </router-link>
                  </p>
                </div>
              </div>
            </div>

          </div>
          <div class="over-mob-flow" style="text-align: center;margin-top: 16px;margin-bottom: -10px;">
            <h4>
              <router-link to="/deals/index" style="color: rgb(241, 105, 37);">
                View All
              </router-link>
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 pt-0">
      <div class="card" card-body-classes="table-full-width">
        <!---->
        <div class="card-header top-leads">
          <div class="row">
            <div class="col-12 pt-0">
              <div class="leads_left">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
                     y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                                    <g id="deals_heading_svg">
                                    <path class="st16"
                                          d="M14.3,1.7L1.6,10.9c-0.7,0.5-0.9,1.5-0.4,2.3c0,0,0.1,0.1,0.1,0.1l0,0c0.6,0.7,1.6,1.4,2.3,0.9l11.7-8.3   c0.3-0.3,0.8-0.3,1.1,0l11.7,8.4c0.7,0.5,1.8-0.2,2.4-0.9l0,0c0.6-0.7,0.5-1.7-0.2-2.3c0,0-0.1-0.1-0.1-0.1L17.7,1.7   C16.7,0.8,15.3,0.8,14.3,1.7z"/>
                                      <polyline class="st16" points="3.9,13.6 3.9,30.9 28.1,30.9 28.1,13.7  "/>
                                      <path class="st17"
                                            d="M17,18.6h-2c-0.9,0.1-1.7-0.6-1.8-1.5c-0.1-0.9,0.6-1.7,1.5-1.8c0.1,0,0.2,0,0.4,0h3.6   c0.4,0.1,0.7-0.2,0.8-0.5c0.1-0.4-0.2-0.7-0.5-0.8c-0.1,0-0.1,0-0.2,0h-2v-1.3c-0.1-0.4-0.4-0.6-0.8-0.5c-0.3,0-0.5,0.3-0.5,0.5   v1.3H15c-1.6,0-3,1.3-3,3s1.3,3,3,3h2c0.9,0,1.7,0.8,1.7,1.7s-0.8,1.7-1.7,1.7h-4.3c-0.4,0.1-0.6,0.4-0.5,0.8   c0,0.3,0.3,0.5,0.5,0.5h2.6v1.3c0.1,0.4,0.4,0.6,0.8,0.5c0.3,0,0.5-0.3,0.5-0.5v-1.3H17c1.6,0,3-1.3,3-3   C19.9,19.9,18.6,18.6,17,18.6L17,18.6z"/>
                                </g>
                                </svg>
                <h4 class="card-title pull-left">New Leads </h4>
              </div>

              <div class="pull-right form-pull" v-if="false">
                <!---->
                <div class=" search-input el-input el-input--prefix el-input--suffix">
                  <!---->
                  <input type="search" name="search" autocomplete="off" @keyup.enter="lead_fetch"
                         placeholder="Search records" aria-controls="datatables" class="el-input__inner"
                         v-model="search"/><span class="el-input__prefix"><i
                  class="el-input__icon el-icon-search searchbarb"></i></span>
                  <!---->
                  <!---->
                  <!---->
                </div>
                <!---->
                <!-- <div class="add-leads-btn ml-2">
                     <button type="button" class=" pull-right btn btn-simple btn-linkedin mt-0" @click="lead_fetch">Search</button>
                 </div> -->

                <div class="dealFilter inputSvg">
                  <p>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns: xlink="http://www.w3.org/1999/xlink" version="1.1"
                         id="openDealleads" x="0px" y="0px" viewBox="0 0 32 32" xml: space="preserve">
                      <path
                        d="M3.1,13.8V7.6h25.8v6.1c0,0.5-0.2,0.9-0.5,1.3l-7.2,7.1c-0.2,0.2-0.3,0.4-0.3,0.6v5c0,0.4-0.3,0.7-0.6,0.8  l-8.2,2.2c-0.5,0.1-1.1-0.3-1.1-0.8v-7.1c0-0.2-0.1-0.4-0.2-0.6L3.7,15C3.3,14.7,3.1,14.2,3.1,13.8z"
                        class="st0"></path>
                      <line x1="3.4" y1="11.9" x2="28.6" y2="11.9" class="st0"></line>
                      <circle cx="10.9" cy="2.7" r="1.5" class="st1"></circle>
                      <circle cx="15.9" cy="1.7" r="1.5" class="st1"></circle>
                      <circle cx="20.2" cy="3.8" r="1.5" class="st1"></circle>
                    </svg>
                  </p>

                  <el-select class="select-primary" size="large" placeholder="Select" v-model="lead_status.simple"
                             @change="lead_status_change">
                    <el-option v-for="option in lead_status.name" class="select-info" :value="option.STATUS_ID"
                               :label="option.NAME" :key="option.id">
                    </el-option>
                  </el-select>
                </div>


                <div class="add-leads-btn ml-2">
                  <button type="button" class=" pull-right btn btn-simple btn-linkedin mt-0" @click="lead_fetch">
                    Filter
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="box-table">
            <div class="Table">

              <div class="Heading">
                <div class="Cell">
                  <p>Title</p>
                </div>
                <div class="Cell">
                  <p>Phone</p>
                </div>
                <div class="Cell">
                  <p>STATUS</p>
                </div>
                <div class="Cell leads_svg">
                  <svg data-v-9ff36b54="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                       version="1.1" x="0px" y="0px" viewBox="0 0 32 32" xml:space="preserve"
                       @click="lead_orderby_toggle('created_at')"><g data-v-9ff36b54="" id="filter_leads"><polyline data-v-9ff36b54="" points="30.9,8.5 16,23.5 1.1,8.5 " class="st4"></polyline></g></svg>
                  <p>Created <!--<font-awesome-icon style="" :icon="lead_orderby.icon" ></font-awesome-icon>--></p>
                </div>
                <div class="Cell">
                  <p>Action</p>
                </div>
              </div>
              <div class="Row" v-for="lead in leads" :key="lead.id">
                <div class="Cell leads_first_cell">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                       x="0px" y="0px" viewBox="0 0 32 32" xml:space="preserve"><g id="leads_phone_incoming"><path d="M18.6,19.5c0.9-0.9,1.9-1.1,2.5-1.1c0.6,0,1.6,0.2,2.5,1.1l3.1,3.1c1.4,1.4,1.4,3.5,0,4.9L26,28.2   c-0.4,0.4-0.8,0.8-1.2,1.2c-1,1-2.2,1.5-3.8,1.5c-1.1,0-2.4-0.3-3.9-0.8c-3.2-1.2-6.4-3.3-9.2-6c-2.7-2.6-4.8-5.8-6-9.1   c-0.5-1.3-1.9-5.3,0.7-7.7l0.6-0.6c0.4-0.5,0.9-0.9,1.4-1.3c0.9-0.9,1.8-1.1,2.5-1.1c0.9,0,1.7,0.4,2.4,1.2c1,0.9,2,1.9,3.1,3   c0.9,0.9,1.1,1.9,1.1,2.5c0,0.6-0.2,1.6-1.1,2.5l0,0c-0.8,0.9-0.8,2.1-0.2,3c0.6,0.8,1.2,1.4,1.5,1.6c0,0,0.1,0.1,0.1,0.1   c0.2,0.2,0.4,0.5,0.8,0.8C15.8,20,17.4,20.1,18.6,19.5L18.6,19.5z" class="st4"></path><g><polyline points="24,1 19.2,5.8 24,10.5   " class="st4"></polyline>
                    <line x1="19.2" y1="5.8" x2="31" y2="5.8" class="st4"></line></g></g></svg>
                  <p>{{ lead.TITLE }}</p>
                </div>
                <div class="Cell">
                  <p>{{
                      (lead.terms.find(t => t.type_id === 'PHONE') !== undefined ? lead.terms.find(t => t.type_id === 'PHONE').value : '') | VMask('+1 (###) ###-####')
                    }}</p>
                </div>
                <div class="Cell">
                  <p>{{ lead.STATUS_ID }}</p>
                </div>
                <div class="Cell">
                  <p>{{ formatter(lead.DATE_CREATE,'h:mm:ss a MM-DD-YYYY') }}</p>
                </div>
                <div class="Cell">
                  <p>
                    <router-link :to="{ name :'View Lead', params:{id:lead.id}}">
                      <button data-v-01e1f50f="" type="button"
                              class="btn edit btn-link btn-icon btn-fab btn-warning btn-sm">
                        <i data-v-01e1f50f="" class="fas fa-eye"></i>
                      </button>
                    </router-link>
                  </p>
                </div>
              </div>


            </div>

          </div>
          <div class="over-mob-flow" style="text-align: center;    margin-top: 16px;
    margin-bottom: -10px;">
            <h4>
              <router-link to="/leads/index" style="color: rgb(241, 105, 37);">
                View All
              </router-link>
            </h4>
          </div>

        </div>
      </div>
    </div>

    <div class="col-12 pt-0" v-if="user.id < 3">
      <div class="card" card-body-classes="table-full-width">
        <!---->
        <div class="card-header top-leads">
          <div class="row">
            <div class="col-12 pt-0">
              <div class="leads_left">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
                     y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                                    <g xmlns="http://www.w3.org/2000/svg" id="agents">
                                    <g>
                                        <ellipse class="st4" cx="10.6" cy="6.7" rx="6.1" ry="5.6"/>
                                      <ellipse class="st4" cx="24.5" cy="7.5" rx="2.9" ry="2.7"/>
                                      <path class="st4"
                                            d="M20.9,15.9c0.4-1.3,1.5-2.9,4.1-2.9c3.2,0,4.1,3,4.4,4.5c0.1,0.4,0.2,0.9,0.3,1.3c0.2,1.1,0,3.7-6.8,3.7"/>
                                      <path class="st4"
                                            d="M2.2,25.6l0-0.3c0-2.3,0.6-4.6,1.9-6.4c1.3-1.8,3.2-3.2,6.3-3.2c3.1,0,5.1,1.5,6.4,3.3c1.4,1.8,2,4.2,2,6.6v0    c0,1.5-0.8,3-2,3.6c-2.7,1.5-7.7,3.2-12.9,0C2.9,28.4,2.2,27,2.2,25.6z"/>
                                    </g>
                                </g>
                                </svg>
                <h4 class="card-title pull-left ">New Agents </h4>
              </div>
              <div class="pull-right form-pull" v-if="false">
                <!---->
                <div class=" search-input el-input el-input--prefix el-input--suffix">
                  <!---->
                  <input type="search" name="search" autocomplete="off" @keyup.enter="lead_fetch"
                         placeholder="Search records" aria-controls="datatables" class="el-input__inner"
                         v-model="search"/><span class="el-input__prefix"><i
                  class="el-input__icon el-icon-search searchbarb"></i></span>
                  <!---->
                  <!---->
                  <!---->
                </div>
                <!---->
                <!--  <div class="add-leads-btn ml-2">
                      <button type="button" class=" pull-right btn btn-simple btn-linkedin mt-0" @click="agent_fetch">Search</button>
                  </div>-->
              </div>

            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="box-table">
            <div class="Table">
              <div class="Heading">
                <div class="Cell">
                  <p>Name</p>
                </div>
                <div class="Cell">
                  <p>USerName</p>
                </div>
                <div class="Cell">
                  <p>Email</p>
                </div>
                <div class="Cell">
                  <p>Action</p>
                </div>
              </div>
              <div class="Row" v-for="agent in agents" :key="agent.id">
                <div class="Cell agent_dashboard_cell">

                  <img height="24" width="24"
                    :src="agent.image !== undefined && agent.image !== null ? agent.image :
                          '/img/mike.jpg'">

                  <p>{{ agent.name }}</p>
                </div>
                <div class="Cell">
                  <p>{{ agent.username }}</p>
                </div>
                <div class="Cell">
                  <p>{{ agent.email }}</p>
                </div>
                <div class="Cell">
                  <p>
                    <router-link :to="{ name :'View Agent', params:{id:agent.id}}">
                      <button data-v-01e1f50f="" type="button"
                              class="btn edit btn-link btn-icon btn-fab btn-warning btn-sm">
                        <!----><i data-v-01e1f50f="" class="fas fa-eye"></i>
                      </button>
                    </router-link>
                  </p>
                </div>
              </div>


            </div>

          </div>
          <div class="over-mob-flow" style="text-align: center;    margin-top: 16px;
    margin-bottom: -10px;">
            <h4>
              <router-link to="/agents/index" style="color: rgb(241, 105, 37);">
                View All
              </router-link>
            </h4>
          </div>

        </div>

      </div>
    </div>

  </div>
</template>

<script>
import moment from "moment";
import {
  Select,
  Option
} from 'element-ui';

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      deal_status: {
        simple: '',
        name: [],
        orig: [],
      },
      lead_status: {
        simple: '',
        name: [],
        orig: [],
      },
      leads: [],
      deals: [],
      agents: {},
      deal_orderby: {
        field: "created_at",
        direction: "DESC",
        icon: ['fas', 'sort-amount-up'],
      },
      lead_orderby: {
        field: "created_at",
        direction: "DESC",
        icon: ['fas', 'sort-amount-up'],
      },
      counter: {
        agents: 0,
        conference: 0,
        leads: 0,
        message_received: 0,
        message_sent: 0
      },
      user: {},
      leadsearch: '',
      dealsearch: '',
      agentsearch: ''
    };
  },
  computed: {},
  methods: {
    lead_fetch() {
      this.$insProgress.start();
      var data = {};
      if (this.leadsearch !== undefined && this.leadsearch !== '') {
        data = {
          search: this.leadsearch,
          field: this.lead_orderby.field,
          direction: this.lead_orderby.direction
        };
        if (this.lead_status.simple !== "") {
          data = {
            search: this.leadsearch,
            filter: true,
            status: this.lead_status.simple,
            field: this.lead_orderby.field,
            direction: this.lead_orderby.direction
          };
        }
      } else {
        if (this.lead_status.simple !== "") {
          data = {
            filter: true,
            status: this.lead_status.simple,
            field: this.lead_orderby.field,
            direction: this.lead_orderby.direction
          };
        } else {
          data = {
            field: this.lead_orderby.field,
            direction: this.lead_orderby.direction
          }
        }
      }

      this.$store.dispatch("lead/fetch", data).then((response) => {
        //console.log(response.data.data);
        this.leads = response.data.data.data;
        //console.log(this.leads);
        this.$insProgress.finish();
      });
    },
    status_fetch() {
      this.$insProgress.start();
      this.$store.dispatch("general/fetch_status").then((response) => {
        //console.log(response);
        this.deal_status.orig = response.data.data;
        this.lead_status.orig = response.data.data;
        this.$insProgress.finish();
        this.deal_status_update();
        this.lead_status_update();
      }).catch((e) => {
        this.$insProgress.finish();
      });
    },
    deal_status_change() {
      //console.log(this.deal_status.simple);
    },
    lead_status_change() {
      //console.log(this.deal_status.simple);
    },
    deal_status_update() {
      let type = "deal";
      this.deal_status.name = [];
      for (var x = 0; x < this.deal_status.orig.length; x++) {
        if (type === "lead") {
          if (this.deal_status.orig[x].ENTITY_ID === "STATUS") {
            this.deal_status.name.push(this.deal_status.orig[x]);
          }
        }
        if (type === "deal") {
          if (this.deal_status.orig[x].ENTITY_ID.includes("DEAL_STAGE")) {
            this.deal_status.name.push(this.deal_status.orig[x]);
          }
        }

      }
      const filteredArr = this.deal_status.name.reduce((acc, current) => {
          const x = acc.find(item => item.NAME === current.NAME);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []
      );
      this.deal_status.name = filteredArr;
      //console.log(this.deal_status.name);
    },
    lead_status_update() {
      let type = "lead";
      this.lead_status.name = [];
      for (var x = 0; x < this.lead_status.orig.length; x++) {
        if (type === "lead") {
          if (this.lead_status.orig[x].ENTITY_ID === "STATUS") {
            this.lead_status.name.push(this.lead_status.orig[x]);
          }
        }
        if (type === "deal") {
          if (this.lead_status.orig[x].ENTITY_ID.includes("DEAL_STAGE")) {
            this.lead_status.name.push(this.lead_status.orig[x]);
          }
        }

      }
      const filteredArr = this.lead_status.name.reduce((acc, current) => {
          const x = acc.find(item => item.NAME === current.NAME);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []
      );
      this.lead_status.name = filteredArr;
      //console.log(this.lead_status.name);
    },
    deal_orderby_toggle(field) {
      this.deal_orderby.field = field;
      //console.log(this.deal_orderby);

      if (this.deal_orderby.direction === 'DESC') {
        this.deal_orderby.icon = ['fas', 'sort-amount-down-alt'];
        this.deal_orderby.direction = "ASC";
      } else {
        this.deal_orderby.icon = ['fas', 'sort-amount-up'];
        this.deal_orderby.direction = "DESC";
      }
      this.deal_fetch();
    },
    lead_orderby_toggle(field) {
      this.lead_orderby.field = field;
      //console.log(this.lead_orderby);

      if (this.lead_orderby.direction === 'DESC') {
        this.lead_orderby.icon = ['fas', 'sort-amount-down-alt'];
        this.lead_orderby.direction = "ASC";
      } else {
        this.lead_orderby.icon = ['fas', 'sort-amount-up'];
        this.lead_orderby.direction = "DESC";
      }
      this.lead_fetch();
    },
    deal_fetch() {
      this.$insProgress.start();
      var data = {};
      if (this.dealsearch !== undefined && this.dealsearch !== '') {
        data = {
          search: this.dealsearch,
          field: this.deal_orderby.field,
          direction: this.deal_orderby.direction
        };
        if (this.deal_status.simple !== "") {
          data = {
            search: this.dealsearch,
            filter: true,
            status: this.deal_status.simple,
            field: this.deal_orderby.field,
            direction: this.deal_orderby.direction
          };
        }
      } else {
        if (this.deal_status.simple !== "") {
          data = {
            filter: true,
            status: this.deal_status.simple,
            field: this.deal_orderby.field,
            direction: this.deal_orderby.direction
          };
        } else {
          data = {
            field: this.deal_orderby.field,
            direction: this.deal_orderby.direction
          }
        }
      }

      this.$store.dispatch("deal/fetch", data).then((response) => {
        //console.log(response.data.data);
        this.deals = response.data.data.data;
        //console.log(this.leads);
        this.$insProgress.finish();
      });
    },
    agent_fetch() {
      this.$insProgress.start();
      var data = {};
      if (this.agentsearch !== undefined && this.agentsearch !== '') {
        data = {
          search: this.agentsearch
        }
      }
      this.$store.dispatch("agent/fetch", data).then((response) => {
        //console.log(response);
        this.agents = response.data.data.data;
        this.$insProgress.finish();
      });
    },
    counter_fetch() {
      this.$store.dispatch("general/fetch").then((response) => {
        //console.log(response);
        this.counter = response.data.data;
        this.$insProgress.finish();
      });
    },
    number_format(number) {
      return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(number)
    },
    date_format(date_string) {
      const date = new Date(date_string);
      return date.toLocaleTimeString('en-US') + ' ' + (((date.getMonth() + 1) < 10 ? ('0' + (date.getMonth() + 1).toString()) : (date.getMonth() + 1))) + '-' + (((date.getDate() + 1) < 10 ? ('0' + (date.getDate() + 1).toString()) : (date.getDate() + 1))) + '-' + date.getFullYear();
    },
    formatter(payload,pattern){
      return moment(payload).format(pattern)
    },
  },
  mounted() {
    this.user = this.$store.getters['api/user'];
    this.lead_fetch();
    this.deal_fetch();
    this.agent_fetch();
    //this.counter_fetch();
    this.status_fetch();
  },
};
</script>


<style scoped>

.box-table .Row {
  height: 60px;
}
.Cell.agent_dashboard_cell {
  padding-left: 22px;
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  height: 60px;
  align-items: center;
}
.agent_dashboard_cell img {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 100%;
}
input.el-input__inner {

  border-radius: 10px;
}

.inputSvg p {
  width: 50px;
  height: 44px !important;
}

svg#openDealleads {
  width: 24px;
}

.dealFilter div.select-primary.el-select .el-input:hover input::placeholder {
  color: #c3cad9;
}

.select-primary.el-select .el-input .el-input__icon {
  color: #124678;
}

.dealFilter input.el-input__inner {
  border: none !important;
}
</style>
