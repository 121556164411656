<template>
<card>
    <h3 slot="" class="title">Add Lead</h3>
    <form>
        <div class="row mb-4">
            <div class="col-md-6">
                <base-input type="text" label="Title" placeholder="Title" v-model="user.title">
                </base-input>
            </div>
            <div class="col-md-6">
                <base-input type="text" label="FullName" placeholder="FullName" v-model="user.name">
                </base-input>
            </div>
        </div>

        <div class="row mb-4">
            <div class="col-md-6">
                <base-input type="email" label="Email address" placeholder="email" name="email" v-validate="modelValidations.email" v-model="user.email" :error="getError('email')">
                </base-input>
            </div>
            <div class="col-md-6">
                <base-input type="tel" label="Phone" placeholder="phone" name="phone" v-validate="modelValidations.phone" v-model="user.phone" :error="getError('phone')">
                </base-input>
            </div>
        </div>

        <div class="row mb-4">
            <div class="col-md-12">
                <base-input type="text" label="Address" placeholder="Home Address" v-model="user.address">
                </base-input>
            </div>
        </div>

        <div class="row mb-4">
            <div class="col-md-4">
                <base-input type="text" label="City" placeholder="City" v-model="user.address_city">
                </base-input>
            </div>
            <div class="col-md-4">
                <base-input type="text" label="Country" placeholder="Country" v-model="user.address_country">
                </base-input>
            </div>
            <div class="col-md-4">
                <base-input label="Postal Code" placeholder="ZIP Code" v-model="user.address_postal_code">
                </base-input>
            </div>
        </div>
        <base-button native-type="submit" @click.prevent="addlead" type="success" class="btn-fill mr-4 mb-5">
            Save
        </base-button>
        <base-button type="default" class="btn-fill mb-5" @click="$router.go(-1)">
            Back
        </base-button>
    </form>
</card>
</template>

<script>
export default {
    components: {

    },
    data() {
        return {
            user: {
                title: '',
                name: '',
                email: '',
                phone: '',
                address: '',
                address_city: '',
                address_country: '',
                address_postal_code: '',
            },
            modelValidations: {

                email: {
                    required: true,
                    email: true
                },
                phone: {
                    required: true
                }
            }
        };
    },
    methods: {
        getError(fieldName) {
            return this.errors.first(fieldName);
        },
        addlead() {
            this.$validator.validateAll().then(isValid => {
                if (isValid) {
                    var data = {
                        "TITLE": this.user.title,
                        "NAME": this.user.name,
                        "ADDRESS": this.user.address,
                        "ADDRESS_CITY": this.user.address_city,
                        "ADDRESS_POSTAL_CODE": this.user.address_postal_code,
                        "ADDRESS_COUNTRY": this.user.address_country,
                        "terms": [{
                                "type_id": "PHONE",
                                "value": this.user.phone,
                                "value_type": "WORK"
                            },
                            {
                                "type_id": "EMAIL",
                                "value": this.user.email,
                                "value_type": "WORK"
                            }
                        ]
                    };
                    this.$insProgress.start();
                    this.$store.dispatch("lead/insert", data).then((response) => {
                        this.$router.push('/leads/index');
                        this.$insProgress.finish();
                        this.$notify({
                            timeout: 2000,
                            verticalAlign: 'top',
                            horizontalAlign: 'right',
                            message: 'Your record Added successfully!',
                            icon: 'tim-icons icon-check-2',
                            type: 'success',
                        });
                    });
                }
            });
        }
    }
};
</script>

<style scoped>
h3.title {
  font-weight: bold !important;
  color: #f16925 !important;
  font-family: 'Inter';
  font-size: 20px;
  letter-spacing: 2px;
}
</style>
