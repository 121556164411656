<template >
      <div class="PhoneBox" id="phonechallbox" v-if="object.show">

        <div class="PhoneBoxHeader">
            <h2 id="phone_body_text">{{calling.text + " " +(object.lead.NAME !== undefined ? object.lead.NAME : object.lead.TITLE)}}</h2>
            <span class="PhoneBox-control">
                <a href="#" class="min-phone" @click="toggle()"><i id="window_toggler" class="tim-icons icon-simple-delete"></i></a>
                <a  class="closePhone" v-on:click="exit()"><i class="tim-icons icon-simple-remove"></i></a>
            </span>
        </div>
        <div class="PhoneBoxBody" id="phone_body">
            <div class="BodySelection">

                <div class="customSelect">
                    <p class="infoShort">Call to:</p>
                    <input id="calleePhoneNumber" type="tel" :value="object.lead.terms.find(t => t.type_id === 'PHONE').value" placeholder="Enter Desired Number"  readonly/>
                    <span class="infoShort"> The Lead named as :( {{object.lead.NAME}} ) will be called.</span>
                    <!--
                    <el-select
                  class="select-primary"
                  size="large"
                  placeholder="please Select"
                  v-model="selects.simple"
                >
                  <el-option
                    v-for="option in selects.numbers"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
                -->
                </div>

                <div class="customSelect" id="callSelectDiv">
                    <p class="infoShort">Where can we call you?</p>
                    <input id="calleePhoneNumber" type="tel" :value="user.phone" placeholder="Enter Desired Number"  readonly />
                    <span class="infoShort"> First we call your line, and when you pick up, we connect you to your client.</span>
                </div>
            </div>
        </div>
        <div class="PhoneBoxFooter text-center" id="phone_footer">
            <!-- <button id="button-call">Call</button>-->
            <!-- <button id="button-hangup">Hangup</button>-->
            <!-- <a href="#" id="button-call" class="CallBtn" style="display: none;">Please Call</a>-->
            <!-- <a href="#" id="button-hangup" class="CallBtn">Hangup</a>-->
            <button data-v-01e1f50f="" @click="conference_start(object.lead)" type="button" class="btn btn-default">
                <a id="placeCall" style="cursor: pointer;"  class="CallBtn">{{calling.text}}</a>
            </button>
            <a id="phone-button-call" style="display: none; cursor: pointer;" class="CallBtn">Calling...</a>

            <a id="button-call" style="display: none; cursor: pointer;" class="CallBtn">Call</a>
            <a id="button-hangup" style="display: none; cursor: pointer;" class="CallBtn">Hangup</a>
            <button data-v-01e1f50f="" @click="conference_end()" type="button" class="btn btn-danger">
                <a id="callBoxCancel"  href="#">Hangup</a>
            </button>
        </div>
    </div>
</template>
<script>
// import { RingLoader } from 'vue-spinners-css';
import swal from "sweetalert2";
import config from "@/config";
import { TimeSelect, DatePicker, Select, Option } from 'element-ui';

export default {
    components: {
        //[Select.name]: Select,
        //[Option.name]: Option,
        //  RingLoader,
    },
    props: {
        type: '',
        object: {
            type: Object,
            required: true,
        },
    },
    watch: {
        object: {
            deep: true,
            handler(new_value){
                if(new_value.lead.id !== undefined){
                    //console.log("re-fetch");
                    this.init();
                }
            }
        },
    },
    data() {
        return {
            selects: {
                simple: 'Twillio Numbers',
                numbers: [],
            },
            calling : {
                text : "Call",
                current_conference : {},
            },
            dom : {
                window_toggler : false,
                phone_body_text : "Call "
            },
            user:{},
            channel: {}
        }
    },
    methods: {
        init(){
            if(this.object.lead.id !== undefined){
                if(this.user.id === undefined){
                    var user = this.$store.getters['api/user'];
                    //console.log(user);
                    this.user = user;
                    this.channel = this.$pusher.subscribe('CallStatus');
                    this.channel.bind('status', this.status );
                }
            }else{
                this.exit();
            }
        },
        exit(){
            this.conference_end(this.object.lead);
            this.window_toggler = false;
            this.object.lead = {};
            this.object.show = false;
        },
        toggle(){
            if(this.window_toggler){
                this.maximize();
            }else{
                this.minimize();
            }
        },
        maximize() {
            var window_toggler = document.getElementById('window_toggler');
            window_toggler.classList.remove("icon-simple-add");
            window_toggler.classList.add("icon-simple-delete");
            document.getElementById('phone_body').style.display = "inherit";
            document.getElementById('phone_footer').style.display = "inherit";
            this.window_toggler = false;
        },
        minimize() {
            var window_toggler = document.getElementById('window_toggler');
            window_toggler.classList.remove("icon-simple-delete");
            window_toggler.classList.add("icon-simple-add");
            document.getElementById('phone_body').style.display = "none";
            document.getElementById('phone_footer').style.display = "none";
            this.window_toggler = true;
        },
        conference_start(lead) {
            //console.log("confernece_start");
            var anchor = document.getElementById('placeCall');
            this.calling.text = "Calling...";
            //console.log(lead);
            this.$store
            .dispatch(this.type+"/call", lead.id)
            .then((response) => {
                //console.log(response);
                this.calling.current_conference = response.data.data;
                this.calling.text = "[ connecting ]";
            });
        },
        conference_end(){
            if(this.calling.current_conference.id !== undefined){
                var anchor = document.getElementById('callBoxCancel');
                this.calling.text = "Interupting...";
                this.$store
                .dispatch(this.type+"/hangup", this.calling.current_conference.id)
                .then((response) => {
                    //console.log(response);
                    this.calling.current_conference = {};
                    this.calling.text = "Call";
                });
            }
        },
        status(data){
            //console.log(data);
            if(data.call.id === this.calling.current_conference.id){
                let twillio_verbs = [
                    {
                        status : 'canceled',
                        show : '[ canceled ]'
                    },
                    {
                        status : 'failed',
                        show : '[ failed ]'
                    },
                    {
                        status : 'no-answer',
                        show : '[ no-answer ]'
                    },
                    {
                        status : 'busy',
                        show : '[ busy ]'
                    },
                    {
                        status : 'answered',
                        show : '[ answered ]'
                    },
                    {
                        status : 'completed',
                        show : '[ completed ]'
                    }
                ];
                let twillio_status = twillio_verbs.find(t => t.status === data.call.status);
                if(twillio_status !== undefined){
                     this.calling.text = twillio_status.status;
                     setTimeout(this.reboot, 1200);
                }else{
                    this.calling.text = "[ " + data.call.status.toString() + " ]";
                }
            }
        },
        reboot(){
            this.calling.text = "Call";
            this.calling.current_conference = {};
        }
    },
    created(){
        this.$store.dispatch("lead/twiilio_number_fetch",null)
        .then((response) => {
            //console.log(response);
            const numbers = response.data.data.numbers.map(function(row) {
                return { value : row.number, label : row.name }
            })
            //console.log(numbers);
            this.selects.numbers = numbers;
        });
    }
};
</script>
