<template>
  <!--Deal  Notes-->
  <div class="notifications-section" style="height: 460px">
    <div class="call-log-detail" v-for="notification of deal_notes" :key="notification.id">
      <div class="outMain">
        <div class="outMainInner">
          <div class="outMedia">
            <p class="">
              <svg data-v-9ff36b54="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                   version="1.1" x="0px" y="0px" viewBox="0 0 32 32" xml:space="preserve"><g data-v-9ff36b54="" id="openDealDocs"><polyline data-v-9ff36b54="" points="24.9,20.8 24.9,30.9 1.1,30.9 1.1,1.1 24.9,1.1 24.9,6.7  " class="st4"></polyline><line
                data-v-9ff36b54="" x1="6.8" y1="6.9" x2="19.1" y2="6.9" class="st4"></line><line data-v-9ff36b54=""
                                                                                                 x1="6.8" y1="13"
                                                                                                 x2="17.7" y2="13"
                                                                                                 class="st4"></line><line
                data-v-9ff36b54="" x1="6.8" y1="19.1" x2="11.1" y2="19.1" class="st4"></line><line
                data-v-9ff36b54=""
                x1="6.8" y1="25.1"
                x2="11.1" y2="25.1"
                class="st4"></line><path
                data-v-9ff36b54=""
                d="M14.9,21v2.1c0,0.2,0.2,0.4,0.4,0.4h2.1c0.4,0,0.5-0.4,0.3-0.7c-0.6-0.6-1.5-1.5-2.1-2.1   C15.4,20.5,14.9,20.7,14.9,21z"
                class="st14"></path><path data-v-9ff36b54=""
                                          d="M18.6,22.1l-2.2-2.2c-0.2-0.2-0.2-0.4,0-0.6l8.1-8.1c0.2-0.2,0.4-0.2,0.6,0l2.2,2.2c0.2,0.2,0.2,0.4,0,0.6   l-8.1,8.1C19,22.3,18.7,22.3,18.6,22.1z"
                                          class="st14"></path><path data-v-9ff36b54=""
                                                                    d="M28.1,12.6l-2.2-2.2c-0.2-0.2-0.2-0.4,0-0.6l1.3-1.3c0.2-0.2,0.4-0.2,0.6,0l2.2,2.2c0.2,0.2,0.2,0.4,0,0.6   l-1.3,1.3C28.5,12.8,28.2,12.8,28.1,12.6z"
                                                                    class="st14"></path></g></svg>
            </p>
          </div>
          <div class="outContent">
            <h2>Deal NOTES : <span> {{ object.TITLE }} </span></h2>
            <div class="outSmsText">
              <p>{{ notification.notes !== null ? notification.notes : ' Empty...' }}</p>
            </div>
          </div>
        </div>
        <div class="outUser">
          <div class="outUserMedia">
            <img src="/img/mike.jpg">
          </div>
          <div class="outUserContent">
            <p>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                   stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
              </svg>
              <span>{{ date_format(notification.created_at) }} |</span>
              <span class="userBlue"> Saved</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <infinite-loading :identifier="infinIdnote" direction="bottom"
                      ref="infiniteLoading" @infinite="infiniteHandler">
    </infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: "deal-notes-tabs",
  components: {
    InfiniteLoading,
  },
  props: {
    object: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      deal_notes: [],
      page: 1,
      infinIdnote: +new Date(),
    };
  },
  watch: {
    object: {
      handler(newValue, oldValue) {
        this.page = 1
        this.deal_notes = [];
        this.infinIdnote = +new Date();
      },
      deep: true
    }
  },
  mounted() {
    this.init();

    this.$root.$on('add_note', (obj) => {
      this.deal_notes.splice(0, 0, obj);
    });
  },
  methods: {
    init() {
      this.user = this.$store.getters["api/user"];
    },
    fetchNotification() {
      this.$insProgress.start();
      var data = {};
      data.parent_id = this.object.id;
      data.parent_type = this.type;
      data.page = this.page;
      this.$store.dispatch("combined_notification/get_notes_list", data).then((response) => {
        console.log(response.data);
        let fetchedNotifications = response.data.data;
        if (fetchedNotifications.length > 0) {
          this.deal_notes.push(...fetchedNotifications);
          console.log(this.deal_notes);
          this.page++;
          this.infinIdnote = +new Date();
          this.$refs.infiniteLoading.$emit('$InfiniteLoading:loaded')
        } else {
          this.$refs.infiniteLoading.$emit('$InfiniteLoading:complete')
        }
        this.$insProgress.finish();
      }).catch((e) => {
        this.$insProgress.finish();
      });
    },
    infiniteHandler($state) {
      this.fetchNotification();
      return true;
    },
    date_format(date_string) {
      const date = new Date(date_string);
      return date.toLocaleTimeString("en-US") + " " + (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1).toString() : date.getMonth() + 1) + "-" + (date.getDate() + 1 < 10 ? "0" + (date.getDate() + 1).toString() : date.getDate() + 1) + "-" + date.getFullYear();
    },
    splitter(type) {
      return type.split("\\").pop();
    },
  },
};
</script>
<style scoped>

.call-Log-main .call-log-detail {
  display: flex;
  gap: 20px;
  align-items: center;
  border: 1px solid #e2e8f0;
  padding: 5px;
  background-color: white;
  border-radius: 6px;
  margin: 10px 0px;
}

.call-Log-main .call-log-detail svg {
  width: 24px;
  height: 24px;
}

.call-Log-main .time-details {
  color: #a0a0a0;
  font-size: 11px;
}

.call-log-name {
  color: #ed8936;
}

.call-log-status {
  color: #124678;
}

.call-Log-content p {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
}

.call-log-detail .st1 {
  display: inline;
  fill: none;
  stroke: #ed8936;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.call-log-detail .st4 {
  fill: none;
  stroke: #ed8936;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
</style>

