<template>
  <div class="content leads-content">
    <div class=" leads-content" style="">
      <div class="row">
        <div class="col-12 pt-0 leads-col">
          <div class="card" card-body-classes="table-full-width">
            <!---->
            <div class="card-header top-leads responsive-leads">
              <div class="row">
                <div class="col-12 pt-0 leads-col">
                  <div class="leads_left">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                         x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;"
                         xml:space="preserve">
                                    <g id="leads_heading_svg">
                                        <polygon class="st18"
                                                 points="3.1,14.5 3.1,7.6 28.9,7.6 28.9,14.5 20.9,22.4 20.9,28.4 11,31 11,22.4  "/>
                                      <line class="st18" x1="3.4" y1="11.9" x2="28.6" y2="11.9"/>
                                      <circle class="st19" cx="10.9" cy="2.7" r="1.5"/>
                                      <circle class="st19" cx="15.9" cy="1.7" r="1.5"/>
                                      <circle class="st19" cx="20.2" cy="3.8" r="1.5"/>
                                    </g>
                                    </svg>
                    <h4 class="card-title pull-left">Leads </h4>
                  </div>
                  <div class="form-group leads_right">
                    <!---->
                    <div class="pull-right form-pull">
                      <!---->
                      <p v-if="last_updated!== ''" class="myp"><img class="mr-2" src="../../../public/img/refresh.png"/>Refreshed:
                        {{ last_updated }}
                      </p>
                      <div class=" search-input el-input el-input--prefix el-input--suffix">
                        <!---->
                        <div class="searchbarb">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                               stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                          </svg>
                        </div>
                        <input type="search" name="search" autocomplete="off" @keyup.enter="lead_fetch"
                               @keyup="lead_fetch_debounce"
                               placeholder="Search records" aria-controls="datatables" class="el-input__inner"
                               v-model="search"/>
                        <!--                        <span class="el-input__prefix">-->
                        <!--                          <i class="el-input__icon el-icon-search searchbarb"></i>-->
                        <!--                      </span>-->
                        <!---->
                        <!---->
                        <!---->
                      </div>
                      <!---->
                      <!--
                      <span class="add-btn-main">
                      <div class="add-leads-btn ">
                          <button type="button" class=" pull-right btn btn-simple btn-linkedin mt-0" @click="lead_fetch">Search</button>
                      </div>
                      </span>
                      -->

                      <div class="dealFilter inputSvg dealsIndexFilter">
                        <p>
                          <svg xmlns="http://www.w3.org/2000/svg" xmlns: xlink="http://www.w3.org/1999/xlink"
                               version="1.1" id="openDealleads" x="0px" y="0px" viewBox="0 0 32 32" xml:
                               space="preserve">
                            <path
                              d="M3.1,13.8V7.6h25.8v6.1c0,0.5-0.2,0.9-0.5,1.3l-7.2,7.1c-0.2,0.2-0.3,0.4-0.3,0.6v5c0,0.4-0.3,0.7-0.6,0.8  l-8.2,2.2c-0.5,0.1-1.1-0.3-1.1-0.8v-7.1c0-0.2-0.1-0.4-0.2-0.6L3.7,15C3.3,14.7,3.1,14.2,3.1,13.8z"
                              class="st0"></path>
                            <line x1="3.4" y1="11.9" x2="28.6" y2="11.9" class="st0"></line>
                            <circle cx="10.9" cy="2.7" r="1.5" class="st1"></circle>
                            <circle cx="15.9" cy="1.7" r="1.5" class="st1"></circle>
                            <circle cx="20.2" cy="3.8" r="1.5" class="st1"></circle>
                          </svg>
                        </p>
                        <el-select class="select-primary leadsSelect" size="large" placeholder="Select"
                                   v-model="status.simple"
                                   @change="status_change">
                          <el-option v-for="option in status.name" class="select-info" :value="option.STATUS_ID"
                                     :label="option.NAME" :key="option.id">
                          </el-option>
                        </el-select>
                      </div>


                      <div class="add-leads-btn" v-if="false">
                        <button type="button" class=" pull-right btn btn-simple btn-linkedin mt-0" @click="lead_fetch">
                          Filter
                        </button>
                      </div>
                      <span>
                                        <div class="add-leads-btn add_button" v-if="user.id === 1">
                                            <router-link :to="{ name :'Add Leads'}" class="nav-item dropdown-item"><button
                                              type="button" class=" pull-right btn btn-simple btn-linkedin"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                                            <g id="add_leads">
                                                <circle class="st22" cx="16" cy="16" r="14.9"/>
                                                <line class="st22" x1="25.6" y1="16" x2="6.4" y2="16"/>
                                                <line class="st22" x1="16" y1="25.6" x2="16" y2="6.4"/>
                                            </g>
                                            </svg> Add Lead</button></router-link>
                                        </div>
                                        </span>
                    </div>
                    <!---->
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="box-table">
                <div class="Table">
                  <div class="Heading">
                    <div class="Cell">
                      <p>Title</p>
                    </div>
                    <div class="Cell">
                      <p>Phone</p>
                    </div>
                    <div class="Cell">
                      <p>STATUS</p>
                    </div>
                    <div class="Cell leads_svg">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                           x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;"
                           xml:space="preserve" @click="orderby_toggle('created_at')">
                                            <g id="filter_leads">
                                                <polyline class="st4" points="30.9,8.5 16,23.5 1.1,8.5  "/>
                                            </g>
                                            </svg>
                      <p>Created <!--<font-awesome-icon style="" :icon="orderby.icon"></font-awesome-icon>--></p>
                    </div>
                    <div class="Cell">
                      <p>Actions</p>
                    </div>
                  </div>
                  <div class="Row" v-for="lead in leads" :key="lead.id">
                    <div class="Cell leads_first_cell leads-inner">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                           x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;"
                           xml:space="preserve">
                                        <g id="leads_phone_incoming">
                                            <path class="st4"
                                                  d="M18.6,19.5c0.9-0.9,1.9-1.1,2.5-1.1c0.6,0,1.6,0.2,2.5,1.1l3.1,3.1c1.4,1.4,1.4,3.5,0,4.9L26,28.2   c-0.4,0.4-0.8,0.8-1.2,1.2c-1,1-2.2,1.5-3.8,1.5c-1.1,0-2.4-0.3-3.9-0.8c-3.2-1.2-6.4-3.3-9.2-6c-2.7-2.6-4.8-5.8-6-9.1   c-0.5-1.3-1.9-5.3,0.7-7.7l0.6-0.6c0.4-0.5,0.9-0.9,1.4-1.3c0.9-0.9,1.8-1.1,2.5-1.1c0.9,0,1.7,0.4,2.4,1.2c1,0.9,2,1.9,3.1,3   c0.9,0.9,1.1,1.9,1.1,2.5c0,0.6-0.2,1.6-1.1,2.5l0,0c-0.8,0.9-0.8,2.1-0.2,3c0.6,0.8,1.2,1.4,1.5,1.6c0,0,0.1,0.1,0.1,0.1   c0.2,0.2,0.4,0.5,0.8,0.8C15.8,20,17.4,20.1,18.6,19.5L18.6,19.5z"/>
                                          <g>
                                                <polyline class="st4" points="24,1 19.2,5.8 24,10.5   "/>
                                            <line class="st4" x1="19.2" y1="5.8" x2="31" y2="5.8"/>
                                            </g>
                                        </g>
                                        </svg>
                      <p>{{ lead.TITLE }}</p>
                    </div>
                    <div class="Cell">
                      <p>{{
                          (lead.terms.find(t => t.type_id === 'PHONE') !== undefined ? lead.terms.find(t => t.type_id === 'PHONE').value : '') | VMask('+1 (###) ###-####')
                        }}</p>
                    </div>
                    <div class="Cell">
                      <p>{{ lead.STATUS_ID }}</p>
                    </div>
                    <div class="Cell">
                      <p>{{ formatter(lead.DATE_CREATE,'h:mm:ss a MM-DD-YYYY') }}</p>
                    </div>
                    <div class="Cell leads_svg_block">
                      <p>
                        <button data-v-01e1f50f="" type="button"
                                class="phone btn like btn-link btn-icon btn-fab btn-info btn-sm"
                                v-on:click="toggle_phone(lead)">
                          <!---->
                          <svg xmlns="http://www.w3.org/2000/svg" id="phone_leads" viewBox="0 0 32 32">
                            <path class="cls-1"
                                  d="M7.08,2.14h.08a2,2,0,0,1,1.45.74L8.73,3c1.11,1,2.37,2.28,3.58,3.49a2.24,2.24,0,0,1,.74,1.57,2.24,2.24,0,0,1-.74,1.56l-.1.1a4.92,4.92,0,0,0-.35,6.11,12.56,12.56,0,0,0,2,2.27h0a12.25,12.25,0,0,0,1.16,1.16,6,6,0,0,0,4,1.48,6.32,6.32,0,0,0,2.86-.69,2,2,0,0,0,.49-.37,2.24,2.24,0,0,1,1.57-.74,2.21,2.21,0,0,1,1.56.75l3.69,3.69a2,2,0,0,1,0,3l-.8.78-.07.06c-.47.46-.95.92-1.42,1.44a3.94,3.94,0,0,1-3.06,1.18A11.39,11.39,0,0,1,19.93,29,28.49,28.49,0,0,1,9.69,22.26,28,28,0,0,1,3,12.15c-.78-2.16-1.6-5.24.3-7l.1-.09.68-.71c.49-.51,1-1,1.51-1.49l.08-.08a2,2,0,0,1,1.43-.67m0-2A4,4,0,0,0,4.24,1.4c-.56.5-1.09,1-1.62,1.59l-.68.71C-1.17,6.52.53,11.26,1.1,12.83A30.08,30.08,0,0,0,8.3,23.7a30.55,30.55,0,0,0,10.94,7.19,13.29,13.29,0,0,0,4.62,1A6,6,0,0,0,28.39,30c.44-.49.92-.95,1.4-1.42l.82-.79a4,4,0,0,0,0-5.87l-3.7-3.69a4,4,0,0,0-5.95,0,4.17,4.17,0,0,1-1.94.47,4,4,0,0,1-2.65-1,13.06,13.06,0,0,1-1-1,.93.93,0,0,0-.14-.14,11.3,11.3,0,0,1-1.73-1.94,2.93,2.93,0,0,1,.21-3.63v0a4.14,4.14,0,0,0,1.33-3,4.15,4.15,0,0,0-1.33-3c-1.25-1.25-2.49-2.5-3.64-3.55A4,4,0,0,0,7.21.14Z"/>
                          </svg>
                        </button>

                        <button data-v-01e1f50f="" type="button"
                                class="btn like btn-link btn-icon btn-fab btn-info btn-sm"
                                v-on:click="toggle_chat(lead)">
                          <div v-if="lead.notify" class="notification d-none d-lg-block d-xl-block "
                               style="top: 2px; right: 3px;"></div>
                          <!--<i data-v-01e1f50f="" class="tim-icons icon-chat-33"></i>-->
                          <svg xmlns="http://www.w3.org/2000/svg" id="sms_lead" viewBox="0 0 32 32">
                            <path
                              d="M12.44,21.82H4.81a3.7,3.7,0,0,1-3.7-3.69V6.41a3.7,3.7,0,0,1,3.7-3.7H27.19a3.7,3.7,0,0,1,3.7,3.7V18.13a3.7,3.7,0,0,1-3.7,3.69h0a1.55,1.55,0,0,0-1.55,1.55v3.8a2.13,2.13,0,0,1-3.49,1.63l-7.3-6.1A3.73,3.73,0,0,0,12.44,21.82Z"
                              class="cls-1"></path>
                            <circle cx="10.29" cy="12.67" r="1.4" class="cls-2"></circle>
                            <circle cx="16" cy="12.67" r="1.4" class="cls-2"></circle>
                            <circle cx="21.71" cy="12.67" r="1.4" class="cls-2"></circle>
                          </svg>
                        </button>
                        <router-link :to="{ name :'Edit Lead', params:{id:lead.id}}">
                          <button data-v-01e1f50f="" type="button"
                                  class="btn edit btn-link btn-icon btn-fab btn-warning btn-sm">
                            <!--<i data-v-01e1f50f="" class="tim-icons icon-pencil"></i>-->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                 version="1.1" x="0px" y="0px" viewBox="0 0 32 32"
                                 style="enable-background:new 0 0 32 32;" xml:space="preserve">

                                                    <g id="doc_requested_leads">
                                                        <polyline class="st4"
                                                                  points="24.9,20.8 24.9,30.9 1.1,30.9 1.1,1.1 24.9,1.1 24.9,6.7  "/>
                                                      <line class="st4" x1="6.8" y1="6.9" x2="19.1" y2="6.9"/>
                                                      <line class="st4" x1="6.8" y1="13" x2="17.7" y2="13"/>
                                                      <line class="st4" x1="6.8" y1="19.1" x2="11.1" y2="19.1"/>
                                                      <line class="st4" x1="6.8" y1="25.1" x2="11.1" y2="25.1"/>
                                                      <path class="st14"
                                                            d="M14.9,21v2.1c0,0.2,0.2,0.4,0.4,0.4h2.1c0.4,0,0.5-0.4,0.3-0.7c-0.6-0.6-1.5-1.5-2.1-2.1   C15.4,20.5,14.9,20.7,14.9,21z"/>
                                                      <path class="st14"
                                                            d="M18.6,22.1l-2.2-2.2c-0.2-0.2-0.2-0.4,0-0.6l8.1-8.1c0.2-0.2,0.4-0.2,0.6,0l2.2,2.2c0.2,0.2,0.2,0.4,0,0.6   l-8.1,8.1C19,22.3,18.7,22.3,18.6,22.1z"/>
                                                      <path class="st14"
                                                            d="M28.1,12.6l-2.2-2.2c-0.2-0.2-0.2-0.4,0-0.6l1.3-1.3c0.2-0.2,0.4-0.2,0.6,0l2.2,2.2c0.2,0.2,0.2,0.4,0,0.6   l-1.3,1.3C28.5,12.8,28.2,12.8,28.1,12.6z"/>
                                                    </g>
                                                    </svg>
                          </button>
                        </router-link>
                        <button class="dealsScheduler">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            version="1.1"
                            x="0px"
                            y="0px"
                            viewBox="0 0 32 32"
                            style="enable-background:new 0 0 32 32;"
                            xml:space="preserve"
                          >
                              <g id="scheduler">
                                <path
                                  class="st4"
                                  d="M6.9,19.7H2.8c-0.9,0-1.7-0.7-1.7-1.7V4.6C1.1,3.7,1.9,3,2.8,3h17.5C21.2,3,22,3.7,22,4.6v3.3"
                                />
                                <line
                                  class="st4"
                                  x1="8"
                                  y1="1.1"
                                  x2="7.9"
                                  y2="4.8"
                                />
                                <line
                                  class="st4"
                                  x1="14.8"
                                  y1="1.1"
                                  x2="14.7"
                                  y2="4.8"
                                />
                                <circle
                                  class="st4"
                                  cx="21.1"
                                  cy="21.4"
                                  r="9.7"
                                />
                                <polyline
                                  class="st4"
                                  points="21.1,16 21.1,21.4 26.2,21.4  "
                                />
                              </g>
                            </svg>
                        </button>
                        <!--

                                          <router-link :to="{ name :'View Lead', params:{id:lead.id}}"><button data-v-01e1f50f="" type="button" class="btn edit btn-link btn-icon btn-fab btn-warning btn-sm">
                                                 <i data-v-01e1f50f="" class="fas fa-eye"></i>
                                              </button></router-link>
                                          <button data-v-01e1f50f="" type="button" class="btn remove btn-link btn-icon btn-fab btn-danger btn-sm" @click="lead_delete(lead.id)">
                                             <i data-v-01e1f50f="" class="tim-icons icon-simple-remove"></i>
                                          </button>
                                          <router-link :to="{ name :'Record Lead', params:{id:lead.id}}"><button data-v-01e1f50f="" type="button" class="btn edit btn-link btn-icon btn-fab btn-warning btn-sm">
                                                  <i data-v-01e1f50f="" class="tim-icons icon-triangle-right-17"></i>
                                              </button></router-link>-->
                      </p>
                    </div>
                  </div>

                </div>
                <div class="el-table__column-resize-proxy" style="display: none"></div>
                <div class="card-footer">
                  <div class="row">
                    <div class="col-12">
                      <p class="card-category" style="float:left;">
                        Showing {{ result.from }} to {{ result.to }} of {{ result.total }} entries
                      </p>
                      <pagination style="float:right;" :data="result" @pagination-change-page="lead_fetch" :limit="1">
                        <span slot="prev-nav">&lt; Previous</span>
                        <span slot="next-nav">Next &gt;</span>
                      </pagination>
                    </div>

                  </div>

                  <!---->
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <phonebox :object="lead_phone" :type="'lead'"></phonebox>
    <chatbox :object="lead_chat" :type="'lead'"></chatbox>
  </div>
</template>

<script>
// import { RingLoader } from 'vue-spinners-css';
import moment from "moment";
import swal from "sweetalert2";
import config from "@/config";
import {
  TimeSelect,
  DatePicker,
  Select,
  Option
} from 'element-ui';
import phonebox from 'src/components/leads/phonebox';
import chatbox from '../../components/leads/chatbox';

const debounce = {
  interval: null,
  wait: 500,
  func: () => {
  },
  __: function (f, w) {
    if (this.interval) {
      clearTimeout(this.interval);
    }
    this.wait = w;
    this.func = f;
    this.interval = setTimeout(debounce.func, debounce.wait);
  }
};

export default {
  components: {
    phonebox,
    chatbox,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  data() {
    return {
      orderby: {
        field: "created_at",
        direction: "DESC",
        icon: ['fas', 'sort-amount-up'],
      },
      status: {
        simple: '',
        name: [],
        orig: [],
      },
      last_updated: '',
      leads: [],
      result: {},
      channel: {},
      lead_phone: {
        lead: {},
        show: false,
      },
      lead_chat: {
        lead: {},
        show: false,
      },
      show: false,
      display: false,
      sync_loader: true,
      user: {},
      search: '',
    }
  },
  methods: {
    sync() {
      this.$insProgress.start();
      this.$store.dispatch("lead/sync").then((response) => {
        this.$insProgress.finish();
        this.sync_loader = false;
      });
    },
    orderby_toggle(field) {
      this.orderby.field = field;
      //console.log(this.orderby);

      if (this.orderby.direction === 'DESC') {
        this.orderby.icon = ['fas', 'sort-amount-down-alt'];
        this.orderby.direction = "ASC";
      } else {
        this.orderby.icon = ['fas', 'sort-amount-up'];
        this.orderby.direction = "DESC";
      }
      this.lead_fetch();
    },
    status_fetch() {
      this.$insProgress.start();
      this.$store.dispatch("general/fetch_status").then((response) => {
        //console.log(response);
        this.status.orig = response.data.data;
        this.$insProgress.finish();
        this.status_update()
      }).catch((e) => {
        this.$insProgress.finish();
      });
    },
    status_change() {
      //console.log(this.status.simple);
      this.lead_fetch_debounce();
    },
    status_update() {
      let type = "lead";
      this.status.name = [];
      for (var x = 0; x < this.status.orig.length; x++) {
        if (type === "lead") {
          if (this.status.orig[x].ENTITY_ID === "STATUS") {
            this.status.name.push(this.status.orig[x]);
          }
        }
        if (type === "deal") {
          if (this.status.orig[x].ENTITY_ID.includes("DEAL_STAGE")) {
            this.status.name.push(this.status.orig[x]);
          }
        }

      }
      const filteredArr = this.status.name.reduce((acc, current) => {
          const x = acc.find(item => item.NAME === current.NAME);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []
      );
      this.status.name = filteredArr;
      //console.log(this.status.name);
    },
    lead_fetch(page = 1) {
      this.$insProgress.start();
      var data = {};
      if (this.search !== undefined && this.search !== '') {
        data = {
          page: page,
          search: this.search,
          field: this.orderby.field,
          direction: this.orderby.direction
        };
        if (this.status.simple !== "") {
          data = {
            page: page,
            search: this.search,
            filter: true,
            status: this.status.simple,
            field: this.orderby.field,
            direction: this.orderby.direction
          };
        }
      } else {
        if (this.status.simple !== "") {
          data = {
            page: page,
            filter: true,
            status: this.status.simple,
            field: this.orderby.field,
            direction: this.orderby.direction
          };
        } else {
          data = {
            page: page,
            field: this.orderby.field,
            direction: this.orderby.direction
          }
        }
      }


      this.$store.dispatch("lead/fetch", data).then((response) => {
        //console.log(response.data.data);
        this.leads = response.data.data.data;
        /*
        this.leads = this.leads.map((value,index)=>{
            var lead = value;
            lead['notify'] = false;
            return lead;
        });
        */
        //console.log(this.leads);
        this.result = response.data.data;
        this.$insProgress.finish();
      }).catch((response) => {
        this.$insProgress.finish();
      });
    },
    toggle_phone(lead) {
      //console.log(lead);
      if (!this.lead_phone.show) {
        this.lead_phone.lead = lead;
        this.lead_phone.show = true;
      } else {
        if (this.lead_phone.lead.id === lead.id) {
          this.lead_phone.lead = {};
          this.lead_phone.show = false;
        } else {
          this.lead_phone.lead = lead;
          this.lead_phone.show = true;
        }
      }
    },
    date_format(date_string) {
      const date = new Date(date_string);
      return date.toLocaleTimeString('en-US') + ' ' + (((date.getMonth() + 1) < 10 ? ('0' + (date.getMonth() + 1).toString()) : (date.getMonth() + 1))) + '-' + (((date.getDate() + 1) < 10 ? ('0' + (date.getDate() + 1).toString()) : (date.getDate() + 1))) + '-' + date.getFullYear();
    },
    toggle_chat(lead) {
      //console.log(lead);
      if (!this.lead_chat.show) {
        var ind = this.leads.findIndex(l => l.id === lead.id);
        this.leads[ind]['notify'] = false;
        var title = this.leads[ind]['LAST_NAME'];
        this.leads[ind]['LAST_NAME'] = " ";
        this.leads[ind]['LAST_NAME'] = title;
        this.$store.dispatch("lead/mark", this.leads[ind]['id']);
        this.lead_chat.lead = lead;
        this.lead_chat.show = true;
      } else {
        if (this.lead_chat.lead.id === lead.id) {
          this.lead_chat.lead = {};
          this.lead_chat.show = false;
        } else {
          var ind = this.leads.findIndex(l => l.id === lead.id);
          this.leads[ind]['notify'] = false;
          var title = this.leads[ind]['LAST_NAME'];
          this.leads[ind]['LAST_NAME'] = " ";
          this.leads[ind]['LAST_NAME'] = title;
          this.$store.dispatch("lead/mark", this.leads[ind]['id']);
          this.lead_chat.lead = lead;
          this.lead_chat.show = true;
        }
      }
    },
    lead_fetch_debounce() {
      debounce.__(this.lead_fetch, 500);
    },
    conference_start(lead) {
      //console.log(lead);
      this.$store
        .dispatch("lead/conference_start", lead.id)
        .then((response) => {
          //console.log(response);
        });
    },

    lead_delete(id) {
      swal({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.$insProgress.start();
          this.$store.dispatch("lead/delete", id).then((response) => {
            this.lead_fetch();
            this.$insProgress.finish();
            this.$notify({
              timeout: 2000,
              verticalAlign: 'top',
              horizontalAlign: 'right',
              message: 'Your record deleted successfully!',
              icon: 'tim-icons icon-check-2',
              type: 'success',
            });
          });

        }
      });
    },
    recieved_message(data) {
      //console.log(data);
      if (data.object.id !== undefined) {
        if (data.object.parent_type === 'lead') {
          var ind = this.leads.findIndex(lead => lead.id === data.object.id);
          //console.log(ind);
          if (ind !== undefined && ind !== -1) {
            this.leads[ind]['notify'] = true;
            var title = this.leads[ind]['LAST_NAME'];
            this.leads[ind]['LAST_NAME'] = " ";
            this.leads[ind]['LAST_NAME'] = title;
          }
          //console.log(this.leads);
        }
      }
    },
    date_format_2(date_string) {
      const date = new Date(date_string);
      return date.toLocaleTimeString(['en-US'], {hour: '2-digit', minute: '2-digit'})  //if you want, you can change locale date string
    },
    formatter(payload,pattern){
      return moment(payload).format(pattern)
    },
    get_lastupdate_time() {
      this.$store
        .dispatch("lead/get_last_lead_time")
        .then((response) => {
          this.last_updated = this.formatter(response.data.data,'h:mm a');
        })
    },


  },

  created() {
    this.user = this.$store.getters['api/user'];
    this.channel = this.$pusher.subscribe('MessageEvent');
    this.channel.bind('received', this.recieved_message);
    this.get_lastupdate_time();
    this.lead_fetch();
    this.status_fetch();
  },
};
</script>

<style scoped>

.myp {
  margin-bottom: 0;
  margin-right: 10px;
  font-size: 11px;
}

.leads-content .select-primary.el-select .el-input input {
  border: none !important;
  padding-left: 15px !important;
  width: 200px;
}

.dealFilter div.select-primary.el-select .el-input:hover input::placeholder {
  color: #b8b8b8;
}

.searchbarb {
  background-color: #245482;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  width: 55px;
  height: 100% !important;
  font-size: 20px;
  color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-input__prefix {
  left: 0 !important;

}


.dealFilter p {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}


.btn, .navbar .navbar-nav > a.btn {

  margin: 0;
}

div.dealsIndexFilter p {
  width: 55px !important;
  height: 100% !important;
}

.leads_right .el-input__inner {
  height: 100%;
}

.dealFilter.inputSvg.dealsIndexFilter .leads_right .el-input__inner {
  background-color: transparent !important;
}

.dealFilter {
  height: 42px;
}

.dealFilter div.select-primary.el-select .el-input:hover input::placeholder {
  color: lightgrey;
}

.dealFilter.inputSvg.dealsIndexFilter .leadsSelect input.el-input__inner {
  padding: 0px 10px !important;
}

.el-select.select-primary.el-select--large {
  padding: 0px 10px;
  width: 100%;
  border: 1px solid #245482;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  height: 100%;
  border-left: 0px;
}

.search-input {
  display: flex;
  align-items: center;
  height: 42px;
  width: 250px;
}

.search-input input {
  padding: 10px !important;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.dealFilter div.select-primary.el-select .el-input:hover input::placeholder {
  color: #b3b3b3 !important;
}

@media screen and (max-width: 991px) {
  .search-input {
    margin-bottom: 15px;
    width: 100% !important;
  }
}
</style>
