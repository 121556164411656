<template>
  <card>
    <h3 slot="" class="title">Edit Agent</h3>
    <form @submit.prevent="updateProfile">
      <div class="row mb-4">

        <div class="col-md-4">
          <base-input type="text" label="Name" placeholder="Name" v-model="agent.name">
          </base-input>
        </div>
        <div class="col-md-4">
          <base-input type="text" label="User Name" placeholder="User Name" v-model="agent.username">
          </base-input>
        </div>
        <div class="col-md-4">
          <base-input readonly type="email" label="Email" placeholder="Email" v-model="agent.email">
          </base-input>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-md-4">
          <base-input type="password" label="Password" placeholder="Password" v-model="agent.password">
          </base-input>
        </div>
        <div class="col-md-4">
          <label>Assigned_Number</label>
          <div class="mb-0">
            <el-select class="select-primary" size="large" placeholder="Select" v-model="agent.twillio">
              <el-option v-for="option in t_numbers.numbers" class="select-primary" :value="option.number"
                         :label="option.number" :key="option.number">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-md-4">
          <base-input type="text" label="Position" placeholder="Position" v-model="agent.WORK_POSITION">
          </base-input>
        </div>
      </div>

      <div class="row mb-4">

        <div class="col-md-4">
          <base-input type="text" label="Work Phone" placeholder="Work Phone" v-model="agent.WORK_PHONE">
          </base-input>
        </div>
        <div class="col-md-4">
          <base-input label="Ext" placeholder="Ext" v-model="agent.UF_PHONE_INNER">
          </base-input>
        </div>
        <div class="col-md-4">
          <base-input type="text" label="Cell" placeholder="Cell" v-model="agent.phone">
          </base-input>
        </div>
      </div>

      <div class="row mb-4">

        <div class="col-md-4">
          <base-input type="text" label="Address" placeholder="Home Address" v-model="agent.address">
          </base-input>
        </div>
        <div class="col-md-4">
          <base-input type="text" label="City" placeholder="City" v-model="agent.city">
          </base-input>
        </div>
        <div class="col-md-4">
          <base-input label="Postal Code" placeholder="ZIP Code" v-model="agent.zipcode">
          </base-input>
        </div>
      </div>

      <div class="row mb-4">

<!--        <div class="col-md-4">-->
<!--          <label>Country</label>-->
<!--          <el-select class="select-primary" size="large" placeholder="Select" v-model="agent.country">-->
<!--            <el-option disabled class="select-primary" :value="0" :label="'Not Specified'">-->
<!--            </el-option>-->
<!--            <el-option v-for="option in agent.countries" class="select-primary"-->
<!--                       :value="option.id" :label="option.name" :key="option.id"-->
<!--                       :selected="option.id === agent.country">-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--        </div>-->

      </div>
      <div class="my-4 mb-4">
      <base-button native-type="submit" type="success" class="btn-fill mr-4">
        Update
      </base-button>
      <base-button type="default" class="btn-fill" @click="$router.go(-1)">
        Back
      </base-button>
      </div>
    </form>
  </card>
</template>

<script>
import {
  TimeSelect,
  DatePicker,
  Select,
  Option
} from 'element-ui';

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      agent: {},

      t_numbers: {
        simple: '',
        numbers: [],
      },
      modelValidations: {

        email: {
          required: true,
          email: true
        },
      }
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    updateProfile() {
      this.$validator.validateAll().then(isValid => {
        if (isValid) {
          this.$insProgress.start();
          this.$store.dispatch("agent/update", this.agent).then((response) => {
            //console.log(response);
            this.$insProgress.start();
            this.$router.push('/agents/index');
            this.$insProgress.finish();
            this.$notify({
              timeout: 2000,
              verticalAlign: 'top',
              horizontalAlign: 'right',
              message: 'Your record updated successfully!',
              icon: 'tim-icons icon-check-2',
              type: 'success',
            });
          });
        }
      });
    },
    Viewagent() {
      this.$insProgress.start();
      this.$store.dispatch('agent/getagent', +this.$route.params.id).then((response) => {
        //console.log(response);
        this.agent = response.data.data;
        // this.agent.country = parseInt(this.agent.country);
        //console.log(this.agent);
        this.$insProgress.finish();
      });
    },
    twillio_nbr_fetch() {
      this.$insProgress.start();
      this.$store.dispatch("general/twillio_numbers").then((response) => {
        //console.log(response);
        this.t_numbers.numbers = response.data.data;
        this.$insProgress.finish();
      }).catch((e) => {
        this.$insProgress.finish();
      });
    },

  },
  created() {
    this.Viewagent();
    this.twillio_nbr_fetch();
  },

};
</script>

<style>

h3.title {
  font-weight: 700 !important;
  color: #f16925 !important;
  font-family: Inter;
  font-size: 20px;
  letter-spacing: 2px;
}

.el-input__prefix, .el-input__suffix {
  top: 0 !important;
}

.el-select.select-primary.el-select--large {
  width: 100%;
  padding-left: 0 !important;
}

.el-select.select-primary.el-select--large input {
  color: #222a42 !important;
  border-color: rgba(29, 37, 59, 0.5);
  border-radius: 0.4285rem;
  font-size: 12px;
}

.el-select.select-primary.el-select--large input:hover {
  color: #222a42;
  border-color: rgba(29, 37, 59, 0.5) !important;
}
.select-primary.el-select input{
  border-color: #a6a6a6 !important;
}
.el-input.el-input--large.el-input--suffix:hover{
  color: #222a42 !important;
  border-color: rgba(29, 37, 59, 0.3) !important;
  border-radius: 0.4285rem !important;
}
</style>
