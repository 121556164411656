<template>
<card>
    <h3 slot="header" class="title">Edit Deal</h3>
    <div class="Queue">
        <form>
            <div class="row">
                <div class="col-md-4">
                    <base-input type="text" label="Title" placeholder="Title" v-model="user.TITLE">
                    </base-input>
                </div>
                <div class="col-md-4">
                    <base-input type="email" label="Email address" placeholder="email" name="email" v-validate="modelValidations.email" v-model="user.email" :error="getError('email')">
                    </base-input>
                </div>
                <div class="col-md-4">
                    <base-input type="tel" label="Phone" placeholder="phone" name="phone" v-validate="modelValidations.phone" v-model="user.phone" :error="getError('phone')">
                    </base-input>
                </div>
            </div>
            <div class="row">
                <div v-for="field in fields" :key="field.id" class="col-md-4">
                    <base-input :label="field.label" :placeholder="field.label" v-model="field.value">
                    </base-input>
                </div>
            </div>
            <div class="col-md-6 pt-4">
                <base-button native-type="submit" @click.prevent="editlead" type="success" class="btn-fill">
                    Update
                </base-button>
                <base-button type="default" class="btn-fill" @click="$router.go(-1)">
                    Back
                </base-button>
            </div>
        </form>
    </div>
</card>
</template>

<script>
export default {
    data() {
        return {
            user: {

            },
            modelValidations: {

                email: {
                    required: true
                },
                phone: {
                    required: true
                }
            },
            fields: []
        };
    },
    methods: {

        getError(fieldName) {
            return this.errors.first(fieldName);
        },

        editlead() {
            this.$validator.validateAll().then(isValid => {
                if (isValid) {
                    var data = {
                        "id": this.user.id,
                        "TITLE": this.user.TITLE,
                        "terms": [{
                                "type_id": "PHONE",
                                "value": this.user.phone,
                                "value_type": "WORK"
                            },
                            {
                                "type_id": "EMAIL",
                                "value": this.user.email,
                                "value_type": "WORK"
                            }
                        ]
                    };
                    this.fields.map((field) => {
                        data[field.title] = field.value;
                    });
                    this.$insProgress.start();
                    this.$store.dispatch("deal/update", data).then((response) => {
                        //console.log(response);
                        this.$router.push('/deals/index');
                        this.$insProgress.finish();
                        this.$notify({
                            timeout: 2000,
                            verticalAlign: 'top',
                            horizontalAlign: 'right',
                            message: 'Your record Updated successfully!',
                            icon: 'tim-icons icon-check-2',
                            type: 'success',
                        });
                    });
                }
            });
        },

        ViewDeal() {
            this.$store.dispatch('deal/getdeal', +this.$route.params.id).then((response) => {
                this.$insProgress.finish();
                //console.log(response);
                var lead = response.data.data;
                var phone = lead.terms.find(term => term.type_id === 'PHONE');
                var email = lead.terms.find(term => term.type_id === 'EMAIL');
                //console.log("Email", email);
                this.user = lead;
                this.fields = lead.fields;
                this.user.phone = phone.value;
                this.user.email = email.value;
                // //console.log(this.user);
            });
        },
    },
    created() {
        this.$insProgress.start();
        this.ViewDeal();
    }
};
</script>
<style>
.col-md-4 {
    margin-top: 10px;
}
</style>