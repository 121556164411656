<template>
  <div class="notesMain">
    <div class="notesMainInner">
      <div class="">
        <div class="inputFlexInner">
          <label>Subject</label>
          <div class="inputSvg">
            <p>
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="mail_subject" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                    <path class="st0" d="M27.1,9.4L16.3,20.2c-0.8,0.8-1.7,1.3-2.8,1.7l-3.9,1.2c0,0-0.6-0.3-0.7-0.7l1.2-3.9c0.3-1.1,0.9-2,1.7-2.8  L22.6,5l1.7-1.7l2.1-2.1c0.1-0.1,0.4-0.1,0.6,0l3.9,3.9c0.2,0.2,0.2,0.4,0,0.6l-2.1,2.1L27.1,9.4z"/>
                <path class="st0" d="M1,28.8c1.4-0.3,3.9-0.6,6.8,0c1.8,0.4,3,1,3.6,1.3c2.3,1,6.2,1.6,13.1-0.6"/>
                    </svg>
            </p>
            <input type="text" label="Subject" placeholder="Thank You For Choosing MBANC">
          </div>
        </div>
        <div class="messageMain">
          <label>Message</label>
          <div class="messageBox">
            <div class="messageInner">
              <div class="messageHeader">
                <a href="#">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="bold" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                        <line class="st0" x1="31" y1="31" x2="1" y2="31"/>
                    <g>
                          <path class="st1" d="M5.6,27.3v-27h10.8c2,0,3.6,0.3,5,0.9c1.3,0.6,2.3,1.4,3,2.4c0.7,1,1,2.2,1,3.6c0,1.1-0.2,2-0.6,2.8   c-0.4,0.8-1,1.4-1.7,2c-0.7,0.5-1.6,0.9-2.5,1.1v0.3c1,0,2,0.3,2.9,0.9c0.9,0.5,1.6,1.3,2.2,2.3c0.6,1,0.8,2.1,0.8,3.4   c0,1.4-0.4,2.7-1.1,3.8s-1.8,2-3.1,2.7s-3.1,1-5.1,1H5.6z M11.3,11.6h4.2c0.8,0,1.5-0.1,2.1-0.4c0.6-0.3,1.1-0.7,1.5-1.2   s0.5-1.1,0.5-1.8c0-1-0.3-1.8-1-2.4c-0.7-0.6-1.7-0.9-2.9-0.9h-4.3V11.6z M11.3,22.6h4.7c1.6,0,2.8-0.3,3.5-0.9   c0.7-0.6,1.1-1.4,1.1-2.4c0-0.7-0.2-1.4-0.5-2c-0.4-0.6-0.9-1-1.5-1.3c-0.7-0.3-1.5-0.5-2.4-0.5h-4.8V22.6z"/>
                        </g>
                        </svg>
                </a>
                <a href="#">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="italic" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                        <line class="st0" x1="31" y1="31" x2="1" y2="31"/>
                    <path class="st1" d="M24.8,0.3h-4.2h-4.1h-5.9c-0.2,0-0.4,0.2-0.4,0.4v3.3c0,0.2,0.2,0.4,0.4,0.4h5.2l-3.1,18.9H7.2  c-0.2,0-0.4,0.2-0.4,0.4v3.3c0,0.2,0.2,0.4,0.4,0.4H12h4.1h5.3c0.2,0,0.4-0.2,0.4-0.4v-3.3c0-0.2-0.2-0.4-0.4-0.4h-4.7l3.1-18.9h4.9  c0.2,0,0.4-0.2,0.4-0.4V0.7C25.2,0.5,25,0.3,24.8,0.3z"/>
                        </svg>
                </a>
                <a href="#">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="underline" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                        <line class="st0" x1="31" y1="31" x2="1" y2="31"/>
                    <g>
                          <path class="st1" d="M22.5,0.3h4v17.3c0,1.8-0.4,3.5-1.3,4.9c-0.9,1.4-2.1,2.5-3.7,3.3c-1.6,0.8-3.4,1.2-5.5,1.2   c-2.1,0-3.9-0.4-5.5-1.2s-2.8-1.9-3.7-3.3c-0.9-1.4-1.3-3.1-1.3-4.9V0.3h4v17c0,1.2,0.3,2.3,0.8,3.2c0.5,0.9,1.3,1.7,2.2,2.2   s2.1,0.8,3.4,0.8c1.3,0,2.5-0.3,3.5-0.8c1-0.5,1.7-1.3,2.2-2.2c0.5-0.9,0.8-2,0.8-3.2V0.3z"/>
                        </g>
                        </svg>
                </a>
                <a href="#">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="format_x5F_list_x5F_numbers" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                        <g>
                          <line class="st0" x1="31" y1="21" x2="13" y2="21"/>
                          <line class="st0" x1="31" y1="11" x2="13" y2="11"/>
                          <line class="st0" x1="31" y1="31" x2="13" y2="31"/>
                          <line class="st0" x1="31" y1="1" x2="13" y2="1"/>
                        </g>
                    <g>
                          <path class="st1" d="M6.1,1v9.9H4.1V3H4L1.7,4.4V2.6L4.2,1H6.1z"/>
                        </g>
                    <g>
                          <path class="st1" d="M1.8,31v-1.5l3.5-3.3c0.3-0.3,0.5-0.5,0.8-0.8c0.2-0.2,0.4-0.5,0.5-0.7c0.1-0.2,0.2-0.5,0.2-0.7   c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.4-0.5-0.5s-0.5-0.2-0.8-0.2c-0.3,0-0.6,0.1-0.8,0.2C4.2,23,4,23.1,3.9,23.4   c-0.1,0.2-0.2,0.5-0.2,0.8h-2c0-0.6,0.1-1.2,0.4-1.7c0.3-0.5,0.7-0.8,1.2-1.1C3.9,21.1,4.6,21,5.2,21c0.7,0,1.3,0.1,1.8,0.4   c0.5,0.2,0.9,0.6,1.2,1c0.3,0.4,0.4,0.9,0.4,1.5c0,0.4-0.1,0.7-0.2,1.1s-0.4,0.8-0.8,1.2s-0.9,1-1.6,1.6l-1.4,1.4v0.1h4.1V31H1.8z"/>
                        </g>
                        </svg>
                </a>
                <a href="#">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="format_x5F_list_x5F_bullets" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                        <g>
                          <line class="st0" x1="31" y1="21" x2="13" y2="21"/>
                          <line class="st0" x1="31" y1="11" x2="13" y2="11"/>
                          <line class="st0" x1="31" y1="31" x2="13" y2="31"/>
                          <line class="st0" x1="31" y1="1" x2="13" y2="1"/>
                        </g>
                    <circle class="st1" cx="5" cy="6" r="4"/>
                    <circle class="st1" cx="5" cy="26" r="4"/>
                        </svg>
                </a>
                <a href="#">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="left" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                        <line class="st0" x1="21" y1="21" x2="1" y2="21"/>
                    <line class="st0" x1="26" y1="11" x2="1" y2="11"/>
                    <line class="st0" x1="31" y1="31" x2="1" y2="31"/>
                    <line class="st0" x1="14" y1="1" x2="1" y2="1"/>
                        </svg>
                </a>
                <a href="#">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="center" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                        <g>
                          <line class="st0" x1="26" y1="21" x2="6" y2="21"/>
                          <line class="st0" x1="28.5" y1="11" x2="3.5" y2="11"/>
                          <line class="st0" x1="31" y1="31" x2="1" y2="31"/>
                          <line class="st0" x1="22.5" y1="1" x2="9.5" y2="1"/>
                        </g>
                        </svg>
                </a>
                <a href="#">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="justify" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                        <line class="st0" x1="31" y1="21" x2="1" y2="21"/>
                    <line class="st0" x1="31" y1="11" x2="1" y2="11"/>
                    <line class="st0" x1="31" y1="31" x2="1" y2="31"/>
                    <line class="st0" x1="31" y1="1" x2="1" y2="1"/>
                        </svg>
                </a>
                <a href="#">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="url" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                        <g>
                          <path class="st0" d="M19.9,23.9h3.2c4.3,0,7.9-3.6,7.9-7.9s-3.6-7.9-7.9-7.9h-3.2"/>
                          <path class="st0" d="M12.1,23.9H8.9C4.6,23.9,1,20.3,1,16s3.6-7.9,7.9-7.9h3.2"/>
                          <line class="st0" x1="24.3" y1="16.2" x2="7.7" y2="16.2"/>
                        </g>
                        </svg>
                </a>
                <a href="#">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="image" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                        <rect x="1.1" y="1.1" class="st0" width="29.8" height="29.8"/>
                    <circle class="st1" cx="8.6" cy="8.6" r="2.6"/>
                    <path class="st1" d="M4.3,30.9L9.4,20c0.2-0.4,0.8-0.6,1.2-0.3l4.8,4.6c0.3,0.3,0.8,0.2,1.1-0.2l5.7-8.6c0.3-0.3,0.8-0.4,1.1-0.1  l7.6,7"/>
                        </svg>
                </a>
                <a href="#">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="table" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                        <rect x="1.1" y="1.1" class="st0" width="29.8" height="29.8"/>
                    <line class="st1" x1="1.1" y1="7.3" x2="30.9" y2="7.3"/>
                    <line class="st1" x1="1.1" y1="19.1" x2="30.9" y2="19.1"/>
                    <g>
                          <line class="st1" x1="10.9" y1="7.3" x2="10.9" y2="30.9"/>
                      <line class="st1" x1="21.1" y1="7.3" x2="21.1" y2="30.9"/>
                        </g>
                        </svg>
                </a>
              </div>
              <div class="messageBody">
                <p>Hi Raquel,</p>
                <p>Thank you for allowing us to join you on your mortgage journey. We received your online inquiry and wanted to say thank you for choosing us. I can assure you; we take this seriously.</p>
                <p>These days, every time you buy something, it’s always about the money and little to no attention placed on the customer, you Raquel</p>
                <p>Raquel, thank you again for your inquiry and giving us a chance to prove ourselves to you.</p>
                <a href="#">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 300 40" xml:space="preserve"><g id="logo"><path d="M6.3,14.9h5.6v2.5h0.1c0.8-1.4,2.7-3,5.5-3c2.6,0,4.8,1.1,5.8,3.4c1.4-2.4,3.2-3.4,5.9-3.4   c5.1,0,6.7,3.6,6.7,8.2v11.1H30v-11c0-1.7-0.7-3.2-2.5-3.2c-2.7,0-3.5,1.9-3.5,4.2v10h-5.8v-9.9c0-1.4,0.1-4.2-2.6-4.2   c-3,0-3.5,2.3-3.5,4.8v9.4H6.3V14.9z" class="st0"></path><path d="M40,4.5h5.8v12.4h0.1c1.3-1.8,3.6-2.5,5.9-2.5c5.3,0,8.6,4.8,8.6,10c0,5.7-3.6,9.7-9.2,9.7   c-2.6,0-4.9-1.6-5.8-3h-0.1v2.5H40L40,4.5z M50,29.1c2.9,0,4.6-1.9,4.6-4.7s-1.7-4.7-4.6-4.7c-2.9,0-4.6,1.9-4.6,4.7   C45.3,27.2,47.1,29.1,50,29.1z" class="st1"></path><path d="M75.2,31.4L75.2,31.4c-1.4,2-3.6,2.8-6,2.8c-3.5,0-6.7-2-6.7-5.8c0-6.5,7.9-6.6,12.7-6.6   c0-2.1-1.7-3.4-3.7-3.4c-1.9,0-3.5,0.8-4.7,2.3l-3.1-3.2c2.1-2,5.2-3,8.2-3c6.7,0,8.6,3.4,8.6,9.7v9.6h-5.4V31.4z M73.8,25.5   c-1.8,0-5.7,0.1-5.7,2.7c0,1.4,1.4,2.1,2.8,2.1c2.3,0,4.4-1.2,4.4-3.5v-1.2L73.8,25.5z" class="st1"></path><path d="M83.8,14.9h5.6v2.5h0.1c0.8-1.4,2.7-3,5.5-3c5.8,0,6.9,3.9,6.9,8.9v10.4h-5.8v-9.3c0-2.2-0.1-5-3-5   c-3,0-3.5,2.3-3.5,4.8v9.3h-5.8L83.8,14.9z" class="st1"></path><path d="M118.3,21c-0.5-0.8-1.6-1.4-2.9-1.4c-2.9,0-4.6,1.9-4.6,4.7s1.7,4.7,4.6,4.7c1.3,0,2.2-0.7,2.9-1.4l3.9,4.1   c-1.8,1.9-4.5,2.5-6.7,2.5c-5.8,0-10.4-3.8-10.4-9.9s4.6-9.9,10.4-9.9c2.2,0,4.9,0.7,6.7,2.5L118.3,21z" class="st1"></path></g>
                        </svg>
                </a>
                <p>Mortgage Bank of California</p>
              </div>
            </div>
          </div>
          <div class="messageButtonsMain">
            <div class="messageButtonInner">
              <div class="messageFileInfo">
                <a href="#" class="linkBin">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="attachment" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                        <path class="st0" d="M26.5,18.4L14.8,30.1c-1,1-2.7,1-3.8,0l-7.3-7.3c-1-1-1-2.7,0-3.8L20.8,1.9c1-1,2.7-1,3.8,0l3.8,3.8  c1,1,1,2.7,0,3.8l-4,4l-8.6,8.6c-1,1-2.7,1-3.8,0l0,0c-1-1-1-2.7,0-3.8l8.6-8.6"/>
                        </svg>
                </a>
                <p class="fileName">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="attachment" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                        <path class="st0" d="M26.5,18.4L14.8,30.1c-1,1-2.7,1-3.8,0l-7.3-7.3c-1-1-1-2.7,0-3.8L20.8,1.9c1-1,2.7-1,3.8,0l3.8,3.8  c1,1,1,2.7,0,3.8l-4,4l-8.6,8.6c-1,1-2.7,1-3.8,0l0,0c-1-1-1-2.7,0-3.8l8.6-8.6"/>
                        </svg>
                  <span>0</span>Attachments
                </p>
              </div>
              <a href="#" class="linkBin">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="discard" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                      <polyline class="st0" points="26.9,6.9 26.9,30.9 5.1,30.9 5.1,6.9 "/>
                  <line class="st0" x1="21" y1="14" x2="21" y2="24.6"/>
                  <line class="st0" x1="16" y1="14" x2="16" y2="24.6"/>
                  <line class="st0" x1="10" y1="14" x2="10" y2="24.6"/>
                  <line class="st0" x1="1" y1="6.9" x2="31" y2="6.9"/>
                  <rect x="9.7" y="1.1" class="st0" width="12.6" height="5.8"/>
                      </svg>
              </a>
            </div>
            <div class="messageButtonInner">
              <a href="#">
                <button>Send
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="email_send" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                        <g>
                          <path class="st0" d="M1.1,11.1L30.9,1L21,31l-6.1-12.2c-0.4-0.7-1-1.3-1.7-1.7L1.1,11.1z"/>
                          <line class="st0" x1="30.1" y1="1.8" x2="14.3" y2="17.6"/>
                        </g>
                        </svg>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: "email-tab",
  components: {
    InfiniteLoading,
  },
  props: {
    object: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      deal_notes: [],
      page: 1,
      infinIdnote: +new Date(),
    };
  },
  watch: {
    object: {
      handler(newValue, oldValue) {
        this.page = 1
        this.deal_notes = [];
        this.infinIdnote = +new Date();
      },
      deep: true
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.user = this.$store.getters["api/user"];
    },
    fetchNotification() {
      this.$insProgress.start();
      var data = {};
      data.parent_id = this.object.id;
      data.parent_type = this.type;
      data.page = this.page;
      this.$store.dispatch("combined_notification/get_notes_list", data).then((response) => {
        console.log(response.data);
        let fetchedNotifications = response.data.data;
        if (fetchedNotifications.length > 0) {
          this.deal_notes.push(...fetchedNotifications);
          console.log(this.deal_notes);
          this.page++;
          this.infinIdnote = +new Date();
          this.$refs.infiniteLoading.$emit('$InfiniteLoading:loaded')
        } else {
          this.$refs.infiniteLoading.$emit('$InfiniteLoading:complete')
        }
        this.$insProgress.finish();
      }).catch((e) => {
        this.$insProgress.finish();
      });
    },
    infiniteHandler($state) {
      this.fetchNotification();
      return true;
    },
    date_format(date_string) {
      const date = new Date(date_string);
      return date.toLocaleTimeString("en-US") + " " + (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1).toString() : date.getMonth() + 1) + "-" + (date.getDate() + 1 < 10 ? "0" + (date.getDate() + 1).toString() : date.getDate() + 1) + "-" + date.getFullYear();
    },
    splitter(type) {
      return type.split("\\").pop();
    },
  },
};
</script>
<style scoped>

.call-Log-main .call-log-detail {
  display: flex;
  gap: 20px;
  align-items: center;
  border: 1px solid #e2e8f0;
  padding: 5px;
  background-color: white;
  border-radius: 6px;
  margin: 10px 0px;
}

.call-Log-main .call-log-detail svg {
  width: 24px;
  height: 24px;
}

.call-Log-main .time-details {
  color: #a0a0a0;
  font-size: 11px;
}

.call-log-name {
  color: #ed8936;
}

.call-log-status {
  color: #124678;
}
.call-Log-content p {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
}

.call-log-detail .st1 {
  display: inline;
  fill: none;
  stroke: #ed8936;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.call-log-detail .st4 {
  fill: none;
  stroke: #ed8936;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}



.messageMain .messageHeader {
  background-color: #f3f3f3;
  padding: 10px 15px;
  border-bottom: 1px solid #dedede;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.messageMain .messageBox {
  border: 1px solid #dedede;
  border-radius: 12px;
}

.messageMain .messageBody {
  padding: 30px 15px;
}

.messageMain .messageHeader svg {
  width: 20px;
  height: 20px;
}

.messageMain {
  margin-top: 20px;
}

.messageBody p {
  margin-bottom: 15px;
}

.messageBody svg#Layer_1 {
  width: 70%;
  height: 100%;
}

.messageBody svg#Layer_1 path.st0 {
  fill: #f16925;
}

.messageBody svg#Layer_1 path.st1 {
  fill: #245482;
}

.notesMain .inputSvg input[type="text"]::placeholder {
  color: #245482;
}

.messageHeader #bold .st0 {
  fill: none;
  stroke: #afb1ba;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.messageHeader #bold .st1 {
  fill: #afb1ba;
}

.messageHeader #italic .st0 {
  fill: none;
  stroke: #afb1ba;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.messageHeader #italic .st1 {
  fill: #afb1ba;
}

.messageHeader #underline .st0 {
  fill: none;
  stroke: #afb1ba;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.messageHeader #underline .st1 {
  fill: #afb1ba;
}

.messageHeader #format_x5F_list_x5F_numbers .st0 {
  fill: none;
  stroke: #afb1ba;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.messageHeader #format_x5F_list_x5F_numbers .st1 {
  fill: #afb1ba;
}

.messageHeader #format_x5F_list_x5F_bullets .st0 {
  fill: none;
  stroke: #afb1ba;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.messageHeader #format_x5F_list_x5F_bullets .st1 {
  fill: none;
  stroke: #afb1ba;
  stroke-width: 2;
  stroke-miterlimit: 10;
}

.messageHeader #left .st0 {
  fill: none;
  stroke: #245482;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.messageHeader #center .st0 {
  fill: none;
  stroke: #afb1ba;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.messageHeader #justify .st0 {
  fill: none;
  stroke: #afb1ba;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.messageHeader #url .st0 {
  fill: none;
  stroke: #afb1ba;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.messageHeader #image .st0 {
  fill: none;
  stroke: #afb1ba;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.messageHeader #image .st1 {
  fill: none;
  stroke: #afb1ba;
  stroke-width: 2;
  stroke-miterlimit: 10;
}

.messageHeader #table .st0 {
  fill: none;
  stroke: #afb1ba;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.messageHeader #table .st1 {
  fill: none;
  stroke: #afb1ba;
  stroke-width: 2;
  stroke-miterlimit: 10;
}


.notesMain #mail_subject .st0 {
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}


.messageButtonsMain {
  display: grid;
  margin-top: 20px;
  align-items: center;
  grid-template-columns: 1fr auto;
  gap: 20px;
  justify-content: space-between;
}

.messageButtonsMain #attachment .st0 {
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.messageButtonsMain #discard .st0 {
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.fileName #attachment .st0 {
  fill: none;
  stroke: #8c8d97;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.linkBin {
  background-color: #8c8d97;
  height: 50px;
  width: 50px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.messageButtonsMain svg {
  width: 26px;
  height: 25px;
}

.fileName svg {
  width: 18px;
  height: 18px;
}

.fileName {
  display: flex;
  align-items: center;
  gap: 10px;
}

.messageButtonInner button {
  background-color: #124678;
  height: 50px;
  width: 120px;
  gap: 10px;
  font-size: 18px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.messageButtonInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#email_send .st0 {
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.messageFileInfo {
  display: flex;
  gap: 20px;
  align-items: center;
}
</style>

