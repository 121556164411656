<template>
  <div class="content leads-content">
    <div class=" leads-content" style="">
      <div class="row">
        <div class="col-12 pt-0 leads-col">
          <div class="card" card-body-classes="table-full-width">
            <!---->
            <div class="card-header top-leads responsive-leads">
              <div class="row justify-content-between alig-items-center pl-4 pr-4">

                <div class="leads_left">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                       x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;"
                       xml:space="preserve">
                                    <g id="agents_inner">
                                        <g>
                                            <ellipse class="st4" cx="10.6" cy="6.7" rx="6.1" ry="5.6"/>
                                          <ellipse class="st4" cx="24.5" cy="7.5" rx="2.9" ry="2.7"/>
                                          <path class="st4"
                                                d="M20.9,15.9c0.4-1.3,1.5-2.9,4.1-2.9c3.2,0,4.1,3,4.4,4.5c0.1,0.4,0.2,0.9,0.3,1.3c0.2,1.1,0,3.7-6.8,3.7"/>
                                          <path class="st4"
                                                d="M2.2,25.6l0-0.3c0-2.3,0.6-4.6,1.9-6.4c1.3-1.8,3.2-3.2,6.3-3.2c3.1,0,5.1,1.5,6.4,3.3c1.4,1.8,2,4.2,2,6.6v0    c0,1.5-0.8,3-2,3.6c-2.7,1.5-7.7,3.2-12.9,0C2.9,28.4,2.2,27,2.2,25.6z"/>
                                        </g>
                                    </g>
                                    </svg>
                  <h4 class="card-title pull-left">Employees </h4>
                </div>


                <!---->
                <div class="pull-right form-pull">
                  <!---->
                  <div class=" search-input el-input el-input--prefix el-input--suffix">
                    <div class="searchbarb agentSearch">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                      </svg>
                    </div>
                    <input type="search"
                           name="search"
                           autocomplete="off"
                           placeholder="Cameron Gilroy"
                           aria-controls="datatables"
                           class="el-input__inner">
                    <!--<span class="el-input__prefix">
                       <i class="el-input__icon el-icon-search searchbarb"></i>
                    </span>-->
                  </div>
                  <!---->
                  <span class="add-btn-main">
                                          <!--  <div class="add-leads-btn ml-2">
                                                <button type="button" class="mb-3 pull-right btn btn-simple btn-linkedin mt-0" @click="agent_fetch">Search</button>
                                            </div> -->
                                            <div class="add-leads-btn">
                                                <router-link :to="{ name :'Add Agent'}" class="nav-item dropdown-item">
                                                <button type="button"
                                                        class=" mb-3 pull-right btn btn-simple btn-linkedin">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                                         viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                    <path stroke-linecap="round" stroke-linejoin="round"
                                                          d="M12 4v16m8-8H4"/>
                                                </svg> Add Employee</button></router-link>
                                            </div>
                                        </span>
                </div>
                <!---->


              </div>
            </div>
            <div class="card-body">

              <div class="box-table">
                <div class="Table">
                  <div class="Heading">
                    <div class="Cell">
                      <div class="d-flex empl-box">
                        <p>Employee</p>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor" stroke-width="2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7"/>
                        </svg>
                      </div>
                    </div>
                    <div class="Cell">
                      <p>Position</p>
                    </div>
                    <div class="Cell">
                      <p>Email</p>
                    </div>
                    <div class="Cell">
                      <p>Cell</p>
                    </div>
                    <div class="Cell">
                      <p>Work</p>
                    </div>
                    <div class="Cell">
                      <p>Ext</p>
                    </div>
                    <div class="Cell">
                      <p>Edit</p>
                    </div>
                  </div>
                  <div class="Row table-content" v-for="agent in agents" :key="agent.id">
                    <div class="Cell agents_page_cell">
                      <div class="d-flex right-inner-user">

                        <img
                          :src="agent !== undefined && agent.image !== null ? agent.image :
                          '/img/mike.jpg'">
                        <p>{{ agent.name }}</p>
                      </div>
                    </div>
                    <div class="Cell agent-user">
                      <p>{{ agent.WORK_POSITION }}</p>
                    </div>
                    <div class="Cell">
                      <p>{{ agent.email }}</p>
                    </div>
                    <div class="Cell">
                      <p>{{ agent.phone }}</p>
                    </div>
                    <div class="Cell">
                      <p>{{ agent.WORK_PHONE }}</p>
                    </div>
                    <div class="Cell">
                      <p>{{ agent.UF_PHONE_INNER }}</p>
                    </div>
                    <div class="Cell">
                      <p>
                        <!-- <router-link :to="{ name :'View Agent', params:{id:agent.id}}"><button data-v-01e1f50f="" type="button" class="btn edit btn-link btn-icon btn-fab btn-warning btn-sm">
                                <i data-v-01e1f50f="" class="fas fa-eye"></i>
                            </button></router-link> -->
                        <router-link :to="{ name :'Edit Agent', params:{id:agent.id}}">
                          <button data-v-01e1f50f="" type="button"
                                  class="btn edit btn-link btn-icon btn-fab btn-warning btn-sm">
                            <!---->
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor" stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"/>
                            </svg>
                          </button>
                        </router-link>
                        <!-- <button data-v-01e1f50f="" type="button" class="btn remove btn-link btn-icon btn-fab btn-danger btn-sm" @click="agent_delete(agent.id)">
                            <i data-v-01e1f50f="" class="tim-icons icon-simple-remove"></i>
                        </button> -->
                      </p>
                    </div>
                  </div>

                </div>

                <div class="el-table__column-resize-proxy" style="display: none"></div>
                <div class="card-footer">
                  <div class="row">
                    <div class="col-12">
                      <p class="card-category" style="float:left;">
                        Showing {{ result.from }} to {{ result.to }} of {{ result.total }} entries
                      </p>
                      <pagination style="float:right;" :data="result" @pagination-change-page="agent_fetch" :limit="1">
                        <span slot="prev-nav">&lt; Previous</span>
                        <span slot="next-nav">Next &gt;</span>
                      </pagination>
                    </div>

                  </div>
                  <!---->
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { RingLoader } from 'vue-spinners-css';

import swal from "sweetalert2";
import config from "@/config";
import {
  Collapse,
  CollapseItem
} from 'src/components';

export default {
  components: {
    Collapse,
    CollapseItem
  },

  data() {
    return {
      agents: {},
      result: {},
      search: ''
    }
  },
  methods: {
    //this method is use is pagination

    agent_fetch(page = 1) {
      this.$insProgress.start();
      var data = {};
      if (this.search !== undefined && this.search !== '') {
        data = {
          page: page,
          search: this.search
        }
      } else {
        data = {
          page: page,
        }
      }
      this.$store.dispatch("agent/fetch", data).then((response) => {
        //console.log(response);
        this.agents = response.data.data.data;
        this.result = response.data.data;
        this.$insProgress.finish();
      }).catch((e) => {
        this.$insProgress.finish();
      });
    },
    date_format(date_string) {
      const date = new Date(date_string);
      return date.toLocaleTimeString('en-US') + ' ' + (((date.getMonth() + 1) < 10 ? ('0' + (date.getMonth() + 1).toString()) : (date.getMonth() + 1))) + '-' + (((date.getDate() + 1) < 10 ? ('0' + (date.getDate() + 1).toString()) : (date.getDate() + 1))) + '-' + date.getFullYear();
    },
    agent_delete(id) {
      swal({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.$insProgress.start();
          this.$store.dispatch("agent/delete", id).then((response) => {
            this.$insProgress.start();
            this.agent_fetch();
            this.$insProgress.finish();
            this.$notify({
              timeout: 2000,
              verticalAlign: 'top',
              horizontalAlign: 'right',
              message: 'Your record deleted successfully!',
              icon: 'tim-icons icon-check-2',
              type: 'success',
            });
          }).catch((e) => {
            this.$insProgress.finish();
          });

        }
      }).catch((e) => {
        this.$insProgress.finish();
      });
    },

  },

  created() {

    this.agent_fetch();
  },
};
</script>

<style scoped>
@media screen and (max-width: 991px){

  .search-input {
    margin-bottom: 15px;
    width: 100% !important;
  }
}


.searchbarb {
  background-color: #245482;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  width: 60px;
  font-size: 20px;
  color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.agentSearch{
  height: 100% !important;
}

.btn.btn-linkedin.btn-simple {
  color: #fff;
  background-color: #124678 !important;
  background-image: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #00669c;
  margin: 0 !important;
}

.top-leads .col-12.pt-0 .el-input input {
  height: 43px;
}
.search-input{
  height: 42px;
}

.btn.btn-linkedin.btn-simple:hover, .btn.btn-linkedin.btn-simple:focus, .btn.btn-linkedin.btn-simple:active {
  color: #ffffff;
  border-color: #416b93;
}

.box-table .Heading .Cell:first-child p {
  margin-left: 60px;
}

/* .Heading .Cell p {
    font-size: 14px !important;
} */
.table-content .Cell p {
  font-size: 14px;
}

.agent-user p {
  color: #f16925 !important;
}

.Row.table-content .Cell {
  padding: 15px 5px;
}

.btn-warning.btn-link {
  color: #124678;
}

.btn-warning.btn-link svg {
  height: 18px;
  width: 18px;
}

.btn-warning.btn-link {
  border: 1px solid #f3f3f3;
  border-radius: 20px;
}

.Cell.agents_page_cell p {
  font-weight: bold;
}

.empl-box svg {
  width: 20px;
  color: #6f8fad;
}

.empl-box {
  gap: 4px;
}

.card .card-header {
  padding: 40px 15px;
}


span.el-input__prefix {
  left: 0px;
}

.searchbar-btn {
  height: 40px !important;
}

.btn.btn-linkedin.btn-simple {
  display: flex;
}

.add-leads-btn button svg {
  border: 2px solid #fff;
  border-radius: 20px;
}

.white-content .btn.btn-link:hover, .white-content .btn.btn-link:active, .white-content .btn.btn-link:focus {
  color: #f16925 !important;
}


.search-input {
  display: flex;
  align-items: center;
  height: 42px;
  width: 250px;
}

.search-input input {
  padding: 10px !important;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 100%;
}

.agents_page_cell img {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 100%;
}

</style>
